import React from "react";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../components/Breadcrumb";
import ReactSelect from "../../../components/reactSelect/ReactSelect";

const AddInterview = () => {
  const breadcrumbItems = [
    { label: "Foray", link: "/" },
    { label: "Human Resource Management", link: "/" },
    { label: "Employee Setup", link: "/" },
    { label: "Prospective", isCurrent: true },
  ];
  return (
    <>
      <DashboardWrapper>
        <div className="p-6">
          <div className="space-y-7 mb-6">
            <Breadcrumb items={breadcrumbItems} />
            <h4 className=" text-secondary font-semibold text-xl">
              Add Prospective{" "}
            </h4>
          </div>
          <div className="bg-white p-6 rounded-xl">
            <h2 className="text-xl text-secondary font-semibold mb-9">
              Fill Out The Information{" "}
            </h2>

            <div className="grid grid-cols-2 gap-x-7 gap-y-9 mb-9">
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  First Name*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Last Name*{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <ReactSelect
                  label="Classroom*"
                  placeholder="Classroom* "
                  required
                />
              </div>
              <div>
                <ReactSelect
                  label="Job Title*"
                  placeholder="Job Title "
                  required
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Email
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Mobile Number{" "}
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
            </div>

            <div className="flex items-center gap-3">
              <button className="btnPrimary max-w-[181px]">Save</button>
              <button className="btnSimple max-w-[181px]">Cancel</button>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default AddInterview;
