import * as React from "react";
import { IoCloseOutline } from "react-icons/io5";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";
import { NewClassroom } from "../../types/IClassroomSetup.interface";
import { IOptionS } from "../../types/ICenterSetup.interface";

interface ModalProps {
  classrooms: NewClassroom[];
  classroomOptions: IOptionS[];
  savingClassrooms: string;
  isOpen: boolean;
  onClose: () => void;
  onSave: () => Promise<void>;
}

const ReviewEssentialClassroomsModal: React.FC<ModalProps> = ({
  classrooms,
  classroomOptions,
  savingClassrooms,
  isOpen,
  onClose,
  onSave,
}) => {
  const reviewModalRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(reviewModalRef, onClose);
  // console.log("check:  ", classrooms)

  const handleTransitionClassroomName = (transitionClassroomId: string) => {
    return classroomOptions?.find(
      (item) => item?.value === transitionClassroomId
    )?.label;
  };

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div
        className="bg-white p-9 rounded-3xl shadow-lg w-[711px] "
        ref={reviewModalRef}
      >
        <div className="flex items-center justify-between mb-9">
          <h2 className="text-2xl font-semibold text-secondary">
            Review Classrooms-Essentials Setup
          </h2>
          <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700"
          >
            <IoCloseOutline className="w-6 h-6 text-secondary" />
          </button>
        </div>

        <p className="text-base text-secondary mb-9">
          <strong className="text-primary"> THIS IS VERY IMPORTANT. </strong>
          Please review this table carefully to make sure that all classroom
          age-groups are correct <strong>AND</strong> that all transition
          classrooms are correctly assigned. Please <strong>NOTE</strong> that
          the last classroom in <strong>Section 1</strong> and any or all
          classrooms in <strong>Section 2 </strong>
          are <strong>NOT</strong> required to have a transition classroom. You
          will be able to assign one later, however, if necessary. E.g. from
          Before/After Program to Summer Program, etc.
        </p>

        <div className="mb-9">
          <h3 className="text-lg text-secondary font-semibold mb-3">
            {savingClassrooms === "sequential"
              ? "Section 1: Age-Sequential Classrooms"
              : "Section 2: Special Classrooms"}
          </h3>
          <div className="border border-secondaryVariant2 rounded-lg max-h-[230px] overflow-y-scroll">
            <div className="grid grid-cols-12 text-base font-medium text-secondary bg-secondaryNeutral border-b border-secondaryVariant2 py-3 px-4">
              <div className="col-span-4">Classroom</div>
              <div className="col-span-2">Min Age</div>
              <div className="col-span-2">Max Age</div>
              <div className="col-span-4">Student Transitions To</div>
            </div>
            {classrooms?.map((classroom, index) => (
              <div className="grid grid-cols-12 text-base text-secondaryVariant py-3 px-4">
                <div className="col-span-4">{classroom?.classroomName}</div>
                <div className="col-span-2">
                  {classroom?.ageRange?.minimumAge?.ageInDays} Days
                </div>
                <div className="col-span-2">
                  {classroom?.ageRange?.maximumAge?.ageInDays} Days
                </div>
                {savingClassrooms === "sequential" ? (
                  <div className="col-span-4">
                    {(handleTransitionClassroomName(
                      classroom?.transition?.TransitionClassroom as string
                    ) ??
                      classrooms[index + 1]?.classroomName) ||
                      "None"}
                  </div>
                ) : (
                  <div className="col-span-4">
                    {handleTransitionClassroomName(
                      classroom?.transition?.TransitionClassroom as string
                    ) || "None"}
                  </div>
                )}
              </div>
            ))}
            {/* <div className="grid grid-cols-12 text-base text-secondaryVariant py-3 px-4">
                            <div className="col-span-4">06-12 Months</div>
                            <div className="col-span-2">148 Days</div>
                            <div className="col-span-2">156 Days</div>
                            <div className="col-span-4">12-18 Months</div>
                        </div>
                        <div className="grid grid-cols-12 text-base text-secondaryVariant py-3 px-4">
                            <div className="col-span-4">12-18 Months</div>
                            <div className="col-span-2">148 Days</div>
                            <div className="col-span-2">156 Days</div>
                            <div className="col-span-4">none</div>
                        </div> */}
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button className="btnPrimary max-w-[303px]" onClick={onSave}>
            Save Classroom-Essentials Data
          </button>
          <button
            className="btnSimple max-w-[135px] flex justify-center"
            onClick={onClose}
          >
            Back to Edit
          </button>
        </div>
      </div>
    </div>
  );
};

export default ReviewEssentialClassroomsModal;
