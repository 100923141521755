import React from "react";
import ReactSelect from "../../../components/reactSelect/ReactSelect";

const UnplannedPto = () => {
  return (
    <>
      <div className="flex items-center gap-6 mb-9">
        <button className="text-lg text-secondaryVariant rounded-lg p-3 bg-secondaryVariant2 font-medium hover:bg-primary hover:text-white">
          Absences
        </button>
        <button className="text-lg text-secondaryVariant rounded-lg p-3 bg-secondaryVariant2 font-medium hover:bg-primary hover:text-white">
          Tardies
        </button>
      </div>
      {/* when active tardies then show this div */}
      {/* <div>
        <h3 className="text-xl text-secondary font-semibold mb-3">
          Definition{" "}
        </h3>
        <p className="text-base text-secondary mb-3 font-medium">
          How long after scheduled arrival time should employee be marked
          “Tardy”?
        </p>

        <div className="flex items-center gap-2 mb-3">
          <input
            type="radio"
            className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
          />
          <label
            htmlFor=""
            className="text-xs font-medium text-secondaryVariant"
          >
            Immediately after scheduled arrival time{" "}
            <span className="text-primary">(Strongly recommended)</span>{" "}
          </label>
        </div>
        <div className="flex items-center gap-2 mb-3">
          <input
            type="radio"
            className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
          />
          <label
            htmlFor=""
            className="text-xs font-medium text-secondaryVariant"
          >
            Custom{" "}
          </label>
        </div>
        <div className="grid grid-cols-4 gap-4 mb-6">
          <div>
            <ReactSelect
              label={"Select Time unit"}
              placeholder={"Hours"}
              required
            />
          </div>
        </div>
      </div> */}
      <h3 className="text-xl text-secondary font-semibold mb-3">
        Complete absence on any given day
      </h3>
      <p className="text-base text-secondary mb-3">
        How long after scheduled arrival time should employee be marked “Absent”
        if not present at work?
      </p>
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div>
          <ReactSelect label={"Select No."} placeholder={"1-60"} required />
        </div>
        <div>
          <ReactSelect
            label={"Select Time unit"}
            placeholder={"Hours"}
            required
          />
        </div>
      </div>
      <h3 className="text-xl text-secondary font-semibold mb-3">Callout </h3>
      <p className="text-base text-secondary mb-3">
        Defined as less than the following time frame before shift start
      </p>
      <div className="grid grid-cols-4 gap-4 mb-6">
        <div>
          <ReactSelect label={"Select No."} placeholder={"1-60"} required />
        </div>
        <div>
          <ReactSelect
            label={"Select Time unit"}
            placeholder={"Hours"}
            required
          />
        </div>
      </div>
      <h3 className="text-xl text-secondary font-semibold mb-3">
        No call no show
      </h3>
      <p className="text-base text-secondary mb-3">
        Define time frame after scheduled arrival time regarding no
        communication for absence
      </p>
      <div className="grid grid-cols-4 gap-4 mb-9">
        <div>
          <ReactSelect label={"Select No."} placeholder={"1-60"} required />
        </div>
        <div>
          <ReactSelect
            label={"Select Time unit"}
            placeholder={"Hours"}
            required
          />
        </div>
      </div>
      <div className="flex items-center gap-4 mb-6">
        <button className="btnPrimary max-w-[120px]">Save</button>
        <button className="btnSimple max-w-[120px]">Cancel </button>
      </div>
    </>
  );
};

export default UnplannedPto;
