import React, { useEffect, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FiEdit3 } from "react-icons/fi";
import { HiOutlineTrash } from "react-icons/hi2";
import { Swiper, SwiperSlide } from "swiper/react";
import SelectColumnModal from "../modals/SelectColumnModal";
import { ICategory, Section } from "../../types/ICategories.interface";
import { useDeleteCategory } from "../../hooks/useDeleteCategory";

// type Item = {
//   id: string;
//   title: string;
//   time: string;
// };

type MappedCardsProps = {
  items: ICategory[] | undefined;
  handleSelected: (id: string) => void;
  openModal: (modalType: string) => void;
  selectedCard: ICategory;
  handleSelectedSection: (section: Section | undefined) => void;
};

const MappedCards: React.FC<MappedCardsProps> = ({
  items,
  handleSelected,
  openModal,
  selectedCard,
  handleSelectedSection,
}) => {
  const { mutate: deleteCategory, isSuccess } = useDeleteCategory();

  const handleDeleteCategory = (categoryId: string) => {
    if (categoryId) {
      deleteCategory(categoryId);
    } else {
      console.log("Category Id is not available");
    }
  };

  const handleClick = (id: string) => {
    handleSelected(id);
    // Removed handleSelectedSection(undefined) to allow parent component to handle section selection
  };

  useEffect(() => {
    if (isSuccess) {
      handleSelected("");
    }
  }, [isSuccess]);

  return (
    <>
      <Swiper
        slidesPerView={1}
        spaceBetween={10}
        pagination={{
          clickable: true,
        }}
        breakpoints={{
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          768: {
            slidesPerView: 4,
            spaceBetween: 40,
          },
          1024: {
            slidesPerView: 3.3,
            spaceBetween: 20,
          },
        }}
        className="mySwiper"
      >
        {items?.map((item) => (
          <SwiperSlide key={item._id}>
            <div
              onClick={() => {
                handleClick(item._id);
              }}
              className={`w-full min-w-[277px] border-secondary rounded-lg p-6 hover:bg-primary  group ${
                selectedCard?._id === item._id ? "bg-primary" : "bg-white"
              } transition-all duration-300`}
            >
              <div className="flex items-start justify-between mb-7">
                <h3 className="text-2xl text-secondary font-semibold">
                  {item.title}
                </h3>
                <div className="relative">
                  <Dropdown>
                    <Dropdown.Toggle
                      variant="success"
                      id={`dropdown-basic-${item._id}`}
                      className="!p-0 !border-0 bg-transparent"
                    >
                      <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
                    </Dropdown.Toggle>
                    <Dropdown.Menu className="bg-white p-2 rounded-lg shadow-lg min-w-[137px] left-[auto_!important] right-[0_!important]">
                      <Dropdown.Item
                        href="#"
                        className=" hover:text-primary py-1 px-3 flex  items-center gap-3"
                        onClick={() => {
                          openModal("edit");
                        }}
                      >
                        <FiEdit3 className="text-md" />
                        Edit
                      </Dropdown.Item>
                      {!(
                        item.title === "Starts" ||
                        item.title === "Withdrawals" ||
                        item.title === "Transitions"
                      ) && (
                        <Dropdown.Item
                          href="#"
                          className="hover:text-primary py-1 px-3 flex items-center gap-3"
                          onClick={() => handleDeleteCategory(item._id)}
                        >
                          <HiOutlineTrash className="text-md" />
                          Delete
                        </Dropdown.Item>
                      )}
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
              <span
                className={`text-lg font-semibold group-hover:text-white ${
                  selectedCard?._id === item._id
                    ? " text-white"
                    : "text-primary"
                }`}
              >
                {item.scheduledTime}
              </span>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};

export default MappedCards;
