import { useEffect, useState } from "react";
import { StudentStatuses } from "../../enums/Foray.enums";
import { useStudents } from "../../hooks/useStudents";
import { studentSvc } from "../../services/student.service";
import { IDashboardData } from "../../types/IDashboard.interface";
import { Filters } from "../../types/IStudentSetup.interface";
import { ITasksResponse } from "../../types/ITaskGeneration.interface";

interface ForayDashboardCardProps {
  tasksData?: ITasksResponse;
}

const ForayDashboardCard = ({ tasksData }: ForayDashboardCardProps) => {
  const studentFilters: Filters = {
    status: [StudentStatuses.Enrolled],
  };
  const { data: students } = useStudents(studentFilters);
  const [dashboardData, setDashboardData] = useState<IDashboardData | null>(
    null
  );

  useEffect(() => {
    const fetchData = async () => {
      const response = await studentSvc.fetchDashboardData();
      if (response.ok && response.data) {
        setDashboardData(response.data);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div className="grid grid-cols-5 gap-4 mb-6">
        <div className="bg-secondary border-secondaryNeutral border rounded-xl px-4 py-3 space-y-4">
          <span className="text-sm font-semibold text-secondaryVariant2">
            Children Enrolled
          </span>
          <h3 className="text-white font-bold text-[28px]">
            {students?.data?.students.length}
          </h3>
        </div>
        <div className="bg-secondary border-secondaryNeutral border rounded-xl px-4 py-3 space-y-4">
          <span className="text-sm font-semibold text-secondaryVariant2">
            Tomorrow Transitions {/* changed intentionlly to tommorow */}
          </span>
          <h3 className="text-white font-bold text-[28px]">
            {dashboardData?.tomorrowTransitions}{" "}
          </h3>
        </div>
        <div className="bg-secondary border-secondaryNeutral border rounded-xl px-4 py-3 space-y-4">
          <span className="text-sm font-semibold text-secondaryVariant2">
            Today Tours
          </span>
          <h3 className="text-white font-bold text-[28px]">
            {dashboardData?.todayTours}{" "}
          </h3>
        </div>
        <div className="bg-secondary border-secondaryNeutral border rounded-xl px-4 py-3 space-y-4">
          <span className="text-sm font-semibold text-secondaryVariant2">
            Today Tasks
          </span>
          <h3 className="text-white font-bold text-[28px]">
            {tasksData?.data?.length || 0}
          </h3>
        </div>
        <div className="bg-secondary border-secondaryNeutral border rounded-xl px-4 py-3 space-y-4">
          <span className="text-sm font-semibold text-secondaryVariant2">
            Today Absents
          </span>
          <h3 className="text-white font-bold text-[28px]">
            {dashboardData?.todayAbsents}{" "}
          </h3>
        </div>
      </div>
    </>
  );
};

export default ForayDashboardCard;
