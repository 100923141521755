import React from "react";
import { formatTrialEndDate } from "../../utils/formatTrialEndDate";
import { SubscriptionStatus } from "../../enums/sharedEnums";

interface IProps {
  mySubscription: any;
  handleUpgradePlan: () => void;
  handleCancel: (subscriptioId: string) => void;
}

const ActivePlan2: React.FC<IProps> = ({
  mySubscription,
  handleUpgradePlan,
  handleCancel,
}) => {
  const trialEndDate = formatTrialEndDate(
    mySubscription.data[0].current_period_end
  );
  const currentInterval = mySubscription.data[0].items.data[0].plan.interval;
  const currentPlanPrice =
    mySubscription.data[0].items.data[0].price.unit_amount / 100;

  const currentStatus = mySubscription.data[0].status;

  return (
    <>
      <div className="bg-white rounded-2xl border border-secondaryVariant2 p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg text-secondary font-semibold">
            Active Plan 2
          </h3>
          <button
            onClick={() => handleCancel(mySubscription.data[0].id)}
            className="text-base text-dangerAlert underline"
          >
            Cancel
          </button>
        </div>
        <div className="flex items-stretch justify-between">
          <div className="flex justify-between flex-col items-start">
            <div>
              <h3 className="text-[28px] text-primary font-semibold mb-2">
                <span className="italic">Foray</span> Basic{" "}
                <span className="font-normal text-secondary">{`${
                  currentInterval === "month" ? "(Monthly)" : "(Yearly)"
                } `}</span>
              </h3>
              {currentStatus === SubscriptionStatus.TRIALING ? (
                <div className="text-[#18C55F] border border-[#18C55F] text-xs font-medium bg-[#18C55F4D] bg-opacity-20 max-w-[160px] py-0.5 px-2 rounded-full">
                  {`Trial ends ${trialEndDate}`}
                </div>
              ) : (
                <div className="text-[#18C55F] border border-[#18C55F] text-xs font-medium bg-[#18C55F4D] bg-opacity-20 max-w-[160px] py-0.5 px-2 rounded-full">
                  {currentStatus}
                </div>
              )}
            </div>
            <button
              onClick={handleUpgradePlan}
              className="text-lg text-statusColor underline"
            >
              Upgrade plan
            </button>
          </div>
          <div className="flex items-start justify-between flex-col gap-9 me-12">
            <h3 className="text-[28px] text-secondary font-semibold">
              US ${currentPlanPrice}/
              <span className="font-normal">
                {currentInterval === "month" ? "month" : "year"}{" "}
              </span>
            </h3>
            {currentStatus === SubscriptionStatus.TRIALING && (
              <p className="text-secondary text-base">
                After free trial ends {trialEndDate} this <br /> subscription
                will continue automatically.
              </p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default ActivePlan2;
