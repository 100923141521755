import React, { useState } from 'react';
import { VscEdit } from 'react-icons/vsc';
import TimeDropdown from '../../components/dropdowns/TimeDropdown';
import { timeOptions } from '../../constants/constantValues';
import CustomSelect, { DropdownOption } from '../../components/select/CustomSelect';
import { FaRegCircleCheck } from 'react-icons/fa6';
import { IoCloseOutline } from 'react-icons/io5';

const headers = ["Duration", "Break Start", "Break End", "Breaker"];
const data = [
    ["120", "10:00 am", "12:00 pm", "John", "Doe"],
    ["90", "11:00 am", "12:30 pm", "Jane", "Smith"],
    ["60", "1:00 pm", "2:00 pm", "Emily", "Johnson"],
];

const teachers: DropdownOption[] = [
  { value: "hamid", label: "Hamid", avatar: "https://randomuser.me/api/portraits/men/1.jpg" },
  { value: "umar", label: "Umar", avatar: "https://randomuser.me/api/portraits/men/2.jpg" },
  { value: "samar", label: "Samar", avatar: "https://randomuser.me/api/portraits/women/1.jpg" },
  { value: "hamza", label: "Hamza", avatar: "https://randomuser.me/api/portraits/men/3.jpg" },
];

const Breaks = () => {
    const [isModifyMode, setIsModifyMode] = useState(false);
    const [selectedBreakers, setSelectedBreakers] = useState<{ [key: number]: DropdownOption | null }>({});
    const [selectedTimes, setSelectedTimes] = useState<{ [key: number]: { start: string; end: string } }>({});

    const handleSelectChange = (rowIndex: number, selectedOption: DropdownOption | null) => {
        setSelectedBreakers((prev) => ({ ...prev, [rowIndex]: selectedOption }));
    };

    const handleTimeChange = (rowIndex: number, timeType: "start" | "end", selectedTime: string) => {
        setSelectedTimes((prev) => ({
            ...prev,
            [rowIndex]: { ...prev[rowIndex], [timeType]: selectedTime },
        }));
    };

    return (
        <div className="border-[0.5px] border-secondaryVariant2 rounded-xl bg-white h-full overflow-hidden">
            <div className={`flex bg-[#fafafa] items-center justify-between py-3 min-h-[60px] px-3 group cursor-pointer rounded-ss-xl  ${isModifyMode ? 'bg-primary' : ''}`}>
                <h3 className={` ${isModifyMode ? 'text-white' : ''}`}>BREAKS</h3>
                {!isModifyMode ?
                <button 
                    className="text-base text-statusColor font-medium flex items-center gap-1"
                    onClick={() => setIsModifyMode(!isModifyMode)}
                >
                    <VscEdit className="w-5 h-5" />
                   Modify
                </button> : 
                     <div className="flex items-center gap-2">
                           <button className="rounded-lg py-1 px-3 bg-secondary text-white gap-2 inline-flex items-center"><FaRegCircleCheck className="w-5 h-5"/> Save</button>
                           <button className="rounded-lg py-1 px-3 bg-white text-secondary gap-2 inline-flex items-center"  onClick={() => setIsModifyMode(!isModifyMode)}><IoCloseOutline className="w-5 h-5"/> Cancel</button>
                         </div>
                }
            </div>
            <div className="px-4 py-4 text-center flex items-center justify-center gap-3 flex-wrap text-sm font-semibold text-secondary">
                All Breaks: <span className="py-1 px-3 rounded-[14px] bg-green-100 text-green-500">60 min</span>
            </div>

            <div className="px-3 pb-3 space-y-3">
                <div className="grid grid-cols-4 gap-2 px-2 bg-[#FFE6D8] text-sm text-secondary font-semibold leading-none">
                    {headers.map((header, index) => (
                        <div key={index} className='py-2 text-xs leading-none min-h-[40px] flex items-center' >{header}</div>
                    ))}
                </div>
                {data.map((row, rowIndex) => (
                    <div key={rowIndex} className="bg-secondaryNeutral rounded-lg grid grid-cols-4 p-2 gap-2">
                        <div className=" py-1 text-sm leading-none text-secondaryVariant">
                        {isModifyMode ? (
                                <TimeDropdown
                                    times={timeOptions}
                                    label="Start Time"
                                    small={true}
                                    placeholder="Set start time"
                                    value={selectedTimes[rowIndex]?.start || row[1]}
                                    onSelectTime={(selectedTime) => handleTimeChange(rowIndex, "start", selectedTime)}
                                />
                            ) : (
                                <>
                                   {row[0]} <br />
                                   <span className="text-[10px]">minutes</span>
                                </>
                            )}
                            

                        </div>

                        <div className=" py-1 text-sm leading-none text-secondaryVariant">
                            {isModifyMode ? (
                                <TimeDropdown
                                    times={timeOptions}
                                    label="Start Time"
                                    small={true}
                                    placeholder="Set start time"
                                    value={selectedTimes[rowIndex]?.start || row[1]}
                                    onSelectTime={(selectedTime) => handleTimeChange(rowIndex, "start", selectedTime)}
                                />
                            ) : (
                                <>
                                    {row[1].split(" ")[0]} <br />
                                    <span className="text-[10px]">{row[1].split(" ")[1]}</span>
                                </>
                            )}
                        </div>

                        <div className="py-1 text-sm leading-none text-secondaryVariant">
                            {isModifyMode ? (
                                <TimeDropdown
                                    times={timeOptions}
                                    label="End Time"
                                    small={true}
                                    placeholder="Set end time"
                                    value={selectedTimes[rowIndex]?.end || row[2]}
                                    onSelectTime={(selectedTime) => handleTimeChange(rowIndex, "end", selectedTime)}
                                />
                            ) : (
                                <>
                                    {row[2].split(" ")[0]} <br />
                                    <span className="text-[10px]">{row[2].split(" ")[1]}</span>
                                </>
                            )}
                        </div>

                        <div className="py-1 text-sm leading-none text-secondaryVariant">
                            {isModifyMode ? (
                                <CustomSelect
                                    options={teachers}
                                    placeholder="Select Breaker"
                                    isright={true}
                                    onChange={(selectedOption) => handleSelectChange(rowIndex, selectedOption)}
                                />
                            ) : (
                                <>
                                    {row[3]} <br />
                                    <span className="text-[10px]">{row[4]}</span>
                                </>
                            )}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default Breaks;
