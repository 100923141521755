import * as React from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiEdit } from "react-icons/bi";
import { CgEye } from "react-icons/cg";
import { RiDeleteBin3Line } from "react-icons/ri";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import {
  Classroom,
  IClassroomSetup,
} from "../../types/IClassroomSetup.interface";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";
import { useNavigate } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";
import { classroomSvc } from "../../services/classroom.service";
import { SessionType } from "../../enums/Foray.enums";
import { showErrorToast } from "../../../shared/utils/toast";
import DeActivateConfirmationModal from "../modals/DeActivateConfirmationModal";
import { FiEdit3 } from "react-icons/fi";
interface ActionDropdownProps {
  classroomId: string;
  isActive: boolean;
  isOpen: boolean;
  toggleDropdown: (id: string) => void;
  fetchAgain: boolean;
  setFetchAgain?: (fetch: boolean) => void;
  handleOpenConfirmationModal: (classroomId: string, isActive: boolean) => void;
}
const ActionDropdown: React.FC<ActionDropdownProps> = ({
  classroomId,
  isActive,
  isOpen,
  toggleDropdown,
  fetchAgain,
  setFetchAgain,
  handleOpenConfirmationModal,
}) => {
  const actionModalRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(actionModalRef, () => isOpen && toggleDropdown(classroomId));
  const navigate = useNavigate();

  const handleView = () => {
    navigate(forayRoutes.view_classroom, { state: { classroomId } });
  };

  const handleModify = () => {
    navigate(forayRoutes.detailed_classroom_setup + "?modify", {
      state: { classroomId },
    });
  };

  // const handleArchive = async () => {
  //   const res = await classroomSvc.archiveClassroom(classroomId, !isActive);
  //   if (!res?.ok && res?.message) {
  //     showErrorToast(res?.message)
  //   } else if (res?.ok) {
  //     toggleDropdown(classroomId);
  //     setFetchAgain && setFetchAgain(!fetchAgain);
  //   }
  // };

  const handleArchiveClick = () => {
    handleOpenConfirmationModal(classroomId, !isActive);
  };

  return (
    <>
      <div className="relative text-end">
        <button
          className="text-base inline-flex items-center justify-center gap-4"
          onClick={handleModify}
        >
          <FiEdit3 className="w-6 h-6 text-info" />
        </button>
        {/* <button
          onClick={() => toggleDropdown(classroomId)}
          className="focus:outline-none"
        >
          <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
        </button>
        {isOpen && (
          <div
            className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md z-10 p-4"
            ref={actionModalRef}
          >
            <ul>
              <li
                className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
                onClick={handleModify}
              >
                <button className="text-base text-secondary flex items-center gap-4">
                  <BiEdit className="w-6 h-6 text-secondaryVariant2" />
                  Modify
                </button>
              </li>
              <li
                className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
                onClick={handleArchiveClick}
              >
                <button className="text-base text-dangerAlert flex items-center gap-4">
                  <RiDeleteBin3Line className="w-6 h-6" />
                  Deactivate
                </button>
              </li>
            </ul>
          </div>
        )} */}
      </div>
    </>
  );
};

const columnHelper = createColumnHelper<IClassroomSetup>();
interface ClassroomListTableProps {
  classrooms: IClassroomSetup[];
  handleAddClassroomDetail: () => void;
  fetchAgain?: boolean;
  setFetchAgain?: (fetch: boolean) => void;
}
const ClassroomDetailedListTable: React.FC<ClassroomListTableProps> = ({
  classrooms,
  handleAddClassroomDetail,
  fetchAgain,
  setFetchAgain,
}) => {
  const [data, _setData] = React.useState(classrooms);
  const [hasMoreThanFiveClassrooms, setHasMoreThanFiveClassrooms] =
    React.useState(false);
  const [openDropdownId, setOpenDropdownId] = React.useState<string | null>(
    null
  );
  const [selectedClassroom, setSelectedClassroom] = React.useState<any>();
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] =
    React.useState(false);
  let hasRenderedEmptyColumn = false;

  const handleOpenConfirmationModal = (
    classroomId: string,
    isActive: boolean
  ) => {
    setIsConfirmationModalOpen(true);
    setSelectedClassroom({ classroomId, isActive });
  };
  const handleCloseConfirmationModal = () => {
    setIsConfirmationModalOpen(false);
    setSelectedClassroom(null);
  };
  const navigate = useNavigate();

  React.useEffect(() => {
    console.log("classrooms", classrooms);
    if (!classrooms?.length || classrooms.length === 0) _setData([]);
    _setData(classrooms);
  }, [classrooms]);

  const handleGetNameOfTransitionClassroom = (classroomId: string) => {
    const transitionClass = classrooms?.find(
      (item) => item._id === classroomId
    );
    return transitionClass?.classroomName;
  };

  const columns = [
    columnHelper.accessor("classroomName", {
      id: "classroomName",
      header: () => <div className="break-words">Classroom Name</div>,
      cell: (info) => (
        <div className="break-words">
          <span className="w-full whitespace-nowrap">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("transition.TransitionClassroom", {
      id: "nextClassroom",
      header: () => (
        <div className="text-start">
          <span>Transition To</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start">
          {handleGetNameOfTransitionClassroom(info.getValue() as string) ||
            "None"}
        </div>
      ),
    }),
    columnHelper.accessor("settingType", {
      id: "classroomSession",
      header: () => (
        <div className="text-start min-w-[130px]">
          <span>
            Classroom <br /> Sessions
          </span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start min-w-[130px]">
          {" "}
          {info.getValue() === SessionType.FullTimeOnly ? (
            <span
              className={`bg-[#00C14F] text-white py-1 px-1.5 rounded text-xs font-medium`}
            >
              {"Full-time"}
            </span>
          ) : (
            <>
              <span
                className={`bg-[#00C14F] text-white py-1 px-1.5 rounded text-xs font-medium`}
              >
                {"Full-time"}
              </span>
              <br />
              <span
                className={`bg-[#FFAE00] text-white py-1 px-1.5 rounded text-xs font-medium`}
              >
                {"Part-time"}
              </span>
              <>
                {info.row.original.partTimeSlots.map((slot) => (
                  <div className="text-xs text-secondaryVariant mt-1">
                    {slot.startTime} to {slot.endTime}
                  </div>
                ))}
              </>
            </>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("BAProgram", {
      id: "bAProgram",
      header: () => (
        <div className="text-start">
          <span>BA/Summer Program</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start">
          {" "}
          <span
            // ${info.getValue() ? "bg-[#E6F2FE] text-statusColor text-xs" : ""} 
            className={`py-1 px-1.5 rounded text-base font-medium`}
          >
            {" "}
            {info.row.original.summerProgram || info.getValue() ? "Yes" : "--"}
          </span>{" "}
          {/* <span className="bg-[#FCEBE9] text-dangerAlert py-1 px-1.5 rounded text-xs font-medium">
          {" "}
          {info.getValue()}
        </span>{" "} */}
        </div>
      ),
    }),

    columnHelper.accessor("_id", {
      id: "essentialSetup",
      header: () => (
        // <div className="break-words cursor-pointer" onClick={() => navigate(forayRoutes.essential_classroom_setup)}>
        //   <FiEdit3 className="w-6 h-6 text-info" />
        // </div>
        <div></div>
      ),
      cell: (info) => (
        <div className="break-words cursor-pointer" onClick={() => navigate(forayRoutes.essential_classroom_setup)}>
          <FiEdit3 className="w-6 h-6 text-info" />
        </div>
        // <div className="flex items-center gap-3">
        //   <span className="w-full whitespace-nowrap"></span>
        // </div>
      ),
    }),
    columnHelper.accessor("_id", {
      id: "empty",
      header: () => <div className=""></div>,
      cell: (info) => (
        <div className="">
        </div>
      ),
    }),
    columnHelper.accessor("nickName", {
      id: "nickName",
      header: () => <div className="break-words">Nick Name</div>,
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span className="w-full whitespace-nowrap">
            {info.getValue() || "--"}
          </span>
        </div>
      ),
    }),

    columnHelper.accessor("capacity.licenseCapacity", {
      id: "licenseCapacity",
      header: () => (
        <div className="text-start">
          <span>Licensed Capacity</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("capacity.physicalCapacity", {
      id: "physicalCapacity",
      header: () => (
        <div className="text-start">
          <span>Physical Capacity</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),

    columnHelper.accessor("capacity.studentPerTeacher", {
      id: "stuTeachRatio",
      header: () => (
        <div className="text-start">
          <span>Stu-Teach Ratio</span>
        </div>
      ),
      cell: (info) => <div className="text-start">{info.getValue()}</div>,
    }),
    columnHelper.accessor("selfBreaking", {
      id: "selfBreak",
      header: () => (
        <div className="text-start">
          <span>Self Break</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start">
          <span
            // ${info.getValue()? "bg-[#E6F2FE] text-statusColor text-xs": "text-base"} 
            className={`
              py-1 px-1.5 rounded text-base font-medium`}
          >
            {" "}
            {info.row.original?.summerProgram || info.row.original.BAProgram ? "--" : info.getValue() ? "Yes" : "No"}
          </span>{" "}
          {/* <span className=" py-1 px-1.5 rounded text-xs font-medium">
          {" "}
          {info.getValue()}
        </span>{" "} */}
        </div>
      ),
    }),

    columnHelper.accessor("_id", {
      id: "action",
      header: () => (
        <div className="text-end">
          <span></span>
        </div>
      ),
      cell: (row) => (
        <ActionDropdown
          classroomId={row.row.original?._id as string}
          isActive={row.row.original.isActive as boolean}
          isOpen={openDropdownId === row.row.original?._id}
          toggleDropdown={toggleDropdown}
          fetchAgain={fetchAgain as boolean}
          setFetchAgain={setFetchAgain && setFetchAgain}
          handleOpenConfirmationModal={handleOpenConfirmationModal}
        />
      ),
    }),
    columnHelper.accessor("_id", {
      id: "emptyClassroomDetails",
      header: () => <></>,
      cell: (row) => (
        <div className="max-w-[711px] flex items-center gap-3 justify-center mx-auto ">
          {/* {hasMoreThanFiveClassrooms ?
            <>
              <div>
                <img
                  className="max-w-[161px] max-h-[161px] rounded-full"
                  src="https://a360csastorage.blob.core.windows.net/childcare/515fa21f-2483-4189-87c7-5748a74233ee-image%20(1).jpeg"
                  alt="miley"
                />
              </div>
              <p className="text-base text-secondary">
                <strong className="block">Hi, I'm Miley.</strong>
                <strong className="text-primary">Congratulations!</strong> You have
                completed Classroom-Essential! Now, lets add Classroom-Detailed
                information one by one.
              </p>
            </> :
          <>*/}
          <p className="text-secondaryVariant font-normal text-base">
            Classroom-Details information is missing.
          </p>
          {/*</>
          } */}
          <button
            // className={`btnPrimary whitespace-nowrap ${hasMoreThanFiveClassrooms ? "max-w-[334px]" : "max-w-[231px]"}`}
            className={`btnPrimary whitespace-nowrap max-w-[334px]`}
            onClick={handleAddClassroomDetail}
          >
            {/* {hasMoreThanFiveClassrooms ? " */}
            Add Classroom-Details Information
            {/* " : "Add Classroom-Details"}{" "} */}
          </button>
        </div>
      ),
    }),
  ];
  const handleArchive = async () => {
    const res = await classroomSvc.archiveClassroom(
      selectedClassroom?.classroomId,
      selectedClassroom?.isActive
    );
    if (!res?.ok && res?.message) {
      showErrorToast(res?.message);
    } else if (res?.ok) {
      toggleDropdown(selectedClassroom?.classroomId);
      handleCloseConfirmationModal();
      setFetchAgain && setFetchAgain(!fetchAgain);
    }
  };
  // let numOfEmptyRows = 0
  // const handleViewSelection = (showFullRow: boolean) => {
  //   if (!showFullRow)
  //     numOfEmptyRows++
  //   if (numOfEmptyRows > 5)
  //     setHasMoreThanFiveClassrooms(true)
  //   else setHasMoreThanFiveClassrooms(false)
  // }

  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const emptyRowRef = React.useRef<HTMLTableRowElement | null>(null);

  React.useEffect(() => {
    if (emptyRowRef.current) {
      emptyRowRef.current.scrollIntoView({ behavior: "smooth", block: "nearest" });
    }
  }, [data]);
  return (
    <>
      <DeActivateConfirmationModal
        isOpen={isConfirmationModalOpen}
        onClose={handleCloseConfirmationModal}
        handleArchive={handleArchive}
      />
      <div className="custom-scrollbar sw-10 overflow-y-auto h-[calc(100vh-477px)] border-secondaryVariant2 border rounded-xl mb-6 bg-white">
        <table className="w-full bg-white rounded-t-lg ">
          <thead className="sticky top-0 bg-white z-[999]">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`p-4 text-left text-base font-medium text-secondary capitalize
                      ${header.id === "empty" ? "border-x border-secondaryVariant bg-secondaryNeutral" : ""}
                      `}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-secondaryNeutral">
            {table.getRowModel().rows.map((row) => {
              const { licenseCapacity, physicalCapacity, studentPerTeacher } =
                row.original.capacity || {};
              const showFullRow =
                licenseCapacity &&
                physicalCapacity &&
                studentPerTeacher &&
                row.original.settingType;
              // hasRenderedEmptyColumn = false; // Reset for each row
              return (
                <tr key={row.id} className="odd:bg-secondaryNeutral"
                  ref={
                    !showFullRow &&
                      row.getVisibleCells().some((cell) => cell.column.id === "emptyClassroomDetails")
                      ? emptyRowRef
                      : null
                  }>
                  {row.getVisibleCells().map((cell) => {
                    if (
                      !showFullRow &&
                      cell.column.id === "emptyClassroomDetails" &&
                      !hasRenderedEmptyColumn
                    ) {
                      hasRenderedEmptyColumn = true;

                      return (
                        <td
                          key={cell.id}
                          colSpan={row.getVisibleCells().length}
                          className="px-4 text-base text-secondaryVariant border-b border-secondaryNeutral capitalize text-center bg-white "
                        // className={`
                        //   ${cell.column.id === "empty" ? "border-x border-secondaryVariant bg-secondaryNeutral" : "p-4 text-base text-secondaryVariant  border-secondaryNeutral capitalize"}`}

                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    }

                    if (cell.column.id === "classroomName") {
                      return (
                        <td
                          key={cell.id}
                          className="p-4 text-base text-secondaryVariant border-b border-secondaryNeutral capitalize"
                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    }

                    // Show other columns only if showFullRow is true
                    if (showFullRow && cell.column.id !== "emptyClassroomDetails") {
                      return (
                        <td
                          key={cell.id}
                          className={`
                            ${cell.column.id === "empty" ? "border-x border-secondaryVariant bg-secondaryNeutral" : "p-4 text-base text-secondaryVariant  border-secondaryNeutral capitalize"}`}

                        >
                          {flexRender(
                            cell.column.columnDef.cell,
                            cell.getContext()
                          )}
                        </td>
                      );
                    }

                    return null;
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
        {/* <div className="max-w-[711px] flex items-center gap-3 flex-col justify-center mx-auto py-10">
        <p className="text-base text-secondaryVariant">
          As you finished your Essential classroom, now you need to add
          classroom details.
        </p>
        <button className="btnPrimary max-w-[220px]" onClick={handleAddClassroomDetail}>
          Add Classroom Details
        </button>
      </div> */}
      </div>
    </>
  );
};

export default ClassroomDetailedListTable;
function setFetchAgain(fetch: boolean): void {
  throw new Error("Function not implemented.");
}

function toggleDropdown(id: string): void {
  throw new Error("Function not implemented.");
}
