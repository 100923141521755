import React from "react";
import { IoIosInformation } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";

interface ModalProps {
  open: boolean;
  onClose: () => void;
  onProceed: () => void;
  warning: string;
  text: string;
}

const DuplicateStudentWarningModal: React.FC<ModalProps> = ({
  open,
  onClose,
  onProceed,
  warning,
  text,
}) => {
  if (!open || !warning || !text) return null;

  return (
    <div className="fixed inset-0 bg-secondaryVariant bg-opacity-20 flex justify-center items-center z-[9999]">
      <div className="bg-white py-9 px-12 rounded-3xl shadow-lg w-[676px] relative">
        <button
          onClick={onClose}
          className="text-gray-500 hover:text-gray-700 absolute top-1 right-3"
        >
          {" "}
          <IoCloseOutline className="w-8 h-8 text-secondary" />
        </button>
        <h3 className="text-xl text-secondary font-medium">{warning}</h3>

        <p className="text-xl text-secondary mb-9">{text}</p>

        <button
          className="btnPrimary max-w-[116px] flex justify-center"
          onClick={onProceed}
        >
          Okay
        </button>
      </div>
    </div>
  );
};

export default DuplicateStudentWarningModal;
