import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";

export const useUpdateTask = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      reqBody,
      taskId,
    }: {
      reqBody: { initials?: string; comments?: string };
      taskId: string;
    }) => taskGenerationSvc.updateTaskById(taskId, reqBody),
    onError: (err) => {
      showErrorToast(err.message);
      console.error("error:", err);
    },
    onSuccess: (res) => {
      if (res?.data) {
        showSuccessToast("Task updated successfully");
        queryClient.invalidateQueries({
          queryKey: [TaskGenerationQueryKeyString.DashboardTasks],
        });
      } else {
        showErrorToast("Couldn't update task");
      }
    },
  });
};
