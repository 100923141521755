import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
} from "@tanstack/react-query";
import { CenterQueryKeyString } from "../enums/centerQueryKey.enum";
import { foraySvc } from "../services/foray.service";
import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";
import { useNavigate } from "react-router-dom";
import { forayRoutes } from "../routes/routes";

type UpdateCenterSetupResponse = {
  data: any;
  ok: boolean;
};

type UpdateCenterSetupParams = {
  reqBody: any;
  shouldNavigate?: boolean;
  transitionUpdate?: boolean;
};

export const useUpdateCenterSetup = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const mutationOptions: UseMutationOptions<
    UpdateCenterSetupResponse | undefined,
    Error,
    UpdateCenterSetupParams
  > = {
    mutationFn: async ({ reqBody }: UpdateCenterSetupParams) => {
      const res = await foraySvc.updateCenterSetup(reqBody);
      return res || undefined;
    },
    onSuccess: (res, { shouldNavigate, transitionUpdate }) => {
      if (res?.ok) {
        transitionUpdate ? showSuccessToast("Transition mode updated successfully.") : showSuccessToast("Center setup updated successfully.");
        queryClient.invalidateQueries({
          queryKey: [CenterQueryKeyString.CenterSetup],
        });

        if (shouldNavigate) {
          navigate(forayRoutes.center_view);
        }
      } else {
        transitionUpdate ? showErrorToast("Couldn't update transition mode. Try again.") : showErrorToast("Error updating center setup.");
      }
    },
  };

  const { mutateAsync } = useMutation(mutationOptions);

  return {
    updateCenterSetup: (reqBody: any, shouldNavigate: boolean = true, transitionUpdate: boolean = false) =>
      mutateAsync({ reqBody, shouldNavigate, transitionUpdate }),
  };
};
