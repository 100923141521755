import React, { useState } from "react";
import { GoPlus } from "react-icons/go";
import CompleteEmployeeSetup from "../Modals/CompleteEmployeeSetup";
import { IEmployeeInfo } from "../../types/IStaff";
import FileUploadBox from "../FileUploadBox"; // Assume this is the reusable upload component

interface IProps {
  employeeData?: IEmployeeInfo;
  updateEmployeeData: (data: any) => void;
  navigateToTab: (tabKey: string) => void;
}

const UploadEmployeeDocuments: React.FC<IProps> = ({
  employeeData,
  updateEmployeeData,
  navigateToTab,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const [identityDocs, setIdentityDocs] = useState<File[]>([]);
  const [educationDocs, setEducationDocs] = useState<File[]>([]);
  const [agreementDocs, setAgreementDocs] = useState<File[]>([]);
  const [otherDocs, setOtherDocs] = useState<File[]>([]);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="bg-white p-6 rounded-xl">
      <h2 className="text-xl text-secondary font-semibold mb-9">
        Upload Documents
      </h2>

      <div className="grid grid-cols-2 gap-9 mb-12">
        <FileUploadBox
          label="Identity Document"
          onFilesUpload={(files: File[]) => setIdentityDocs([...identityDocs, ...files])}
          uploadedFiles={identityDocs}
          onRemoveFile={(index: number) =>
            setIdentityDocs((prev) => prev.filter((_, i) => i !== index))
          }
        />

        <FileUploadBox
          label="Education Document"
          onFilesUpload={(files: File[]) => setEducationDocs([...educationDocs, ...files])}
          uploadedFiles={educationDocs}
          onRemoveFile={(index: number) =>
            setEducationDocs((prev) => prev.filter((_, i) => i !== index))
          }
        />

        <FileUploadBox
          label="Signed Agreement"
          onFilesUpload={(files: File[]) => setAgreementDocs([...agreementDocs, ...files])}
          uploadedFiles={agreementDocs}
          onRemoveFile={(index: number) =>
            setAgreementDocs((prev) => prev.filter((_, i) => i !== index))
          }
        />

        <FileUploadBox
          label="Other Document"
          onFilesUpload={(files: File[]) => setOtherDocs([...otherDocs, ...files])}
          uploadedFiles={otherDocs}
          onRemoveFile={(index: number) =>
            setOtherDocs((prev) => prev.filter((_, i) => i !== index))
          }
        />
      </div>

      <div className="flex items-center gap-6">
        <button className="btnPrimary max-w-[182px]" onClick={openModal}>
          Save & Complete
        </button>
        <button className="btnSimple max-w-[182px]">Cancel</button>
        <CompleteEmployeeSetup isOpen={isModalOpen} onClose={closeModal} />
      </div>
    </div>
  );
};

export default UploadEmployeeDocuments;
