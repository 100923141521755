import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { RootState } from "../../../../store/store";
import MainWrapper from "../../components/layout/MainWrapper";
import StripePricingTable from "../../components/stripePricingTable/StripePricingTable";
import { stripeSvc } from "../../services/stripe.service";

const CheckoutPage = () => {
  const { User } = useSelector((state: RootState) => state.auth);
  const { state } = useLocation();
  const { priceId } = state;
  const fetchClientSecret = async () => {
    try {
      const response = await stripeSvc.subscribeToService(priceId);
      if (response?.ok && response.data) {
        return response.data;
      }
      throw new Error("Failed to get client secret");
    } catch (err) {
      console.error("Error fetching client secret:", err);
      throw err;
    }
  };

  return (
    <MainWrapper>
      <section className="py-[100px]">
        <div className="container">
          <h2 className="text-2xl md:text-xll xl:text-4xl text-secondary font-semibold mb-3 text-center">
            Plans
          </h2>
          <StripePricingTable fetchClientSecret={fetchClientSecret} />
        </div>
      </section>
    </MainWrapper>
  );
};

export default CheckoutPage;
