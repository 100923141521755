import { Nav, Tab } from "react-bootstrap";
import { IoDocumentAttachOutline } from "react-icons/io5";
import { PiBriefcaseLight, PiCertificate } from "react-icons/pi";
import { RiUser6Line } from "react-icons/ri";
import { useLocation } from "react-router-dom";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../components/Breadcrumb";
import EducationNotification from "../../components/fillOutInformation/Education&Certification";
import EmployeeInformationForm from "../../components/fillOutInformation/EmployeeInformationForm";
import ProfessionalInformation from "../../components/fillOutInformation/ProfessionalInformation";
import { IEmployeeInfo } from "../../types/IStaff";
import { useState, useEffect } from "react";
import { EditType } from "../../enums/editType";
import UploadEmployeeDocuments from "../../components/fillOutInformation/UploadEmployeeDocuments";
import { forayRoutes } from "../../../routes/routes";

const EmployeePersonalInformation = () => {
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Human Resource Management", link: forayRoutes.hr_dashboard },
    { label: "Employee Setup", isCurrent: true },
  ];

  const { state } = useLocation();
  const initialEmployee =
    (state as { employee?: IEmployeeInfo })?.employee ?? undefined;
  const initialType =
    (state as { type?: string })?.type ?? EditType.personalInfo;

  const [activeTab, setActiveTab] = useState<string>(initialType);
  const [employeeData, setEmployeeData] = useState<IEmployeeInfo | undefined>(
    initialEmployee
  );

  const [isPersonalInfoComplete, setIsPersonalInfoComplete] = useState(false);
  const [isEducationComplete, setIsEducationComplete] = useState(false);
  const [isProfessionalInfoComplete, setIsProfessionalInfoComplete] =
    useState(false);
  const [isDocumentComplete, setIsDocumentComplete] = useState(false);

  // Check section completion status whenever employee data changes
  useEffect(() => {
    if (!employeeData) return;

    // Check personal info completeness
    const hasPersonalInfo =
      employeeData.personalInformation &&
      employeeData.personalInformation.firstName?.trim() &&
      employeeData.personalInformation.lastName?.trim() &&
      employeeData.personalInformation.email?.trim() &&
      employeeData.personalInformation.mobileNumber?.trim();

    // Check education completeness
    const hasEducation =
      employeeData.educationalInformation &&
      employeeData.educationalInformation.education &&
      employeeData.educationalInformation.education.length > 0;

    // Check professional info completeness
    const hasProfessionalInfo =
      employeeData.professionalInformation &&
      employeeData.professionalInformation.jobTitle?.trim();

    // Check document completeness
    const hasDocuments =
      employeeData.uploadedDocuments &&
      ((employeeData.uploadedDocuments.identityDocuments?.length || 0) > 0 ||
        (employeeData.uploadedDocuments.educationDocuments?.length || 0) > 0 ||
        (employeeData.uploadedDocuments.signedAgreement?.length || 0) > 0 ||
        (employeeData.uploadedDocuments.otherDocuments?.length || 0) > 0);

    // Update completion states
    setIsPersonalInfoComplete(!!hasPersonalInfo);
    setIsEducationComplete(!!hasEducation);
    setIsProfessionalInfoComplete(!!hasProfessionalInfo);
    setIsDocumentComplete(!!hasDocuments);
  }, [employeeData]);

  // Simple data update function for child components
  const updateEmployeeData = (data: any) => {
    setEmployeeData((prevData) => {
      if (!prevData) return data;
      return { ...prevData, ...data };
    });
  };

  // Tab navigation function for child components
  const navigateToTab = (tabKey: string) => {
    setActiveTab(tabKey);
  };

  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-6">
          <div className="space-y-6">
            <Breadcrumb items={breadcrumbItems} />
            <h4 className="text-secondary font-semibold text-xl">
              {employeeData?._id ? "Update Employee" : "Add Employee"}
            </h4>
            <Tab.Container
              id="left-tabs-example"
              activeKey={activeTab}
              onSelect={(key) => {
                // Only allow navigation to enabled tabs
                if (
                  key === EditType.personalInfo ||
                  (key === EditType.education && isPersonalInfoComplete) ||
                  (key === EditType.professionalInfo && isEducationComplete) ||
                  (key === EditType.documents && isProfessionalInfoComplete)
                ) {
                  setActiveTab(key || EditType.personalInfo);
                }
              }}
            >
              <Nav
                variant="pills"
                className="flex items-center border-b border-secondaryVariant2 mb-6"
              >
                <Nav.Item>
                  <Nav.Link
                    eventKey={EditType.personalInfo}
                    className="inline-flex items-center gap-2 ps-0 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                  >
                    <RiUser6Line className="w-6 h-6" />
                    Personal Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    disabled={!isPersonalInfoComplete}
                    eventKey={EditType.education}
                    className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary [&.disabled]:opacity-50 [&.disabled]:cursor-not-allowed [&.disabled]:text-gray-400 [&.disabled]:pointer-events-none"
                  >
                    <PiCertificate className="w-6 h-6" />
                    Education & Certification
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    disabled={!isEducationComplete}
                    eventKey={EditType.professionalInfo}
                    className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary [&.disabled]:opacity-50 [&.disabled]:cursor-not-allowed [&.disabled]:text-gray-400 [&.disabled]:pointer-events-none"
                  >
                    <PiBriefcaseLight className="w-6 h-6" />
                    Professional Information
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link
                    disabled={!isProfessionalInfoComplete}
                    eventKey={EditType.documents}
                    className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary [&.disabled]:opacity-50 [&.disabled]:cursor-not-allowed [&.disabled]:text-gray-400 [&.disabled]:pointer-events-none"
                  >
                    <IoDocumentAttachOutline className="w-6 h-6" />
                    Documents
                  </Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <div className="overflow-y-scroll h-[calc(100vh-307px)] custom-scrollbar">
                  <Tab.Pane
                    eventKey={EditType.personalInfo}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                  >
                    <EmployeeInformationForm
                      employeeData={employeeData}
                      updateEmployeeData={updateEmployeeData}
                      navigateToTab={navigateToTab}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey={EditType.education}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                  >
                    <EducationNotification
                      employeeData={employeeData}
                      updateEmployeeData={updateEmployeeData}
                      navigateToTab={navigateToTab}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey={EditType.professionalInfo}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                  >
                    <ProfessionalInformation
                      employeeData={employeeData}
                      updateEmployeeData={updateEmployeeData}
                      navigateToTab={navigateToTab}
                    />
                  </Tab.Pane>
                  <Tab.Pane
                    eventKey={EditType.documents}
                    className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
                  >
                    <UploadEmployeeDocuments
                      employeeData={employeeData}
                      updateEmployeeData={updateEmployeeData}
                      navigateToTab={navigateToTab}
                    />
                  </Tab.Pane>
                </div>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EmployeePersonalInformation;
