import {
    useMutation,
    UseMutationOptions,
    useQueryClient,
} from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";
import { CenterQueryKeyString } from "../enums/centerQueryKey.enum";
import { foraySvc } from "../services/foray.service";
import { ICenterSetupPayload } from "../types/ICenterSetup.interface";
  
  type UpdatePartTimeSlotsResponse = {
    data: any;
    ok: boolean;
  };
  
  type UpdatePartTimeSlotsParams = {
    reqBody: ICenterSetupPayload;
  };
  
  export const useUpdatePartTimeSlots = () => {
    const queryClient = useQueryClient();
  
    const mutationOptions: UseMutationOptions<
      UpdatePartTimeSlotsResponse | undefined,
      Error,
      UpdatePartTimeSlotsParams
    > = {
      mutationFn: async ({ reqBody }: UpdatePartTimeSlotsParams) => {
        const res = await foraySvc.updatePartTimeSlots(reqBody);
        return res || undefined;
      },
      onSuccess: (res) => {
        if (res?.ok) {
          showSuccessToast("Part-time slots updated successfully.");
          queryClient.invalidateQueries({
            queryKey: [CenterQueryKeyString.CenterSetup],
          });
        } else {
          showErrorToast("Error updating part-time slots.");
        }
      },
    };
  
    const { mutateAsync } = useMutation(mutationOptions);
  
    return {
      updatePartTimeSlots: (reqBody: ICenterSetupPayload) =>
        mutateAsync({ reqBody }),
    };
  };
  