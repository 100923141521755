import React, { useEffect, useState } from "react";
import { TiArrowSortedDown, TiArrowSortedUp } from "react-icons/ti";
import { Classroom } from "../../../types/IEnrollmentTrackingInfo";
import { SessionType } from "../../../enums/Foray.enums";
import * as HoverCard from "@radix-ui/react-hover-card";
import { EnrollmentTrackingSvc } from "../../services/enrollment.service";
import { IStudentInfo } from "../../types/IStudentInfo";
import {
  formatDateAmerican,
  formatDateSlashFullYear,
  formatDateYearFirst,
} from "../../../../shared/utils/formatDate";
import { useLocation, useNavigate } from "react-router-dom";
import { forayRoutes } from "../../../routes/routes";

interface IProps {
  classroom: Classroom;
  handleOpenChange?: (open: boolean) => void;
}
const SessionTime: React.FC<IProps> = ({ classroom, handleOpenChange }) => {
  const [fullTimeCount, setFullTimeCount] = useState(0);
  const [partTimeCount, setPartTimeCount] = useState(0);
  const [isCollapseTime, setIsCollapseTime] = useState(false);
  const [fullTimeStudents, setFullTimeStudents] = useState<IStudentInfo[]>([]);
  const [partTimeStudents, setPartTimeStudents] = useState<IStudentInfo[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (classroom?.waitListedStudents) {
      const fullTimeLength = Array.isArray(
        classroom.waitListedStudents.fullTime
      )
        ? classroom.waitListedStudents.fullTime.length
        : 0;

      const partTimeLength = Array.isArray(
        classroom.waitListedStudents.partTime
      )
        ? classroom.waitListedStudents.partTime.length
        : 0;

      setFullTimeCount(fullTimeLength);
      setPartTimeCount(partTimeLength);

      // Fetch student data for waitlisted students
      const fetchWaitlistedStudents = async () => {
        setIsLoading(true);
        try {
          // Fetch full time waitlisted students
          if (fullTimeLength > 0) {
            const fullTimeStudentData =
              await EnrollmentTrackingSvc.fetchStudents(
                classroom.waitListedStudents.fullTime
              );
            setFullTimeStudents(fullTimeStudentData);
          }

          // Fetch part time waitlisted students
          if (partTimeLength > 0) {
            const partTimeStudentData =
              await EnrollmentTrackingSvc.fetchStudents(
                classroom.waitListedStudents.partTime
              );
            setPartTimeStudents(partTimeStudentData);
          }
        } catch (error) {
          console.error("Error fetching waitlisted students:", error);
        } finally {
          setIsLoading(false);
        }
      };

      fetchWaitlistedStudents();
    } else {
      // Reset counts and student data if no data
      setFullTimeCount(0);
      setPartTimeCount(0);
      setFullTimeStudents([]);
      setPartTimeStudents([]);
    }
  }, [classroom]);

  const toggleList = () => {
    setIsCollapseTime(!isCollapseTime);
  };

  const handleViewRoster = () => {
    navigate(forayRoutes.class_roster, {
      state: { classroomId: classroom?.classroomId },
    });
  };

  const handleEditWaitListedStudent = (student: IStudentInfo) => {
    navigate(forayRoutes.view_student_profile + `?pr=${location.pathname}`, {
      state: { studentId: student._id, classroomId: student.classroom },
    });
  };

  const renderWaitListedStudents = (students: IStudentInfo[]) => {
    return students.map((student, index) => (
      <React.Fragment key={student._id}>
        <div className="flex flex-col p-2 bg-white relative rounded-lg">
          <div className="mb-1">
            <p className="text-[10px] font-bold text-secondary">Name:</p>
            <p className="text-sm text-secondary">
              {student.firstName} {student.lastName}
            </p>
          </div>
          <div className="mb-4">
            <p className="text-[10px] font-bold text-secondary">Start Date:</p>
            <p className="text-sm text-secondary">
              {formatDateSlashFullYear(
                student.Dates?.startAtCenter as string
              ) || "N/A"}
            </p>
          </div>
          <button
            onClick={() => handleEditWaitListedStudent(student)}
            className="max-w-[100px] bg-[#e28b6d] hover:bg-[#d17c5f] text-white text-sm font-bold py-1.5 px-2 rounded flex items-center justify-center whitespace-nowrap"
          >
            Edit Student
          </button>
          {index < students.length - 1 && (
            <hr className="mt-4 border-gray-200" />
          )}
        </div>
      </React.Fragment>
    ));
  };

  // Check if there are missed start dates for full time students
  const hasMissedStartDateFullTime =
    Array.isArray(classroom?.missedStartDateStudentIdsWaitlistedFullTime) &&
    classroom.missedStartDateStudentIdsWaitlistedFullTime.length > 0;

  // Check if there are missed start dates for part time students
  const hasMissedStartDatePartTime =
    Array.isArray(classroom?.missedStartDateStudentIdsWaitlistedPartTime) &&
    classroom.missedStartDateStudentIdsWaitlistedPartTime.length > 0;

  // Define color classes for counts
  const fullTimeCountClass = hasMissedStartDateFullTime
    ? "text-base text-[#AB4CFF] font-bold cursor-pointer pe-3"
    : "text-base text-secondary font-bold cursor-pointer pe-3";

  const partTimeCountClass = hasMissedStartDatePartTime
    ? "text-base text-[#AB4CFF] font-bold cursor-pointer pe-3"
    : "text-base text-secondary font-bold cursor-pointer pe-3";

  return (
    <>
      <div className="bg-white ps-3 pe-2 py-1.5 relative">
        <div className="flex items-start gap-3 pb-3">
          <div className="flex flex-col gap-2">
            <div className="rounded-lg py-1 px-2 min-w-[119px] max-w-[119px] w-full bg-[#686C82]">
              <div className="flex items-start gap-1 pt-1 justify-between ">
                <strong className="text-sm flex-1 font-semibold text-white line-clamp-2">
                  {classroom.classroomName}
                </strong>
                <span className="text-white bg-primary text-xs px-2 py-1 rounded-xl inline-flex items-center justify-center">
                  {classroom.licenseCapacity}
                </span>
              </div>
            </div>
            <div className="flex items-center justify-center w-full">
              <button
                className="relative text-statusColor font-medium text-sm"
                onClick={handleViewRoster}
              >
                View Roster
                <span className="absolute bottom-0 left-0 w-full h-[4px] bg-statusColor border-white border-b-[3.5px] rounded-br-[100px] rounded-bl-[100px]"></span>
              </button>
            </div>
          </div>
          <div className="flex items-start justify-between w-full">
            <div className="bg-successAlert py-1 px-1.5 text-white text-xs font-medium rounded">
              Full Time
            </div>
            <HoverCard.Root onOpenChange={handleOpenChange}>
              <HoverCard.Trigger asChild>
                <div className={fullTimeCountClass}>{fullTimeCount}</div>
              </HoverCard.Trigger>
              {classroom?.waitListedStudents?.fullTime?.length > 0 && (
                <HoverCard.Portal>
                  <HoverCard.Content
                    className="bg-white rounded-lg border-[0.5px] border-secondaryVariant2 p-0 shadow-lg w-auto min-w-[150px]"
                    sideOffset={5}
                    style={{ zIndex: 99999 }}
                  >
                    {isLoading ? (
                      <div className="p-2 text-center">Loading...</div>
                    ) : (
                      renderWaitListedStudents(fullTimeStudents)
                    )}
                  </HoverCard.Content>
                </HoverCard.Portal>
              )}
            </HoverCard.Root>
          </div>
        </div>
        {SessionType.FullAndPartTime === classroom.settingType && (
          <div className="flex items-start gap-3">
            <div className="max-w-[119px] h-[28px] w-full"></div>
            <div className="flex items-start justify-between w-full">
              <div className="flex flex-col">
                <button
                  className="flex items-center gap-1"
                  onClick={toggleList}
                >
                  <span className="bg-ageViolation py-1 px-1.5 text-white text-xs font-medium rounded">
                    Part-time{" "}
                  </span>
                  {isCollapseTime ? (
                    <TiArrowSortedUp className="text-secondary w-6 h-6" />
                  ) : (
                    <TiArrowSortedDown className="text-secondary w-6 h-6" />
                  )}
                </button>
                <ul className="text-xs text-secondaryVariant2 mt-1">
                  {isCollapseTime &&
                    classroom.dailyCounts[0].partTimeSlots.map((slot, idx) => {
                      return (
                        <li key={idx}>
                          {slot.slotStartTime} to {slot.slotEndTime}
                        </li>
                      );
                    })}
                </ul>
              </div>
              <HoverCard.Root
                onOpenChange={handleOpenChange}
                openDelay={0}
                closeDelay={0}
              >
                <HoverCard.Trigger asChild>
                  <div className={partTimeCountClass}>{partTimeCount}</div>
                </HoverCard.Trigger>
                {classroom?.waitListedStudents?.partTime?.length > 0 && (
                  <HoverCard.Portal>
                    <HoverCard.Content
                      className="bg-white rounded-lg border-[0.5px] border-secondaryVariant2 p-0 shadow-lg w-auto min-w-[150px]"
                      sideOffset={5}
                      style={{ zIndex: 99999 }}
                    >
                      {isLoading ? (
                        <div className="p-2 text-center">Loading...</div>
                      ) : (
                        renderWaitListedStudents(partTimeStudents)
                      )}
                    </HoverCard.Content>
                  </HoverCard.Portal>
                )}
              </HoverCard.Root>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default SessionTime;
