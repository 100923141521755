import { useQuery } from "@tanstack/react-query";
import { CenterQueryKeyString } from "../enums/centerQueryKey.enum";
import { foraySvc } from "../services/foray.service";

export const useCenterSetup = () => {
  return useQuery({
    queryKey: [CenterQueryKeyString.CenterSetup],
    queryFn: async () => {
      const res = await foraySvc.fetchCenterSetup();
      if (res?.ok) {
        return res.data as any;
      }
      throw new Error("Failed to fetch center setup");
    },
    staleTime: 1000 * 30,
    refetchOnMount:true,
    refetchOnWindowFocus:true
  });
};
