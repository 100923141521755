import React from "react";

const EssentialCharacterCard = () => {
  return (
    <>
      <div className="bg-white rounded-xl p-6 text-center flex gap-6  items-center my-6">
        {" "}
        <img
          className="rounded-full w-[126px] h-[126px] object-cover"
          src="https://a360csastorage.blob.core.windows.net/childcare/518662de-8b71-4c1e-a4fb-9e2b6f924e81-fileName-season-title-img.png"
          alt="season"
        />
        <div className="text-left mt-0">
          <h3 className="text-lg text-secondary font-semibold ">
            Hi, I’m Lucas.
          </h3>
          <p className="text-base text-secondaryVariant">
            I am Enzo’s best friend, at least I think so! Well, you have reached
            the most critical part of the setup. Please do follow the
            instructions on the left very carefully. This software is amazing
            and will essentially do all your work for you. BUT, it needs to be
            setup correctly. By the way, AFTER finishing your setup, check me
            out in Episode 1 of Adventures. This Enzo, hmmm, interesting
            character!
          </p>
        </div>
      </div>
    </>
  );
};

export default EssentialCharacterCard;
