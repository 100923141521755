import React, { useEffect, useState } from "react";
import ReactDatePicker from "../../../../shared/components/reactDatePicker/ReactDatePicker";
import { PiCalendarPlus } from "react-icons/pi";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import MultiSelectClassroom from "../dropdowns/ReactMultiSelect";
import {
  employmentTypes,
  JobTitle,
  jobTitles,
  salaryTypes,
} from "../../constants/dropdowns";
import { IEmployeeInfo } from "../../types/IStaff";
import { IClassroomSetup } from "../../../types/IClassroomSetup.interface";
import { IOptionS } from "../../../types/ICenterSetup.interface";
import { classroomSvc } from "../../../services/classroom.service";
import { showErrorToast, showSuccessToast } from "../../../../shared/utils/toast";
import {
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../../shared/utils/formatDate";
import { useUpdateStaffMutation } from "../../hooks/mutations/useUpdateStaffMutaion";
import { EditType } from "../../enums/editType";
import { WeekDay } from "../../../enums/Foray.enums";
import DaysDropdown from "../../../components/dropdowns/DaysDropdown";
import TimeDropdown from "../../../components/dropdowns/TimeDropdown";
import moment from "moment";
import RequiredAsterisk from "../../../../shared/components/ui/RequiredAsterisk";

// Time options (24-hour format)
export let timeOptions: IOptionS[] = [];
for (let hour = 12; hour <= 23; hour++) {
  for (let minute = 0; minute < 60; minute += 15) {
    let displayHour = hour > 12 ? hour - 12 : hour;
    let hourString = displayHour.toString().padStart(2, "0");
    let minuteString = minute === 0 ? "00" : minute.toString().padStart(2, "0");
    let timeString = `${hourString}:${minuteString}`;
    timeOptions.push({ label: timeString, value: timeString });
  }
}

interface IProps {
  employeeData?: IEmployeeInfo;
  updateEmployeeData: (data: any) => void;
  navigateToTab: (tabKey: string) => void;
}

const ProfessionalInformation: React.FC<IProps> = ({
  employeeData,
  updateEmployeeData,
  navigateToTab,
}) => {
  const isEditMode = !!employeeData?._id;
  const { mutate: updateStaff } = useUpdateStaffMutation();

  const [classrooms, setClassrooms] = useState<IClassroomSetup[]>([]);
  const [classroomOptions, setClassroomOptions] = useState<IOptionS[]>([]);
  const [isSaving, setIsSaving] = useState(false);

  // Professional information form state
  const [professionalInfo, setProfessionalInfo] = useState({
    employeeId: "",
    startAtCenter: "",
    jobTitle: "",
    session: "",
    startTime: "",
    endTime: "",
    salaryType: "",
    salaryAmount: "",
    days: [] as any[],
    classroom: [] as string[],
    color: "#000000", // Default color (black)
  });

  const [initialProfessionalInfo, setInitialProfessionalInfo] = useState({
    employeeId: "",
    startAtCenter: "",
    jobTitle: "",
    session: "",
    startTime: "",
    endTime: "",
    salaryType: "",
    salaryAmount: "",
    days: [] as any[],
    classroom: [] as string[],
    color: "#000000",
  });
  const requiredJobTitles = [
    JobTitle.AIDE,
    JobTitle.ASSISTANT_TEACHER,
    JobTitle.LEAD_TEACHER,
  ];
  const isClassroomRequired = requiredJobTitles.includes(
    professionalInfo.jobTitle as JobTitle
  );

  const isEndTimeAfterStartTime = (startTime: string, endTime: string) => {
    if (startTime === "" || endTime === "") {
      return true;
    }
    const format = "hh:mm A";
    const start = moment(startTime, format);
    const end = moment(endTime, format);
    return end.isAfter(start);
  };

  const handleSelectTime = (time: string, name: string) => {
    setProfessionalInfo((prev) => ({
      ...prev,
      [name]: time,
    }));
  };

  // Track if form has been modified
  const [isFormModified, setIsFormModified] = useState(false);

  // Initialize form data if employee data exists
  useEffect(() => {
    if (employeeData?.professionalInformation) {
      const profInfo = {
        employeeId: employeeData.professionalInformation.employeeId || "",
        startAtCenter: employeeData.professionalInformation.startAtCenter || "",
        jobTitle: employeeData.professionalInformation.jobTitle || "",
        session: employeeData.professionalInformation.session || "",
        startTime: employeeData.professionalInformation.startTime || "",
        endTime: employeeData.professionalInformation.endTime || "",
        salaryType: employeeData.professionalInformation.salaryType || "",
        salaryAmount: employeeData.professionalInformation.salaryAmount || "",
        days: employeeData.professionalInformation.days || [],
        classroom: employeeData.professionalInformation.classroom || [],
        color: employeeData.professionalInformation.color || "#000000", // Load color if available
      };

      setProfessionalInfo(profInfo);
      setInitialProfessionalInfo(JSON.parse(JSON.stringify(profInfo)));
    }

    // Reset form modification status
    setIsFormModified(false);
  }, [employeeData]);

  const isFormValid = () => {
    return Object.values(professionalInfo).every((value: any) => {
      if (Array.isArray(value)) return value.length > 0;
      return value !== "" && value !== 0;
    });
  };

  const isSaveButtonDisabled =
    isSaving || (isEditMode && !isFormModified) || !isFormValid();

  // Fetch classrooms data
  const getClassrooms = async () => {
    try {
      const res = await classroomSvc.fetchClassrooms();
      if (res?.ok) {
        const classroomSetups = res?.data?.existingClassroomSetups || [];
        const options = classroomSetups.map((classroom: IClassroomSetup) => ({
          value: classroom?._id || "",
          label: classroom?.classroomName || "",
        }));

        setClassroomOptions(options);
        setClassrooms(classroomSetups);
      }
    } catch (error) {
      console.error("Error fetching classrooms:", error);
    }
  };

  const ensureProperDateFormat = (date: string): string => {
    // If the date is already in MM/DD/YYYY format, return it
    if (/^\d{2}\/\d{2}\/\d{4}$/.test(date)) {
      return date;
    }

    try {
      // Try to convert it to a proper Date object and then format it
      const dateObj = revertToDateType(date);
      if (dateObj) {
        const formattedDate = formatDateSlashFullYear(dateObj);
        return formattedDate || date; // Return original if formatting fails
      }
    } catch (error) {
      console.error("Error formatting date:", error);
    }

    return date; // Return original if conversion fails
  };

  useEffect(() => {
    getClassrooms();
  }, []);

  // Check if form has been modified
  useEffect(() => {
    if (!isEditMode) return;

    const isModified = () => {
      // Compare all fields in professional information
      return Object.keys(professionalInfo).some((key) => {
        const k = key as keyof typeof professionalInfo;

        // Special handling for arrays
        if (Array.isArray(professionalInfo[k])) {
          const currentArray = professionalInfo[k] as any[];
          const initialArray = initialProfessionalInfo[k] as any[];

          if (currentArray.length !== initialArray.length) {
            return true;
          }

          // For simple array of primitives, we can sort and compare
          if (k === "days" || k === "classroom") {
            const sortedCurrent = [...currentArray].sort();
            const sortedInitial = [...initialArray].sort();
            return (
              JSON.stringify(sortedCurrent) !== JSON.stringify(sortedInitial)
            );
          }

          return false;
        }

        return professionalInfo[k] !== initialProfessionalInfo[k];
      });
    };

    setIsFormModified(isModified());
  }, [professionalInfo, initialProfessionalInfo, isEditMode]);

  // Handle input changes
  const handleInputChange = (field: string, value: any) => {
    setProfessionalInfo((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  const handleClassroomsChange = (selectedValues: string[]) => {
    handleInputChange("classroom", selectedValues);
  };

  const handleSelectDays = (selectedDays: string[]) => {
    setProfessionalInfo((prev) => ({
      ...prev,
      days: selectedDays,
    }));
  };
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = formatDateSlashFullYear(date);
      if (formattedDate) {
        setProfessionalInfo((prev) => ({
          ...prev,
          startAtCenter: formattedDate,
        }));
      }
    }
  };

  const validateProfessionalInfo = (): boolean => {
    const requiredFields = [
      "employeeId",
      "jobTitle",
      "startTime",
      "endTime",
      "salaryType",
      "salaryAmount",
    ];

    for (const field of requiredFields) {
      if (!professionalInfo[field as keyof typeof professionalInfo]) {
        showErrorToast(
          `${field.charAt(0).toUpperCase() + field.slice(1)} is required`
        );
        return false;
      }
    }
    if (
      !isEndTimeAfterStartTime(
        professionalInfo?.startTime,
        professionalInfo?.endTime
      )
    ) {
      showErrorToast("Start time should be before end time.");
      return false;
    }

    if (!professionalInfo.startAtCenter) {
      showErrorToast("Start date is required");
      return false;
    }

    if (professionalInfo.days.length === 0) {
      showErrorToast("At least one working day must be selected");
      return false;
    }

    if (professionalInfo.classroom.length === 0) {
      showErrorToast("At least one classroom must be selected");
      return false;
    }

    if (isClassroomRequired && professionalInfo.classroom.length === 0) {
      showErrorToast(
        "At least one classroom must be selected for this job title"
      );
      return false;
    }

    if (professionalInfo.classroom.length > 2) {
      showErrorToast("A maximum of 2 classrooms can be selected");
      return false;
    }

    return true;
  };

  // Handle form cancel
  const handleCancel = () => {
    // Reset to initial state
    setProfessionalInfo(JSON.parse(JSON.stringify(initialProfessionalInfo)));

    // Reset form modification status
    setIsFormModified(false);

    showSuccessToast("Changes cancelled");
  };

  // Handle form save
  const handleSave = () => {
    // Prevent multiple save operations
    if (isSaving) {
      console.log("Save already in progress");
      return;
    }

    // For edit mode, only save if form is modified
    if (isEditMode && !isFormModified) {
      // Skip saving and navigate directly to next tab
      navigateToTab(EditType.documents);
      return;
    }

    // Validate form data
    if (!validateProfessionalInfo()) {
      return;
    }

    // Set saving flag
    setIsSaving(true);

    const formattedProfInfo = {
      ...professionalInfo,
      startAtCenter: ensureProperDateFormat(professionalInfo.startAtCenter),
    };
    // Prepare data for API
    const formattedData = {
      professionalInformation: formattedProfInfo,
    };

    if (isEditMode && employeeData?._id) {
      // Update existing employee
      updateStaff(
        {
          staffId: employeeData._id,
          updatedData: formattedData,
        },
        {
          onSuccess: (response) => {
            showSuccessToast("Professional information updated successfully");

            // Update parent component with response data
            const responseData = response?.data || response;
            updateEmployeeData(responseData);

            // Update initial state for future cancellations
            setInitialProfessionalInfo(
              JSON.parse(JSON.stringify(professionalInfo))
            );

            // Reset form modification status
            setIsFormModified(false);

            // Navigate to next tab
            setTimeout(() => {
              navigateToTab(EditType.documents);
            }, 100);
          },
          onError: (error) => {
            showErrorToast("Failed to update professional information");
            console.error("Save error:", error);
          },
          onSettled: () => {
            setIsSaving(false);
          },
        }
      );
    } else {
      // Handle new employee case
      // Create a mock response with the updated professional info
      const mockEmployeeData = {
        ...employeeData,
        professionalInformation: professionalInfo,
      };

      // Update parent component
      updateEmployeeData(mockEmployeeData);

      // Update initial state for future cancellations
      setInitialProfessionalInfo(JSON.parse(JSON.stringify(professionalInfo)));

      // Reset form modification status
      setIsFormModified(false);

      showSuccessToast("Professional information saved");

      // Navigate to next tab
      setTimeout(() => {
        navigateToTab(EditType.documents);
        setIsSaving(false);
      }, 100);
    }
  };

  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">
          Fill Professional Information{" "}
        </h2>
        <div className="grid grid-cols-6 gap-3 mb-9">
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Employee Id <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={professionalInfo.employeeId}
              onChange={(e) => handleInputChange("employeeId", e.target.value)}
            />
          </div>

          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Start Date <RequiredAsterisk />{" "}
            </label>
            <div className="w-full relative">
              <ReactDatePicker
                placeholder="MM/DD/YYYY"
                // maxDate={new Date()}
                value={
                  professionalInfo.startAtCenter
                    ? revertToDateType(professionalInfo.startAtCenter as string)
                    : null
                }
                onChange={handleDateChange}
              />
              <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
            </div>
          </div>

          <div>
            <ReactSelect
              options={jobTitles}
              label="Job Title"
              placeholder="Job Title"
              required
              value={
                jobTitles.find(
                  (option) => option.value === professionalInfo.jobTitle
                ) || null
              }
              onChange={(option) =>
                handleInputChange("jobTitle", option?.value || "")
              }
            />
          </div>
          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Color
            </label>
            <input
              type="color"
              className="w-full bg-white border border-secondaryVariant2 rounded-lg p-3 h-[54px]"
              value={professionalInfo.color}
              onChange={(e) => handleInputChange("color", e.target.value)}
            />
          </div>
          <div>
            <ReactSelect
              label="Full / Part Time"
              placeholder="Full / Part Time "
              required
              options={employmentTypes}
              value={
                employmentTypes.find(
                  (option) => option.value === professionalInfo.session
                ) || null
              }
              onChange={(e) => handleInputChange("session", e?.value || "")}
            />
          </div>

          <div>
            <MultiSelectClassroom
              label="Primary Classrooms"
              placeholder="Select Classrooms"
              options={classroomOptions}
              selectedValues={professionalInfo.classroom}
              onChange={handleClassroomsChange}
              required={isClassroomRequired} // Dynamically make it required
              maxSelections={2} // Ensure max of 2 classrooms
            />
          </div>

          <div>
            <DaysDropdown
              options={[
                { value: WeekDay.MONDAY, label: "Monday" },
                { value: WeekDay.TUESDAY, label: "Tuesday" },
                { value: WeekDay.WEDNESDAY, label: "Wednesday" },
                { value: WeekDay.THURSDAY, label: "Thursday" },
                { value: WeekDay.FRIDAY, label: "Friday" },
                { value: WeekDay.Saturday, label: "Saturday" },
                { value: WeekDay.Sunday, label: "Sunday" },
              ]}
              label="Working Days"
              placeholder="Select Working Days"
              value={professionalInfo?.days}
              onSelectDays={handleSelectDays}
              daysInStudentSetup={true}
              required
            />
          </div>

          <div>
            <TimeDropdown
              times={timeOptions}
              label="Start Time"
              placeholder="Set start time"
              value={professionalInfo?.startTime}
              onSelectTime={(value) => {
                handleSelectTime(value, "startTime");
              }}
              required={true}
            />
          </div>

          <div>
            <TimeDropdown
              times={timeOptions}
              label="End Time"
              placeholder="Set End time"
              value={professionalInfo?.endTime}
              onSelectTime={(value) => {
                handleSelectTime(value, "endTime");
              }}
              required={true}
            />
          </div>

          <div>
            <ReactSelect
              label="Salary Type"
              placeholder="Salary Type"
              required
              options={salaryTypes}
              value={
                salaryTypes.find(
                  (option) => option.value === professionalInfo.salaryType
                ) || null
              }
              onChange={(option) =>
                handleInputChange("salaryType", option?.value || "")
              }
            />
          </div>

          <div>
            <label className="text-secondaryVariant text-base mb-1 block">
              Salary Amount <RequiredAsterisk />{" "}
            </label>
            <input
              type="text"
              className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              value={professionalInfo.salaryAmount}
              onChange={(e) =>
                handleInputChange("salaryAmount", e.target.value)
              }
            />
          </div>
        </div>

        <div className="flex items-center gap-3">
          <button
            className={`btnPrimary max-w-[181px] ${
              isSaveButtonDisabled ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleSave}
            disabled={isSaveButtonDisabled}
          >
            {isSaving
              ? "Saving..."
              : isEditMode
              ? "Update & Next"
              : "Save & Next"}
          </button>
          <button
            className={`btnSimple max-w-[181px] ${
              !isFormModified || isSaving ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleCancel}
            disabled={!isFormModified || isSaving}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ProfessionalInformation;
