import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  Daum as Product,
  StripePricesResponseBody,
} from "../../types/ISubscription.interface";
import { stripeSvc } from "../../services/stripe.service";

import {
  stripePremiumMonthlyPriceId,
  stripePremiumYearlyPriceId,
} from "../../constants/environment.constants";
import { sharedRoutes } from "../../routes/routes";
import CancelSubscriptionDialog from "../../../../shared/components/pricingPlans/CancelSubscriptionDialog";

interface PricingPlanProps {
  onSelectPlan: (priceId: string) => void;
}

interface EnhancedProduct extends Product {
  prices: StripePricesResponseBody["data"];
}

const PricingPlans: React.FC<PricingPlanProps> = ({ onSelectPlan }) => {
  const [billingInterval, setBillingInterval] = useState<"month" | "year">(
    "month"
  );
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [products, setProducts] = useState<EnhancedProduct[]>([]);
  const [currentPlan, setCurrentPlan] = useState("");
  const [currentSubscriptionId, setCurrentSubscriptionId] = useState<
    string | null
  >(null);
  const [isPremiumPlan, setIsPremiumPlan] = useState(false);
  const [showCancelDialog, setShowCancelDialog] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    fetchProductsAndPrices();
    fetchSubscription();
  }, []);

  const fetchProductsAndPrices = async () => {
    try {
      setIsLoading(true);
      setError(null);

      const productsResponse = await stripeSvc.fetchProducts();
      const pricesResponse = await stripeSvc.fetchProductPrices();

      if (!productsResponse.ok || !pricesResponse.ok) {
        throw new Error("Failed to fetch products or prices");
      }

      const enhancedProducts = productsResponse.data.map((product) => ({
        ...product,
        prices: pricesResponse.data.filter(
          (price) => price.product === product.id
        ),
      }));

      setProducts(enhancedProducts);
    } catch (err) {
      setError("Failed to load pricing plans. Please try again later.");
      console.error("Error loading products:", err);
    } finally {
      setIsLoading(false);
    }
  };

  const fetchSubscription = async () => {

    // export interface Root {
    //   id: string
    //   status: string
    //   current_period_end: number
    //   plan: string
    // }
    
    try {
      const userSubscriptions = await stripeSvc.fetchMySubsciption();

      if (
        userSubscriptions?.data?.length > 0 &&
        userSubscriptions.data[0]?.subscriptionId
      ) {
        const subscription = await stripeSvc.fetchSubsciption(
          userSubscriptions.data[0].subscriptionId
        );
        console.log("subscription.data.status", subscription.data.status);
        if (
          subscription.data.status !== "active" &&
          subscription.data.status !== "trialing"
        ) {
          return;
        }

        const currentPlanId = subscription.data.plan;
        console.log("currentPlan", currentPlanId);
        setCurrentPlan(currentPlanId);
        setCurrentSubscriptionId(userSubscriptions.data[0].subscriptionId);

        // Check if current plan is premium
        const isPremium =
          currentPlanId === stripePremiumMonthlyPriceId ||
          currentPlanId === stripePremiumYearlyPriceId;
        setIsPremiumPlan(isPremium);
      }
    } catch (error) {
      console.error("Error fetching subscription:", error);
      // Handle error appropriately
    }
  };

  const formatAmount = (amount: number, currency: string) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: currency,
    }).format(amount / 100);
  };

  if (isLoading) {
    return (
      <div className="flex justify-center items-center p-8">
        Loading pricing plans...
      </div>
    );
  }

  if (error) {
    return <div className="text-red-600 text-center p-4">{error}</div>;
  }

  return (
    <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      {/* Billing Toggle */}
      <div className="flex items-center justify-center">
        <div className="inline-flex justify-center px-1 py-1 bg-gray-100 mb-8 rounded-full">
          <button
            className={`px-8 py-2 rounded-full text-base font-medium transition-colors
            ${
              billingInterval === "month"
                ? "bg-primary text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
            onClick={() => setBillingInterval("month")}
          >
            Monthly
          </button>
          <button
            className={`px-8 py-2 rounded-full text-base font-medium transition-colors relative
            ${
              billingInterval === "year"
                ? "bg-primary text-white"
                : "bg-gray-100 text-gray-600 hover:bg-gray-200"
            }`}
            onClick={() => setBillingInterval("year")}
          >
            Yearly
            {/* <span className="absolute -top-2 -right-2 bg-green-500 text-white text-xs px-2 py-1 rounded-full">
            Save 17% 
          </span> */}
          </button>
        </div>
      </div>

      {/* Plans Container */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        {products.map((product) => {
          const price = product.prices.find(
            (p) => p.recurring.interval === billingInterval
          );

          if (!price) return null;

          return (
            <div
              key={product.id}
              className="bg-white rounded-lg shadow-lg p-6 border border-gray-200 hover:shadow-xl transition-shadow"
            >
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                {product.name}
              </h3>
              <p className="text-gray-500 mb-4">{product.description}</p>

              <div className="mb-6">
                <span className="text-4xl font-bold text-gray-900">
                  {formatAmount(price.unit_amount, price.currency)}
                </span>
                <span className="text-gray-500 ml-2">/{billingInterval}</span>
              </div>

              <ul className="space-y-3 mb-8">
                {product.marketing_features.map((feature, index) => (
                  <li key={index} className="flex items-start">
                    <svg
                      className="h-5 w-5 text-green-500 mr-2 flex-shrink-0"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path d="M5 13l4 4L19 7"></path>
                    </svg>
                    <span className="text-gray-600">{feature.name}</span>
                  </li>
                ))}
              </ul>

              {currentPlan === price.id ? (
                <div className="space-y-2">
                  <div className="w-full bg-gray-400 text-white py-2 px-4 rounded-md font-medium">
                    Current Plan
                  </div>
                  <button
                    onClick={() => {
                      console.log("clicked");
                      setShowCancelDialog(true);
                    }}
                    className="w-full bg-red-500 text-white py-2 px-4 rounded-md hover:bg-red-600 transition-colors"
                  >
                    Cancel Plan
                  </button>
                </div>
              ) : (
                <button
                  onClick={() => {
                    if (isPremiumPlan && currentSubscriptionId) {
                      navigate(sharedRoutes.upgradeConfirmation, {
                        state: {
                          subscriptionId: currentSubscriptionId,
                          newPriceId: price.id,
                        },
                      });
                    } else {
                      onSelectPlan(price.id);
                    }
                  }}
                  className="w-full bg-primary/90 text-white py-2 px-4 rounded-md hover:bg-primary transition-colors font-medium"
                >
                  Subscribe
                </button>
              )}
            </div>
          );
        })}
      </div>

      <CancelSubscriptionDialog
        isOpen={showCancelDialog}
        onClose={() => setShowCancelDialog(false)}
        onConfirm={async () => {
          try {
            if (currentSubscriptionId) {
              await stripeSvc.cancelSubscription(currentSubscriptionId);
              setCurrentPlan("");
              setCurrentSubscriptionId(null);
              setIsPremiumPlan(false);
              setShowCancelDialog(false);
            }
          } catch (error) {
            console.error("Error canceling subscription:", error);
          }
        }}
      />
    </div>
  );
};

export default PricingPlans;
