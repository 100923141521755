// features/EnrollmentView/enrollmentViewSlice.ts
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IEnrollmentSwitchesState {
  strategicView: boolean;
  enrollmentNo: boolean;
  ageViolation: boolean;
  movements: boolean;
  optimization: boolean;
}

interface EnrollmentViewState {
  previewId: string | undefined; // Changed from string | null to string | undefined
  switches: IEnrollmentSwitchesState;
}

const initialState: EnrollmentViewState = {
  previewId: undefined,
  switches: {
    strategicView: true,
    enrollmentNo: false,
    ageViolation: false,
    movements: false,
    optimization: false,
  }
};

const enrollmentViewSlice = createSlice({
  name: "enrollmentView",
  initialState,
  reducers: {
    setPreviewId: (state, action: PayloadAction<string | undefined>) => {
      state.previewId = action.payload;
      // Only set optimization to true if previewId exists
      if (action.payload) {
        state.switches.optimization = true;
      }
    },
    setSwitches: (state, action: PayloadAction<Partial<IEnrollmentSwitchesState>>) => {
      state.switches = { ...state.switches, ...action.payload };
    },
    resetEnrollmentView: (state) => {
      state.previewId = undefined;
      state.switches = initialState.switches;
    },
  },
});

export const { setPreviewId, setSwitches, resetEnrollmentView } = enrollmentViewSlice.actions;
export default enrollmentViewSlice.reducer;