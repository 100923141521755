import * as React from "react";
import { IoCloseOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { AiOutlineDollar } from "react-icons/ai";
import { ExistingCenterSetup } from "../../../types/ICenterSetup.interface";
import { formatMonthDateSlash } from "../../../../shared/utils/formatDate";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  centerSetup: ExistingCenterSetup;
}

const SummerPrintPreviewModal: React.FC<ModalProps> = ({ isOpen, onClose, centerSetup }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white p-4 rounded-3xl shadow-lg w-[1034px]">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-2xl font-semibold text-secondary">Summer Week</h2>
          <button
            onClick={onClose}
            className="bg-secondaryNeutral w-12 h-12 rounded-full flex justify-center items-center  "
          >
            <IoCloseOutline className="w-6 h-6 text-secondary" />
          </button>
        </div>
        <div className="flex items-center gap-12 text-base mb-12">
          <span className="text-primary">
            <strong className="text-secondary">From:</strong> {formatMonthDateSlash(centerSetup?.summerProgram?.summerStartDate)}
          </span>
          <span className="text-primary">
            <strong className="text-secondary">To:</strong> {formatMonthDateSlash(centerSetup?.summerProgram?.summerEndDate)}
          </span>
        </div>
        <p className="text-base text-secondary font-bold py-10 text-start">
          Preview Required
        </p>
        <div className="flex justify-end">
          <button className="bg-primary text-sm font-semibold text-white h-9 w-full rounded max-w-[131px]">
            Print
          </button>
        </div>
      </div>
    </div>
  );
};

export default SummerPrintPreviewModal;
