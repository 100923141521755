import { ApiResponse } from "apisauce";
import {
  ISubscriptionResponse,
  MySubscription,
  ProrateUpgradeResponse,
  StripePricesResponseBody,
  StripeProductsBody,
  SubscriptionResponse,
  SubscriptionResponseBody,
  SubscriptionUpdateResponse,
  UserSubscription,
} from "../types/ISubscription.interface";
import { paymentApi } from "../utils/axios";

const subscribeToService = async (priceId: string) => {
  try {
    const res = await paymentApi.post<ISubscriptionResponse>(
      `subscription/subscribe?priceId=${priceId}`
    );
    if (res?.data?.clientSecret) {
      return { data: res.data.clientSecret, ok: true };
    }
    throw new Error("No client secret received");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

const fetchProducts = async () => {
  try {
    const res = await paymentApi.get<StripeProductsBody>(`stripe/products`);
    if (res?.data) {
      console.log("res.data: ", res.data);
      return { data: res.data.data, ok: true };
    }
    throw new Error("No products found");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

const fetchProductPrices = async (productId?: string) => {
  try {
    const res = await paymentApi.get<StripePricesResponseBody>(
      `stripe/prices`,
      {
        params: productId ? { productId } : undefined,
      }
    );
    if (res?.data) {
      return { data: res.data.data, ok: true };
    }
    throw new Error("No prices found");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

const fetchSubsciption = async (subscriptionId: string) => {
  try {
    const res = await paymentApi.get<SubscriptionResponseBody>(
      `stripe/subscriptions/${subscriptionId}`
    );
    if (res?.data) {
      console.log("resp", res.data);
      return { data: res.data, ok: true };
    }
    throw new Error("No data found");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

// UserSubscription
const fetchMySubsciption = async () => {
  try {
    const res = await paymentApi.get<UserSubscription[]>(`subscription`);
    if (res?.data) {
      return { data: res.data, ok: true };
    }
    throw new Error("No data found");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

const calculateUpgrade = async (subscriptionId: string, newPriceId: string) => {
  try {
    const res = await paymentApi.get<ProrateUpgradeResponse>(
      `subscription/${subscriptionId}/calculate-upgrade?newPriceId=${newPriceId}`
    );
    if (res?.data) {
      return { data: res.data, ok: true };
    }
    throw new Error("No data found");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

const updateSubscription = async (
  subscriptionId: string,
  newPriceId: string
) => {
  try {
    const res = await paymentApi.patch<SubscriptionUpdateResponse>(
      `subscription/${subscriptionId}/update`,
      {
        newPriceId,
      }
    );
    if (res?.data) {
      return { data: res.data, ok: true };
    }
    throw new Error("No data found");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

const cancelSubscription = async (subscriptionId: string) => {
  try {
    const res = await paymentApi.delete<SubscriptionUpdateResponse>(
      `subscription/${subscriptionId}/cancel`
    );
    if (res?.data) {
      return { data: res.data, ok: true };
    }
    throw new Error("No data found");
  } catch (err) {
    console.log("error: ", err);
    throw err;
  }
};

const fetchMyCurrentSubscription = async () => {
  try {
    const res = await paymentApi.get<SubscriptionResponse>(
      `stripe/mySubscriptions`
    );
    if (res?.data) {
      return {
        success: true,
        data: res.data.data,
      };
    }
    return {
      success: false,
      data: [],
    };
  } catch (err) {
    console.log("error: ", err);
    return {
      success: false,
      data: [],
    };
  }
};

export const stripeSvc = {
  subscribeToService,
  fetchProducts,
  fetchProductPrices,
  fetchSubsciption,
  fetchMySubsciption,
  calculateUpgrade,
  updateSubscription,
  cancelSubscription,
  fetchMyCurrentSubscription,
};
