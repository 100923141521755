import React, { FC, useEffect, useState } from "react";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiDeleteBin3Line } from "react-icons/ri";
import TimeDropdown from "../../components/dropdowns/TimeDropdown";
import InputField from "../../components/inputsFields/InputField";
import { timeOptions } from "../../constants/constantValues";
import { initPartTimeSessionEssentials } from "../../constants/initialState";
import { IPartTimeSlots } from "../../types/IClassroomSetup.interface";
import { FiEdit } from "react-icons/fi";
import { MdInfoOutline } from "react-icons/md";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import { parseTime } from "../../../shared/utils/parseTime";

const tooltips = {
  partTime: {
    id: "partTime",
    content: `<p>Please note that Part-Time session timings cannot overlap. Also, Before-After/School Age programs are NOT defined as Part-Time so please do NOT include them as Part-Time.</p>`,
  },
};

interface Props {
  partTimeSessions: IPartTimeSlots[];
  setPartTimeSessions: React.Dispatch<React.SetStateAction<IPartTimeSlots[]>>;
  havePartTimeSessions: boolean;
  setHavePartTimeSessions: (check: boolean) => void;
  handleCancelPartTimeSession: () => void;
  handleSave: () => Promise<void>;
}

const PartTimeSession: FC<Props> = ({
  partTimeSessions,
  setPartTimeSessions,
  havePartTimeSessions,
  setHavePartTimeSessions,
  handleCancelPartTimeSession,
  handleSave,
}) => {
  const [isEdit, setIsEdit] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [hasChanges, setHasChanges] = useState(false);
  const [initialFormState, setInitialFormState] = useState({
    havePartTimeSessions,
    partTimeSessions,
  });
  // const handleEdit = () => {
  //   setIsEdit((prev) => !prev);
  // };
  const handleEdit = () => {
    if (!isEdit) {
      setInitialFormState({
        havePartTimeSessions,
        partTimeSessions: JSON.parse(JSON.stringify(partTimeSessions)),
      });
    }
    setIsEdit((prev) => !prev);
    setHasChanges(false);
  };

  const handleHavePartTimeSession = (check: boolean) => {
    setHavePartTimeSessions(check);
    if (!check) {
      setPartTimeSessions([]);
    } else setPartTimeSessions([initPartTimeSessionEssentials]);
  };

  const handlePartTimeSessions = (
    fieldName: string,
    value: string | number,
    index: number
  ) => {
    setPartTimeSessions((prev) => {
      const updatedSessions = [...prev];
      updatedSessions[index] = {
        ...updatedSessions[index],
        [fieldName]: value,
      };
      setHasChanges(true);
      return updatedSessions;
    });
  };

  const handleAddPartTimeSession = (index: number) => {
    setPartTimeSessions((prev) => {
      const updatedSessions = [...prev];
      updatedSessions.splice(index + 1, 0, initPartTimeSessionEssentials);
      return updatedSessions;
    });
  };

  const handleRemovePartTimeSession = (index: number) => {
    setPartTimeSessions((prev) => {
      const updatedSessions = [...prev];
      updatedSessions.splice(index, 1);
      return updatedSessions;
    });
  };

  const checkForChanges = () => {
    // Check if radio button value has changed
    const radioChanged =
      initialFormState.havePartTimeSessions !== havePartTimeSessions;

    // If not havePart time sessions, just check if that's different from initial
    if (!havePartTimeSessions) {
      return radioChanged;
    }

    // If we have sessions, check if they're different from initial in a meaningful way
    const initialSessionsJSON = JSON.stringify(
      initialFormState.partTimeSessions
    );
    const currentSessionsJSON = JSON.stringify(partTimeSessions);

    const sessionsChanged = initialSessionsJSON !== currentSessionsJSON;

    return radioChanged || sessionsChanged;
  };

  // const handleCancel = () => {
  // handleCancelPartTimeSession();
  // setIsEdit(false);
  // };
  const handleCancel = () => {
    setHavePartTimeSessions(initialFormState.havePartTimeSessions);
    setPartTimeSessions(
      JSON.parse(JSON.stringify(initialFormState.partTimeSessions))
    );
    handleCancelPartTimeSession();
    setIsEdit(false);
    setHasChanges(false);
  };

  // const validateSessions = () => {
  //   const hasErrors =
  //     (partTimeSessions?.length === 0 && havePartTimeSessions) ||
  //     partTimeSessions.some(
  //       (session) =>
  //         !session?.name?.trim() || !session.startTime || !session.endTime
  //     );
  //   setIsDisabled(hasErrors);
  // };

  const validateSessions = () => {
    const validationErrors: string[] = [];

    // Convert times to comparable Date objects for validation
    const parsedSessions = partTimeSessions.map((session, index) => ({
      index,
      startTime: parseTime(session.startTime),
      endTime: parseTime(session.endTime),
      name: session.name,
    }));

    partTimeSessions.forEach((session, index) => {
      if (!session.name?.trim() || !session.startTime || !session.endTime) {
        validationErrors.push(`Session ${index + 1} has missing fields.`);
      }
    });

    parsedSessions.forEach((session) => {
      if (session.startTime >= session.endTime) {
        validationErrors.push(
          `Session "${session.name}" must have an end time greater than the start time.`
        );
      }
    });

    parsedSessions.forEach((session, i) => {
      parsedSessions.forEach((otherSession, j) => {
        if (i !== j) {
          if (
            (session.startTime >= otherSession.startTime &&
              session.startTime < otherSession.endTime) ||
            (session.endTime > otherSession.startTime &&
              session.endTime <= otherSession.endTime) ||
            (session.startTime <= otherSession.startTime &&
              session.endTime >= otherSession.endTime)
          ) {
            validationErrors.push(
              `Session "${session.name}" overlaps with "${otherSession.name}".`
            );
          }
        }
      });
    });

    setErrors(validationErrors);
    // setIsDisabled(validationErrors.length > 0);
    setIsDisabled(validationErrors.length > 0 || !hasChanges);
  };

  // const handleSaveClick = () => {
  //   handleSave();
  //   setIsEdit(false);
  // };
  const handleSaveClick = () => {
    if (hasChanges && !isDisabled) {
      handleSave();
      setIsEdit(false);
      setHasChanges(false);
      // After saving, update the initialFormState to the current state
      setInitialFormState({
        havePartTimeSessions,
        partTimeSessions: JSON.parse(JSON.stringify(partTimeSessions)),
      });
    }
  };

  useEffect(() => {
    validateSessions();
  }, [partTimeSessions]);

  useEffect(() => {
    if (isEdit) {
      setHasChanges(checkForChanges());
    }
  }, [partTimeSessions, havePartTimeSessions, isEdit]);
  function handleSectionChange(
    index: any,
    arg1: string,
    arg2: string,
    selectedTime: string
  ) {
    throw new Error("Function not implemented.");
  }

  return (
    <>
      <div>
        <h3 className="text-xl text-secondary font-semibold mb-3 flex items-start gap-2">
          Part-time Sessions{" "}
          <span data-tooltip-id={tooltips.partTime.id}>
            <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
          </span>
        </h3>
        <TooltipComponent
          id={tooltips.partTime.id}
          content={tooltips.partTime.content}
          isWhite={true}
        />
        <div className="bg-white p-6 rounded-xl mb-3">
          <div className="flex items-center justify-between">
            <h3 className="text-base text-secondary mb-3">
              Do you have part-time students in your center?{" "}
              <span className="text-primary text-base">
                (Please do <span className="font-bold">NOT</span> include
                Before-After/School Age programs here)?
              </span>
            </h3>
            {!isEdit && (
              <button
                onClick={handleEdit}
                className="text-statusColor inline-flex items-center gap-2 text-lg"
              >
                Modify <FiEdit />
              </button>
            )}
          </div>
          <div
            className={`flex items-center gap-6 mb-6 ${
              !isEdit ? "opacity-50" : ""
            }`}
          >
            <div className="flex items-center gap-2">
              <input
                disabled={!isEdit}
                type="radio"
                className={`w-5 h-5 border-2 ${
                  isEdit ? "cursor-pointer" : ""
                } border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3`}
                checked={havePartTimeSessions}
                onChange={() => {
                  handleHavePartTimeSession(true);
                }}
              />

              <label htmlFor="" className="text-sm text-secondaryVariant">
                Yes
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                disabled={!isEdit}
                type="radio"
                className={`w-5 h-5 border-2 ${
                  isEdit ? "cursor-pointer" : ""
                } border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3`}
                checked={!havePartTimeSessions}
                onChange={() => {
                  handleHavePartTimeSession(false);
                }}
              />
              <label htmlFor="" className="text-sm text-secondaryVariant">
                No
              </label>
            </div>
          </div>
          {havePartTimeSessions && (
            <div className="grid grid-cols-12 gap-2">
              <div className="col-span-8">
                <h3 className="text-base text-secondary font-medium mb-4">
                  Please select up to 4 part-time sessions at your center.
                </h3>
                <div className="grid grid-cols-6 gap-3 mb-3">
                  {partTimeSessions?.map(
                    (session: IPartTimeSlots, index: number) => (
                      <div
                        className="col-span-6 flex items-center gap-6"
                        key={index}
                      >
                        <div className="grid grid-cols-12 gap-3 bg-secondaryNeutral py-1 px-2 rounded-lg">
                          <div className="col-span-4">
                            <InputField
                              disabled={!isEdit}
                              label="Session Name"
                              type="text"
                              name="name"
                              placeholder=""
                              value={session?.name}
                              onChange={(e) =>
                                handlePartTimeSessions(
                                  e.target.name,
                                  e.target.value,
                                  index
                                )
                              }
                              required
                            />
                          </div>
                          <div className="col-span-4">
                            <div>
                              <TimeDropdown
                                key={session?.startTime}
                                disabled={!isEdit}
                                times={timeOptions}
                                label="Start Time"
                                required
                                placeholder="Set start time"
                                value={session?.startTime}
                                onSelectTime={(selectedTime) => {
                                  handlePartTimeSessions(
                                    "startTime",
                                    selectedTime,
                                    index
                                  );
                                }}
                              />
                            </div>
                          </div>
                          <div className="col-span-4 flex items-end w-full gap-4">
                            <div className="w-full">
                              <TimeDropdown
                                key={session?.endTime}
                                disabled={!isEdit}
                                times={timeOptions}
                                label="End Time "
                                required
                                placeholder="Set End time"
                                value={session?.endTime}
                                onSelectTime={(selectedTime) => {
                                  handlePartTimeSessions(
                                    "endTime",
                                    selectedTime,
                                    index
                                  );
                                }}
                              />
                            </div>
                            <button
                              className={`${
                                !isEdit ? "text-[#DADADA]" : "text-dangerAlert"
                              } bg-white rounded-full p-1`}
                              onClick={() => {
                                handleRemovePartTimeSession(index);
                              }}
                              disabled={!isEdit}
                            >
                              <RiDeleteBin3Line className="w-6 h-6 " />
                            </button>
                          </div>
                        </div>
                        {partTimeSessions?.length < 4 && (
                          <div>
                            <button
                              className={`text-base  ${
                                !isEdit ? "text-[#DADADA]" : "text-statusColor"
                              } flex items-center gap-2 font-medium `}
                              onClick={() => {
                                handleAddPartTimeSession(index);
                              }}
                              disabled={!isEdit}
                            >
                              Add <IoIosAddCircleOutline className="w-6 h-6" />
                            </button>
                          </div>
                        )}
                      </div>
                    )
                  )}
                  {partTimeSessions?.length === 0 && (
                    <button
                      className={`text-base  ${
                        !isEdit ? "text-[#DADADA]" : "text-statusColor"
                      } flex items-center gap-2 font-medium `}
                      onClick={() => {
                        handleAddPartTimeSession(0);
                      }}
                      disabled={!isEdit}
                    >
                      {" "}
                      <IoIosAddCircleOutline className="w-6 h-6" />
                      Add
                    </button>
                  )}
                </div>
              </div>
              <div className="col-span-4 flex justify-center items-center">
                <div className="border border-black p-6 rounded-lg text-center text-base text-primary font-medium">
                  You will be able to assign part-time sessions to the
                  classrooms in Section 1 below
                </div>
              </div>
            </div>
          )}
          {isEdit && (
            <div className="mb-3">
              {errors?.length > 0 && (
                <>
                  <div>
                    {errors?.map((error, idx) => (
                      <p key={idx} className="text-dangerAlert">
                        {error}
                      </p>
                    ))}
                  </div>
                  <br />
                </>
              )}
              <button
                onClick={handleSaveClick}
                className={`bg-primary text-white rounded-lg h-[58px] px-6 min-w-[125px] ${
                  isDisabled || !hasChanges
                    ? "opacity-50 cursor-not-allowed"
                    : ""
                }`}
                // disabled={isDisabled}
                disabled={isDisabled || !hasChanges}
              >
                Save Part-time Session Timings
              </button>
              <button
                onClick={handleCancel}
                className=" text-black rounded-lg h-[58px] ml-2 px-6 min-w-[125px] border-secondary border"
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default PartTimeSession;
