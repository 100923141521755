import React, { useState, useEffect } from "react";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import { IoIosAddCircleOutline } from "react-icons/io";
import { RiDeleteBin3Line } from "react-icons/ri";

import {
  certifications,
  ceuUnits,
  educationLevels,
} from "../../constants/dropdowns";
import {
  Certificate,
  Education,
  EducationalInformation,
  IEmployeeInfo,
} from "../../types/IStaff";
import { useUpdateStaffMutation } from "../../hooks/mutations/useUpdateStaffMutaion";
import { EditType } from "../../enums/editType";
import { showErrorToast, showSuccessToast } from "../../../../shared/utils/toast";

interface IProps {
  employeeData?: IEmployeeInfo;
  updateEmployeeData: (data: any) => void;
  navigateToTab: (tabKey: string) => void;
}

const EducationNotification: React.FC<IProps> = ({
  employeeData,
  updateEmployeeData,
  navigateToTab,
}) => {
  const isEditMode = !!employeeData?._id;
  const { mutate } = useUpdateStaffMutation();

  const [isSaving, setIsSaving] = useState(false);

  const [educationList, setEducationList] = useState<Education[]>([
    {
      educationLevel: "",
      university: "",
    },
  ]);

  const [certificateList, setCertificateList] = useState<Certificate[]>([
    {
      certificate: "",
      issuingOrganization: "",
    },
  ]);

  const [ceuUnit, setCeuUnit] = useState("");
  const [initialState, setInitialState] = useState({
    educationList: [] as Education[],
    certificateList: [] as Certificate[],
    ceuUnit: "",
  });
  const [isFormModified, setIsFormModified] = useState(false);
  const [isFormValid, setIsFormValid] = useState(false);

  // Initialize data when component loads
  useEffect(() => {
    if (employeeData?.educationalInformation) {
      if (employeeData.educationalInformation.education?.length > 0) {
        const educationData = employeeData.educationalInformation.education.map(
          (edu) => ({
            educationLevel: edu.educationLevel || "",
            university: edu.university || "",
          })
        );
        setEducationList(educationData);
        setInitialState((prev) => ({
          ...prev,
          educationList: JSON.parse(JSON.stringify(educationData)),
        }));
      }

      if (employeeData.educationalInformation.certificates?.length > 0) {
        const certificateData =
          employeeData.educationalInformation.certificates.map((cert) => ({
            certificate: cert.certificate || "",
            issuingOrganization: cert.issuingOrganization || "",
          }));
        setCertificateList(certificateData);
        setInitialState((prev) => ({
          ...prev,
          certificateList: JSON.parse(JSON.stringify(certificateData)),
        }));
      }

      if (employeeData.educationalInformation.requiredCEUPointsPerYear) {
        setCeuUnit(
          employeeData.educationalInformation.requiredCEUPointsPerYear
        );
        setInitialState((prev) => ({
          ...prev,
          ceuUnit: employeeData.educationalInformation.requiredCEUPointsPerYear,
        }));
      }
    }

    // Reset form modification status
    setIsFormModified(false);
  }, [employeeData]);

  // Check for form validity
  useEffect(() => {
    // Validate education data
    const hasCompleteEducation = educationList.every(
      (edu) => edu.educationLevel?.trim() && edu.university?.trim()
    );

    // Validate certificate data
    const hasCompleteCertificate = certificateList.every(
      (cert) => cert.certificate?.trim() && cert.issuingOrganization?.trim()
    );

    // Validate CEU data
    const hasCeuUnit = !!ceuUnit && ceuUnit.trim() !== "0";

    // Set form validity
    setIsFormValid(
      hasCompleteEducation && hasCompleteCertificate && hasCeuUnit
    );
  }, [educationList, certificateList, ceuUnit]);

  // Check if form has been modified
  useEffect(() => {
    if (!isEditMode) return;

    const checkFormModified = () => {
      if (educationList.length !== initialState.educationList.length) {
        return true;
      }

      for (let i = 0; i < educationList.length; i++) {
        if (i >= initialState.educationList.length) {
          return true;
        }

        if (
          educationList[i].educationLevel !==
            initialState.educationList[i].educationLevel ||
          educationList[i].university !==
            initialState.educationList[i].university
        ) {
          return true;
        }
      }

      if (certificateList.length !== initialState.certificateList.length) {
        return true;
      }

      for (let i = 0; i < certificateList.length; i++) {
        if (i >= initialState.certificateList.length) {
          return true;
        }

        if (
          certificateList[i].certificate !==
            initialState.certificateList[i].certificate ||
          certificateList[i].issuingOrganization !==
            initialState.certificateList[i].issuingOrganization
        ) {
          return true;
        }
      }

      if (ceuUnit !== initialState.ceuUnit) {
        return true;
      }

      return false;
    };

    setIsFormModified(checkFormModified());
  }, [educationList, certificateList, ceuUnit, initialState, isEditMode]);

  const handleAddEducation = () => {
    setEducationList([
      ...educationList,
      { educationLevel: "", university: "" },
    ]);
  };

  const handleAddCertificate = () => {
    setCertificateList([
      ...certificateList,
      { certificate: "", issuingOrganization: "" },
    ]);
  };

  const handleRemoveEducation = (index: number) => {
    const newList = educationList.filter((_, i) => i !== index);
    setEducationList(newList);
  };

  const handleRemoveCertificate = (index: number) => {
    const newList = certificateList.filter((_, i) => i !== index);
    setCertificateList(newList);
  };

  const handleEducationChange = (
    index: number,
    field: keyof Education,
    value: string
  ) => {
    const newList = [...educationList];
    newList[index][field] = value;
    setEducationList(newList);
  };

  const handleCertificateChange = (
    index: number,
    field: keyof Certificate,
    value: string
  ) => {
    const newList = [...certificateList];
    newList[index][field] = value;
    setCertificateList(newList);
  };

  const validateEducationData = (): boolean => {
    // Check if at least one education entry is complete
    const hasCompleteEducation = educationList.every(
      (edu) => edu.educationLevel?.trim() && edu.university?.trim()
    );

    if (!hasCompleteEducation) {
      showErrorToast(
        "Please complete at least one education entry with both fields"
      );
      return false;
    }

    // Check if at least one certificate entry is complete
    const hasCompleteCertificate = certificateList.every(
      (cert) => cert.certificate?.trim() && cert.issuingOrganization?.trim()
    );

    if (!hasCompleteCertificate) {
      showErrorToast(
        "Please complete at least one certificate entry with both fields"
      );
      return false;
    }

    // Check if CEU unit is selected
    if (!ceuUnit?.trim()) {
      showErrorToast("Required CEU Units (Per Year) is required");
      return false;
    }

    return true;
  };

  const handleCancel = () => {
    setEducationList(
      initialState.educationList.length > 0
        ? JSON.parse(JSON.stringify(initialState.educationList))
        : [{ educationLevel: "", university: "" }]
    );

    setCertificateList(
      initialState.certificateList.length > 0
        ? JSON.parse(JSON.stringify(initialState.certificateList))
        : [{ certificate: "", issuingOrganization: "" }]
    );

    setCeuUnit(initialState.ceuUnit);

    // Reset form modification status
    setIsFormModified(false);

    showSuccessToast("Changes cancelled");
  };

  // Save function
  const handleSave = () => {
    if (isSaving) {
      console.log("Save already in progress");
      return;
    }

    if (isEditMode && !isFormModified) {
      navigateToTab(EditType.professionalInfo);
      return;
    }

    setIsSaving(true);

    // Validate data
    if (!validateEducationData()) {
      setIsSaving(false);
      return;
    }

    // Create educational information with proper structure
    const educationalInfo: EducationalInformation = {
      education: educationList,
      certificates: certificateList,
      requiredCEUPointsPerYear: ceuUnit,
    };

    if (isEditMode && employeeData?._id) {
      // Update existing employee - use the updated format
      mutate(
        {
          staffId: employeeData._id,
          updatedData: {
            educationalInformation: educationalInfo,
          },
        },
        {
          onSuccess: (response) => {
            showSuccessToast("Education information updated successfully");

            // Update parent component with response data
            const responseData = response?.data || response;
            updateEmployeeData(responseData);

            // Update initial state for future cancellations
            setInitialState({
              educationList: JSON.parse(JSON.stringify(educationList)),
              certificateList: JSON.parse(JSON.stringify(certificateList)),
              ceuUnit: ceuUnit,
            });

            // Reset form modification status
            setIsFormModified(false);

            // Navigate to next tab
            setTimeout(() => {
              navigateToTab(EditType.professionalInfo);
            }, 100);
          },
          onError: (error) => {
            showErrorToast("Failed to update education information");
            console.error("Save error:", error);
            setIsSaving(false);
          },
          onSettled: () => {
            // Reset saving state after a delay
            setTimeout(() => {
              setIsSaving(false);
            }, 300);
          },
        }
      );
    } else {
      // Handle new employee case
      // Create a mock response with the updated education info
      const mockEmployeeData = {
        ...employeeData,
        educationalInformation: educationalInfo,
      };

      // Update parent component
      updateEmployeeData(mockEmployeeData);

      // Update initial state for future cancellations
      setInitialState({
        educationList: JSON.parse(JSON.stringify(educationList)),
        certificateList: JSON.parse(JSON.stringify(certificateList)),
        ceuUnit: ceuUnit,
      });

      // Reset form modification status
      setIsFormModified(false);

      showSuccessToast("Education information saved");

      // Navigate to next tab
      setTimeout(() => {
        navigateToTab(EditType.professionalInfo);
        setIsSaving(false);
      }, 100);
    }
  };

  return (
    <>
      <div className="bg-white p-6 rounded-xl">
        <h2 className="text-xl text-secondary font-semibold mb-9">Education</h2>

        {educationList.map((education, index) => (
          <div key={index} className="mb-9">
            <div className="grid grid-cols-6 gap-3">
              <div>
                <ReactSelect
                  options={educationLevels}
                  label="Education Level"
                  placeholder="Select Education"
                  required
                  value={
                    educationLevels?.find(
                      (option) => option?.value === education.educationLevel
                    ) || null
                  }
                  onChange={(option) =>
                    handleEducationChange(
                      index,
                      "educationLevel",
                      (option?.value as string) || ""
                    )
                  }
                />
              </div>
              <div className="relative">
                <label className="text-secondaryVariant text-base mb-1 block">
                  University
                </label>
                <input
                  value={education.university}
                  onChange={(e) =>
                    handleEducationChange(index, "university", e.target.value)
                  }
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                />
              </div>
              <div className="flex items-center gap-3 pt-6">
                {index > 0 && (
                  <button
                    onClick={() => handleRemoveEducation(index)}
                    className=""
                    type="button"
                  >
                    <RiDeleteBin3Line className="w-6 h-6 text-red-500" />
                  </button>
                )}
                <button
                  className="text-base text-statusColor flex items-center gap-2"
                  type="button"
                  onClick={handleAddEducation}
                >
                  <IoIosAddCircleOutline className="w-6 h-6" />
                  Add Education
                </button>
              </div>
            </div>
          </div>
        ))}

        <h2 className="text-xl text-secondary font-semibold mb-9">
          Certification
        </h2>

        {certificateList.map((cert, index) => (
          <div key={index} className="grid grid-cols-6 gap-3 mb-9">
            <div>
              <ReactSelect
                options={certifications}
                label="Certificate"
                placeholder="Select Certification"
                required
                value={
                  certifications?.find(
                    (option) => option?.value === cert.certificate
                  ) || null
                }
                onChange={(option) =>
                  handleCertificateChange(
                    index,
                    "certificate",
                    (option?.value as string) || ""
                  )
                }
              />
            </div>
            <div className="relative">
              <label className="text-secondaryVariant text-base mb-1 block">
                Issuing organization
              </label>
              <input
                type="text"
                value={cert.issuingOrganization}
                onChange={(e) =>
                  handleCertificateChange(
                    index,
                    "issuingOrganization",
                    e.target.value
                  )
                }
                className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
              />
            </div>
            <div className="flex items-center gap-3 pt-6">
              {index > 0 && (
                <button
                  onClick={() => handleRemoveCertificate(index)}
                  className=""
                  type="button"
                >
                  <RiDeleteBin3Line className="w-6 h-6 text-red-500" />
                </button>
              )}
              <button
                className="text-base text-statusColor flex items-center gap-2"
                type="button"
                onClick={handleAddCertificate}
              >
                <IoIosAddCircleOutline className="w-6 h-6" />
                Add Certificate
              </button>
            </div>
          </div>
        ))}

        <div className="grid grid-cols-6 gap-3 mb-9">
          <div>
            <ReactSelect
              label="Required CEU Units (Per Year)"
              placeholder="Select Options"
              required
              options={ceuUnits}
              value={
                ceuUnits?.find((option) => option?.value === ceuUnit) || null
              }
              onChange={(option) => setCeuUnit((option?.value as string) || "")}
            />
          </div>
        </div>

        <div className="flex items-center gap-3">
          <button
            className={`btnPrimary max-w-[181px] ${
              (isEditMode && !isFormModified) || isSaving || !isFormValid
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            onClick={handleSave}
            disabled={
              (isEditMode && !isFormModified) || isSaving || !isFormValid
            }
          >
            {isSaving
              ? "Saving..."
              : isEditMode
              ? "Update & Next"
              : "Save & Next"}
          </button>
          <button
            className={`btnSimple max-w-[181px] ${
              !isFormModified || isSaving ? "opacity-50 cursor-not-allowed" : ""
            }`}
            onClick={handleCancel}
            disabled={!isFormModified || isSaving}
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default EducationNotification;
