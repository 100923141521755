import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import React, { useState } from "react";
import { BiEdit } from "react-icons/bi";
import { PiCalendarPlus } from "react-icons/pi";
import { CgChevronLeft, CgChevronRight } from "react-icons/cg";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import ReactDatePicker from "../../../../shared/components/reactDatePicker/ReactDatePicker";
import {
  calculateAgeYM,
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../../shared/utils/formatDate";
import {
  showErrorToast,
  showInfoToast,
  showSuccessToast,
} from "../../../../shared/utils/toast";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import { Slot } from "../../../enums/Foray.enums";
import { classroomSvc } from "../../../services/classroom.service";
import { studentSvc } from "../../../services/student.service";
import { IClassroomSetup } from "../../../types/IClassroomSetup.interface";
import {
  Transition,
  Transitions,
} from "../../../types/IStudentSetup.interface";
import { useEditStudentPreview } from "../../hooks/useEditStudentPreview";
import { useStudentPreviews } from "../../hooks/useStudentPreviews";
import { IStudentInfo } from "../../types/IStudentInfo";
import { getTransitionObject } from "../../utils/getTransitionObject";
import PreviewModal from "../modals/PreviewModal";

const columnHelper = createColumnHelper<IStudentInfo>();

interface IProps {
  students: string[];
  date?: string;
  studentsOutsideMinimumRange?: string[];
  studentsOutsideMaximumRange?: string[];
}

interface IOptionS {
  value: string;
  label: string;
}

interface IOptionN {
  value: number;
  label: string;
}

const StudentEnrollmentTable: React.FC<IProps> = ({
  students,
  date,
  studentsOutsideMinimumRange = [],
  studentsOutsideMaximumRange = [],
}) => {
  const { previewId } = useSelector((state: RootState) => state.enrollmentView);
  const { data: fullTimeStudents } = useStudentPreviews(students, previewId);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [editRowId, setEditRowId] = useState<string | null>(null);
  const [selectedStudent, setSelectedStudent] = useState<IStudentInfo>();
  const [isModalOpen, setModalOpen] = React.useState(false);

  // Add state for form fields
  const [t1Date, setT1Date] = useState<string | undefined>();
  const [t1Session, setT1Session] = useState<string | undefined>();
  const [t2Date, setT2Date] = useState<string | undefined>();
  const [t2Session, setT2Session] = useState<string | undefined>();
  const { mutate: EditStudentPreview } = useEditStudentPreview();
  const [defaultTransitions, setDefaultTransition] = useState<Transitions>();

  // Add loading state for API calls
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { data: classroomSetups } = useQuery<IClassroomSetup[]>({
    queryKey: ["get-classroom-setups", students],
    queryFn: async () => {
      if (!students?.length) return;
      const res = await classroomSvc.fetchClassrooms();

      if (res?.ok) {
        return res?.data?.existingClassroomSetups;
      }
      return [];
    },
  });

  // Function to handle edit button click
  const handleEditClick = (row: any) => {
    const student = row.original;
    setEditRowId(row.id);
    setSelectedStudent(student);

    // Get default transitions
    getDefaultTransitions(student);
  };

  const handleGetClassroomName = (classroomId: string) => {
    if (!classroomId) return "";
    return (
      classroomSetups?.find((classroom) => classroom._id === classroomId)
        ?.classroomName || classroomId
    );
  };

  const handleGetTransitionSlotName = (
    transition: Transition | { slot: string; classroomId: string; date: string }
  ) => {
    if (transition?.slot === Slot.FULL_TIME) {
      return Slot.FULL_TIME;
    }
    return (
      classroomSetups
        ?.find((classroom) => classroom._id === transition?.classroomId)
        ?.partTimeSlots?.find((slot) => slot?.uuid === transition?.slot)
        ?.name || transition?.slot
    );
  };

  const handleCancelEdit = () => {
    setEditRowId(null);
    // Reset form fields
    setT1Date("");
    setT1Session("");
    setT2Date("");
    setT2Session("");
  };

  // Function to handle preview submission
  const handlePreviewSubmit = async () => {
    if (!selectedStudent || !previewId) return;

    try {
      setIsSubmitting(true);

      // Prepare the data for API call - only include changed transitions
      const reqBody: any = {};

      // Only include first transition if it was changed
      if (t1Date || t1Session) {
        reqBody.firstTransition = getTransitionObject(
          {
            classroomId:
              selectedStudent?.transitions?.firstTransition?.classroomId,
            slot:
              t1Session || selectedStudent?.transitions?.firstTransition?.slot,
            date: t1Date || selectedStudent?.transitions?.firstTransition?.date,
          },
          defaultTransitions?.firstTransition
        );
      }

      // Only include second transition if it was changed
      if (t2Date || t2Session) {
        reqBody.secondTransition = getTransitionObject(
          {
            classroomId:
              selectedStudent?.transitions?.secondTransition?.classroomId,
            slot:
              t2Session || selectedStudent?.transitions?.secondTransition?.slot,
            date:
              t2Date || selectedStudent?.transitions?.secondTransition?.date,
          },
          defaultTransitions?.secondTransition
        );
      }

      // Check if any transitions were changed
      if (Object.keys(reqBody).length === 0) {
        showInfoToast("No changes detected to save.");
        setIsSubmitting(false);
        return;
      }

      // Call the API with only the changed transitions
      EditStudentPreview(
        {
          previewId: previewId as string,
          studentId: selectedStudent?._id as string,
          reqBody,
        },
        {
          onSuccess: () => {
            showSuccessToast("Student preview updated successfully");
            handleCancelEdit();
            closeModal();
          },
        }
      );
    } catch (error) {
      console.error("Error updating student preview:", error);
      showErrorToast("An error occurred while updating student preview");
    } finally {
      setIsSubmitting(false);
    }
  };

  const getDefaultTransitions = async (student: IStudentInfo) => {
    const res = await studentSvc.fetchDefaultTransitions(
      student?.classroom as string,
      formatDateSlashFullYear(student?.dateOfBirth as string) as string,
      student?.slot[0] as string,
      student?.temporaryAbsences || false
    );
    if (res?.ok) {
      setDefaultTransition(res?.data);
    }
  };

  const columns = [
    columnHelper.accessor("firstName", {
      id: "studentName",
      header: () => (
        <div className="flex items-center gap-3">
          <span>Student Name</span>
        </div>
      ),
      cell: (info) => {
        const studentId = info.row.original._id;
        const isOutsideMinRange =
          studentsOutsideMinimumRange.includes(studentId);
        const isOutsideMaxRange =
          studentsOutsideMaximumRange.includes(studentId);

        return (
          <div className="flex items-center gap-3">
            <span className="w-full">
              {info.getValue()}
              {isOutsideMinRange && (
                <span className="ml-2 text-xs bg-ageViolation text-secondary px-1 py-0.5 rounded">
                  <CgChevronLeft className="inline w-3 h-3" />
                </span>
              )}
              {isOutsideMaxRange && (
                <span className="ml-2 text-xs bg-ageViolation text-secondary px-1 py-0.5 rounded">
                  <CgChevronRight className="inline w-3 h-3" />
                </span>
              )}
            </span>
          </div>
        );
      },
    }),
    columnHelper.accessor("dateOfBirth", {
      id: "age",

      header: () => (
        <div className="text-center">
          <span>Age</span>
        </div>
      ),
      cell: (info) => {
        const dob = info.getValue() as string;
        const { years, months, days } = calculateAgeYM(dob, date);
        return (
          <div className="flex items-center gap-3">
            <span className="w-full">
              {years}Y {months}M {days}D
            </span>
          </div>
        );
      },
    }),

    columnHelper.accessor("Dates.startAtCenter", {
      id: "startDate",
      header: () => (
        <div className="text-center">
          <span>Start Date</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {formatDateSlashFullYear(info.getValue() as string)}
        </div>
      ),
    }),

    columnHelper.accessor("transitions.firstTransition.classroomId", {
      id: "t1Class",
      header: () => (
        <div className="text-center">
          <span>T1 Class</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {handleGetClassroomName(info.getValue()) || "--"}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.firstTransition.date", {
      id: "t1",
      header: () => (
        <div className="text-center">
          <span>T1 Date</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {formatDateSlashFullYear(info.getValue() as string) || "--"}
          {editRowId === info.row.id && (
            <div className="max-w-[110px] mt-3">
              <ReactDatePicker
                placeholder="MM/DD/YYYY"
                small={true}
                value={revertToDateType(t1Date || "")}
                onChange={(date: Date | null) => {
                  if (date) {
                    // Format the date and update state
                    const formattedDate = formatDateSlashFullYear(date);
                    if (formattedDate) {
                      setT1Date(formattedDate);
                    }
                  } else {
                    setT1Date("");
                  }
                }}
              />
              <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
            </div>
          )}
        </div>
      ),
    }),

    columnHelper.accessor("transitions.firstTransition", {
      id: "session",
      header: () => (
        <div className="text-center">
          <span>T1 Session</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          <span
            className={`text-center rounded py-1 px-1.5 w-full inline-flex items-center justify-center  ${
              info.getValue().slot === Slot.FULL_TIME
                ? "bg-successAlert text-white"
                : "bg-[#FFAE00] text-white"
            } `}
          >
            {handleGetTransitionSlotName(info.getValue()) || "--"}
          </span>

          {editRowId === info.row.id && ( // Check if this specific row is being edited
            <div>
              <ReactSelect
                label=""
                placeholder="Select"
                menuPlacement="bottom"
                value={
                  t1Session
                    ? {
                        value: t1Session,
                        label:
                          handleGetTransitionSlotName({
                            slot: t1Session,
                            classroomId: info.getValue()?.classroomId,
                            date: "",
                          }) || t1Session,
                      }
                    : null
                }
                onChange={(option) => {
                  if (option && typeof option.value !== "undefined") {
                    setT1Session(String(option.value));
                  } else {
                    setT1Session("");
                  }
                }}
                options={[
                  { value: String(Slot.FULL_TIME), label: Slot.FULL_TIME },
                  ...(classroomSetups
                    ?.find(
                      (classroom) =>
                        classroom._id === info.getValue()?.classroomId
                    )
                    ?.partTimeSlots?.map((slot) => ({
                      value: slot.uuid || "",
                      label: slot.name,
                    })) || []),
                ]}
                small={true}
              />
            </div>
          )}
        </div>
      ),
    }),

    columnHelper.accessor("transitions.secondTransition.classroomId", {
      id: "t2Class",
      header: () => (
        <div className="text-center">
          <span>T2 Class</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {handleGetClassroomName(info.getValue()) || "--"}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.secondTransition.date", {
      id: "t2",
      header: () => (
        <div className="text-center">
          <span>T2 Date</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-center">
          {formatDateSlashFullYear(info.getValue() as string) || "--"}
          {editRowId === info.row.id && (
            <div className="max-w-[110px] mt-3">
              <ReactDatePicker
                placeholder="MM/DD/YYYY"
                small={true}
                value={revertToDateType(t2Date || "")}
                onChange={(date: Date | null) => {
                  if (date) {
                    // Format the date and update state
                    const formattedDate = formatDateSlashFullYear(date);
                    if (formattedDate) {
                      setT2Date(formattedDate);
                    }
                  } else {
                    setT2Date("");
                  }
                }}
              />
              <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
            </div>
          )}
        </div>
      ),
    }),
    columnHelper.accessor("transitions.secondTransition", {
      id: "session2",
      header: () => (
        <div className="text-center">
          <span>T2 Session</span>
        </div>
      ),
      cell: (info) => (
        <>
          <div
            className={`text-center rounded py-1 px-1.5 ${
              info.getValue().slot === Slot.FULL_TIME
                ? "bg-successAlert text-white"
                : "bg-[#FFAE00] text-white"
            } `}
          >
            {handleGetTransitionSlotName(info.getValue()) || "--"}
          </div>

          {editRowId === info.row.id && (
            <div>
              <ReactSelect
                label=""
                placeholder="Select"
                menuPlacement="bottom"
                value={
                  t2Session
                    ? {
                        value: t2Session,
                        label:
                          handleGetTransitionSlotName({
                            slot: t2Session,
                            classroomId: info.getValue()?.classroomId,
                            date: "",
                          }) || t2Session,
                      }
                    : null
                }
                onChange={(option) => {
                  if (option && typeof option.value !== "undefined") {
                    setT2Session(String(option.value));
                  } else {
                    setT2Session("");
                  }
                }}
                options={
                  (classroomSetups
                    ?.find(
                      (classroom) =>
                        classroom._id === info.getValue()?.classroomId
                    )
                    ?.partTimeSlots?.map((slot) => ({
                      value: slot.uuid || "",
                      label: slot.name,
                    })) as IOptionS[]) || [
                    { value: String(Slot.FULL_TIME), label: Slot.FULL_TIME },
                  ]
                }
                small={true}
              />
            </div>
          )}
        </>
      ),
    }),
    columnHelper.display({
      id: "action",
      header: () => (
        <div className="text-end">
          <span>Action</span>
        </div>
      ),
      cell: (info) => (
        <div className="text-end">
          {editRowId === info.row.id ? (
            <div className="flex items-center gap-2">
              <button
                onClick={openModal}
                className="text-sm text-white font-medium bg-primary py-1.5 px-2 rounded"
                disabled={isSubmitting}
              >
                Preview
              </button>
              <button
                onClick={handleCancelEdit}
                className="text-sm text-white font-medium bg-secondary py-1.5 px-2 rounded"
                disabled={isSubmitting}
              >
                Cancel
              </button>
            </div>
          ) : (
            <div onClick={() => handleEditClick(info.row)}>
              <BiEdit className="w-6 h-6 text-statusColor" />
            </div>
          )}
        </div>
      ),
    }),
  ];

  const onClose = () => {
    setIsEditOpen(false);
  };

  const table = useReactTable({
    data: fullTimeStudents || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  // Add queryClient for data invalidation
  const queryClient = useQueryClient();

  return (
    <>
      <div className=" max-h-[300px] overflow-y-auto relative z-[100]  pb-2">
        <div className="text-xs font-semibold text-primary px-4 py-1 bg-white border border-secondaryNeutral">
          {date ? date : "No date selected"}
        </div>
        <table className="w-full bg-white rounded-t-lg">
          <thead className="sticky top-0 bg-white z-20">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className="px-4 py-2.5 text-left text-base font-medium text-secondary capitalize whitespace-nowrap sticky top-0 text-ellipsis"
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-secondaryNeutral">
            {table.getRowModel().rows?.map((row) => (
              <tr key={row.id} className="hover:bg-secondaryNeutral">
                {row?.getVisibleCells()?.map((cell) => (
                  <td
                    key={cell.id}
                    className="px-5 py-1.5 text-base text-secondaryVariant border-b border-secondaryNeutral whitespace-nowrap text-ellipsis"
                  >
                    {flexRender(
                      cell.column?.columnDef?.cell,
                      cell?.getContext()
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <PreviewModal
        isOpen={isModalOpen}
        onClose={closeModal}
        onPreview={handlePreviewSubmit}
      />
    </>
  );
};

export default StudentEnrollmentTable;
