import { lazy, Suspense } from "react";
import { RouteObject } from "react-router-dom";

import { forayRoutes } from "./routes/routes";
import SubscriptionProtection from "../../shared/components/SubscriptionProtection";
import ForayModuleRoutes from "./forayRoutes";
import LoaderSpinner from "../shared/components/Loader/Loader";
import GoToForay from "./pages/foray/GoToForay";

const FrLandingScreen = lazy(() => import("./pages/foray/FrLandingScreen"));

const ProtectedForayRoutes: RouteObject[] = [
  // Landing route - accessible without subscription
  {
    path: forayRoutes.foray,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <FrLandingScreen />
      </Suspense>
    ),
  },
  {
    path: forayRoutes.sub_foray,
    element: (
      <Suspense fallback={<LoaderSpinner />}>
        <GoToForay />
      </Suspense>
    ),
  },
  // Protected routes
  {
    element: <SubscriptionProtection />,
    children: ForayModuleRoutes.filter(
      (route) => route.path !== forayRoutes.foray
    ),
  },
];

export default ProtectedForayRoutes;
