import { useState } from "react";
import { FileTypeEnum } from "../../../enums/fileTypeEnums";
import FileUploadDropzone from "./FileUploadDropzone";
import UploadHandbook from "./UploadHandbook";

const CustomizeHandbook = () => {
  const [selectedTab, setSelectedTab] = useState<string>("ccf");

  const handleFileUpload = (files: File[]) => {
    // Handle file upload logic
    console.log(files);
  };

  return (
    <div>
      <p className="text-secondary">
        Would you like to <strong>Use/Customize</strong> our{" "}
        <strong>CCF Employee Handbook</strong>{" "}
        <span className="text-primary">OR</span> Upload your own?
      </p>
      <div>
        <div className="flex flex-wrap gap-8 my-6">
          <div
            className={`flex items-center gap-2 cursor-pointer text-secondary`}
            onClick={() => setSelectedTab("ccf")}
          >
            <div
              className={`w-5 h-5 border-2 border-gray-400 rounded-full flex items-center justify-center ${
                selectedTab === "ccf" ? "border-primary bg-white" : ""
              }`}
            >
              {selectedTab === "ccf" && (
                <div className="w-3 h-3 bg-primary rounded-full"></div>
              )}
            </div>
            <label className="text-base cursor-pointer">
              Yes, I’ll use CCF Handbook
            </label>
          </div>

          <div
            className={`flex items-center gap-2 cursor-pointer text-secondary`}
            onClick={() => setSelectedTab("edit")}
          >
            <div
              className={`w-5 h-5 border-2 border-gray-400 rounded-full flex items-center justify-center ${
                selectedTab === "edit" ? "border-primary bg-white" : ""
              }`}
            >
              {selectedTab === "edit" && (
                <div className="w-3 h-3 bg-primary rounded-full"></div>
              )}
            </div>
            <label className="text-base cursor-pointer">
              I’ll Edit/Customize CCF Handbook
            </label>
          </div>

          <div
            className={`flex items-center gap-2 cursor-pointer text-secondary`}
            onClick={() => setSelectedTab("upload")}
          >
            <div
              className={`w-5 h-5 border-2 border-gray-400 rounded-full flex items-center justify-center ${
                selectedTab === "upload" ? "border-primary bg-white" : ""
              }`}
            >
              {selectedTab === "upload" && (
                <div className="w-3 h-3 bg-primary rounded-full"></div>
              )}
            </div>
            <label className="text-base cursor-pointer">
              I’ll upload my own
            </label>
          </div>
        </div>

        <div className="mt-4">
          {selectedTab === "ccf" && <></>}
          {selectedTab === "edit" && (
            <>
              <UploadHandbook
                fileName="Customized CCF_Employee_Handbook.docx"
                isModified={true}
              />
            </>
          )}
          {selectedTab === "upload" && (
            <>
              {/* <UploadInput/> */}
              <FileUploadDropzone
                onFileUpload={handleFileUpload}
                acceptedFileTypes={[FileTypeEnum.DOCX, FileTypeEnum.PDF]}
                maxFiles={1}
                maxSize={5 * 1024 * 1024} // 5MB
              />
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default CustomizeHandbook;
