import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import { forayRoutes } from "../../routes/routes";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { Link } from "react-router-dom";
import { HiOutlineUser } from "react-icons/hi2";
import { TbUsersGroup } from "react-icons/tb";
import { Nav, Tab } from "react-bootstrap";
import { IoCalendarClearOutline } from "react-icons/io5";
import { LuContrast } from "react-icons/lu";
import { CiUnlock } from "react-icons/ci";
import { HiOutlineChartSquareBar } from "react-icons/hi";
import { GiBackwardTime } from "react-icons/gi";
import TodaySchedule from "../../dailySchedule/Components/TodaySchedule";
import Breaks from "../../dailySchedule/Components/Breaks";
import TodayTabular from "../../components/tabular/TodayTabular";
import TabularGrid from "../../components/tabular/TabularGrid";

const TabularView = () => {
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    {
      label: "Human Resource Management",
      link: forayRoutes.hr_dashboard,
    },
    { label: "Staff Operations", link: forayRoutes.classroom_detailed },
    { label: "Daily Schedule/Breaks", isCurrent: true },
  ];
  const linkData = [
    {
      to: forayRoutes.hr_dashboard,
      icon: (
        <MdOutlineDashboardCustomize className="w-6 h-6 text-primary group-hover:text-white" />
      ),
      label: "Dashboard-HR",
    },
    {
      to: forayRoutes.handbook,
      icon: (
        <TbUsersGroup className="w-6 h-6 text-primary group-hover:text-white" />
      ),
      label: "Staff Operations",
    },
    {
      to: forayRoutes.foray_dashboard,
      icon: (
        <HiOutlineUser className="w-6 h-6 text-primary group-hover:text-white" />
      ),
      label: "Employee View/Setup",
    },
  ];
  return (
    <>
      <DashboardWrapper>
        <div className="pt-9 px-6">
          <Breadcrumb items={breadcrumbItems} />
          <div className="flex flex-wrap items-center gap-4 mb-7">
            {linkData.map((link, index) => (
              <Link
                key={index}
                to={link.to}
                className="group rounded-lg p-3 flex items-center gap-2 bg-white hover:bg-primary hover:border-secondary border-white border"
              >
                {link.icon}
                <span className="text-lg text-secondary font-medium text-truncate line-clamp-1 text-center group-hover:text-white">
                  {link.label}
                </span>
              </Link>
            ))}
          </div>
            <Nav
              variant="pills"
              className="flex items-center border-b border-secondaryVariant2 mb-6"
            >
              <Nav.Item>
                <Nav.Link
                  eventKey={"0"}
                  className="inline-flex items-center gap-2 ps-0 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                >
                  <IoCalendarClearOutline className="w-6 h-6" />
                  Daily Schedule/Breaks
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  // disabled={!isPersonalInfoComplete}
                  eventKey={"1"}
                  className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                >
                  <LuContrast className="w-6 h-6" />
                  Turnover Mng{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={"2"}
                  className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                >
                  <CiUnlock className="w-6 h-6" />
                  Cond. Vionls{" "}
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={"3"}
                  className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                >
                  <HiOutlineChartSquareBar className="w-6 h-6" />
                  Evaluation
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={"4"}
                  className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                >
                  <GiBackwardTime className="w-6 h-6" />
                  Overtime Mngmt / Payroll
                </Nav.Link>
              </Nav.Item>
              <Nav.Item>
                <Nav.Link
                  eventKey={"5"}
                  className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
                >
                  <IoCalendarClearOutline className="w-6 h-6" />
                  PTO / Absence / Tardy
                </Nav.Link>
              </Nav.Item>
            </Nav>
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-3">
            <TodayTabular/>
            </div>
            <div className="col-span-3">
              <Breaks/>
            </div>
            <div className="col-span-6">
              <TabularGrid/>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default TabularView;
