import { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";
import { VscEdit } from "react-icons/vsc";
import { Popover } from "react-tiny-popover";
import CustomSelect, { DropdownOption } from "../../components/select/CustomSelect";
import { FiPlusCircle } from "react-icons/fi";
import TimeDropdown from "../../components/dropdowns/TimeDropdown";
import { timeOptions } from "../../constants/constantValues";

interface TimeSlot {
  start: string;
  person: string;
  end: string;
  bgColor: string;
  status?: string;
}

interface ScheduleItem {
  name: string;
  timeSlots: TimeSlot[];
}

interface Category {
  title: string;
  ageGroup: string;
  ratio: number;
  fullTime: number;
  partTime: number;
  pending: number;
  schedules: ScheduleItem[];
}

const teachers: DropdownOption[] = [
  {
    value: "hamid",
    label: "Hamid",
    avatar: "https://randomuser.me/api/portraits/men/1.jpg",
  },
  {
    value: "umar",
    label: "Umar",
    avatar: "https://randomuser.me/api/portraits/men/2.jpg",
  },
  {
    value: "samar",
    label: "Samar",
    avatar: "https://randomuser.me/api/portraits/women/1.jpg",
  },
  {
    value: "hamza",
    label: "Hamza",
    avatar: "https://randomuser.me/api/portraits/men/3.jpg",
  },
];



const ScheduleCard: React.FC<{ category: Category; isModifyMode?: boolean }> = ({ category, isModifyMode }) => {
  const [openPopoverIndex, setOpenPopoverIndex] = useState<number | null>(null);
  
    const handleSelectChange = (selectedOption: DropdownOption | null) => {
      console.log("Selected:", selectedOption);
    };
  

  return (
    <div className="flex">
      <div className="flex-[0_0_174px] pr-6">
        {/* Category Card */}
        {category.title !== "Breaker" && category.title !== "Admin" && category.title !== "Other" ? (
          <div className="bg-secondary rounded-lg overflow-hidden flex flex-col h-full">
            <div className="flex items-start py-2 px-2 flex-1">
              <div className="flex-1 text-base font-semibold text-white leading-none flex flex-col h-full justify-center">
                {category.title} <br />
                {category.ageGroup}
              </div>
              <span className="bg-primary text-secondary w-5 h-5 rounded-full inline-flex items-center justify-center text-[10px]">
                S
              </span>
            </div>
            <div className="flex bg-[#343C66]">
              <div className="flex-[0_0_36px] p-[6px] gap-1 border-r border-secondary">
                <div className="flex flex-col text-xs text-white leading-none">
                  <span>Ratio</span>
                  <span className="font-semibold text-primary">{category.ratio}</span>
                </div>
              </div>
              <div className="flex-1 flex gap-1 items-end">
                <div className="flex flex-col items-center justify-center p-1 text-xs text-white flex-1 leading-none">
                  <span>FT</span>
                  <span className="font-semibold">{category.fullTime}</span>
                </div>
                <div className="flex flex-col items-center justify-center p-1 text-xs text-white flex-1 leading-none">
                  <span>PT</span>
                  <span className="font-semibold">{category.partTime}</span>
                </div>
                <div className="flex flex-col items-center justify-center p-1 text-xs text-primary flex-1 leading-none">
                  <span>P</span>
                  <span className="font-semibold">{category.pending}</span>
                </div>
                <span className="text-primary pb-1">
                  <IoMdArrowDropdown />
                </span>
              </div>
            </div>
          </div>
        ) : null}
        {category.title === "Breaker" && (
          <div className="bg-[#3396F8] rounded-lg overflow-hidden flex flex-col h-full">
            <div className="flex items-center py-2 px-2 flex-1">
              <div className="flex-1 text-base font-semibold text-white leading-none flex flex-col h-full justify-center">
                Breaker
              </div>
              <span className="bg-primary text-white min-w-5 h-5 px-2 rounded-full inline-flex items-center justify-center text-[10px]">
                02
              </span>
            </div>
          </div>
        )}

        {category.title === "Admin" && (
          <div className="bg-[#18C55F] rounded-lg overflow-hidden flex flex-col h-full">
            <div className="flex items-center py-2 px-2 flex-1">
              <div className="flex-1 text-base font-semibold text-white leading-none flex flex-col h-full justify-center">
                Admin
              </div>
              <span className="bg-primary text-white min-w-5 h-5 px-2 rounded-full inline-flex items-center justify-center text-[10px]">
                02
              </span>
            </div>
          </div>
        )}

        {category.title === "Other" && (
          <div className="bg-[#FFA323] rounded-lg overflow-hidden flex flex-col h-full">
            <div className="flex items-center py-2 px-2 flex-1">
              <div className="flex-1 text-base font-semibold text-white leading-none flex flex-col h-full justify-center">
                Others
              </div>
              <span className="bg-primary text-white px-2 min-w-5 h-5 rounded-full inline-flex items-center justify-center text-[10px]">
                02
              </span>
            </div>
          </div>
        )}
      </div>

      {/* Schedules Section */}
      {!isModifyMode ?
      <div className="flex-1">
        {category.schedules.map((schedule, index) => (
          <div key={index} className="flex items-center space-y-1">
            <div className="pr-3 flex-[0_0_132px]">
              <div className="flex items-center gap-2">
                <div className="w-8 h-8 rounded-full bg-secondaryNeutral border border-dashed border-secondaryVariant relative"></div>
                <div className="flex-1 font-bold text-secondaryVariant">{schedule.name}  
                </div>
              </div>
            </div>
            <div className="px-3 flex-[0_0_84px]">
              <span className="text-secondaryVariant text-base">Umar</span>
             
            </div>
         
            <div className="px-3 flex-1">
              <div className="bg-secondaryNeutral pt-3 2xl:px-8 px-4  rounded-lg flex">
                {schedule.timeSlots.map((slot, idx) => (

                  <div
                    className="flex-1 relative flex items-center justify-between 2xl:text-sm text-xs text-secondaryVariant  rounded-[4px_4px_0_0] px-2 py-1"
                    style={{ backgroundColor: slot.bgColor }}

                  >
                    <div >
                      <span className="font-semibold line-clamp-1">
                      {slot.start}
                      </span>
                    
                    
                      </div>
                    <div className="flex-1 px-1">
                      <p className="line-clamp-1 ">{slot.person}</p>
                     
                    </div>
                    <div >
                      <span className="font-semibold line-clamp-1">{slot.end}</span>

                    </div>
                    {slot.status && (
                      <Popover
                        key={idx}
                        isOpen={openPopoverIndex === idx}
                        positions={["top", "right"]}
                        onClickOutside={() => setOpenPopoverIndex(null)}
                        content={
                          <div className="bg-white shadow-lg p-6 rounded-xl text-secondary min-w-[432px] w-full" >
                            <div className="mb-2 flex items-center justify-between">
                              <h2 className="font-bold text-2xl">Break Duration</h2>
                              <button onClick={() => setOpenPopoverIndex(null)}>
                                <IoCloseOutline size={24} className="text-secondaryVariant" />
                              </button>
                            </div>
                            <div className="flex items-center gap-4">
                              <span className='py-1 px-3 rounded-[14px] bg-green-100 text-green-500'>60 minutes</span>
                              <button className="text-base text-statusColor font-medium inline-flex items-center gap-1 group-hover:text-white">
                                <VscEdit className="w-5 h-5" />
                                Modify
                              </button>
                            </div>
                            <hr className="my-3 border-secondaryNeutral"/>
                            <div className="grid grid-cols-2 gap-3">
                              <div>
                                <h3 className="text-secondary font-semibold mb-2">Teacher</h3>
                            <div className="flex items-center gap-2">
                              <div className="w-8 h-8 rounded-full bg-secondaryNeutral border border-dashed border-secondaryVariant relative"></div>
                                <div className="flex-1 ">
                                  <h4 className="font-medium text-secondaryVariant">{schedule.name}</h4>
                                  <p className="text-xs text-secondaryVariant">{slot.start} to {slot.end}</p>
                                </div>
                            </div>
                            </div>
                            <div>
                                <h3 className="text-secondary font-semibold mb-2">Breaker</h3>
                            <div className="flex items-center gap-2">
                              <div className="w-8 h-8 rounded-full bg-secondaryNeutral border border-dashed border-secondaryVariant relative"></div>
                                <div className="flex-1 ">
                                  <h4 className="font-medium text-secondaryVariant">{schedule.name}</h4>
                                  <p className="text-xs text-secondaryVariant">{slot.start} to {slot.end}</p>
                                </div>
                            </div>
                            </div>
                            </div>
                          </div>
                        }
                      >
                        <span
                          className="absolute inline-flex left-8 w-[15px] rounded-tl-full rounded-tr-full h-[8px] bottom-full cursor-pointer"
                          style={{
                            backgroundColor: slot.status === "active" ? "#FF5733" : "#CCCCCC",
                          }}
                          onClick={() =>
                            setOpenPopoverIndex(openPopoverIndex === idx ? null : idx)
                          }
                        ></span>

                      </Popover>
                    )}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ))}
      </div>
      : 
      <div className="flex-1">
      <div className="flex items-center ">
        <div className="pr-1 flex-[0_0_150px]">
          <div className="flex items-center gap-1">
            <div className="w-8 h-8 rounded-full bg-secondaryNeutral border border-dashed border-secondaryVariant relative"></div>
            <div className="flex-1 font-bold text-secondaryVariant">
              <CustomSelect
                options={teachers}
                placeholder="Select Teacher"
                onChange={handleSelectChange}
              />
            </div>
          </div>
        </div>
        <div className="px-2 flex-[0_0_110px]">
          <CustomSelect
            options={teachers}
            placeholder="Select Breaker"
            onChange={handleSelectChange}
          />
        </div>
        <div className="px-2 flex-[0_0_96px] text-center">

          <button className="text-[#3396F8] text-base font-normal inline-flex flex-col items-center" >
            <FiPlusCircle className="w-6 h-6" />
            Add
          </button>
          {/* <button className="text-[#DB3B21] text-base font-normal inline-flex flex-col items-center" >
                                <FiMinusCircle className="w-6 h-6" />
                                Remove
                              </button> */}
        </div>
        <div className="px-2 flex-1">
          <div className="bg-secondaryNeutral  2xl:px-3 px-2 py-2 rounded-lg flex items-center gap-2">
            <div
              className="flex-1" >
          <label htmlFor="" className="text-xs font-normal text-secondaryVariant leading-none">Teacher</label>
            <CustomSelect
            options={teachers}
            placeholder="Select Breaker"
            onChange={handleSelectChange}
          />
            </div>
            <div
              className="flex-1" >
                
          <label htmlFor="" className="text-xs font-normal text-secondaryVariant leading-none">Session start</label>
          <TimeDropdown          
                times={timeOptions}
                label="Start Time"
                small={true}
                placeholder="Set start time" value={""} onSelectTime={function (selectedTime: string): void {
                  console.log(selectedTime);
                } }                            
                            />
            </div>
            <div
              className="flex-1" >
          <label htmlFor="" className="text-xs font-normal text-secondaryVariant leading-none">Session End</label>
          <TimeDropdown          
                times={timeOptions}
                label="Start Time"
                small={true}
                placeholder="Set start time" value={""} onSelectTime={function (selectedTime: string): void {
                  console.log(selectedTime);
                } }                            
                            />
            </div>
            
          <button className="text-[#3396F8] text-base font-normal inline-flex flex-col items-center" >
            <FiPlusCircle className="w-6 h-6" />
            Session
          </button>
          </div>
        </div>
      </div>
    </div>
      }
    </div>
  );
};

export default ScheduleCard;
