import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { BiEdit } from "react-icons/bi";
import { RiDeleteBin3Line } from "react-icons/ri";
import { classroomSvc } from "../../services/classroom.service";
import useOutsideClick from "../../../shared/hooks/useOutsideClick";
import { showErrorToast } from "../../../shared/utils/toast";
import { SessionType } from "../../enums/Foray.enums";

interface INumOfSpecialClassrooms {
  numberOfSummerClassrooms: number;
  numberOfBaClassrooms: number;
}

interface ActionDropdownProps {
  classroom: IClassroomSetup;
  isActive: boolean;
  isOpen: boolean;
  numOfSpecialClassrooms: INumOfSpecialClassrooms;
  toggleDropdown: (id: string) => void;
  // fetchAgain: boolean;
  fetchAgain: () => void;
}
const ActionDropdown: React.FC<ActionDropdownProps> = ({
  classroom,
  isActive,
  isOpen,
  numOfSpecialClassrooms,
  toggleDropdown,
  fetchAgain,
}) => {
  const actionModalRef = React.useRef<HTMLDivElement>(null);
  useOutsideClick(
    actionModalRef,
    () => isOpen && toggleDropdown(classroom?._id as string)
  );
  // const navigate = useNavigate();

  // const handleView = () => {
  //   navigate(forayRoutes.view_classroom, { state: { classroomId } });
  // };

  // const handleModify = () => {
  //   navigate(forayRoutes.detailed_classroom_setup, { state: { classroomId } });
  // };

  const handleArchive = async () => {
    const isBaClassroom = classroom?.BAProgram;
    const isSummerClassroom = classroom?.summerProgram;
    const reqBody = {
      numberOfBAClassrooms: isBaClassroom
        ? numOfSpecialClassrooms?.numberOfBaClassrooms - 1
        : numOfSpecialClassrooms?.numberOfBaClassrooms,
      numberOfSummerClassrooms: isSummerClassroom
        ? numOfSpecialClassrooms?.numberOfSummerClassrooms - 1
        : numOfSpecialClassrooms?.numberOfSummerClassrooms,
    };
    const res =
      isBaClassroom || isSummerClassroom
        ? await classroomSvc.archiveSpecialClassroom(
          classroom?._id as string,
          false,
          reqBody
        )
        : await classroomSvc.archiveClassroom(
          classroom?._id as string,
          !isActive
        );
    if (res?.ok) {
      toggleDropdown(classroom?._id as string);
      fetchAgain();
    } else {
      showErrorToast(
        "This classroom may be selected for transition from a classroom. Change it, then try again."
      );
    }
  };

  return (
    <div className="relative text-end">
      <button
        onClick={() => toggleDropdown(classroom?._id as string)}
        className="focus:outline-none"
      >
        <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
      </button>
      {isOpen && (
        <div
          className="absolute right-0 mt-2 w-56 bg-white shadow-lg rounded-md z-10 p-4"
          ref={actionModalRef}
        >
          <ul>
            {/* <li
              className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={handleModify}
            >
              <button className="text-base text-secondary flex items-center gap-4">
                <BiEdit className="w-6 h-6 text-secondaryVariant2" />
                Modify
              </button>
            </li> */}
            <li
              className="px-1 py-4 hover:bg-gray-100 cursor-pointer rounded-md"
              onClick={handleArchive}
            >
              <button className="text-base text-dangerAlert flex items-center gap-4">
                <RiDeleteBin3Line className="w-6 h-6" />
                Deactivate
              </button>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

const columnHelper = createColumnHelper<IClassroomSetup>();

interface Props {
  classrooms: IClassroomSetup[];
  allClassrooms: IClassroomSetup[];
}

const EssentialsClassroomTable: React.FC<Props> = ({ classrooms, allClassrooms }) => {
  const [data, _setData] = useState(() => [...classrooms]);
  const [openDropdownId, setOpenDropdownId] = React.useState<string | null>(
    null
  );
  const toggleDropdown = (id: string) => {
    setOpenDropdownId((prevId) => (prevId === id ? null : id));
  };

  const calculateNumberOfSpecialClassrooms = () => {
    const baClassrooms = classrooms?.filter(
      (classroom) => classroom.BAProgram
    )?.length;
    const summerClassrooms = classrooms?.filter(
      (classroom) => classroom.summerProgram
    )?.length;

    return {
      numberOfSummerClassrooms: summerClassrooms,
      numberOfBaClassrooms: baClassrooms,
    };
  };

  const handleGetClassroomName = (id: string) => {
    return allClassrooms?.find((classroom) => classroom._id === id)?.classroomName;
  };
  console.log("check classroom: ", classrooms);
  const columns = [
    columnHelper.accessor("classroomName", {
      id: "classroomName",
      header: () => (
        <div className="flex items-center gap-3">
          <span>
            Classroom <br /> Name*
          </span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span className="w-full">{info.getValue()}</span>
        </div>
      ),
    }),
    columnHelper.accessor("settingType", {
      id: "settingType",
      header: () => (
        <div className="flex items-center gap-3">
          <span>
            Part-time
            <br />
            Sessions?
          </span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span className="w-full">
            {info.row.original.BAProgram || info.row.original.summerProgram ? "--" : info.getValue() === SessionType.FullAndPartTime ? "Yes" : "No"}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("ageSequential", {
      id: "ageSequential",
      header: () => (
        <div className="flex items-center gap-3">
          <span>
            Classroom <br /> Type
          </span>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-3">
          <span
            className={` px-[6px] rounded-[4px] font-medium ${info.getValue()
                ? "text-statusColor bg-[#E6F2FE]"
                : "text-successAlert bg-[#E8FFF1]"
              }`}
          >
            {info.getValue() ? "Sequential" : "Special"}
          </span>
        </div>
      ),
    }),

    columnHelper.accessor("ageRange.minimumAge", {
      id: "time",
      header: () => (
        <div className="text-start">
          <span className=" border-secondaryVariant2 border-b-[0.5px] block">
            Minimum Age*
          </span>
          <div className="flex items-center gap-2">
            <span className="min-w-[50px]">Years</span>
            <span className="min-w-[64px]">Months</span>
            <span className="min-w-[39px]">
              Days <span className="text-primary min-w-[39px] ps-2">Days</span>
            </span>
          </div>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-2 bg-secondaryNeutral px-2 py-2 font-medium">
          <span className="min-w-[48px]">{info.getValue().years}</span>
          <span className="min-w-[61px]">
            <span className=""></span> {info.getValue().months}
          </span>
          <span className="min-w-[40px]">
            <span className=""></span>
            {info.getValue().days}
          </span>
          <span className="text-primary min-w-[39px]">
            {info.getValue().ageInDays}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("ageRange.maximumAge", {
      id: "date",
      header: () => (
        <div className="text-start">
          <span className=" border-secondaryVariant2 border-b-[0.5px] block max-w-[219px]">
            Maximum Age*
          </span>
          <div className="flex items-center gap-2">
            <span className="min-w-[50px]">Years</span>
            <span className="min-w-[64px]">Months</span>
            <span className="min-w-[39px]">
              Days <span className="text-primary min-w-[39px] ps-2">Days</span>
            </span>
          </div>
        </div>
      ),
      cell: (info) => (
        <div className="flex items-center gap-2 bg-secondaryNeutral font-medium p-2">
          <span className="min-w-[48px]">{info.getValue().years}</span>

          <span className="min-w-[61px]">
            <span className=""></span>
            {info.getValue().months}
          </span>

          <span className="min-w-[40px]">
            <span className=""></span>
            {info.getValue().days}
          </span>

          <span className="text-primary min-w-[39px]">
            {info.getValue().ageInDays}
          </span>
        </div>
      ),
    }),
    columnHelper.accessor("transition", {
      id: "price",
      header: () => (
        <div className="text-start">
          <span>
            Student <br /> Transitions To
          </span>
        </div>
      ),
      cell: (info) => (
        <div className="text-start">
          {handleGetClassroomName(
            info.getValue()?.TransitionClassroom as string
          ) || "Auto Calculated"}
        </div>
      ),
    }),

    // columnHelper.accessor("_id", {
    //   id: "action",
    //   header: () => (
    //     <div className="text-end">
    //       <span>Action</span>
    //     </div>
    //   ),
    //   cell: (row) => (
    //     <ActionDropdown
    //       classroom={row.row.original}
    //       isActive={row.row.original.isActive as boolean}
    //       isOpen={openDropdownId === row.row.original?._id}
    //       numOfSpecialClassrooms={calculateNumberOfSpecialClassrooms()}
    //       toggleDropdown={toggleDropdown}
    //       // fetchAgain={fetchAgain}
    //       // setFetchAgain={fetchAgain}
    //       fetchAgain={fetchAgain}
    //     />
    //     // <div className="text-end">
    //     //   <button>
    //     //     <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
    //     //   </button>
    //     // </div>
    //   ),
    // }),
  ];

  useEffect(() => {
    _setData(classrooms);
  }, [classrooms]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="custom-scrollbar overflow-y-scroll h-[calc(100vh-445px)] bg-white rounded-xl border-secondaryVariant2 border">
      <table className="w-full bg-white rounded-lg">
        <thead className="sticky top-0 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => (
                <th
                  key={header.id}
                  className="p-5 text-left text-base font-medium text-secondary capitalize"
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                      header.column.columnDef.header,
                      header.getContext()
                    )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-secondaryNeutral">
          {table.getRowModel().rows.map((row) => (
            <tr key={row.id} className="hover:bg-secondaryNeutral">
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="p-5 text-base text-secondaryVariant border-b border-secondaryNeutral"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default EssentialsClassroomTable;
