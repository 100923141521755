import { WeekDay, Months } from "../../enums/Foray.enums";

export const getTodayDayName = (): WeekDay => {
  const days = [
    WeekDay.Sunday,
    WeekDay.MONDAY,
    WeekDay.TUESDAY,
    WeekDay.WEDNESDAY,
    WeekDay.THURSDAY,
    WeekDay.FRIDAY,
    WeekDay.Saturday,
  ];
  const today = new Date().getDay(); // 0 = Sunday, 1 = Monday, etc.
  return days[today];
};

export const getCurrentMonth = (): Months => {
  const months = [
    Months.January,
    Months.February,
    Months.March,
    Months.April,
    Months.May,
    Months.June,
    Months.July,
    Months.August,
    Months.September,
    Months.October,
    Months.November,
    Months.December,
  ];
  const currentMonth = new Date().getMonth(); // 0 = January, 1 = February, etc.
  return months[currentMonth];
};
