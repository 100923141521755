import React from "react";
import { IoIosInformation } from "react-icons/io";
import { IoCloseOutline } from "react-icons/io5";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  onPreview: () => void;
}

const PreviewModal: React.FC<ModalProps> = ({ isOpen, onClose, onPreview }) => {
  if (!isOpen) return null;

  const handlePreviewClick = () => {
    onPreview();
    // Don't close the modal here - let the API call handle that
  };

  return (
    <div className="fixed inset-0 bg-black/60 flex justify-center items-center z-[9999]">
      <div className="bg-white py-9 px-12 rounded-3xl shadow-lg w-[676px] max-w-full relative">
      <button
                        onClick={onClose}
                        className="text-gray-500 hover:text-gray-700 absolute top-1 right-3"
                      >
                        {" "}
                        <IoCloseOutline className="w-8 h-8 text-secondary" />
                      </button>
        <div className="flex items-start flex-col whitespace-break-spaces text-left">
          <h3 className="text-2xl text-secondary font-semibold ">
            Clicking this button will temporarily show you all the changes you
            made.
          </h3>
          <br />
          <p className="text-xl text-secondary mb-9">
            After previewing, if you're happy with these changes, and want to
            permanently save them, please go to upper right corner and click
            "Save."{" "}
          </p>

          <button
            className="btnPrimary max-w-[158px] flex justify-center"
            onClick={handlePreviewClick}
          >
            Yes, Preview
          </button>
        </div>
      </div>
    </div>
  );
};

export default PreviewModal;
