import { VscEdit } from "react-icons/vsc";
import { RxCheckCircled } from "react-icons/rx";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";

interface ScheduleSelectorProps {

  toggleModify: () => void;
  toggleIdeal:() => void;
  isModifyMode?:boolean;
  isModifyIdealMode?:boolean;
}

const ScheduleSelector: React.FC<ScheduleSelectorProps> = ({
  isModifyMode,
  toggleModify,
  toggleIdeal,
  isModifyIdealMode
}) => {
  return (
    <div className="grid grid-cols-2">
      {/* Today’s Schedule */}
      <div className={`flex items-center justify-between py-3 min-h-[60px] px-3 group cursor-pointer rounded-ss-xl ${isModifyMode ? 'bg-primary' : ''} `}>
        <div className={ `text-lg ${isModifyMode ? 'text-white' : 'text-secondaryVariant'} font-semibold flex items-center gap-2 uppercase`}>
          <RxCheckCircled className="w-6 h-6" />
          Today’s Schedule
        </div>

        {isModifyMode ? (
           <div className="flex items-center gap-2">
           <button className="rounded-lg py-1 px-3 bg-secondary text-white gap-2 inline-flex items-center"><FaRegCircleCheck className="w-5 h-5"/> Save</button>
           <button className="rounded-lg py-1 px-3 bg-white text-secondary gap-2 inline-flex items-center" onClick={toggleModify}><IoCloseOutline className="w-5 h-5"/> Cancel</button>
         </div>
         
        ) : (
          
          <button className="text-secondaryVariant text-base font-medium flex items-center gap-1" onClick={toggleModify}>
          <VscEdit className="w-5 h-5" />
          Modify
        </button>
         
        )}
      </div>

      {/* Ideal Schedule */}
      <div className={`flex items-center justify-between py-3 min-h-[60px] px-3 group cursor-pointer rounded-ss-xl ${isModifyIdealMode ? 'bg-primary' : ''} `}>
      <div className={ `text-lg ${isModifyIdealMode ?  'text-white' : 'text-secondaryVariant'} font-semibold flex items-center gap-2 uppercase`}>
          Ideal Schedule
        </div>

        {isModifyIdealMode ? (
           <div className="flex items-center gap-2">
           <button className="rounded-lg py-1 px-3 bg-secondary text-white gap-2 inline-flex items-center"><FaRegCircleCheck className="w-5 h-5"/> Save</button>
           <button className="rounded-lg py-1 px-3 bg-white text-secondary gap-2 inline-flex items-center" onClick={toggleIdeal}><IoCloseOutline className="w-5 h-5"/>  Cancel</button>
         </div>
          
        ) : (
          <button className="text-secondaryVariant text-base font-medium flex items-center gap-1" onClick={toggleIdeal}>
           <VscEdit className="w-5 h-5" />
           Modify
         </button>
         
        )}
      </div>
    </div>
  );
};

export default ScheduleSelector;
