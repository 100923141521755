import * as React from "react";
import { MdOutlineModeEdit } from "react-icons/md";
import { BsThreeDotsVertical } from "react-icons/bs";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

type Person = {
  name: string;
  rules: string;
  howEarned: string; // Changed to string to match your example
  mayNotUsePTO: string;
  action: string;
};

const defaultData: Person[] = [
  {
    name: "PTO-1",
    rules: "May Use Right Away",
    howEarned: "Fixed & Assigned at Start of Work", // Changed to string to match the expected format
    mayNotUsePTO: "120 days",
    action: "",
  },
  {
    name: "PTO-2",
    rules: "May Use Right Away",
    howEarned: "Fixed & Assigned at Start of Work", // Changed to string to match the expected format
    mayNotUsePTO: "120 days",
    action: "",
  },
  {
    name: "PTO-3",
    rules: "May Use Right Away",
    howEarned: "Fixed & Assigned at Start of Work", // Changed to string to match the expected format
    mayNotUsePTO: "120 days",
    action: "",
  },
];

const columnHelper = createColumnHelper<Person>();

const columns = [
  columnHelper.accessor("name", {
    id: "name",
    header: () => (
      <div className="flex items-center gap-3">
        <span>Name</span>
      </div>
    ),
    cell: (info) => (
      <div className="flex items-center gap-3">
        <span className="w-full">{info.getValue()}</span>
      </div>
    ),
  }),
  columnHelper.accessor("rules", {
    id: "rules",
    header: () => (
      <div className="flex items-center gap-3">
        <span>Rules</span>
      </div>
    ),
    cell: (info) => (
      <div className="flex items-center gap-3">
        <span className="w-full">{info.getValue()}</span>
      </div>
    ),
  }),

  columnHelper.accessor("howEarned", {
    id: "howEarned",
    header: () => (
      <div className="text-start">
        <span>how Earned</span>
      </div>
    ),
    cell: (info) => <div className="text-start">{info.getValue()}</div>,
  }),
  columnHelper.accessor("mayNotUsePTO", {
    id: "mayNotUsePTO",
    header: () => (
      <div className="text-start">
        <span>May not use PTO during first</span>
      </div>
    ),
    cell: (info) => <div className="text-start">{info.getValue()}</div>,
  }),

  columnHelper.accessor("action", {
    id: "action",
    header: () => (
      <div className="text-end">
        <span>Action</span>
      </div>
    ),
    cell: (info) => (
      <div className="text-end">
        <button>
          {info.getValue()}{" "}
          <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
        </button>
      </div>
    ),
  }),
];

const PtoSickTable = () => {
  const [data, _setData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="mb-3">
      <table className="w-full bg-white rounded-t-lg overflow-hidden">
        <thead className="sticky top-0 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={`py-4 px-5 text-left text-base font-medium text-secondary capitalize 
              ${index === 0 ? "rounded-tl-lg" : ""} 
              ${
                index === headerGroup.headers.length - 1 ? "rounded-tr-lg" : ""
              }`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-secondaryNeutral">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="hover:bg-secondaryNeutral odd:bg-secondaryNeutral"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="p-2.5 px-5 text-base text-secondaryVariant border-b border-secondaryNeutral"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default PtoSickTable;
