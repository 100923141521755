import React from "react";
import { IoIosInformation } from "react-icons/io";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store/store";
import { EnrollmentTrackingSvc } from "../../services/enrollment.service";

interface ModalProps {
  onClose: () => void;
  // previewID: string | undefined;
}

const WarningModal: React.FC<ModalProps> = ({ onClose }) => {
  const { previewId } = useSelector((state: RootState) => state.enrollmentView);

  const onSave = async () => {
    if (previewId) {
      await EnrollmentTrackingSvc.saveChanges(previewId);
    }

    onClose();
  };

  return (
    <>
      <div className="fixed inset-0 bg-secondaryVariant bg-opacity-20 flex justify-center items-center z-[9999]">
        <div className="bg-white py-9 px-12 rounded-3xl shadow-lg w-[551px] relative">
          {/* <button
            onClick={onClose}
            className="text-gray-500 hover:text-gray-700 absolute right-4 top-4"
          >
            <IoCloseOutline className="w-6 h-6 text-secondary" />
          </button> */}
          <div className="flex items-center flex-col text-center justify-center gap-6">
            <i className="bg-primary rounded-full w-16 h-16 text-center flex items-center justify-center">
              <IoIosInformation className="w-12 h-12 text-white" />
            </i>
            <h3 className="text-2xl text-secondary font-semibold">
              You’re about to leave the <br /> optimization screen.{" "}
            </h3>
            <p className="text-xl text-secondary font-medium">
              Your changes haven’t been saved. Would you like to save them
              before you go?
            </p>

            <div className="flex items-center w-full justify-center gap-3">
              <button
                className="btnPrimary max-w-[172px] flex justify-center"
                onClick={onSave}
              >
                Save Changes
              </button>
              <button onClick={onClose} className="btnSimple max-w-[180px]">
                Discard Changes
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default WarningModal;
