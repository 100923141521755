import { useQuery } from "@tanstack/react-query";
import { StripeQueryKeyString } from "../enums/stripeQueryKeyString";
import { stripeSvc } from "../services/stripe.service";

export const useProducts = () => {
  return useQuery({
    queryKey: [StripeQueryKeyString.Products],
    queryFn: () => stripeSvc.fetchProducts(),
  });
};
