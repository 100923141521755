// FileUploadBox.tsx
import React, { useRef } from "react";
import { GoPlus } from "react-icons/go";
import { IoCloseOutline } from "react-icons/io5";
import { LuFileCheck } from "react-icons/lu";
import { MdOutlineFileUpload } from "react-icons/md";

interface FileUploadBoxProps {
  label: string;
  onFilesUpload: (files: File[]) => void;
  uploadedFiles: File[];
  onRemoveFile: (index: number) => void;
}

const FileUploadBox: React.FC<FileUploadBoxProps> = ({ label, onFilesUpload, uploadedFiles, onRemoveFile }) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = Array.from(e.dataTransfer.files);
    if (files.length) onFilesUpload(files);
  };

  const handleClick = () => fileInputRef.current?.click();

  const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = Array.from(e.target.files || []);
    if (files.length) onFilesUpload(files);
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-between">
        <span className="text-secondary text-base font-medium">
          {label}
        </span>
        {/* <button className="text-statusColor text-lg font-medium px-3 flex items-center gap-1">
          <GoPlus className="w-6 h-6" />
          Add more
        </button> */}
      </div>
      <div
        className="border border-dashed border-secondaryVariant rounded-[10px] p-5 flex items-center gap-6 cursor-pointer"
        onClick={handleClick}
        onDragOver={(e) => e.preventDefault()}
        onDrop={handleDrop}
      >
        <button className="bg-secondaryVariant text-white p-3 rounded-lg pointer-events-none">
          <MdOutlineFileUpload className="w-6 h-6" />
        </button>
        <div>
          <p className="text-base text-secondaryVariant">
            Drag & Drop or <strong className="text-primary">choose file</strong> to upload
          </p>
          <span className="text-xs text-secondaryVariant">Supported formats : Jpeg, pdf</span>
        </div>
        <input
          ref={fileInputRef}
          type="file"
          className="hidden"
          multiple
          accept="image/jpeg, application/pdf"
          onChange={handleFileChange}
        />
      </div>

      <div className="mt-3 space-y-2">
        {uploadedFiles.map((file, index) => (
          <div
            key={index}
            className="flex items-center justify-between py-2 px-3 bg-secondaryNeutral border-[0.5px] border-secondaryVariant2 rounded-md cursor-pointer"
            onClick={() => window.open(URL.createObjectURL(file), '_blank')}
          >
            <p className="text-base text-secondary flex items-center gap-3">
              <i>
                <LuFileCheck className="w-6 h-6" />
              </i>
              {file.name}
            </p>
            <button onClick={(e) => { e.stopPropagation(); onRemoveFile(index); }}>
              <IoCloseOutline className="w-6 h-6 text-secondary" />
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default FileUploadBox;
