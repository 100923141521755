import React from "react";
import { IEnrollmentSwitchesState } from "../../types/IEnrollmentSwitches";

interface IProps {
  switches: IEnrollmentSwitchesState;
  handleToggle: (switchName: string) => void;
}

const EnrolTrackingOptimization: React.FC<IProps> = ({
  switches,
  handleToggle,
}) => {
  return (
    <div className="flex items-center justify-between py-5">
      <h2 className="text-2xl text-secondary font-semibold">
        Enrollment Tracking & Optimization
      </h2>
      <div className="flex items-center gap-3">
        {/* Switch 1 */}
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary line-clamp-1 truncate">
            Strategic View
          </span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="strategicView"
              className="hidden peer"
              checked={switches.strategicView}
              onChange={() => handleToggle("strategicView")}
            />
            <label
              htmlFor="strategicView"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary line-clamp-1 truncate">
            Enrollment No.
          </span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="enrollmentNo"
              className="hidden peer"
              checked={switches.enrollmentNo}
              onChange={() => handleToggle("enrollmentNo")}
            />
            <label
              htmlFor="enrollmentNo"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary line-clamp-1 truncate">
            Age Violation
          </span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="ageViolation"
              className="hidden peer"
              checked={switches.ageViolation}
              onChange={() => handleToggle("ageViolation")}
            />
            <label
              htmlFor="ageViolation"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary line-clamp-1 truncate">
            Movements
          </span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="movements"
              className="hidden peer"
              checked={switches.movements}
              onChange={() => handleToggle("movements")}
            />
            <label
              htmlFor="movements"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        <div className="flex items-center">
          <span className="mr-3 text-base text-secondary line-clamp-1 truncate">
            Optimization
          </span>
          <div className="relative inline-block w-11 h-6">
            <input
              type="checkbox"
              id="optimization"
              className="hidden peer"
              checked={switches.optimization}
              onChange={() => handleToggle("optimization")}
            />
            <label
              htmlFor="optimization"
              className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
            ></label>
            <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
          </div>
        </div>
        {/* Repeat similar structure for other switches */}
      </div>
    </div>
  );
};

export default EnrolTrackingOptimization;
