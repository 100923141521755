import React, { useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import moment from "moment";

// Define the discrete point interface since it's not exported by apexcharts
interface DiscretePoint {
  dataPointIndex: number;
  seriesIndex: number;
  size: number;
  fillColor: string;
  strokeColor: string;
}

interface RegistrationChartProps {
  dayData: any[];
  title: string;
  barcolor: string;
  linecolor: string;
}

const RegistrationChartCustom: React.FC<RegistrationChartProps> = ({
  dayData,
  title,
  barcolor,
  linecolor,
}) => {
  const { barData, lineData, labels } = useMemo(() => {
    const barData: number[] = [];
    const lineData: number[] = [];
    const labels: string[] = [];

    dayData?.forEach((data) => {
      const dailyValue =
        title === "Center Registration"
          ? data.dailyRegistrations.centers
          : title === "Teacher Registration"
          ? data.dailyRegistrations.teachers
          : data.dailyRegistrations.parents;

      const totalValue =
        title === "Center Registration"
          ? data.totalRegistrationsTillDay.centers
          : title === "Teacher Registration"
          ? data.totalRegistrationsTillDay.teachers
          : data.totalRegistrationsTillDay.parents;

      barData.push(dailyValue);
      lineData.push(totalValue);
      labels.push(data.date);
    });

    return { barData, lineData, labels };
  }, [dayData, title]);

  // Create a visibility map for markers based on the date range
  const { adjustedLabels, markerVisibility } = useMemo(() => {
    const totalDays = labels.length;
    let interval = 1; // Default: show every day
    
    if (totalDays <= 60) {
      interval = 1; // Show every day
    } else if (totalDays <= 150) {
      interval = 7; // Show every 7th day
    } else if (totalDays <= 365) {
      interval = 14; // Show every 14th day
    } else {
      interval = 30; // Show every 30th day
    }

    const adjustedLabels = labels.map((label, index) => {
      return (index + 1) % interval === 0 ? moment(label).format("MMM DD") : "";
    });

    // Create a visibility array that matches with the data points
    const markerVisibility = labels.map((_, index) => {
      return (index + 1) % interval === 0;
    });

    return { adjustedLabels, markerVisibility };
  }, [labels]);

  const options: ApexOptions = useMemo(() => {
    return {
      series: [
        {
          name: "Daily Registrations",
          type: "column",
          data: barData,
        },
        {
          name: "Total Registrations",
          type: "line",
          data: lineData,
        },
      ],
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
        animations: {
          enabled: true,
        },
      },
      stroke: {
        width: [0, 4],
        curve: "smooth",
      },
      dataLabels: {
        enabled: false,
      },
      markers: {
        size: 0, // Hide all markers by default
        colors: [linecolor],
        strokeColor: linecolor,
        hover: {
          size: 8,
        },
        discrete: markerVisibility
          .map((visible, index) => {
            if (visible) {
              return { 
                dataPointIndex: index,
                seriesIndex: 1, // For the line series
                size: 5,
                fillColor: linecolor,
                strokeColor: linecolor,
              };
            }
            return undefined;
          })
          .filter((point): point is DiscretePoint => point !== undefined),
      },
      tooltip: {
        enabled: true,
        shared: true,
        intersect: false,
        y: {
          formatter: (value: number) => `${value} Registrations`,
        },
        x: {
          formatter: (value: number, opts) => {
            const dataPointIndex = opts.dataPointIndex;
            return moment(labels[dataPointIndex]).format("MMM DD, YYYY");
          },
        },
      },
      labels: adjustedLabels,
      xaxis: {
        categories: adjustedLabels,
        title: {
          text: "Date",
        },
        labels: {
          formatter: (value: string) => (value ? value : ""), // Hide empty labels
          rotate: -45,
          rotateAlways: false,
          style: {
            fontSize: '12px',
          }
        },
        tickPlacement: 'on',
      },
      yaxis: [
        {
          title: {
            text: "Daily Registrations",
          },
          min: 0,
          forceNiceScale: true,
        },
        {
          opposite: true,
          title: {
            text: "Cumulative Registrations",
          },
          min: 0,
          forceNiceScale: true,
        },
      ],
      legend: {
        show: true,
        position: 'top',
        horizontalAlign: 'right',
      },
      colors: [barcolor, linecolor],
      grid: {
        borderColor: '#f1f1f1',
        xaxis: {
          lines: {
            show: true
          }
        }
      },
      responsive: [
        {
          breakpoint: 1024,
          options: {
            chart: {
              height: 300
            }
          }
        }
      ]
    };
  }, [barData, lineData, adjustedLabels, markerVisibility, barcolor, linecolor, labels]);

  return (
    <div id="chart" className="bg-white rounded-xl p-4">
      <h4 className="text-xl font-semibold text-secondary mb-4">{title}</h4>
      <ApexCharts
        options={options}
        series={options.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default RegistrationChartCustom;