import React, { useState } from "react";
import Select, { SingleValue } from "react-select";
import { IOptionN, IOptionS } from "../../types/ICenterSetup.interface";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import { MdOutlineClose } from "react-icons/md";

const options = [
  // { value: "Select Country", label: "Select Country" },
  { value: "USA", label: "USA" },
];

interface Props {
  label: string;
  placeholder: string;
  value?: IOptionS | IOptionN | null; // Controlled value passed from parent
  onChange?: (selectedOption: SingleValue<IOptionS | IOptionN> | null) => void; // onChange handler passed from parent
  options?: IOptionS[] | IOptionN[]; // Options passed from parent
  required?: boolean;
  isDisabled?: boolean; // Optional prop to disable the select
  onFocus?: () => void;
  onBlur?: () => void;
  menuPlacement?: "auto" | "top" | "bottom";
  small?: boolean;
}

const ReactSelect: React.FC<Props> = ({
  label,
  placeholder,
  value,
  onChange,
  options,
  required = false,
  isDisabled = false, // Default value for isDisabled is false
  onFocus,
  onBlur,
  menuPlacement = "auto",
  small = false,
}) => {
  return (
    <div className="w-full relative">
      <label
        htmlFor="select"
        className="text-sm text-secondaryVariant block truncate"
      >
        {label}
        {required && <RequiredAsterisk />}
      </label>
      <Select
        value={value}
        onChange={onChange} // Controlled value and change handler
        options={options}
        placeholder={placeholder}
        menuPlacement={menuPlacement}
        isDisabled={isDisabled} // Disable the select if required
        className={`w-full relative mt-1 ${isDisabled ? "opacity-50" : ""}`}
        closeMenuOnScroll={true}
        styles={{
          control: (baseStyles, state) => ({
            ...baseStyles,
            width: "100%",
            borderColor: state.isFocused ? "grey" : "#CED2DA",
            padding: small ? "0" : "8px 8px 8px 4px",
            borderRadius: "8px",
            fontSize: small ? "14px" : "16px",
          }),
          menuPortal: (base) => ({ ...base, zIndex: 9999 }),
          singleValue: (baseStyles) => ({
            ...baseStyles,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          placeholder: (baseStyles) => ({
            ...baseStyles,
            whiteSpace: "nowrap",
            overflow: "hidden",
            textOverflow: "ellipsis",
          }),
          /** Custom dropdown menu styles **/
          menu: (baseStyles) => ({
            ...baseStyles,
            backgroundColor: "#ffffff",
            borderRadius: "8px",
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            marginTop: "4px",
            padding: "5px",
            minWidth: small ? "150px" : "auto",
            zIndex: 9999,
          }),
          // menuPortal: (baseStyles) => ({
          //   ...baseStyles,
          //   zIndex: 999,
          // }),
          menuList: (baseStyles) => ({
            ...baseStyles,
            padding: "5px",
            maxHeight: "200px", // Set a max height for scrolling
            overflowY: "auto",
            scrollbarWidth: "thin", // Works in Firefox
            scrollbarColor: "#bebfcd transparent", // Thumb and track color (Firefox)
            "&::-webkit-scrollbar": {
              width: "6px",
            },
            "&::-webkit-scrollbar-track": {
              background: "transparent",
            },
            "&::-webkit-scrollbar-thumb": {
              background: "#bebfcd",
              borderRadius: "10px",
            },
            "&::-webkit-scrollbar-thumb:hover": {
              background: "#666",
            },
          }),
          option: (baseStyles, state) => ({
            ...baseStyles,
            backgroundColor: state.isSelected
              ? "#FFE6D8"
              : state.isFocused
              ? "#FFE6D8"
              : "#ffffff",
            color: state.isSelected ? "#DF7A5E" : "#000000",
            padding: "10px",
            borderRadius: "4px",
            cursor: "pointer",
            "&:hover": {
              backgroundColor: "#FFE6D8",
            },
            "&:active": {
              backgroundColor: "#FFE6D8",
            },
          }),
        }}
        // menuPortalTarget= {small ? null : document.body}
        onFocus={onFocus}
        onBlur={onBlur}
      />
      {value && (
        <button
          className={`absolute right-11 ${label ? "top-11" : "top-6"} ${
            isDisabled ? "opacity-50 cursor-default" : ""
          }`}
          onClick={() => {
            onChange?.(null);
          }}
          disabled={isDisabled}
        >
          <MdOutlineClose className="w-4 h-4 text-secondary" />
        </button>
      )}
    </div>
  );
};

export default ReactSelect;
