import React, { useState } from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { forayRoutes } from "../../routes/routes";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import ForayTabs from "../../components/forayTabs/ForayTabs";
import ClassroomDetailedList from "../../components/classroomDetail/ClassroomDetailedList";
import AddNew from "../../components/addNew/AddNew";
import { Link } from "react-router-dom";

const ClassroomDetailed = () => {
  const [hasClassroomWitUnfilledDetails, setHasClassroomWithUnfilledDetails] =
    useState(false);
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Classroom-Details", isCurrent: true },
  ];

  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-5 bg-secondaryNeutral overflow-y-scroll h-[calc(100vh-100px)] custom-scrollbar">
          <div className="flex items-center justify-between">
            <Breadcrumb items={breadcrumbItems} />
            <div className="flex items-center gap-4">
              <Link
                to={forayRoutes.foray_dashboard}
                className="text-base text-white font-medium px-4 py-3 rounded bg-primary"
              >
                Go to Foray Dashboard
              </Link>
              <Link
                to={forayRoutes.enrollment_tracking}
                className="text-base text-white font-medium px-4 py-3 rounded bg-secondary"
              >
                Go to Enrollment Tracking{" "}
              </Link>
            </div>
          </div>{" "}
          <ForayTabs
            stepNumber={!hasClassroomWitUnfilledDetails ? 3 : 2}
            hasClassrooms={true}
          />
          <ClassroomDetailedList
            setHasClassroomWithUnfilledDetails={
              setHasClassroomWithUnfilledDetails
            }
          />
          <AddNew
            buttonText={"Go to Student Setup"}
            route={
              forayRoutes.student_setup +
              "?pr=" +
              forayRoutes.classroom_detailed
            }
            disabled={hasClassroomWitUnfilledDetails}
          />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ClassroomDetailed;
