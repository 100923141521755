import * as React from "react";
import { MdOutlineModeEdit } from "react-icons/md";
import { HiOutlineTrash } from "react-icons/hi2";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";

type Person = {
  sendBy: string;
  sendTo: string;
  template: string; // Changed to string to match your example
  frequency: string;
  action: string;
};

const defaultData: Person[] = [
  {
    sendBy: "Email",
    sendTo: "Parents",
    template: "Policy update e.g.", // Changed to string to match the expected format
    frequency: "Once",
    action: "",
  },
  {
    sendBy: "Email",
    sendTo: "Parents",
    template: "Policy update e.g.", // Changed to string to match the expected format
    frequency: "Once",
    action: "",
  },
  {
    sendBy: "Email",
    sendTo: "Parents",
    template: "Policy update e.g.", // Changed to string to match the expected format
    frequency: "Once",
    action: "",
  },
];

const columnHelper = createColumnHelper<Person>();

const columns = [
  columnHelper.accessor("sendBy", {
    id: "sendBy",
    header: () => (
      <div className="flex items-center gap-3">
        <span>Send By</span>
      </div>
    ),
    cell: (info) => (
      <div className="flex items-center gap-3">
        <span className="w-full">{info.getValue()}</span>
      </div>
    ),
  }),
  columnHelper.accessor("sendTo", {
    id: "sendTo",
    header: () => (
      <div className="flex items-center gap-3">
        <span>Send To</span>
      </div>
    ),
    cell: (info) => (
      <div className="flex items-center gap-3">
        <span className="w-full">{info.getValue()}</span>
      </div>
    ),
  }),

  columnHelper.accessor("template", {
    id: "template",
    header: () => (
      <div className="text-start">
        <span>Template</span>
      </div>
    ),
    cell: (info) => <div className="text-start">{info.getValue()}</div>,
  }),
  columnHelper.accessor("frequency", {
    id: "frequency",
    header: () => (
      <div className="text-start">
        <span>Frequency</span>
      </div>
    ),
    cell: (info) => <div className="text-start">{info.getValue()}</div>,
  }),

  columnHelper.accessor("action", {
    id: "action",
    header: () => (
      <div className="text-start">
        <span>Action</span>
      </div>
    ),
    cell: (info) => (
      <div className="flex items-center gap-6">
        <button>
          {info.getValue()}{" "}
          <MdOutlineModeEdit className="w-6 h-6 text-statusColor" />
        </button>
        <button>
          {info.getValue()}{" "}
          <HiOutlineTrash className="w-6 h-6 text-dangerAlert" />
        </button>
      </div>
    ),
  }),
];

const CommunicationTable = () => {
  const [data, _setData] = React.useState(() => [...defaultData]);

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  return (
    <div className="scrollHide   overflow-y-auto h-[calc(100vh-350px)]">
      <table className="w-full bg-white rounded-t-lg overflow-hidden">
        <thead className="sticky top-0 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header, index) => (
                <th
                  key={header.id}
                  className={`py-4 px-5 text-left text-base font-medium text-secondary capitalize 
              ${index === 0 ? "rounded-tl-lg" : ""} 
              ${
                index === headerGroup.headers.length - 1 ? "rounded-tr-lg" : ""
              }`}
                >
                  {header.isPlaceholder
                    ? null
                    : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className="bg-white divide-y divide-secondaryNeutral">
          {table.getRowModel().rows.map((row) => (
            <tr
              key={row.id}
              className="hover:bg-secondaryNeutral odd:bg-secondaryNeutral"
            >
              {row.getVisibleCells().map((cell) => (
                <td
                  key={cell.id}
                  className="p-2.5 px-5 text-base text-secondaryVariant border-b border-secondaryNeutral"
                >
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default CommunicationTable;
