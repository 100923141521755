import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import {
  calculateAge,
  formatDateSlash,
} from "../../../shared/utils/formatDate";
import { maskPhoneNumber } from "../../../shared/utils/formatNumbers";
import { Slot } from "../../enums/Foray.enums";
import { IClassroomSetup, Transition } from "../../types/IClassroomSetup.interface";
import { StudentInfoDetailed } from "../../types/IStudentSetup.interface";
import CustomCheckbox from "../customCheckbox/CustomCheckbox";

const emptyVal = "-";
interface IProps {
  students: StudentInfoDetailed[];
  classrooms: IClassroomSetup[];
  handleUpdateStudent: (student: StudentInfoDetailed, comment: string) => void;
  handleGetClassroom: (classroomId: string) => IClassroomSetup | undefined;
}
const StudentManagementTable: FC<IProps> = ({
  students,
  classrooms,
  handleUpdateStudent,
  handleGetClassroom,
}) => {
  const [data, setData] = useState<StudentInfoDetailed[]>(students);
  const [comments, setComments] = useState<{ [key: string]: string }>({}); // Track comments per student
  const [editingRows, setEditingRows] = useState<{ [key: string]: boolean }>(
    {}
  ); // Track which row is being edited
  const inputRefs = useRef<{ [key: string]: HTMLTextAreaElement | null }>({}); // Track input field references

  const handleCommentBlur = (student: StudentInfoDetailed) => {
    handleUpdateStudent(student, comments[student._id as string] || ""); // Save the comment
    setEditingRows((prev) => ({ ...prev, [student._id as string]: false })); // Exit edit mode
  };

  const handleIdealTransitionDate = (
    studentDOB: string | Date,
    currentClassroomId: string,
    receivedTransDate?: string | Date
  ): string | null => {
    // Parse studentDOB as UTC
    const dob = moment.utc(studentDOB);
    if (!dob.isValid()) return null; // Invalid DOB case

    // Parse receivedTransDate as UTC if provided
    const receivedDate = receivedTransDate ? moment.utc(receivedTransDate) : null;

    // Find the student's current classroom by ID
    const currentClassroom = classrooms?.find(classroom => classroom._id === currentClassroomId);
    if (!currentClassroom || !currentClassroom?.transition) return null; // No valid classroom or transition info

    // Extract transition details (age to transition)
    const { years, months, days } = currentClassroom.transition;

    // Calculate transition date in UTC
    const transitionDate = dob.clone().add(years, "years").add(months, "months").add(days, "days");

    // If received transition date exists, compare it
    if (receivedDate && receivedDate.isValid()) {
      if (receivedDate.isSame(transitionDate, "day")) {
        return emptyVal; // If same date, return "-"
      }
      return receivedDate.format("MM/DD/YY"); // Return formatted received transition date
    }

    return transitionDate.format("MM/DD/YY"); // Return formatted transition date
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    studentId: string
  ) => {
    const { selectionStart } = e.target; // Get cursor position
    setComments((prev) => ({ ...prev, [studentId]: e.target.value }));

    // Delay restoring cursor position to avoid lag
    setTimeout(() => {
      if (inputRefs.current[studentId]) {
        inputRefs.current[studentId]!.selectionStart = selectionStart;
        inputRefs.current[studentId]!.selectionEnd = selectionStart;
      }
    }, 0);
  };

  const handleDateDifference = (
    idealDate: string | Date,
    actualDate: string | Date
  ) => {
    if (!idealDate || !actualDate) return emptyVal;
    const ideal = moment(idealDate);
    const actual = moment(actualDate);
    const diff = actual.diff(ideal, "days");
    if (diff > 0) {
      return "+" + diff + " Days";
    }
    return diff + " Days";
  };

  useEffect(() => {
    if (students?.length > 0) setData(students);
  }, [students]);

  const columnHelper = createColumnHelper<StudentInfoDetailed>();
  const basicInfoColsId = [
    "studentDetails",
    "studentFirstName",
    "studentLastName",
    "dob",
    "age",
  ];
  const columns = [
    // Student Details Section - with changed background color
    columnHelper.group({
      id: "studentDetails",
      header: () => <span className="font-medium">Student Details</span>,
      columns: [
        columnHelper.accessor((row) => row.firstName, {
          id: "studentFirstName",
          header: "First",
          cell: (info) => (
            <div className="text-center w-[135px] truncate line-clamp-1 whitespace-nowrap">
              <span className="w-full">{info.getValue() || emptyVal}</span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.lastName, {
          id: "studentLastName",
          header: "Last",
          cell: (info) => (
            <div className="text-center whitespace-nowrap w-[135px] truncate line-clamp-1">
              <span className=" w-full">{info.getValue() || emptyVal}</span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.dateOfBirth, {
          id: "dob",
          header: "DOB",
          cell: (info) => (
            <div className="text-center whitespace-nowrap w-[135px] truncate line-clamp-1">
              <span className="w-full">
                {formatDateSlash(info.getValue()) || emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.dateOfBirth, {
          id: "age",
          header: "Age",
          cell: (info) => (
            <div className="text-center w-[135px] truncate line-clamp-1">
              <span className="w-full">
                {info.getValue() ? calculateAge(info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
      ],
    }),

    // Parent Details Section
    columnHelper.group({
      id: "parentDetails",
      header: () => <span className="font-medium">Parent Details</span>,
      columns: [
        columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
          id: "parentFirstName",
          header: "First",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">{info.getValue() || emptyVal}</span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.parents?.[0]?.lastName, {
          id: "parentLastName",
          header: "Last",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">{info.getValue() || emptyVal}</span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.parents?.[0]?.phone, {
          id: "phoneNo",
          header: "Phone No",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? maskPhoneNumber(info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
      ],
    }),
    // Summer weeks Section
    // columnHelper.group({
    //   id: "parentDetails",
    //   header: () => <span className="font-medium">2024 - Summer Weeks</span>,
    //   columns: [
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           <span className="">
    //             {" "}
    //             <CustomCheckbox isPrimary />
    //           </span>{" "}
    //           18/03
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           Total Present Week
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //     columnHelper.accessor((row) => row.parents?.[0]?.firstName, {
    //       id: "parentFirstName",
    //       header: () => (
    //         <span className="flex items-center gap-1 justify-center">
    //           Total Absent Week
    //         </span>
    //       ),
    //       cell: (info) => (
    //         <div className="text-center whitespace-nowrap">
    //           <span className="w-full">{info.getValue() || emptyVal}</span>
    //         </div>
    //       ),
    //     }),
    //   ],
    // }),

    // Dates Section
    columnHelper.group({
      id: "dates",
      header: () => <span className="font-medium">Dates</span>,
      columns: [
        columnHelper.accessor((row) => row.Dates?.startAtCenter, {
          id: "start",
          header: "Start",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.Dates?.leaveDate, {
          id: "leaving",
          header: "Leaving",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.Dates?.tourDate, {
          id: `vacation`,
          header: "Tour",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.Dates?.dateWaitlisted, {
          id: "waitListed",
          header: "Waitlisted",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
      ],
    }),

    // Comments Section
    columnHelper.group({
      id: "comments",
      header: () => <span className="font-medium">Comments</span>,
      columns: [
        columnHelper.accessor((row) => row.comment, {
          id: "comments",
          header: () => (
            <div className="text-center whitespace-nowrap">
              <span className="block">Add Comment</span>
            </div>
          ),
          cell: (info) => {
            const studentId = info.row.original._id as string; // Get student ID
            const isEditing = editingRows[studentId] || false; // Check if this row is being edited

            return (
              <div className="text-center whitespace-nowrap">
                {isEditing ? (
                  <textarea
                    ref={(el) => (inputRefs.current[studentId] = el)} // Assign ref dynamically
                    rows={1}
                    className="border-none p-1 w-full"
                    value={comments[studentId] || ""}
                    onChange={(e) => handleInputChange(e, studentId)}
                    onBlur={() => handleCommentBlur(info.row.original)}
                    autoFocus
                  />
                ) : (
                  <div
                    className="text-center cursor-pointer whitespace-nowrap"
                    onClick={() => {
                      setEditingRows((prev) => ({
                        ...prev,
                        [studentId]: true,
                      }));
                      setComments((prev) => ({
                        ...prev,
                        [studentId]: info.getValue() || "",
                      }));
                    }}
                  >
                    <span className="w-full">{info.getValue() || "-"}</span>
                  </div>
                )}
              </div>
            );
          },
        }),
      ],
    }),

    // Transition 1 - New column group
    columnHelper.group({
      id: "transition1",
      header: () => <span className="font-medium">Transition 1</span>,
      columns: [
        columnHelper.accessor("transitions.firstTransition.classroomId", {
          id: "classroom1",
          header: "Classroom",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {handleGetClassroom(info.getValue())?.classroomName || emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.firstTransition.slot", {
          id: "fullOrPartTime1",
          header: () => (
            <span className="whitespace-nowrap">Full or Part Time</span>
          ),
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() === Slot.FULL_TIME
                  ? Slot.FULL_TIME
                  : !!info.getValue()
                    ? Slot.PART_TIME
                    : emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.firstTransition.date", {
          id: "idealDate1",
          header: "Ideal Date",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.classroom?._id as string) : emptyVal}
                {/* {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal} */}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.firstTransition.date", {
          id: "date1",
          header: "Modified Date",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.classroom?._id as string, info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.firstTransition.date", {
          id: "transitionDiff1",
          header: "Transition Diff",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {handleDateDifference(
                  handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.classroom?._id as string) as string,
                  info.row.original.transitions.firstTransition?.date as string
                ) || emptyVal}
              </span>
            </div>
          ),
        }),
      ],
    }),
    columnHelper.group({
      id: "transition2",
      header: () => <span className="font-medium">Transition 2</span>,
      columns: [
        columnHelper.accessor("transitions.secondTransition.classroomId", {
          id: "classroom2",
          header: "Classroom",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {handleGetClassroom(info.getValue())?.classroomName || emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.secondTransition.slot", {
          id: "fullOrPartTime2",
          header: () => (
            <span className="whitespace-nowrap">Full or Part Time</span>
          ),
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() === Slot.FULL_TIME
                  ? Slot.FULL_TIME
                  : !!info.getValue()
                    ? Slot.PART_TIME
                    : emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.secondTransition.date", {
          id: "idealDate2",
          header: "Ideal Date",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.transitions?.firstTransition?.classroomId as string) : emptyVal}
                {/* {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal} */}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.secondTransition.date", {
          id: "date2",
          header: "Modified Date",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.transitions?.firstTransition?.classroomId as string, info.getValue()) : emptyVal}
                {/* {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal} */}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.secondTransition.date", {
          id: "transitionDiff2",
          header: "Transition Diff",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {handleDateDifference(
                  handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.transitions?.firstTransition?.classroomId as string) as string,
                  info.row.original.transitions.secondTransition?.date as string
                ) || emptyVal}
              </span>
            </div>
          ),
        }),
      ],
    }),
    columnHelper.group({
      id: "transition3",
      header: () => <span className="font-medium">Transition 3</span>,
      columns: [
        columnHelper.accessor("transitions.thirdTransition.classroomId", {
          id: "classroom3",
          header: "Classroom",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {handleGetClassroom(info.getValue())?.classroomName || emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.thirdTransition.slot", {
          id: "fullOrPartTime3",
          header: () => (
            <span className="whitespace-nowrap">Full or Part Time</span>
          ),
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() === Slot.FULL_TIME
                  ? Slot.FULL_TIME
                  : !!info.getValue()
                    ? Slot.PART_TIME
                    : emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.thirdTransition.date", {
          id: "idealDate3",
          header: "Ideal Date",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {info.getValue() ? handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.transitions?.secondTransition?.classroomId as string) : emptyVal}
                {/* {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal} */}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.thirdTransition.date", {
          id: "date3",
          header: "Modified Date",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">

                {info.getValue() ? handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.transitions?.secondTransition?.classroomId as string, info.getValue()) : emptyVal}
                {/* {info.getValue() ? formatDateSlash(info.getValue()) : emptyVal} */}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor("transitions.thirdTransition.date", {
          id: "transitionDiff3",
          header: "Transition Diff",
          cell: (info) => (
            <div className="text-center whitespace-nowrap">
              <span className="w-full">
                {handleDateDifference(
                  handleIdealTransitionDate(info.row.original?.dateOfBirth, info.row.original?.transitions?.secondTransition?.classroomId as string) as string,
                  info.row.original.transitions.thirdTransition?.date as string
                ) || emptyVal}
              </span>
            </div>
          ),
        }),
      ],
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Function to determine if a cell is the last in its column group
  const isLastCellInGroup = (cellId: string) => {
    const columnGroups = [
      "studentDetails",
      "parentDetails",
      "dates",
      "comments",
      "transition1",
      "transition2",
      "transition3",
    ];
    for (const group of columnGroups) {
      const columnsInGroup =
        columns.find((col) => col.id === group)?.columns || [];
      if (
        columnsInGroup.length > 0 &&
        columnsInGroup[columnsInGroup.length - 1].id === cellId
      ) {
        return true;
      }
    }
    return false;
  };

  // Function to check if cell is in student details section
  const isStudentDetailsCell = (cellId: string) => {
    return (
      cellId === "studentFirstName" ||
      cellId === "studentLastName" ||
      cellId === "dob" ||
      cellId === "age"
    );
  };

  return (
    <div className="overflow-x-auto relative">
      <table className="min-w-full border-collapse bg-white">
        <thead className="sticky top-0 z-10 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // Apply special styling to group headers
                const isGroupHeader = header.depth === 1;
                const isStudentDetailsHeader = basicInfoColsId.includes(
                  header.column.id
                );

                // Determine if this header is in the Student Details section
                const isStudentDetailsGroupHeader =
                  header.column.id === "studentDetails";

                // Determine if this header is one of the columns in the Student Details section
                const isStudentDetailsColumnHeader = isStudentDetailsCell(
                  header.column.id
                );

                // Calculate left position for each Student Details column
                let leftPosition = "0";
                if (header.column.id === "studentLastName")
                  leftPosition = "167px";
                if (header.column.id === "dob") leftPosition = "334px";
                if (header.column.id === "age") leftPosition = "501px";

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`px-4 py-2 min-w-full text-base font-medium text-secondary border-b border-[#F2F2F2] ${isLastCellInGroup(header.column.id)
                      ? "border-r border-gray-200"
                      : ""
                      } ${isStudentDetailsHeader ? "bg-[#FFFAF8]" : ""}
                    ${isStudentDetailsGroupHeader ||
                        isStudentDetailsColumnHeader
                        ? `sticky z-20 bg-[#FFFAF8] shadow-sm`
                        : ""
                      }
                    ${isStudentDetailsColumnHeader
                        ? `left-[${leftPosition}]`
                        : isStudentDetailsGroupHeader
                          ? "left-0"
                          : ""
                      }`}
                    style={{
                      left:
                        isStudentDetailsColumnHeader ||
                          isStudentDetailsGroupHeader
                          ? leftPosition
                          : "auto",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr
              key={row.id}
              className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              {row.getVisibleCells().map((cell) => {
                // Check if this cell is the last in its column group to add right border
                const needsRightBorder = isLastCellInGroup(cell.column.id);
                const isStudentCell = isStudentDetailsCell(cell.column.id);

                // Calculate the background color based on row index for student details cells
                const studentCellBgColor =
                  rowIndex % 2 === 0 ? "bg-[#FFFAF8]" : "bg-[#FFF5F0]";

                // Calculate position for each Student Details cell
                let leftPosition = "0";
                if (cell.column.id === "studentLastName")
                  leftPosition = "167px";
                if (cell.column.id === "dob") leftPosition = "334px";
                if (cell.column.id === "age") leftPosition = "501px";

                return (
                  <td
                    key={cell.id}
                    className={`px-4 py-2 min-w-full text-base text-secondaryVariant border-b border-[#F2F2F2] ${needsRightBorder ? "border-r border-[#F2F2F2]" : ""
                      } ${isStudentCell ? studentCellBgColor : ""} ${isStudentCell ? `sticky z-10 shadow-sm` : ""
                      }`}
                    style={{
                      left: isStudentCell ? leftPosition : "auto",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default StudentManagementTable;
