import React, { useState, useRef, useEffect } from "react";
import { IOptionS } from "../../../types/ICenterSetup.interface";
import RequiredAsterisk from "../../../../shared/components/ui/RequiredAsterisk";


















interface MultiSelectProps {
  label: string;
  placeholder: string;
  options: IOptionS[];
  selectedValues: string[];
  onChange: (selectedValues: string[]) => void;
  required?: boolean;
  isDisabled?: boolean;
  maxSelections?: number; // Added maxSelections prop with default of 2
}

const MultiSelectClassroom: React.FC<MultiSelectProps> = ({
  label,
  placeholder,
  options,
  selectedValues,
  onChange,
  required = false,
  isDisabled = false,
  maxSelections = 2, // Default to 2 selections max
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string>("");
  const dropdownRef = useRef<HTMLDivElement>(null);

  // Close dropdown when clicking outside
  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  // Handle selection of an option
  const handleSelect = (value: string) => {
    if (selectedValues.includes(value)) {
      // Remove if already selected
      onChange(selectedValues.filter((v) => v !== value));
      setErrorMessage("");
    } else {
      // Add if not already selected and under the limit
      if (selectedValues.length < maxSelections) {
        onChange([...selectedValues, value]);
        setErrorMessage("");
      } else {
        // Show error if trying to select more than the maximum
        setErrorMessage(
          `Maximum of ${maxSelections} classrooms can be selected`
        );
      }
    }
  };













































  // Remove a selected value
  const handleRemove = (value: string) => {
    onChange(selectedValues.filter((v) => v !== value));
    setErrorMessage("");
  };

  // Get display labels for selected values
  const getSelectedLabels = () => {
    return selectedValues.map((value) => {
      const option = options.find((opt) => opt.value === value);
      return option ? option.label : value;
    });
  };






































































































































































  return (
    <div className="w-full relative" ref={dropdownRef}>
      <label className="text-secondaryVariant text-base mb-1 block">
        {label}
        {required && <RequiredAsterisk />}
      </label>

      {/* Selected values display */}
      <div
        className={`py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full min-h-[50px] cursor-pointer ${
          isDisabled ? "opacity-50 cursor-not-allowed" : ""
        }`}
        onClick={() => !isDisabled && setIsOpen(!isOpen)}
      >
        <div className="flex flex-wrap gap-1">
          {selectedValues.length > 0 ? (
            getSelectedLabels().map((label, index) => (
              <div
                key={index}
                className="bg-gray-100 rounded-md px-2 py-1 flex items-center text-sm"
              >
                <span>{label}</span>
                {!isDisabled && (
                  <button
                    type="button"
                    className="ml-1 text-gray-500 hover:text-red-500"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleRemove(selectedValues[index]);
                    }}
                  >
                    ×
                  </button>
                )}
              </div>
            ))
          ) : (
            <span className="text-gray-400">{placeholder}</span>
          )}
        </div>
      </div>

      {/* Error message */}
      {errorMessage && (
        <div className="text-red-500 text-sm mt-1">{errorMessage}</div>
      )}

      {/* Selection counter */}
      <div className="text-xs text-gray-500 mt-1">
        {selectedValues.length} of {maxSelections} selected
      </div>

      {/* Dropdown options */}
      {isOpen && !isDisabled && (
        <div className="absolute z-10 mt-1 w-full bg-white border border-gray-200 rounded-md shadow-lg max-h-60 overflow-auto">
          {options.map((option) => (
            <div
              key={option.value}
              className={`px-4 py-2 cursor-pointer hover:bg-gray-100 ${
                selectedValues.includes(option.value)
                  ? "bg-gray-50 text-primary"
                  : ""
              } ${
                selectedValues.length >= maxSelections &&
                !selectedValues.includes(option.value)
                  ? "opacity-50"
                  : ""
              }`}
              onClick={() => handleSelect(option.value)}
            >
              <div className="flex items-center">
                <input
                  type="checkbox"
                  checked={selectedValues.includes(option.value)}
                  disabled={
                    selectedValues.length >= maxSelections &&
                    !selectedValues.includes(option.value)
                  }
                  onChange={() => {}}
                  className="mr-2"
                />
                <span>{option.label}</span>
              </div>
            </div>
          ))}

          {options.length === 0 && (
            <div className="px-4 py-2 text-gray-500">No options available</div>
          )}
        </div>
      )}
    </div>
  );
};

export default MultiSelectClassroom;
