/* eslint-disable react-hooks/rules-of-hooks */
import * as React from "react";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import InputField from "../../../components/inputsFields/InputField";
import BonusPlanModal from "./BonusPlanModal";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreatePtoVocationModal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  if (!isOpen) return null;

  const [isModalOpen, setModalOpen] = React.useState(false);
  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white p-9 rounded-xl shadow-lg w-[633px] space-y-9">
        <h2 className="text-xl text-secondary font-semibold">
          Create PTO-Vacation{" "}
        </h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <InputField label="Name" type={"text"} placeholder={"PTO-1"} />
          </div>
          <div>
            <InputField
              label="Nick name"
              type={"text"}
              placeholder={"optional"}
            />
          </div>
          <div>
            <ReactSelect
              label={"Rules"}
              placeholder={"May Use Right Away"}
              required
            />
          </div>
          <div>
            <ReactSelect
              label={"How Earned"}
              placeholder={"Per Hours Worked"}
              required
            />
          </div>
          <div>
            <ReactSelect
              label={"May not use PTO during first"}
              placeholder={"30 days"}
              required
            />
          </div>
          <div>
            <ReactSelect
              label={"Notice required for vacation request"}
              placeholder={"7-14 days"}
              required
            />
          </div>
        </div>
        <div className="flex items-center gap-4">
          <button className="btnPrimary max-w-[120px]" onClick={openModal}>
            Save
          </button>
          <button className="btnSimple max-w-[120px]" onClick={onClose}>
            Cancel{" "}
          </button>
          <BonusPlanModal isOpen={isModalOpen} onClose={closeModal} />
        </div>
      </div>
    </div>
  );
};

export default CreatePtoVocationModal;
