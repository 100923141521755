import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { FC, memo, useCallback, useEffect, useMemo, useState } from "react";
import { Dropdown } from "react-bootstrap";
import { BsThreeDotsVertical } from "react-icons/bs";
import { GoPlus } from "react-icons/go";
import { HiOutlineTrash } from "react-icons/hi";
import { IoIosArrowRoundBack, IoIosArrowRoundForward } from "react-icons/io";
import { TfiReload } from "react-icons/tfi";
import { formatDateSlashFullYear } from "../../../shared/utils/formatDate";
import { useReOrderColumn } from "../../hooks/useReOrderColumn";
import { useToggleColumnVisibility } from "../../hooks/useToggleColumnVisibility";
import { useUpdateTasks } from "../../hooks/useUpdateTasks";
import { Column, ICategory, Section } from "../../types/ICategories.interface";
import { ICenterSetupRes, IOptionS } from "../../types/ICenterSetup.interface";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { ITasks } from "../../types/ITaskGeneration.interface";
import EditableCell from "../editableCell/EditableCell";
import { MdInfoOutline } from "react-icons/md";

import SelectColumnModal from "../modals/SelectColumnModal";

const getValueFromPath = (obj: any, path: string): any => {
  return path?.split(".")?.reduce((acc, part) => acc && acc[part], obj);
};

interface Props {
  tasks: ITasks[];
  selectedCard: ICategory;
  selectedSection: Section;
  centerSetup: ICenterSetupRes;
  classroomSetups: IClassroomSetup[];
  handleToggleDBView: (item: any, value: boolean) => void;
}
interface EditState {
  rowId: string;
  field: "initials" | "comment";
}

const TaskGenerationListing: FC<Props> = ({
  tasks,
  selectedCard,
  selectedSection,
  centerSetup,
  classroomSetups,
  handleToggleDBView,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [data, setData] = useState<ITasks[]>([]);
  const columnHelper = createColumnHelper<ITasks>();
  const handleOpenModal = () => setIsModalOpen(true);
  const handleCloseModal = () => setIsModalOpen(false);
  const [classroomOptions, setClassroomOptions] = useState<IOptionS[]>([]);
  const [classroomSlots, setClassroomSlots] = useState<IOptionS[]>([]);
  const [elementarySchoolOptions, setElementarySchoolOptions] = useState<
    IOptionS[]
  >([]);

  const [columnsCheckbox, setColumnsCheckbox] = useState<
    { id: string; label: string; isChecked: boolean }[]
  >([]);
  const [editState, setEditState] = useState<EditState | null>(null);
  const [editableRowId, setEditableRowId] = useState<string | null>(null);
  const [editedValues, setEditedValues] = useState<{
    tasks: { _id: string; initials: string; comment: string }[];
  }>({ tasks: [] });

  const { mutate: reOrderColumn } = useReOrderColumn();
  const { mutate: toggleColumnVisibility, isSuccess: isToggleSuccess } =
    useToggleColumnVisibility();
  const { mutate: updateTasks, isSuccess: isUpdateTasksSuccess } =
    useUpdateTasks();

  useEffect(() => {
    if (!classroomSetups?.length) return;
    setClassroomOptions(
      classroomSetups?.map((classroom) => ({
        value: classroom?._id as string,
        label: classroom?.classroomName,
      }))
    );
    setClassroomSlots(
      classroomSetups
        ?.flatMap((classroom) => classroom?.partTimeSlots)
        ?.map((slot) => ({
          value: slot?.uuid as string,
          label: slot?.name,
        }))
    );
  }, [classroomSetups]);

  const handleGetSchoolName = (id: string) => {
    if (!id) return "";
    const school = centerSetup?.existingCenterSetup?.elementarySchools?.find(
      (school) => school.uuid === id
    );
    return school?.schoolName;
  };

  const handleShiftClick = (columnPath: string, newOrder: number) => {
    if (columnPath && newOrder) {
      const reqBody = {
        columnPath,
        newOrder,
      };
      reOrderColumn({
        categoryId: selectedCard?._id,
        sectionId: selectedSection?._id,
        reqBody,
      });
    }
  };
  const handleToggleColumn = (columnId: string) => {
    setColumnsCheckbox((prev) =>
      prev.map((col) =>
        col.id === columnId ? { ...col, isChecked: !col.isChecked } : col
      )
    );
  };

  const handleRemoveColumn = (columnId: string) => {
    // Create a new array with the updated column visibility
    const updatedColumns = columnsCheckbox.map((col) =>
      col.id === columnId ? { ...col, isChecked: false } : col
    );

    // Set the state
    setColumnsCheckbox(updatedColumns);

    // Use the updated columns directly for the API call
    const columnUpdates = updatedColumns.map((col) => ({
      columnPath: col.id,
      visible: col.isChecked,
    })) as [{ columnPath: string; visible: boolean }];

    if (columnUpdates.length > 0) {
      toggleColumnVisibility({
        categoryId: selectedCard?._id,
        sectionId: selectedSection?._id,
        reqBody: { columnUpdates },
      });
    }
  };

  // Handle cell click to toggle edit mode
  // const handleCellClick = (taskId: string) => {
  //   // Persist current edits before switching to another row
  //   setEditableRowId((prev) => {
  //     if (prev && editedValues[prev]) {
  //       setData((prevData) =>
  //         prevData.map((task) =>
  //           task._id === prev
  //             ? {
  //                 ...task,
  //                 initials: editedValues[prev].initials || "-",
  //                 comment: editedValues[prev].comment || "-",
  //               }
  //             : task
  //         )
  //       );
  //     }

  //     // Enter edit mode for the clicked row
  //     return prev === taskId ? null : taskId;
  //   });
  // };

  const handleStartEdit = useCallback(
    (rowId: string, field: "initials" | "comment") => {
      setEditState({ rowId, field });
    },
    []
  );

  // const handleChange = useCallback(
  //   (rowId: string, field: "initials" | "comment", value: string) => {
  //     setData((prev) =>
  //       prev.map((row) =>
  //         row._id === rowId ? { ...row, [field]: value } : row
  //       )
  //     );
  //     setEditedValues((prev) => ({
  //       ...prev,
  //       [rowId]: {
  //         ...prev[rowId],
  //         [field]: value,
  //       },
  //     }));
  //   },
  //   []
  // );

  const handleChange = useCallback(
    (rowId: string, field: "initials" | "comment", value: string) => {
      setData((prev) =>
        prev.map((row) =>
          row._id === rowId ? { ...row, [field]: value } : row
        )
      );

      setEditedValues((prev) => {
        const existingTaskIndex = prev.tasks.findIndex(
          (task) => task._id === rowId
        );

        // If task already exists, update the field
        if (existingTaskIndex !== -1) {
          const updatedTasks = [...prev.tasks];
          updatedTasks[existingTaskIndex] = {
            ...updatedTasks[existingTaskIndex],
            [field]: value,
          };
          return { tasks: updatedTasks };
        }

        // If task does not exist, add a new entry
        return {
          tasks: [
            ...prev.tasks,
            {
              _id: rowId,
              initials: field === "initials" ? value : "",
              comment: field === "comment" ? value : "",
            },
          ],
        };
      });
    },
    []
  );

  // const handleInputChange = (
  //   taskId: string,
  //   field: "initials" | "comment",
  //   value: string
  // ) => {
  //   setEditedValues((prev) => ({
  //     ...prev,
  //     [taskId]: {
  //       ...prev[taskId],
  //       [field]: value,
  //     },
  //   }));
  //   console.log("check values: ", value);
  // };
  // Initialize data and columns dynamically
  // useEffect(() => {
  //   if (selectedSection?.columns) {

  //     const tempCheckCols = [
  //       ...selectedSection.columns.map((col) => ({
  //         id: col.path,
  //         label: col.columnHeader || col.path?.split(".").pop() || col.path,
  //         isChecked: col.visible,
  //       }))
  //     ]
  //     setColumnsCheckbox(tempCheckCols)

  //     const fixedColumns = [
  //       columnHelper.accessor("initials", {
  //         header: "Initials",
  //         cell: (info) => {
  //           const taskId = info.row.original._id;
  //           const isEditing = editableRowId === taskId;
  //           return isEditing ? (
  //             <InputField
  //               type="text"
  //               placeholder=''
  //               value={editedValues[taskId]?.initial || info.getValue() || ""}
  //               onChange={(e) => handleInputChange(taskId, "initials", e.target.value)}
  //             />
  //           ) : (
  //             <span
  //               className="cursor-pointer"
  //               onClick={() => handleCellClick(taskId)}
  //             >
  //               {info.getValue() || "-"}
  //             </span>
  //           );
  //         },
  //       }),
  //       columnHelper.accessor("comment", {
  //         header: "Comments",
  //         cell: (info) => {
  //           const taskId = info.row.original._id;
  //           const isEditing = editableRowId === taskId;
  //           return isEditing ? (
  //             <textarea
  //               className="border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full max-h-[76px] mt-1 bg-white"
  //               value={editedValues[taskId]?.comment || info.getValue() || ""}
  //               onChange={(e) => handleInputChange(taskId, "comment", e.target.value)}
  //             />
  //           ) : (
  //             <span
  //               className="cursor-pointer"
  //               onClick={() => handleCellClick(taskId)}
  //             >
  //               {info.getValue() || "-"}
  //             </span>
  //           );
  //         },
  //       }),
  //     ];

  //     const dynamicColumns = selectedSection.columns
  //       ?.filter((col: Column) => col.visible) // Only visible columns
  //       ?.map((col: Column) => {
  //         const accessorId = col.path || `column-${Math.random()}`;
  //         return columnHelper.accessor(col.path as keyof ITasks, {
  //           id: accessorId,
  //           header: col.columnHeader || col.path?.split(".")?.pop() || col.path,
  //           cell: (info) => {
  //             const value = getValueFromPath(info.row.original, col.path);

  //             // Replace IDs with names for classrooms and elementary schools
  //             if ((col.path?.includes("classroom") || col.path === "studentId.classroom._id") && value) {
  //               const classroom = classroomOptions?.find((opt) => opt.value === value);
  //               return classroom ? classroom.label : "-";
  //             }

  //             if (col.path === "studentId.elementarySchool" && value) {
  //               const school = handleGetSchoolName(value)
  //               return school ? school : "-";
  //             }

  //             // Format dates if applicable
  //             if ((col.path?.includes("Date") || col.path?.includes("date")) && value) {
  //               return formatDateSlashFullYear(value);
  //             }

  //             return value || "-";
  //           },
  //           meta: col.order as number
  //         });
  //       });

  //     setColumns([...fixedColumns, ...dynamicColumns]);
  //   }
  // }, [tasks, selectedSection, editableRowId, editedValues]);

  // const EditableCell = ({
  //   taskId,
  //   value,
  //   type,
  //   onEdit
  // }: {
  //   taskId: string;
  //   value: string;
  //   type: 'initials' | 'comment';
  //   onEdit: (id: string) => void;
  // }) => {
  //   const isEditing = editableRowId === taskId;
  //   const currentValue = isEditing
  //     ? (editedValues[taskId]?.[type] ?? value)
  //     : value;
  //   console.log("check current value: ", currentValue)
  //   if (!isEditing) {
  //     return (
  //       <div
  //         className="cursor-pointer"
  //         onClick={() => onEdit(taskId)}
  //       >
  //         {currentValue || "-"}
  //       </div>
  //     );
  //   }

  //   if (type === 'comment') {
  //     return (
  //       <textarea
  //         className="border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full max-h-[76px] mt-1 bg-white"
  //         value={currentValue}
  //         onChange={(e) => handleInputChange(taskId, type, e.target.value)}

  //       />
  //     );
  //   }

  //   return (
  //     <InputField
  //       type="text"
  //       placeholder=""
  //       value={currentValue}
  //       onChange={(e) => handleInputChange(taskId, type, e.target.value)}
  //     />
  //   );
  // };

  // Define base columns structure - this should rarely change
  // const baseColumns = useMemo(() => {
  //   const fixedColumns = [
  //     columnHelper.accessor("initials", {
  //       header: "Initials",
  //       cell: (info) => (
  //         <EditableCell
  //           taskId={info.row.original._id}
  //           value={info.getValue() || ""}
  //           type="initials"
  //           onEdit={handleCellClick}
  //         />
  //       ),
  //     }),
  //     columnHelper.accessor("comment", {
  //       header: "Comments",
  //       cell: (info) => (
  //         <EditableCell
  //           taskId={info.row.original._id}
  //           value={info.getValue() || ""}
  //           type="comment"
  //           onEdit={handleCellClick}
  //         />
  //       ),
  //     }),
  //   ];

  //   return fixedColumns;
  // }, [editableRowId, editedValues]); // Empty dependency array as this structure never changes

  // const handleInputChange = useCallback(
  //   (taskId: string, field: "initials" | "comment", value: string) => {
  //     setEditedValues((prev) => ({
  //       ...prev,
  //       [taskId]: {
  //         ...prev[taskId],
  //         [field]: value,
  //       },
  //     }));
  //   },
  //   []
  // );

  const handleSaveInitAndComments = () => {
    if (editedValues.tasks?.length === 0) return;

    updateTasks({ reqBody: editedValues });
  };

  useEffect(() => {
    setEditState(null);
  }, [isUpdateTasksSuccess]);

  //   () => [
  //     columnHelper.accessor("initials", {
  //       header: "Initials",
  //       cell: (info) => (
  //         <EditableCell
  //           taskId={info.row.original._id}
  //           value={
  //             editedValues[info.row.original._id]?.initials ??
  //             info.getValue() ??
  //             ""
  //           }
  //           type="initials"
  //           onEdit={handleCellClick}
  //           onChange={(value) =>
  //             handleInputChange(info.row.original._id, "initials", value)
  //           }
  //           isEditing={editableRowId === info.row.original._id}
  //         />
  //       ),
  //     }),
  //     columnHelper.accessor("comment", {
  //       header: "Comments",
  //       cell: (info) => (
  //         <EditableCell
  //           taskId={info.row.original._id}
  //           value={
  //             editedValues[info.row.original._id]?.comment ??
  //             info.getValue() ??
  //             ""
  //           }
  //           type="comment"
  //           onEdit={handleCellClick}
  //           onChange={(value) =>
  //             handleInputChange(info.row.original._id, "comment", value)
  //           }
  //           isEditing={editableRowId === info.row.original._id}
  //         />
  //       ),
  //     }),
  //   ],
  //   [editableRowId, editedValues]
  // );

  // const columns = useMemo(() => [
  //   columnHelper.accessor('initials', {
  //     header: 'Initials',
  //     cell: ({ row, getValue }) => (
  //       <EditableCell
  //         type="initials"
  //         value={getValue() || ''}
  //         isEditing={editState?.rowId === row.original._id && editState?.field === 'initials'}
  //         onChange={(value) => handleChange(row.original._id, 'initials', value)}
  //         onStartEdit={() => handleStartEdit(row.original._id, 'initials')}
  //       />
  //     )
  //   }),
  //   columnHelper.accessor('comment', {
  //     header: 'Comments',
  //     cell: ({ row, getValue }) => (
  //       <EditableCell
  //         type="comment"
  //         value={getValue() || ''}
  //         isEditing={editState?.rowId === row.original._id && editState?.field === 'comment'}
  //         onChange={(value) => handleChange(row.original._id, 'comment', value)}
  //         onStartEdit={() => handleStartEdit(row.original._id, 'comment')}
  //       />
  //     )
  //   }),
  //   // Add your dynamic columns here
  // ], [editState, handleChange, handleStartEdit]);

  // Define dynamic columns separately
  // const dynamicColumns = useMemo(() => {
  //   if (!selectedSection?.columns) return [];

  //   return selectedSection.columns
  //     ?.filter((col: Column) => col.visible)
  //     ?.map((col: Column) => {
  //       const accessorId = col.path || `column-${Math.random()}`;
  //       return columnHelper.accessor(col.path as keyof ITasks, {
  //         id: accessorId,
  //         header: col.columnHeader || col.path?.split(".")?.pop() || col.path,
  //         cell: (info) => {
  //           const value = getValueFromPath(info.row.original, col.path);

  //           if ((col.path?.includes("classroom") || col.path === "studentId.classroom._id") && value) {
  //             const classroom = classroomOptions?.find((opt) => opt.value === value);
  //             return classroom ? classroom.label : "-";
  //           }

  //           if (col.path === "studentId.elementarySchool" && value) {
  //             const school = handleGetSchoolName(value);
  //             return school ? school : "-";
  //           }

  //           if ((col.path?.includes("Date") || col.path?.includes("date")) && value) {
  //             return formatDateSlashFullYear(value);
  //           }

  //           return value || "-";
  //         },
  //         meta: col.order as number
  //       });
  //     });
  // }, [selectedSection, classroomOptions]); // Only depends on section and options
  // const dynamicColumns = useMemo(() => {
  //   if (!selectedSection?.columns) return [];
  //   return selectedSection.columns
  //     .filter((col: Column) => col.visible)
  //     .map((col: Column) => {
  //       const accessorId = col.path || `column-${Math.random()}`;
  //       return columnHelper.accessor(col.path as keyof ITasks, {
  //         id: accessorId,
  //         header: col.columnHeader || col.path?.split(".")?.pop() || col.path,
  //         cell: (info) => {
  //           const value = getValueFromPath(info.row.original, col.path);
  //           // ... rest of your cell rendering logic
  //           return value || "-";
  //         },
  //         meta: col.order as number,
  //       });
  //     });
  // }, [selectedSection, classroomOptions]);
  const getValueFromPath = (obj: any, path: string): any => {
    if (!obj || !path) {
      return undefined;
    }

    // Special handling for student-related fields
    if (!path.includes(".") && obj.studentId && obj.studentId[path]) {
      return obj.studentId[path];
    }

    // Regular path traversal for other cases
    const parts = path.split(".");
    let result = obj;

    for (const part of parts) {
      if (result === null || result === undefined) {
        return undefined;
      }
      result = result[part];
    }

    return result;
  };

  const columns = useMemo(() => {
    // Helper function to get nested values
    // const getValueFromPath = (obj: any, path: string): any => {
    //   return path?.split(".")?.reduce((acc, part) => acc && acc[part], obj);
    // };

    // Base columns for initials and comments
    const baseColumns: any = [
      columnHelper.accessor("isVisibleOnDashboard", {
        id: "isVisibleOnDashboard",
        header: () => (
          <div className="flex items-start gap-1">
            DB View <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
          </div>
        ),

        cell: (info: any) => {
          const uniqueId = `isVisibleOnDashboard-${info.row.original._id}`;
          return (
            <div className="text-start">
              <span className="w-full">
                <div className="relative inline-block w-11 h-6">
                  <input
                    type="checkbox"
                    id={uniqueId}
                    className="hidden peer"
                    checked={info.getValue()}
                    onChange={(e) => {
                      handleToggleDBView(info.row.original, e.target.checked);
                    }}
                  />
                  <label
                    htmlFor={uniqueId}
                    className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
                  ></label>
                  <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
                </div>
              </span>
            </div>
          );
        },
      }),
      columnHelper.accessor("initials", {
        header: "Initials",
        // () => (
        //   <div className="flex items-center gap-3 justify-between">
        //     <span>Initials</span>
        //   </div>
        // ),
        cell: ({ row, getValue }) => (
          <EditableCell
          isCompleted={row.original.completed}
            type="initials"
            value={getValue() || ""}
            isEditing={
              editState?.rowId === row.original._id &&
              editState?.field === "initials"
            }
            onChange={(value) =>
              handleChange(row.original._id, "initials", value)
            }
            onStartEdit={() => handleStartEdit(row.original._id, "initials")}
          />
        ),
      }),
      columnHelper.accessor("comment", {
        header: "Comments",
        // () => (
        //   <div className="flex items-center gap-3 justify-between">
        //     <span>Comments</span>
        //     <button
        //       onClick={() => console.log("Comments refresh clicked")}
        //       className="bg-green-500 text-white w-7 h-7 flex items-center justify-center rounded-md"
        //     >
        //       <TfiReload />
        //     </button>
        //   </div>
        // ),
        cell: ({ row, getValue }) => (
          <div className="w-[237px]">
            <EditableCell
              type="comment"
              value={getValue() || ""}
              isEditing={
                editState?.rowId === row.original._id &&
                editState?.field === "comment"
              }
              onChange={(value) =>
                handleChange(row.original._id, "comment", value)
              }
              onStartEdit={() => handleStartEdit(row.original._id, "comment")}
            />
          </div>
        ),
      }),
    ];

    // Dynamic columns based on section configuration
    const dynamicColumns =
      selectedSection?.columns
        ?.filter((col: Column) => col.visible)
        ?.map((col: Column) => {
          const accessorId = col.path || `column-${Math.random()}`;
          return columnHelper.accessor(col.path as keyof ITasks, {
            id: accessorId,
            header: () => (
              <div className="flex items-center gap-3 justify-between">
                <span>
                  {col.columnHeader || col.path?.split(".")?.pop() || col.path}
                </span>
              </div>
            ),
            cell: (info) => {
              const value = getValueFromPath(info.row.original, col.path);

              // Handle special cases for different column types
              // if (
              //   (col.path?.includes("classroom") ||
              //     col.path === "studentId.classroom._id") &&
              //   value
              // ) {
              //   const classroom = classroomOptions?.find(
              //     (opt) => opt.value === value
              //   );
              //   return classroom?.label || "-";
              // }

              if (col.path === "slot" || col.path === "studentId.slot") {
                if (value[0] !== "Full time") {
                  const slot = classroomSlots?.find(
                    (opt) => opt.value === value[0]
                  );

                  return slot?.label || "-";
                }
              }
              if (
                col.path === "studentId.transitions.firstTransition.slot" ||
                col.path === "studentId.transitions.secondTransition.slot" ||
                col.path === "studentId.transitions.thirdTransition.slot"
              ) {
                if (value !== "Full time") {
                  const slot = classroomSlots?.find(
                    (opt) => opt.value === value
                  );

                  return slot?.label || "-";
                }
              }
              if (col.path?.includes("classroom") || col.path === "classroom") {
                const classroomId = value?._id || value; // Handle both object and direct ID

                const classroom = classroomOptions?.find(
                  (opt) => opt.value === classroomId
                );

                return classroom?.label || "-";
              }

              // if (col.path === "studentId.elementarySchool" && value) {
              //   return handleGetSchoolName(value) || "-";
              // }
              if (
                col.path === "studentId.elementarySchool" ||
                col.path === "elementarySchool"
              ) {
                const schoolValue = value || ""; // Ensure we have a string
                return handleGetSchoolName(schoolValue) || "-";
              }

              if (
                (col.path?.includes("Date") || col.path?.includes("date")) &&
                value
              ) {
                return formatDateSlashFullYear(value);
              }

              return value || "-";
            },
            meta: col.order as number,
          });
        }) || [];

    return [...baseColumns, ...dynamicColumns];
  }, [
    editState,
    handleChange,
    handleStartEdit,
    selectedSection,
    classroomOptions,
    handleOpenModal,
    handleShiftClick,
    handleToggleColumn,
    handleGetSchoolName,
  ]);

  useEffect(() => {
    if (selectedSection?.columns) {
      const tempCheckCols = selectedSection.columns.map((col) => ({
        id: col.path,
        label: col.columnHeader || col.path?.split(".").pop() || col.path,
        isChecked: col.visible,
      }));
      setColumnsCheckbox(tempCheckCols);
    }
  }, [selectedSection]);

  useEffect(() => {
    if (tasks) {
      setData(tasks);
    }
  }, [tasks]);

  const handleUpdateColumnVisibility = () => {
    const columnUpdates = columnsCheckbox?.map((col) => ({
      columnPath: col.id,
      visible: col.isChecked,
    })) as [{ columnPath: string; visible: boolean }];

    if (columnUpdates.length > 0) {
      toggleColumnVisibility({
        categoryId: selectedCard?._id,
        sectionId: selectedSection?._id,
        reqBody: { columnUpdates },
      });
    }
  };

  useEffect(() => {
    if (!isToggleSuccess) return;
    handleCloseModal();
  }, [isToggleSuccess]);

  // });
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  if (!tasks?.length) return null;
  return (
    <div>
      <div className="scrollHide overflow-y-auto h-[calc(100vh-350px)]">
        <table className="w-full bg-white rounded-t-lg">
          <thead className="sticky top-0 bg-white z-50">
            {table.getHeaderGroups().map((headerGroup) => (
              <tr key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <th
                    key={header.id}
                    className={`p-5 text-left  font-medium text-secondary capitalize ${
                      header.column.columnDef.header === "Initials"
                        ? "min-w-[136px]"
                        : "min-w-[220px]"
                    }  text-sm`}
                  >
                    <div className="flex items-center gap-3 justify-between">
                      {flexRender(
                        header.column.columnDef.header,
                        header.getContext()
                      )}
                      {header.column.columnDef.header === "Initials" ||
                      header.column.columnDef.header === "DB View" ? (
                        <></>
                      ) : header.column.columnDef.header === "Comments" ? (
                        <button
                          onClick={handleSaveInitAndComments}
                          className={`bg-green-500 text-white w-7 h-7 flex items-center justify-center rounded-md ${
                            editedValues.tasks?.length === 0 ? "opacity-50" : ""
                          }`}
                          disabled={editedValues.tasks?.length === 0}
                        >
                          <TfiReload />
                        </button>
                      ) : (
                        <Dropdown className="relative inline-flex">
                          <Dropdown.Toggle
                            variant="success"
                            className="!p-0 !border-0 bg-transparent"
                          >
                            <BsThreeDotsVertical className="w-4 h-4 text-secondary" />
                          </Dropdown.Toggle>
                          <Dropdown.Menu className="bg-white transform-none p-2 rounded-lg shadow-lg min-w-[203px]">
                            <Dropdown.Item
                              className="hover:text-primary py-1 px-1 flex items-center gap-2"
                              onClick={handleOpenModal}
                            >
                              <GoPlus className="text-md" />
                              Add Column
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={`${
                                header?.column?.getIndex() === 2
                                  ? "opacity-50 cursor-text"
                                  : "hover:text-primary"
                              } py-1 px-1 flex items-center gap-2`}
                              disabled={header?.column?.getIndex() === 2}
                              onClick={() => {
                                handleShiftClick(
                                  columns[header?.column?.getIndex()]
                                    .accessorKey,
                                  (header.column.columnDef.meta as number) - 1
                                );
                              }}
                            >
                              <IoIosArrowRoundBack className="text-md" />
                              Shift Column Left
                            </Dropdown.Item>
                            <Dropdown.Item
                              className={`${
                                header?.column?.getIndex() ===
                                columns?.length - 1
                                  ? "opacity-50 cursor-text"
                                  : "hover:text-primary"
                              } py-1 px-1 flex items-center gap-2`}
                              disabled={
                                header?.column?.getIndex() ===
                                columns?.length - 1
                              }
                              onClick={() => {
                                handleShiftClick(
                                  columns[header?.column?.getIndex()]
                                    .accessorKey,
                                  (header.column.columnDef.meta as number) + 1
                                );
                              }}
                            >
                              <IoIosArrowRoundForward className="text-md" />
                              Shift Column Right
                            </Dropdown.Item>
                            <Dropdown.Item
                              className="hover:text-primary py-1 px-1 flex items-center gap-2"
                              onClick={() => {
                                const columnId = columns[
                                  header?.column?.getIndex()
                                ].accessorKey as string;
                                handleRemoveColumn(columnId);
                              }}
                            >
                              <HiOutlineTrash className="text-md" />
                              Remove Column
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="bg-white divide-y divide-secondaryNeutral">
            {table.getRowModel().rows.map((row) => (
              <tr key={row.id} className="odd:bg-natural even:bg-white">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className={`p-5 text-base text-secondaryVariant border-b border-secondaryNeutral ${
                      cell.column.id === "comments" ? "w-[237px]" : ""
                    }`}
                  >
                    {cell.column.id === "comments" ? (
                      <span className="p-4 bg-white rounded-lg block border border-secondaryVariant2">
                        {flexRender(
                          cell.column.columnDef.cell,
                          cell.getContext()
                        )}
                      </span>
                    ) : (
                      flexRender(cell.column.columnDef.cell, cell.getContext())
                    )}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {isModalOpen && (
        <SelectColumnModal
          isOpen={isModalOpen}
          onClose={handleCloseModal}
          columnsList={columnsCheckbox}
          onToggleColumn={handleToggleColumn}
          handleUpdate={handleUpdateColumnVisibility}
        />
      )}
    </div>
  );
};

export default memo(TaskGenerationListing);
