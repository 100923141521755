import { useMutation, useQueryClient } from "@tanstack/react-query";
import { QueryKeyString } from "../enums/queryKey.enum";
import { EnrollmentTrackingSvc } from "../services/enrollment.service";
import { showErrorToast, showSuccessToast } from "../../../shared/utils/toast";

export const useEditStudentPreview = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      previewId,
      studentId,
      reqBody,
    }: {
      previewId: string;
      studentId: string;
      reqBody: any;
    }) =>
      EnrollmentTrackingSvc.editStudentPreview(previewId, studentId, reqBody),
    onError: (err) => {
      console.error("error:", err);
      showErrorToast("Failed to edit student preview");
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QueryKeyString.Student_Previews],
      });
      showSuccessToast("Student preview edited successfully");
    },
  });
};
