import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { ITaskGenerationFilters } from "../types/ITaskGeneration.interface";

export const useUpdateSectionFilters = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      categoryId,
      sectionId,
      filters,
    }: {
      categoryId: string;
      sectionId: string;
      filters: ITaskGenerationFilters;
    }) =>
      taskGenerationSvc.updateSectionFilters(categoryId, sectionId, filters),
    onError: (err) => {
      showErrorToast("Error updating filters");
      console.error("error:", err);
    },
    onSuccess: () => {
      showSuccessToast("Filters updated successfully");
      queryClient.invalidateQueries({
        queryKey: [TaskGenerationQueryKeyString.Tasks],
      });
      queryClient.invalidateQueries({
        queryKey: [TaskGenerationQueryKeyString.Category],
      });
      queryClient.refetchQueries({
        queryKey: [TaskGenerationQueryKeyString.DashboardTasks],
      });
    },
  });
};
