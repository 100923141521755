import React from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { LuTable2 } from "react-icons/lu";
import { PiPrinterLight } from "react-icons/pi";
import { RxCheckCircled } from "react-icons/rx";
import { VscEdit } from "react-icons/vsc";

const TodayTabular = () => {
  const categories = [
    {
      title: "Infant",
      ageGroup: "0-1 year",
      ratio: "1:3",
      fullTime: 10,
      partTime: 5,
      pending: 2,
      assignees: [{ name: "John Doe" }, { name: "Jane Smith" }],
    },
    {
      title: "Toddler",
      ageGroup: "1-3 years",
      ratio: "1:5",
      fullTime: 8,
      partTime: 6,
      pending: 1,
      assignees: [{ name: "Mike Johnson" }, { name: "Emily Davis" }],
    },
    {
      title: "Preschool",
      ageGroup: "3-5 years",
      ratio: "1:8",
      fullTime: 12,
      partTime: 4,
      pending: 3,
      assignees: [{ name: "Mike Johnson" }, { name: "Emily Davis" }],
    },
    {
      title: "Breaker",
      fullTime: 2,
      bgColor: "#3396F8",
      assignees: [{ name: "David Clark" }],
    },
    {
      title: "Admin",
      fullTime: 2,
      bgColor: "#18C55F",
      assignees: [{ name: "Olivia Brown" }],
    },
    {
      title: "Other",
      fullTime: 2,
      bgColor: "#FFA323",
      assignees: [{ name: "Sophia Martinez" }],
    },
  ];

  return (
    <div className="h-full rounded-lg overflow-hidden flex flex-col ov">
      <div className="text-lg flex items-center justify-between py-3 min-h-[60px] px-3 group cursor-pointer rounded-ss-xl rounded-se-xl bg-primary font-semibold text-white gap-2 uppercase">
        <div className="flex-1 flex items-center gap-4">
          <RxCheckCircled className="w-6 h-6" />
          Today’s Schedule
        </div>
        <button className="text-white text-base font-medium flex items-center gap-1">
          <VscEdit className="w-5 h-5" />
          Modify
        </button>
      </div>
      <div className="bg-white  p-2 border border-secondaryVariant2 flex-1">
        <div className="flex items-center mb-3 gap-3">
          <button className="inline-flex items-center py-2 px-2 gap-2 rounded-lg bg-secondaryNeutral font-medium text-secondaryVariant">
            <PiPrinterLight size={20} /> Print
          </button>
          <button className="inline-flex items-center py-2 px-2 gap-2 rounded-lg bg-secondaryNeutral font-medium text-secondaryVariant">
            <LuTable2 size={20} /> Tabular View
          </button>
        </div>

        <div className="py-2 px-2 bg-[#FFE6D8] text-sm text-secondary font-semibold leading-none mb-1">
          <h2 className="text-base font-semibold text-secondary">Today Activities</h2>
        </div>

        <div>
          <div className="bg-secondaryNeutral grid grid-cols-2 gap-2">
            <div className="py-2 px-4">Classroom</div>
            <div className="py-2 px-4">Assign</div>
          </div>
          {categories.map((category) => (
            <div key={category.title} className="mb-2 grid grid-cols-2 gap-2 items-center">
              {category.title !== "Breaker" &&
              category.title !== "Admin" &&
              category.title !== "Other" ? (
                <div className="bg-secondary rounded-lg overflow-hidden flex flex-col h-full w-full">
                  <div className="flex items-start py-2 px-2 flex-1">
                    <div className="flex-1 text-xs font-semibold text-white leading-none flex flex-col h-full justify-center">
                      {category.title} <br />
                      {category.ageGroup}
                    </div>
                    <span className="bg-primary text-secondary w-5 h-5 rounded-full inline-flex items-center justify-center text-[10px]">
                      S
                    </span>
                  </div>
                  <div className="flex bg-[#343C66]">
                    <div className="flex-[0_0_36px] p-[6px] gap-1 border-r border-secondary">
                      <div className="flex flex-col text-[10px] text-white leading-none">
                        <span>Ratio</span>
                        <span className="font-semibold text-primary">{category.ratio}</span>
                      </div>
                    </div>
                    <div className="flex-1 flex gap-1 items-end">
                      <div className="flex flex-col items-center justify-center p-1 text-[10px] text-white flex-1 leading-none">
                        <span>FT</span>
                        <span className="font-semibold">{category.fullTime}</span>
                      </div>
                      <div className="flex flex-col items-center justify-center p-1 text-[10px] text-white flex-1 leading-none">
                        <span>PT</span>
                        <span className="font-semibold">{category.partTime}</span>
                      </div>
                      <div className="flex flex-col items-center justify-center p-1 text-[10px] text-primary flex-1 leading-none">
                        <span>P</span>
                        <span className="font-semibold">{category.pending}</span>
                      </div>
                      <span className="text-primary pb-1">
                        <IoMdArrowDropdown />
                      </span>
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className="rounded-lg overflow-hidden flex flex-col h-full w-full"
                  style={{ backgroundColor: category.bgColor }}
                >
                  <div className="flex items-center py-2 px-2 flex-1">
                    <div className="flex-1 text-base font-semibold text-white leading-none flex flex-col h-full justify-center">
                      {category.title}
                    </div>
                    <span className="bg-primary text-white px-2 min-w-5 h-5 rounded-full inline-flex items-center justify-center text-[10px]">
                      {category.fullTime}
                    </span>
                  </div>
                </div>
              )}

              {/* Assignments Section */}
              <div>
                {category.assignees?.map((assignee, index) => (
                  <div key={index} className="flex items-center gap-2 mb-1">
                    <div className="w-8 h-8 rounded-full bg-secondaryNeutral border border-dashed border-secondaryVariant relative"></div>
                    <div className="flex-1 font-bold text-secondaryVariant line-clamp-1 text-xs">{assignee.name}</div>
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TodayTabular;
