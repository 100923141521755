import { useEffect, useState } from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import ForayDashboardCard from "../../components/forayDashboardComponents/ForayDashboardCard";
import ForayTracking from "../../components/forayDashboardComponents/ForayTracking";
import { useDashboardTasks } from "../../hooks/useTasks";
import { useCategories } from "../../hooks/useCateogories";
import ForayModuleTabs from "./components/ForayModuleTabs";
import { ITasks } from "../../types/ITaskGeneration.interface";

const ForayDashboard = () => {
  // Fetch tasks data at the parent level
  const { data: tasksData } = useDashboardTasks();
  const { data: categories } = useCategories();
  const [filteredTasks, setFilteredTasks] = useState<ITasks[]>([]);

  // Filter tasks based on reference date and days before/after
  useEffect(() => {
    if (tasksData?.data && categories?.data) {
      const today = new Date();
      const todayStr = today.toISOString().split("T")[0];

      const filtered = tasksData.data.filter((task) => {
        // Check if the task has a studentId
        if (!task.studentId) {
          return false;
        }

        // Skip tasks without categoryId
        if (!task.categoryId) {
          return false;
        }

        if(task.isVisibleOnDashboard === false) {
        return false;
        }

        // Get the category for this task
        const category = categories?.data?.find(
          (cat) => cat._id === task.categoryId?._id
        );

        // If we can't find the category, skip this task
        if (!category) {
          return false;
        }

        // Find all the sections in this category
        if (!category.sections || category.sections.length === 0) {
          return false;
        }

        // Loop through all sections to find applicable filters
        for (const section of category.sections) {
          if (!section.appliedFilters || section.appliedFilters.length === 0) {
            continue;
          }

          // Check each applied filter
          for (const filter of section.appliedFilters) {
            // Determine which date to use based on the referenceDate value
            let referenceDate: Date | null = null;

            // Map the filter reference date values to the corresponding fields in the student record
            if (
              filter.referenceDate === "START_AT_CENTER" &&
              task.studentId.Dates?.startAtCenter
            ) {
              referenceDate = new Date(task.studentId.Dates.startAtCenter);
            } else if (
              filter.referenceDate === "WAIT_LISTED_DATE" &&
              task.studentId.Dates?.dateWaitlisted
            ) {
              referenceDate = new Date(task.studentId.Dates.dateWaitlisted);
            } else if (
              filter.referenceDate === "LEAVE_DATE" &&
              task.studentId.Dates?.leaveDate
            ) {
              referenceDate = new Date(task.studentId.Dates.leaveDate);
            } else if (
              filter.referenceDate === "DOB" &&
              task.studentId.dateOfBirth
            ) {
              referenceDate = new Date(task.studentId.dateOfBirth);
            } else if (
              filter.referenceDate === "T1" &&
              task.studentId.transitions?.firstTransition?.date
            ) {
              referenceDate = new Date(
                task.studentId.transitions.firstTransition.date
              );
            } else if (
              filter.referenceDate === "T2" &&
              task.studentId.transitions?.secondTransition?.date
            ) {
              referenceDate = new Date(
                task.studentId.transitions.secondTransition.date
              );
            } else if (
              filter.referenceDate === "T3" &&
              task.studentId.transitions?.thirdTransition?.date
            ) {
              referenceDate = new Date(
                task.studentId.transitions.thirdTransition.date
              );
            }
            // Add handling for any other reference date types as needed

            // If we couldn't determine a reference date, skip this filter
            if (!referenceDate) {
              continue;
            }

            // Calculate the date range based on the filter
            const targetDates: { date: Date; type: string }[] = [];

            if (filter.daysBeforeReferenceDate) {
              const beforeDate = new Date(referenceDate);
              beforeDate.setDate(
                referenceDate.getDate() - filter.daysBeforeReferenceDate
              );
              targetDates.push({ date: beforeDate, type: "before" });
            }

            if (filter.daysAfterReferenceDate) {
              const afterDate = new Date(referenceDate);
              afterDate.setDate(
                referenceDate.getDate() + filter.daysAfterReferenceDate
              );
              targetDates.push({ date: afterDate, type: "after" });
            }

            // Check if today matches any of the target dates (comparing only year, month, day)
            for (const targetDate of targetDates) {
              const targetStr = targetDate.date.toISOString().split("T")[0];
              if (todayStr === targetStr) {
                return true;
              }
            }
          }
        }

        // If none of the filters matched, exclude this task
        return false;
      });

      setFilteredTasks(filtered);
    }
  }, [tasksData, categories]);

  // Create a modified tasksData object with filtered tasks
  const filteredTasksData = tasksData
    ? {
        ...tasksData,
        data: filteredTasks,
      }
    : undefined;

  return (
    <>
      <DashboardWrapper>
        <div className="px-5 pt-5">
          <div className="mb-4">
            <ForayModuleTabs />
          </div>
          <div className="text-secondary font-semibold text-2xl my-4">
            Foray Dashboard
          </div>

          <ForayDashboardCard tasksData={filteredTasksData} />
          <ForayTracking
            tasksData={filteredTasksData}
            categories={categories}
          />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ForayDashboard;
