import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StripeQueryKeyString } from "../enums/stripeQueryKeyString";
import { stripeSvc } from "../services/stripe.service";

export const useCancelSubscription = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (subscriptionId: string) =>
      stripeSvc.cancelSubscription(subscriptionId),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [StripeQueryKeyString.MyCurrentSubscription],
      });
      await queryClient.invalidateQueries({
        queryKey: [StripeQueryKeyString.MySubscription],
      });
    },
    onError: (error) => {
      console.error("Failed to cancel subscription:", error);
    },
  });
};
