import React, { useState } from "react";
import Input, { isPossiblePhoneNumber, parsePhoneNumber } from "react-phone-number-input/input";
import { queryClient } from "../../../../config/useQuery.config";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import CustomCheckbox from "../../components/customCheckbox/CustomCheckbox";
import { studentSvc } from "../../services/student.service";
import { Parent } from "../../types/IStudentSetup.interface";
import { showErrorToast, showSuccessToast } from "../../../shared/utils/toast";
import { ERR_MSGS } from "../../../shared/constants/errorMsgs";
import { regexPatterns } from "../../../shared/constants/regexPatterns";

interface IProps {
  parentInfo: Parent | undefined;
  onCancel: () => void;
  index?: number;
}

const ParentEdit: React.FC<IProps> = ({ parentInfo, onCancel, index }) => {
  const [parentInformation, setParentInformation] = useState(parentInfo);
  const [errors, setErrors] = useState<Partial<Record<keyof Parent, string>>>(
    {}
  );
  const validateForm = () => {
    const newErrors: Partial<Record<keyof Parent, string>> = {};
    const requiredFields: (keyof Parent)[] = [
      "firstName",
      "lastName",
      "phone",
      "email",
      "street",
      "city",
      "state",
      "postalCode",
      // "emergencyContact",
      // "centerId",
      // "centerSetupId",
    ];

    requiredFields.forEach((field) => {
      if (!parentInformation || !parentInformation[field]) {
        newErrors[field] = `${field.charAt(0).toUpperCase() +
          field
            .slice(1)
            .replace(/([A-Z])/g, " $1")
            .trim()
          } is required`;
      }
    });

    // Email validation
    if (
      parentInformation?.email &&
      !/\S+@\S+\.\S+/.test(parentInformation.email)
    ) {
      newErrors.email = "Invalid email format";
    }

    // Phone number validation (simple check for numeric value)
    // if (parentInformation?.phone && !/^\d+$/.test(parentInformation.phone)) {
    //   newErrors.phone = "Phone number should contain only digits";
    // }

    if (!parentInformation?.phone) {
      newErrors.phone = "Phone is required";
    } else {
      const phoneNumber = isPossiblePhoneNumber(parentInformation.phone, "US");
      if (!phoneNumber) {
        newErrors.phone = "Invalid phone number format";
      }
    }

    // Emergency phone validation
    // if (
    //   parentInformation?.emergencyPhone &&
    //   !/^\d+$/.test(parentInformation.emergencyPhone)
    // ) {
    //   newErrors.emergencyPhone =
    //     "Emergency phone number should contain only digits";
    // }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const onSave = async () => {
    if (validateForm()) {
      if (parentInformation?._id) {
        try {
          const resp = await studentSvc.updateParentInfo(
            parentInformation._id,
            parentInformation
          );

          if (resp?.ok) {
            queryClient.invalidateQueries({ queryKey: ["student"] });
            showSuccessToast("Parent/Guardian info updated successfully.");
            onCancel();
          }
        } catch (error) {
          console.error("Error updating Parent-Guardian Info:", error);
        }
      } else {
        showErrorToast("Parent not found");
        console.log("Parent not found");
      }
    } else {
      showErrorToast("Form validation failed");
      console.log("Form validation failed");
    }
  };

  const handleBlur = (fieldName: string, value: string, label: string) => {
    if (!value) {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: `${label} is required`,
      }));
    }
    else if ((fieldName === "firstName" || fieldName === "lastName") && value?.length < 3) {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: `${label} must be at least 3 characters`,
      }));
    }
    else if ((fieldName === "email" && !regexPatterns.email.test(value)) || (fieldName === "postalCode" && !regexPatterns.postalCode.test(value))) {
      setErrors((prev) => ({
        ...prev,
        [fieldName]: `Invalid ${label} format`,
      }))
    } 
  }

  const handleFocus = (fieldName: string) => {
    setErrors((prev) => ({ ...prev, [fieldName]: "" }));
  }
  return (
    <>
      <div className="border border-black rounded-xl p-6 mb-6 lg:mb-11">
        <div className="bg-white p-6 mb-10 rounded-xl">
          <h2 className="text-xl text-secondary font-medium mb-6">
            Parent/Guardian Info
          </h2>
          <div>
            <h3 className="text-lg text-secondary font-medium mb-6">
              Parent {(index as number) + 1 || 1}
            </h3>
            <div className="grid grid-cols-6 gap-3 mb-6">
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  First Name
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={parentInformation?.firstName}
                  onChange={(e) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      firstName: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    handleFocus("firstName")
                  }}
                  onBlur={() => {
                    handleBlur("firstName", parentInformation?.firstName as string, "First Name")
                  }}
                />
                {errors.firstName && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.firstName}
                  </p>
                )}
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Last Name
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={parentInformation?.lastName}
                  onChange={(e) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      lastName: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    handleFocus("lastName")
                  }}
                  onBlur={() => { handleBlur("lastName", parentInformation?.lastName as string, "Last Name") }}

                />
                {errors.lastName && (
                  <p className="text-red-500 text-sm mt-1">{errors.lastName}</p>
                )}
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Email
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={parentInformation?.email}
                  onChange={(e) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    handleFocus("email")
                  }}
                  onBlur={() => { handleBlur("email", parentInformation?.email as string, "Email") }}

                />
                {errors.email && (
                  <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                )}
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Phone Number
                  <RequiredAsterisk />
                </label>
                <Input
                  // type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  country="US"
                  placeholder="(123) 456-7890"
                  value={parentInformation?.phone}
                  onChange={(value) => {
                    setParentInformation((prev: any) => ({
                      ...prev,
                      phone: value,
                    }));
                  }}
                />
                {/* {errors.phone && (
                  <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
                )} */}
                {parentInformation?.phone &&
                  !isPossiblePhoneNumber(
                    parentInformation?.phone
                  ) && (
                    <span className="text-red-600 text-sm mt-1">
                      {ERR_MSGS.Invalid_Phone_Number}
                    </span>
                  )}
              </div>
              <div></div>
              <div></div>

              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Street
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={parentInformation?.street}
                  onChange={(e) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      street: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    handleFocus("street")
                  }}
                  onBlur={() => { handleBlur("street", parentInformation?.street as string, "Street") }}

                />
                {errors.street && (
                  <p className="text-red-500 text-sm mt-1">{errors.street}</p>
                )}
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  City
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={parentInformation?.city}
                  onChange={(e) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      city: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    handleFocus("city")
                  }}
                  onBlur={() => { handleBlur("city", parentInformation?.city as string, "City") }}

                />
                {errors.city && (
                  <p className="text-red-500 text-sm mt-1">{errors.city}</p>
                )}
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  State
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={parentInformation?.state}
                  onChange={(e) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      state: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    handleFocus("state")
                  }}
                  onBlur={() => { handleBlur("state", parentInformation?.state as string, "State") }}

                />
                {errors.state && (
                  <p className="text-red-500 text-sm mt-1">{errors.state}</p>
                )}
              </div>
              <div>
                <label className="text-secondaryVariant text-base mb-1 block">
                  Zip Code
                  <RequiredAsterisk />
                </label>
                <input
                  type="text"
                  className="py-3.5 px-2 rounded-lg border-[0.5px] border-secondaryVariant2 bg-white w-full"
                  value={parentInformation?.postalCode}
                  onChange={(e) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      postalCode: e.target.value,
                    }))
                  }
                  onFocus={() => {
                    handleFocus("postalCode")
                  }}
                  onBlur={() => { handleBlur("postalCode", parentInformation?.postalCode as string, "Zip Code") }}

                />
                {errors.postalCode && (
                  <p className="text-red-500 text-sm mt-1">
                    {errors.postalCode}
                  </p>
                )}
              </div>
            </div>
            <div className="flex items-center gap-16">
              <div className="flex items-center gap-4">
                <CustomCheckbox
                  checked={parentInformation?.emergencyContact}
                  onChange={(checked) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      emergencyContact: checked,
                    }))
                  }
                />
                <span className="text-base text-secondary">
                  Emergency Contact
                </span>
              </div>
              <div className="flex items-center gap-4">
                <CustomCheckbox
                  checked={parentInformation?.livesWith}
                  onChange={(checked) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      livesWith: checked,
                    }))
                  }
                />
                <span className="text-base text-secondary">Lives with</span>
              </div>
              <div className="flex items-center gap-4">
                <CustomCheckbox
                  checked={parentInformation?.canPickUp}
                  onChange={(checked) =>
                    setParentInformation((prev: any) => ({
                      ...prev,
                      canPickUp: checked,
                    }))
                  }
                />
                <span className="text-base text-secondary">Can Pick up</span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-3">
          <button onClick={onSave} className={`btnPrimary max-w-[181px] ${Object.values(errors).some((msg) => !!msg) ? "opacity-50" : ""}`} disabled={Object.values(errors).some((msg) => !!msg)}>
            Save
          </button>
          <button
            onClick={() => onCancel()}
            className="btnSimple max-w-[181px]"
          >
            Cancel
          </button>
        </div>
      </div>
    </>
  );
};

export default ParentEdit;
