import { FC } from "react";

interface Props {
  daysData: {
    day: string;
    date: string;
    month: string;
    year: number;
    fullDate: Date;
    isoDateString?: string;
  }[];
  operatingDays: string[];
}

const EnrollmentCalendar: FC<Props> = ({ daysData, operatingDays }) => {
  return (
    <div className="flex overflow-x-auto">
      <div className="flex">
        {daysData.map((dayObj, index) => {
          // Extract the date directly from the pre-formatted string
          // dayObj.date should be something like "24"
          const dateValue = parseInt(dayObj.date, 10);

          // For the day name, use the day string from the data
          // If it's abbreviated (like "Mon"), get the first character
          // If it's already the full day (like "Monday"), get the first character
          const dayChar = dayObj.day.charAt(0).toUpperCase();

          // Determine the full day name for the operating days check
          let fullDayName;
          if (dayObj.day.length <= 3) {
            // If it's abbreviated, map it to the full name
            const dayMap: Record<string, string> = {
              Mon: "Monday",
              Tue: "Tuesday",
              Wed: "Wednesday",
              Thu: "Thursday",
              Fri: "Friday",
              Sat: "Saturday",
              Sun: "Sunday",
            };
            fullDayName = dayMap[dayObj.day] || dayObj.day;
          } else {
            fullDayName = dayObj.day;
          }

          return (
            <div
              key={index}
              className={`flex items-baseline justify-between border-r border-b-[0.5px] ${
                !operatingDays?.includes(fullDayName)
                  ? "bg-secondaryVariant2"
                  : "bg-white"
              } border-secondaryNeutral py-2 px-2.5 w-[56px] flex-[0_0_56px]`}
            >
              <span className="text-[12px] text-secondaryVariant font-semibold">
                {dayChar}
              </span>
              <strong className="text-base font-semibold text-secondaryVariant">
                {dateValue}
              </strong>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default EnrollmentCalendar;
