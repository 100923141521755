import React, { memo, useEffect, useRef } from "react";

interface EditableCellProps {
  type: "initials" | "comment";
  value: string;
  isEditing: boolean;
  onChange: (value: string) => void;
  onStartEdit: () => void;
  isCompleted?: boolean;
}

const EditableCell = memo(
  ({ type, value, isEditing, onChange, onStartEdit,isCompleted }: EditableCellProps) => {
    const inputRef = useRef<HTMLInputElement | HTMLTextAreaElement>(null);

    useEffect(() => {
      if (isEditing && inputRef.current) {
        inputRef.current.focus();
        // Move cursor to end
        if (type === "initials") {
          const input = inputRef.current as HTMLInputElement;
          input.selectionStart = input.selectionEnd = value.length;
        } else {
          const textarea = inputRef.current as HTMLTextAreaElement;
          textarea.selectionStart = textarea.selectionEnd = value.length;
        }
      }
    }, [isEditing, type]);

    if (!isEditing) {
      return (
        <div className="cursor-pointer" onClick={onStartEdit}>
          {value || "-"}
        </div>
      );
    }

    if (type === "comment") {
      return (
        <>
          <textarea
            ref={inputRef as React.RefObject<HTMLTextAreaElement>}
            className="border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full max-h-[76px] mt-1 bg-white"
            value={value}
            onChange={(e) => onChange(e.target.value)}
            maxLength={50}
          />
          <div className="text-xs text-gray-500 mt-1">
            {value.length}/50 characters
          </div>
        </>
      );
    }

    return (
      <input
        ref={inputRef as React.RefObject<HTMLInputElement>}
        type="text"
        className="border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full bg-white"
        value={value}
        onChange={(e) => onChange(e.target.value)}
        maxLength={2}
        disabled={isCompleted}
      />
    );
  }
);

EditableCell.displayName = "EditableCell";

export default EditableCell;
