import { useState } from "react";
import DashboardWrapper from "../../components/layout/DashboardWrapper";
import { useCancelSubscription } from "../../hooks/useCancelSubscription";
import { useMyCurrentSubscription } from "../../hooks/useMyCurrentSubscription";
import { useProductPrices } from "../../hooks/usePrices";
import { useProducts } from "../../hooks/useProducts";
import ActivePlan1 from "./ActivePlan1";
import ActivePlan2 from "./ActivePlan2";
import BillingPlans from "./BillingPlans";
import Complimentary from "./Complimentary";
import SuccessfullySubscribedModal from "./SuccessfullySubscribedModal";

const Subscription = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [subscriptionPeriod, setSubscriptionPeriod] = useState("month");
  const [showPlans, setShowPlans] = useState(true);
  
  const closeModal = () => setModalOpen(false);
  // const tabsData = [
  //   {
  //     eventKey: "profile",
  //     label: "Profile",
  //     content: <div></div>,
  //   },
  //   {
  //     eventKey: "subscription",
  //     label: "Subscription",
  //     content: <div></div>,
  //   },
  // ];

  const { data: mySubscription, isLoading: isSubscriptionLoading } =
    useMyCurrentSubscription();
  const { data: prices, isLoading: isPricesLoading } = useProductPrices();
  const { data: products, isLoading: isProductsLoading } = useProducts();
  const { mutate: cancelSubcription, isPending } = useCancelSubscription();

  const handleSubscriptionPeriod = (period: string) => {
    setSubscriptionPeriod(period);
  };

  const handleUpgradePlan = () => {
    setShowPlans(!showPlans);
  };

  const handleCancel = (subscriptioId: string) => {
    cancelSubcription(subscriptioId);
  };

  if (isSubscriptionLoading || isPricesLoading || isProductsLoading) {
    return <div></div>;
  }

  if (!prices || !products || !mySubscription) {
    return <div>Error: Failed to load data</div>;
  }
  return (
    <>
      <DashboardWrapper>
        <div className="px-8 py-8">
          {/* <div className="mb-9">
            <CustomTabs tabs={tabsData} defaultActiveKey={"subscription"} />
          </div> */}
          <div>
            <h2 className="text-lg text-secondary font-semibold mb-9 ">
              Subscription
            </h2>
            <Complimentary products={products} />
            {mySubscription.data.length > 0 ? (
              <ActivePlan2
                mySubscription={mySubscription}
                handleUpgradePlan={handleUpgradePlan}
                handleCancel={handleCancel}
              />
            ) : (
              <ActivePlan1 handleUpgradePlan={handleUpgradePlan} />
            )}
            {showPlans && (
              <BillingPlans
                subscriptionPeriod={subscriptionPeriod}
                handleSubscriptionPeriod={handleSubscriptionPeriod}
                prices={prices}
                mySubscription={mySubscription}
                products={products}
              />
            )}
            {/* <button
              className="btnPrimary max-w-[100px] mt-6"
              onClick={openModal}
            >
              Pay Now
            </button> */}
            <SuccessfullySubscribedModal
              isOpen={isModalOpen}
              onClose={closeModal}
            />
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default Subscription;
