// import { ApiErrorResponse, create } from "apisauce";
// import { apiBaseUrlForay } from "../../shared/constants/environment.constants";
// import { STORAGE_KEYS } from "../../shared/enums/sharedEnums";
// import spinnerSvc from "../../shared/utils/spinner-service";
// import { refreshToken, signOut } from "../../shared/services/auth.service";

// let isRefreshing = false;
// let waitingQueue: any[] = [];

// if (!apiBaseUrlForay) {
//   throw new Error("No Api apiBaseUrl");
// }

// export const apiForay = create({
//   baseURL: apiBaseUrlForay,
//   headers: { Accept: "application/json"},
// })

// const processQueue = (error: ApiErrorResponse<any> | null, token = null) => {
//   waitingQueue.forEach((prom) => {
//     if (error) {
//       prom.reject(error);
//     } else {
//       prom.resolve(token);
//     }
//   });

//   isRefreshing = false;
//   waitingQueue = [];
// };

// apiForay.axiosInstance.interceptors.request.use(
//   async (config: any) => {
//     const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
//     if (accessToken !== "") {
//       config.headers.Authorization = `Bearer ${accessToken}`;
//     }
//     spinnerSvc.start();
//     return config;
//   },

//   (err) => console.error(err)
// );

// apiForay.axiosInstance.interceptors.response.use(
//   (response: any) => {
//     spinnerSvc.stop();
//     return response;
//   },

//   async (err) => {
//     spinnerSvc.stop();
//     const originalRequest = err.config;

//     if (err.response?.status === 401 && !originalRequest._retry) {
//       if (isRefreshing) {
//         return new Promise(function (resolve, reject) {
//           waitingQueue.push({ resolve, reject });
//         })
//           .then((token) => {
//             originalRequest.headers["Authorization"] = "Bearer " + token;
//             return apiForay.axiosInstance(originalRequest);
//           })
//           .catch((err) => {
//             return Promise.reject(err);
//           });
//       }
//       originalRequest._retry = true;
//       isRefreshing = true;

//       const accessToken = await refreshToken();
//       if (accessToken) {
//         apiForay.axiosInstance.defaults.headers.common["Authorization"] =
//           "Bearer " + accessToken;
//         processQueue(null, accessToken);
//         return apiForay.axiosInstance(originalRequest);
//       } else {
//         spinnerSvc.stop();
//         signOut();
//         // store.dispatch(removeUser());
//         return;
//       }
//     }
//     console.error(err.response.data);

//     switch (err.response?.status) {
//       case 400:
//         console.error(err);

//         spinnerSvc.stop();

//         return err;

//       case 401:
//         console.error(err);
//         signOut();
//         isRefreshing = false;
//         spinnerSvc.stop();

//         return err;

//       case 404:
//         console.error(err);

//         spinnerSvc.stop();

//         return err;

//       case 500:
//         console.error(err);

//         spinnerSvc.stop();

//         return err;

//       default:
//         console.error(err);

//         spinnerSvc.stop();

//         return err;
//     }
//   }
// );

import { ApiErrorResponse, create } from "apisauce";
import axios, { AxiosRequestConfig } from "axios";
import { apiBaseUrlForay } from "../../shared/constants/environment.constants";
import { STORAGE_KEYS } from "../../shared/enums/sharedEnums";
import spinnerSvc from "../../shared/utils/spinner-service";
import { refreshToken, signOut } from "../../shared/services/auth.service";

interface RetryConfig {
  maxRetries: number;
  timeout: number;
  retryDelay: number;
}

const DEFAULT_CONFIG: RetryConfig = {
  maxRetries: 2,
  timeout: 10000, // 30 seconds
  retryDelay: 1000, // 1 second
};

let isRefreshing = false;
let waitingQueue: Array<{
  resolve: (value?: any) => void;
  reject: (reason?: any) => void;
}> = [];

if (!apiBaseUrlForay) {
  throw new Error("No Api apiBaseUrl");
}

export const apiForay = create({
  baseURL: apiBaseUrlForay,
  headers: { Accept: "application/json" },
  timeout: DEFAULT_CONFIG.timeout,
});

const processQueue = (error: ApiErrorResponse<any> | null, token = null) => {
  waitingQueue.forEach((prom) => {
    if (error) {
      prom.reject(error);
    } else {
      prom.resolve(token);
    }
  });

  isRefreshing = false;
  waitingQueue = [];
};

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms));

const retryRequest = async (
  config: any,
  retryCount: number = 0
): Promise<any> => {
  try {
    const response = await apiForay.axiosInstance(config);
    return response;
  } catch (error: any) {
    if (
      retryCount < DEFAULT_CONFIG.maxRetries &&
      error.code === "ECONNABORTED"
    ) {
      await sleep(DEFAULT_CONFIG.retryDelay);
      console.log(
        `Retrying request (${retryCount + 1}/${DEFAULT_CONFIG.maxRetries})`
      );
      return retryRequest(config, retryCount + 1);
    }
    throw error;
  }
};

apiForay.axiosInstance.interceptors.request.use(
  async (config: any) => {
    const accessToken = localStorage.getItem(STORAGE_KEYS?.ACC_TOKEN);
    if (accessToken) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${accessToken}`,
      };
    }

    // Add timeout to individual requests if not specified
    if (!config.timeout) {
      config.timeout = DEFAULT_CONFIG.timeout;
    }

    spinnerSvc.start();
    return config;
  },
  (err) => {
    spinnerSvc.stop();
    return Promise.reject(err);
  }
);

apiForay.axiosInstance.interceptors.response.use(
  (response: any) => {
    spinnerSvc.stop();
    return response;
  },
  async (err) => {
    spinnerSvc.stop();
    const originalRequest = err.config;

    // Handle timeout errors
    if (err.code === "ECONNABORTED") {
      console.log("Request timed out, attempting retry...");
      try {
        return await retryRequest(originalRequest);
      } catch (retryError) {
        console.error("All retry attempts failed");
        return Promise.reject(retryError);
      }
    }

    // Handle 401 and token refresh
    if (err.response?.status === 401 && !originalRequest._retry) {
      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          waitingQueue.push({ resolve, reject });
        })
          .then((token) => {
            originalRequest.headers["Authorization"] = "Bearer " + token;
            return apiForay.axiosInstance(originalRequest);
          })
          .catch((err) => {
            return Promise.reject(err);
          });
      }

      originalRequest._retry = true;
      isRefreshing = true;

      try {
        const accessToken = await refreshToken();
        if (accessToken) {
          apiForay.axiosInstance.defaults.headers.common["Authorization"] =
            "Bearer " + accessToken;
          processQueue(null, accessToken);
          return apiForay.axiosInstance(originalRequest);
        } else {
          // processQueue(new Error('Failed to refresh token'), null);
          processQueue(null, null);
          signOut();
          return Promise.reject(err);
        }
      } catch (refreshError) {
        processQueue(refreshError as ApiErrorResponse<any>, null);
        signOut();
        return Promise.reject(refreshError);
      }
    }

    // Handle other error status codes
    switch (err.response?.status) {
      case 400:
      case 404:
      case 500:
        console.error(err.response?.data || err);
        return Promise.reject(err);
      case 401:
        console.error(err);
        signOut();
        isRefreshing = false;
        return Promise.reject(err);
      default:
        console.error(err);
        return Promise.reject(err);
    }
  }
);
