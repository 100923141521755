import React from "react";
import { GoPlus } from "react-icons/go";
import PtoVacationTable from "../../components/tables/PtoVacationTable";
import PtoSickTable from "../../components/tables/PtoSickTable";
import CreatePtoVocationModal from "../../components/Modals/CreatePtoVocationModal";

const PlannedPto = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);
  return (
    <>
      <div className="flex items-center gap-6 mb-9">
        <button onClick={openModal} className="btnPrimary max-w-[241px]">
          <GoPlus className="w-6 h-6" /> Create PTO-Vacation
        </button>
        <button className="btnSecondary max-w-[202px]">
          {" "}
          <GoPlus className="w-6 h-6" />
          Create PTO-Sick
        </button>
      </div>
      <CreatePtoVocationModal isOpen={isModalOpen} onClose={closeModal} />

      <h2 className="text-xl text-secondary font-semibold mb-3">
        PTO-Vocation
      </h2>
      <PtoVacationTable />
      <h2 className="text-xl text-secondary font-semibold mb-3">PTO-Sick</h2>
      <PtoSickTable />
    </>
  );
};

export default PlannedPto;
