import React, { useMemo } from "react";
import ApexCharts from "react-apexcharts";
import { ApexOptions } from "apexcharts";

// Define the type for the chart data prop
interface RegistrationChartProps {
  dayData: any[]; // Daily data
  title: string;
  barcolor: string;
  linecolor: string;
}

const RegistrationChartMonthly: React.FC<RegistrationChartProps> = ({
  dayData,
  title,
  barcolor,
  linecolor,
}) => {
  // Helper function to calculate weekly and daily data
  const { barData, lineData, labels } = useMemo(() => {
    const barData: number[] = [];
    const lineData: number[] = [];
    const labels: string[] = [];

    let weekTotal = 0;
    let weekNumber = 1;

    dayData?.forEach((item, index) => {
      const dailyValue =
        title === "Center Registration"
          ? item.dailyRegistrations.centers
          : title === "Teacher Registration"
          ? item.dailyRegistrations.teachers
          : item.dailyRegistrations.parents;

      const totalValue =
        title === "Center Registration"
          ? item.totalRegistrationsTillDay.centers
          : title === "Teacher Registration"
          ? item.totalRegistrationsTillDay.teachers
          : item.totalRegistrationsTillDay.parents;

      weekTotal += dailyValue;
      lineData.push(totalValue);

      if ((index + 1) % 7 === 0 || index === dayData?.length - 1) {
        barData.push(weekTotal);
        labels.push(`Week ${weekNumber}`);
        weekTotal = 0;
        weekNumber++;
      } else {
        barData.push(0);
        labels.push("");
      }
    });

    return { barData, lineData, labels };
  }, [dayData, title]);

  const options: ApexOptions = useMemo(() => {
    return {
      series: [
        {
          name: "Total Registrations in this Week",
          type: "column",
          data: barData,
        },
        {
          name: "Total Registrations till Day",
          type: "line",
          data: lineData,
        },
      ],
      chart: {
        height: 300,
        type: "line",
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: [0, 4],
        curve: "smooth",
      },
      dataLabels: {
        enabled: false, 
      },
      markers: {
        size: 5, 
        colors: [linecolor], 
        strokeColor: linecolor,
        hover: {
          size: 8, 
        },
      },
      tooltip: {
        enabled: true, 
        shared: false,
        intersect: true, 
        y: {
          formatter: (value: number) => `${value} Registrations`, 
        },
      },
      labels: labels,
      xaxis: {
        categories: labels,
      },
      yaxis: [
        {
          title: {
            text: "",
          },
        },
        {
          opposite: true,
          title: {
            text: "",
          },
        },
      ],
      legend: {
        show: false,
      },
      colors: [barcolor, linecolor],
    };
  }, [barData, lineData, labels, barcolor, linecolor]);

  return (
    <div id="chart" className="bg-white rounded-xl p-4">
      <h4 className="text-xl font-semibold text-secondary mb-4">{title}</h4>
      <ApexCharts
        options={options}
        series={options.series}
        type="line"
        height={350}
      />
    </div>
  );
};

export default RegistrationChartMonthly;
