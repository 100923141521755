import { useMutation, useQueryClient } from "@tanstack/react-query";
import { checklistSvc } from "../services/checklist.service";
import { IDailyReqBody } from "../types/IChecklist.interface";
import { ChecklistQueryKeyString } from "../enums/checklistQueryKey.enum";

export const useAddDailyChecklist = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: (reqBody: IDailyReqBody) =>
      checklistSvc.createDailyChecklist(reqBody),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [ChecklistQueryKeyString.DailyChecklist],
      });

      queryClient.refetchQueries({
        queryKey: [ChecklistQueryKeyString.Checklist],
      });
    },
  });
};
