import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { TbFileDescription } from "react-icons/tb";
import { FileTypeEnum, FileTypeLabelEnum } from "../../../enums/fileTypeEnums";

interface FileUploadDropzoneProps {
  onFileUpload?: (files: File[]) => void;
  acceptedFileTypes?: FileTypeEnum[];
  maxFiles?: number;
  maxSize?: number;
}

const FileUploadDropzone: React.FC<FileUploadDropzoneProps> = ({
  onFileUpload,
  acceptedFileTypes = [FileTypeEnum.DOCX, FileTypeEnum.PDF],
  maxFiles = 1,
  maxSize = 5 * 1024 * 1024, // 5MB default
}) => {
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      // Validate file types
      const validFiles = acceptedFiles.filter((file) =>
        acceptedFileTypes.includes(file.type as FileTypeEnum)
      );

      // Validate file size
      const validSizedFiles = validFiles.filter((file) => file.size <= maxSize);

      if (validSizedFiles.length > 0) {
        onFileUpload?.(validSizedFiles);
      }
    },
    [onFileUpload, acceptedFileTypes, maxSize]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: Object.fromEntries(acceptedFileTypes.map((type) => [type, []])),
    maxFiles,
    maxSize,
  });

  // Helper function to display readable file types
  const getReadableFileTypes = () => {
    return acceptedFileTypes
      .map((type) => {
        switch (type) {
          case FileTypeEnum.DOCX:
            return FileTypeLabelEnum.DOCX;
          case FileTypeEnum.PDF:
            return FileTypeLabelEnum.PDF;
          default:
            return type;
        }
      })
      .join(", ");
  };

  return (
    <div>
      <div
        {...getRootProps()}
        className={`
          flex items-center gap-4 
          bg-white rounded-lg 
          border border-dotted 
          ${isDragActive ? "border-primary bg-primary/10" : "border-primary"} 
          p-5 cursor-pointer
        `}
      >
        <input {...getInputProps()} />
        <span className="inline-flex items-center justify-center w-10 h-10 rounded-lg bg-primary">
          <TbFileDescription />
        </span>
        <div className="flex-1">
          <p className="text-secondary">
            {isDragActive
              ? "Drop the file here ..."
              : `Drag and Drop your Employee Handbook here or click "Upload" to select a file`}
          </p>
          <p className="text-xs text-[#686C82]">
            Supported formats: {getReadableFileTypes()}
          </p>
        </div>
        <button
          type="button"
          className="text-secondary h-10 px-3 rounded-lg bg-[#F2F2F2] border border-secondary"
        >
          Upload
        </button>
      </div>
    </div>
  );
};

export default FileUploadDropzone;
