import React, { FC, useEffect, useState } from "react";
import { Accordion } from "react-bootstrap";
import InputField from "../inputsFields/InputField";
import { FaChevronDown, FaChevronUp } from "react-icons/fa";
import ReactSelect from "../reactSelect/ReactSelect";
import { Field, ICategory, Section } from "../../types/ICategories.interface";
import { useUpdateSectionFilters } from "../../hooks/useUpdateSectionFilters";
import { ITaskGenerationFilters } from "../../types/ITaskGeneration.interface";
import { showErrorToast, showSuccessToast } from "../../../shared/utils/toast";
import { MdInfoOutline } from "react-icons/md";
import { formatDateYearFirst } from "../../../shared/utils/formatDate";
import ReactDatePicker from "../../../shared/components/reactDatePicker/ReactDatePicker";

interface Props {
  selectedCard: ICategory;
  selectedSection: Section;
}

const FillTaskInformation: FC<Props> = ({ selectedCard, selectedSection }) => {
  const [openKeys, setOpenKeys] = useState<string[]>(["0"]); // Manage multiple open accordions
  const [filters, setFilters] = useState<{ [key: string]: any }>({}); // Store field values dynamically
  const [essentialFieldsValid, setEssentialFieldsValid] = useState(false);

  const { mutate: updateFilters, isSuccess: isUpdateSuccess } =
    useUpdateSectionFilters();

  const handleAccordionToggle = (key: string) => {
    setOpenKeys(
      (prevKeys) =>
        prevKeys.includes(key)
          ? prevKeys.filter((k) => k !== key) // Close the accordion
          : [...prevKeys, key] // Open the accordion
    );
  };

  const handleInputChange = (fieldName: string, value: any) => {
    const updatedFilters = {
      ...filters,
      [fieldName]: value,
    };
    setFilters(updatedFilters);
    validateEssentialFields(updatedFilters);
  };

  const validateEssentialFields = (currentFilters: { [key: string]: any }) => {
    // Get essential fields from the selected section
    const essentialFields = selectedSection?.fields[0]?.essential || [];

    // Check if all required essential fields are filled
    const allEssentialFieldsFilled = essentialFields.every((field) => {
      // If the field is not required, skip validation
      if (!field.required) return true;

      const value = currentFilters[field.fieldName];

      // Check if the value exists and is not an empty string
      // Special handling for 0 which is a valid value but falsy
      return (
        value !== undefined && value !== null && (value !== "" || value === 0)
      );
    });

    setEssentialFieldsValid(allEssentialFieldsFilled);
  };

  const handleSaveFilters = () => {
    // Mapping current filters to match the API interface
    const mappedFilters: ITaskGenerationFilters = {
      referenceDate: filters.referenceDate || null,
      status: filters.status || null,
      daysAfterReferenceDate:
        filters.daysAfterReferenceDate !== undefined
          ? Number(filters.daysAfterReferenceDate)
          : 0,
      daysBeforeReferenceDate:
        filters.daysBeforeReferenceDate !== undefined
          ? Number(filters.daysBeforeReferenceDate)
          : 0,
      asOfDate: filters.asOfDate || null,
      classroom: filters.classroom || null,
      weekDay: filters.weekDay || null,
    };

    // Basic validation before API call
    if (!mappedFilters.referenceDate) {
      console.error("Reference Date is required.");
      showErrorToast("Please select a valid Reference Date.");
      return;
    }
    if (
      mappedFilters.daysAfterReferenceDate < 0 ||
      mappedFilters.daysBeforeReferenceDate < 0
    ) {
      console.error("Days cannot be negative.");
      showErrorToast("Days values cannot be negative.");
      return;
    }

    // API call
    updateFilters({
      categoryId: selectedCard?._id, // Replace with your actual category ID
      sectionId: selectedSection?._id, // Replace with your actual section ID
      filters: mappedFilters,
    });
  };

  useEffect(() => {
    if (selectedSection?.appliedFilters?.length > 0) {
      const initialFilters = { ...selectedSection.appliedFilters[0] }; // Clone the object
      
      // Convert date strings to Date objects for date picker
      if (initialFilters.asOfDate) {
        try {
          initialFilters.asOfDate = new Date(initialFilters.asOfDate);
        } catch (error) {
          console.error("Error parsing asOfDate:", error);
        }
      }
      
      setFilters(initialFilters);
      validateEssentialFields(initialFilters);
    } else {
      setFilters({});
      // No fields filled initially
      setEssentialFieldsValid(false);
    }
  }, [selectedSection]);

  const renderField = (field: Field) => {
    if (field?.fieldType === "dropdown") {
      return (
        <ReactSelect
          key={filters?.[field.fieldName]}
          label={field.fieldLabel}
          placeholder={`Select ${field.fieldLabel}`}
          options={field.options}
          menuPlacement="auto"
          required={field.required}
          value={
            field?.options &&
            field?.options?.find(
              (opt) => opt.value === filters?.[field.fieldName]
            )
          }
          onChange={(option) =>
            handleInputChange(field.fieldName, option?.value)
          }
        />
      );
    }

    if (field?.fieldType === "Date") {
      return (
        <div>
          <label className="text-sm text-secondaryVariant block mb-1">
            {field.fieldLabel}
            {field.required && <span className="text-red-500 ml-1">*</span>}
          </label>
          <div className="relative">
            <ReactDatePicker
              placeholder={`Select ${field.fieldLabel}`}
              value={filters?.[field.fieldName] ? new Date(filters?.[field.fieldName]) : null}
              onChange={(date) => handleInputChange(field.fieldName, date)}
            />
          </div>
        </div>
      );
    }

    return (
      <InputField
        type={field?.fieldType}
        placeholder={`Enter ${field.fieldLabel}`}
        label={field.fieldLabel}
        required={field.required}
        // showInfoIcon={field.fieldLabel === "Days Before Reference Date"}
        value={
          filters?.[field.fieldName] !== undefined &&
          filters?.[field.fieldName] !== null
            ? filters?.[field.fieldName]
            : ""
        }
        onChange={(e) => handleInputChange(field.fieldName, e.target.value)}
      />
    );
  };

  return (
    <div className="bg-white p-6 rounded-xl mb-6">
      <Accordion activeKey={openKeys}>
        {/* Essential Fields */}
        <Accordion.Item eventKey="0" className="mb-7">
          <Accordion.Header
            onClick={() => handleAccordionToggle("0")}
            className="mb-7"
          >
            <h2 className="flex items-center gap-3 font-semibold text-xl">
              Essential{" "}
              {openKeys.includes("0") ? (
                <FaChevronUp className="text-secondaryVariant" />
              ) : (
                <FaChevronDown className="text-secondaryVariant" />
              )}
              {/* <span>
                <MdInfoOutline className="text-primary w-[18px] h-[18px]" />
              </span> */}
            </h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="grid grid-cols-4 gap-7">
              {selectedSection?.fields[0]?.essential?.map((field, index) => (
                <div key={index}>{renderField(field)}</div>
              ))}
            </div>
          </Accordion.Body>
        </Accordion.Item>

        {/* Advanced Fields */}
        <Accordion.Item eventKey="1">
          <Accordion.Header
            onClick={() => handleAccordionToggle("1")}
            className="mb-7"
          >
            <h2 className="flex items-center gap-3 font-semibold text-xl">
              Advanced{" "}
              {openKeys.includes("1") ? (
                <FaChevronUp className="text-secondaryVariant" />
              ) : (
                <FaChevronDown className="text-secondaryVariant" />
              )}
            </h2>
          </Accordion.Header>
          <Accordion.Body>
            <div className="grid grid-cols-4 gap-7 mb-6">
              {selectedSection?.fields[0]?.advanced?.map((field, index) => (
                <div key={index}>{renderField(field)}</div>
              ))}
            </div>

            <div className="">
              <h3 className="text-base text-secondary font-medium mb-3">
                Send Parent Auto Email Notification?
              </h3>
              <div className="flex items-center gap-6 mb-6">
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3"
                  />
                  <label htmlFor="" className="text-sm text-secondaryVariant">
                    Yes
                  </label>
                </div>
                <div className="flex items-center gap-2">
                  <input
                    type="radio"
                    className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 "
                  />
                  <label htmlFor="" className="text-sm text-secondaryVariant">
                    No
                  </label>
                </div>
              </div>
              <div className="grid grid-cols-4 gap-7">
                <div>
                  <ReactSelect label={"Choose Template*"} placeholder={""} />
                </div>
                <div>
                  <InputField
                    type={""}
                    placeholder={"30"}
                    label="Send how many days before Event?"
                    required
                  />
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <hr className="text-[#F6EAEA] my-7" />
      <div className="flex items-center gap-3">
        <button
          disabled={!essentialFieldsValid}
          className={`
          btnPrimary 
          max-w-[182px] 
          ${!essentialFieldsValid ? "opacity-50 cursor-not-allowed" : ""}
          `}
          onClick={handleSaveFilters}
        >
          Save
        </button>
        <button
          className="btnSimple max-w-[182px]"
          onClick={() => {
            setFilters({});
            setEssentialFieldsValid(false);
          }}
        >
          Cancel
        </button>
      </div>
    </div>
  );
};

export default FillTaskInformation;
