import { FC, useEffect, useState } from "react";
import { IoCheckmarkOutline } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import { SubscriptionStatus } from "../../enums/sharedEnums";
import { sharedRoutes } from "../../routes/routes";

interface TransformedResponse<T> {
  data: T[];
  ok: boolean;
}
interface PriceDetails {
  priceId: string;
  price: number;
  interval: string;
}

interface SubscriptionTransformedResponse {
  success: boolean;
  data: any[];
}
interface IProps {
  subscriptionPeriod: string;
  handleSubscriptionPeriod: (period: string) => void;
  prices: TransformedResponse<any>;
  mySubscription: SubscriptionTransformedResponse;
  products: TransformedResponse<any>;
}
const BillingPlans: FC<IProps> = ({
  subscriptionPeriod,
  handleSubscriptionPeriod,
  prices,
  mySubscription,
  products,
}) => {
  const [plan2Price, setPlan2Price] = useState(0);
  const [isPlanSubscribed, setIsPlanSubscribed] = useState(false);
  const [currentSubscriptionPeriod, setCurrentSubscriptionPeriod] =
    useState<string>();
  const [monthlyPriceDetails, setMonthlyPriceDetails] = useState<PriceDetails>({
    priceId: "",
    price: 0,
    interval: "month",
  });

  const [yearlyPriceDetails, setYearlyPriceDetails] = useState<PriceDetails>({
    priceId: "",
    price: 0,
    interval: "year",
  });

  const [subscriptioId, setSubscriptionId] = useState("");
  // const currentStatus = mySubscription?.data[0]?.status;
  const navigate = useNavigate();

  const handleSubscribe = (priceId: string) => {
    navigate(sharedRoutes.checkout, {
      state: {
        priceId,
      },
    });
  };

  const handleUpgradePlan = (priceId: string) => {
    navigate(sharedRoutes.upgradeConfirmation, {
      state: {
        subscriptionId: subscriptioId,
        newPriceId: priceId,
      },
    });
  };

  useEffect(() => {
    if (prices?.data) {
      const monthlyPrice = prices.data.find(
        (price) => price?.recurring?.interval === "month"
      );

      const yearlyPrice = prices.data.find(
        (price) => price?.recurring?.interval === "year"
      );

      if (monthlyPrice) {
        setMonthlyPriceDetails({
          priceId: monthlyPrice.id,
          price: (monthlyPrice.unit_amount || 0) / 100,
          interval: monthlyPrice.recurring?.interval || "month",
        });
      }

      if (yearlyPrice) {
        setYearlyPriceDetails({
          priceId: yearlyPrice.id,
          price: (yearlyPrice.unit_amount || 0) / 100,
          interval: yearlyPrice.recurring?.interval || "year",
        });
      }
    }
  }, [prices]);

  useEffect(() => {
    if (subscriptionPeriod && prices?.data) {
      const plan2Price =
        (prices?.data?.find(
          (price) => price?.recurring?.interval === subscriptionPeriod
        )?.unit_amount || 0) / 100;
      console.log(plan2Price);
      setPlan2Price(plan2Price);
    }
  }, [subscriptionPeriod, prices]);

  useEffect(() => {
    const subscriptionStatus = mySubscription?.data[0]?.status;
    const subscriptionInterval =
      mySubscription?.data[0]?.items?.data[0]?.plan?.interval;
    const subscriptioId = mySubscription?.data[0]?.id;

    // Check if there's an active subscription
    const isSubscribed =
      subscriptionStatus === SubscriptionStatus.ACTIVE ||
      subscriptionStatus === SubscriptionStatus.TRIALING;

    setSubscriptionId(subscriptioId ?? "");
    setIsPlanSubscribed(isSubscribed);
    setCurrentSubscriptionPeriod(subscriptionInterval);
  }, [mySubscription]);

  // useEffect(() => {
  //   const subscriptionStatus = mySubscription?.data[0]?.status;
  //   console.log("subscriptionPeriod", subscriptionPeriod);
  //   const subscriptionInterval =
  //     mySubscription?.data[0]?.items?.data[0]?.plan?.interval;

  //   console.log("subscriptionInterval", subscriptionInterval);
  //   console.log("subscriptionStatus", subscriptionStatus);
  //   const subscriptioId = mySubscription?.data[0]?.id;
  //   const isActiveSubscription =
  //     [SubscriptionStatus.ACTIVE, SubscriptionStatus.TRIALING].includes(
  //       subscriptionStatus
  //     ) && subscriptionInterval === subscriptionPeriod;

  //   console.log("isSubscribed", isActiveSubscription);
  //   setSubscriptionId(subscriptioId ?? "");
  //   setIsPlanSubscribed(isActiveSubscription);
  //   setCurrentSubscriptionPeriod(subscriptionInterval);

  //   console.log("isSubscribed", isActiveSubscription);
  // }, [mySubscription]);

  return (
    <>
      <div className="grid grid-cols-3 bg-white px-6 pt-6 rounded-t-2xl">
        <div className="pb-6">
          <h3 className="text-lg text-secondary font-semibold mb-2">
            Billing/Pay
          </h3>
          <div className="flex items-start gap-4 mb-2">
            <span className="text-base text-secondary">Monthly</span>

            <div className="relative inline-block w-11 h-6">
              <input
                type="checkbox"
                id="strategicView"
                className="hidden peer"
                checked={subscriptionPeriod === "month" ? false : true}
                onChange={(e) => {
                  const temp = e?.target?.checked ? "year" : "month";
                  handleSubscriptionPeriod(temp);
                }}
              />
              <label
                htmlFor="strategicView"
                className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
              ></label>
              <div className="dot pointer-events-none absolute left-0 top-0 bg-white w-6 h-6 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
            </div>

            <span className="text-base text-secondary flex flex-col items-start">
              Yearly{" "}
              <span className="text-base text-primary font-medium">
                (Save 15% annually)
              </span>
            </span>
          </div>
        </div>

        <div className="pb-6 flex flex-col justify-between">
          <div>
            <h3 className="text-lg text-secondary font-semibold mb-2">
              Plan 1 (Primary)
            </h3>
            <h3 className="text-2xl text-primary font-semibold mb-2">
              0$
              <span className="font-normal">(Complimentary)</span>
            </h3>
          </div>
          <button disabled className="btnPrimary max-w-[120px] mt-6">
            {isPlanSubscribed ? "Subscribe" : "Subscribed"}
          </button>
        </div>

        {/* Third Column */}
        {/* <div className="pb-6">
          <h3 className="text-lg text-secondary font-semibold mb-2">
            {`Plan 2 ${products?.data[0].name}`}
          </h3>
          <h3 className="text-2xl text-primary font-semibold mb-2">
            {`${prices?.data[0].}`}/<span className="font-normal">month</span>
          </h3>
          <p className="text-base text-secondary">
            After 90 days free trial, this subscription will continue
            automatically.
          </p>
          <Link to={"/"} className="text-base text-statusColor underline">
            Learn more about Foray
          </Link>
          <button className="btnPrimary max-w-[100px] mt-6">Subscribe</button>
        </div> */}
        <div className="pb-6 ps-16">
          <h3 className="text-lg text-secondary font-semibold mb-2">
            Plan 2 ({products?.data[0]?.name})
          </h3>
          <h3 className="text-2xl text-primary font-semibold mb-2">
            ${plan2Price?.toFixed(2) || "--"}/
            <span className="font-normal">{subscriptionPeriod}</span>
          </h3>
          {/* {currentStatus === SubscriptionStatus.TRIALING && ( */}
          <p className="text-base text-secondary max-w-[347px]">
            After 30 days free trial, this subscription will continue
            automatically.
          </p>
          {/* )} */}
          <Link to={"/"} className="text-base text-statusColor underline">
            Learn more about {products?.data[0]?.name}
          </Link>
          <button
            onClick={() => {
              if (isPlanSubscribed) {
                // Handle plan update
                if (subscriptionPeriod === "month") {
                  handleUpgradePlan(monthlyPriceDetails.priceId);
                } else {
                  handleUpgradePlan(yearlyPriceDetails.priceId);
                }
              } else {
                // Handle new subscription
                if (subscriptionPeriod === "month") {
                  handleSubscribe(monthlyPriceDetails.priceId);
                } else {
                  handleSubscribe(yearlyPriceDetails.priceId);
                }
              }
            }}
            className={`btnPrimary max-w-[130px] whitespace-nowrap mt-6 ${
              isPlanSubscribed &&
              currentSubscriptionPeriod === subscriptionPeriod
                ? "opacity-50 cursor-not-allowed"
                : ""
            }`}
            disabled={
              isPlanSubscribed &&
              currentSubscriptionPeriod === subscriptionPeriod
            }
          >
            {isPlanSubscribed
              ? currentSubscriptionPeriod === subscriptionPeriod
                ? "Subscribed"
                : "Upgrade Plan"
              : "Subscribe"}
          </button>
        </div>
      </div>

      <div className="bg-white">
        <h3 className="text-lg text-secondary font-semibold py-3 px-4">
          What’s Included
        </h3>
        <div className="py-3 px-4 w-full grid grid-cols-3 items-center border-t border-secondaryNeutral">
          <div className="text-secondaryVariant text-lg">Adventures</div>
          <div className="max-w-[221px] text-successAlert ps-4">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
          <div className="text-successAlert ps-20">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full grid grid-cols-3 items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Medical Evals (Access)
          </div>
          <div className="w-1/3 text-successAlert ps-4">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
          <div className="w-1/3 text-successAlert ps-20">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full grid grid-cols-3 items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Tracking Enrollment
          </div>
          <div className="w-1/3 text-successAlert ps-4"></div>
          <div className="w-1/3 text-successAlert ps-20">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full grid grid-cols-3 items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Task Generation
          </div>
          <div className="w-1/3 text-successAlert ps-4"></div>
          <div className="w-1/3 text-successAlert ps-20">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full grid grid-cols-3 items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">Checklist</div>
          <div className="w-1/3 text-successAlert ps-4">
            {/* <IoCheckmarkOutline className="w-6 h-6" /> */}
          </div>
          <div className="w-1/3 text-successAlert ps-20">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
        <div className="py-3 px-4 w-full grid grid-cols-3 items-center border-t border-secondaryNeutral">
          <div className="w-1/3 text-secondaryVariant text-lg">
            Center Management
          </div>
          <div className="w-1/3 text-successAlert ps-4"></div>
          <div className="w-1/3 text-successAlert ps-20">
            <IoCheckmarkOutline className="w-6 h-6" />
          </div>
        </div>
      </div>
    </>
  );
};

export default BillingPlans;
