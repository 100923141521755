import { useEffect, useRef, useState } from "react";
import { FiUser } from "react-icons/fi";
import { HiOutlineBuildingLibrary } from "react-icons/hi2";
import { MdOutlineDashboardCustomize } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { queryClient } from "../../../../../config/useQuery.config";
import {
  setPreviewId,
  setSwitches,
} from "../../../../../features/EnrollmentView/enrollmentViewSlice";
import { RootState } from "../../../../../store/store";
import Breadcrumb from "../../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import { SessionType } from "../../../enums/Foray.enums";
import { useCenterSetup } from "../../../hooks/useCenterSetup";
import { forayRoutes } from "../../../routes/routes";
import { Classroom, DailyCount } from "../../../types/IEnrollmentTrackingInfo";
import SessionTime from "../../components/Session/SessionTime";
import AutoMovements from "../../components/autoMovements/AutoMovements";
import EnrolTrackingOptimization from "../../components/enrolTrackingOptimization/EnrolTrackingOptimization";
import CalendarData from "../../components/enrollmentCalendar/CalendarData";
import EnrollmentCalendar from "../../components/enrollmentCalendar/EnrollmentCalendar";
import WarningModal from "../../components/modals/WarningModal";
import SkippedPastTransitions from "../../components/skippedPastTransitions/SkippedPastTransitions";
import StudentEnrollmentTable from "../../components/tables/StudentEnrollmentTable";
import { QueryKeyString } from "../../enums/queryKey.enum";
import { StudentExecutions } from "../../enums/studentExecutions.enum";
import { useYearlyEnrollments } from "../../hooks/useYearlyEnrollments";
import { EnrollmentTrackingSvc } from "../../services/enrollment.service";
import { IEnrollmentSwitchesState } from "../../types/IEnrollmentSwitches";

const generateNext365Days = (apiStartDate?: string) => {
  const days = [];

  // If API start date is provided, use it; otherwise fallback to current date
  let currentDate;
  if (apiStartDate) {
    // Parse the API date string (YYYY-MM-DD) directly without timezone influence
    currentDate = new Date(apiStartDate + "T00:00:00Z");
  } else {
    // Fallback to current date, but normalize to remove time components
    const today = new Date();
    currentDate = new Date(
      Date.UTC(today.getFullYear(), today.getMonth(), today.getDate())
    );
  }

  for (let i = 0; i < 365; i++) {
    // Create a new date by adding days, using UTC to avoid timezone shifts
    const fullDate = new Date(currentDate);
    fullDate.setUTCDate(currentDate.getUTCDate() + i);

    // Format the date components using UTC methods
    const dayName = fullDate.toLocaleDateString("en-US", {
      weekday: "short",
      timeZone: "UTC",
    });
    const dayDate = fullDate.toLocaleDateString("en-US", {
      day: "2-digit",
      timeZone: "UTC",
    });
    const monthName = fullDate.toLocaleDateString("en-US", {
      month: "long",
      timeZone: "UTC",
    });
    const year = fullDate.getUTCFullYear();

    // Generate a standardized YYYY-MM-DD string for easy comparison with API dates
    const isoDateString = fullDate.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    days.push({
      day: dayName,
      date: dayDate,
      month: monthName,
      year,
      fullDate,
      isoDateString, // Add ISO formatted date string for comparison with API dates
    });
  }

  return days;
};

const EnrollmentTracking = ({ month, year }: any) => {
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const [showLeaveWarningModal, setShowLeaveWarningModal] = useState(false);

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Enrollment Tracking & Optimization", isCurrent: true },
  ];
  const [visibleMonthYear, setVisibleMonthYear] = useState<string>("");
  const [visibleTables, setVisibleTables] = useState<{
    [key: string]: boolean;
  }>({});
  const dispatch = useDispatch();
  const { previewId, switches } = useSelector(
    (state: RootState) => state.enrollmentView
  );

  const { data: centerSetup } = useCenterSetup();

  const updateSwitches = (
    newSwitchValues: Partial<IEnrollmentSwitchesState>
  ) => {
    dispatch(setSwitches(newSwitchValues));
  };

  const { data: enrollmentInfo, refetch: refetchEnrollmentInfo } =
    useYearlyEnrollments(previewId ? previewId : undefined);

  const daysData = generateNext365Days(
    enrollmentInfo?.classrooms?.[0]?.dailyCounts?.[0]?.date
  );

  // useEffect(() => {
  //   console.log("previewId", previewId);
  //   if (!previewId) return;
  //   console.log("refetching");
  //   refetchEnrollmentInfo();
  // }, [previewId]);

  const handleToggle = async (switchName: string) => {
    if (switchName === "optimization" && switches.optimization) {
      setShowLeaveWarningModal(true);
    } else if (
      switchName === "optimization" &&
      switches.optimization === false
    ) {
      const resp = await EnrollmentTrackingSvc.createPreview();

      dispatch(setPreviewId(resp?.preview?.previewId));
      dispatch(setSwitches({ optimization: true }));
    } else {
      dispatch(
        setSwitches({
          [switchName]: !switches[switchName as keyof IEnrollmentSwitchesState],
        })
      );
    }
  };

  const closeModal = async () => {
    toggleTable("");
    dispatch(setPreviewId(undefined)); // Clear previewId
    window.history.replaceState({}, "");
    dispatch(setSwitches({ optimization: false }));
    setShowLeaveWarningModal(false);

    await queryClient.invalidateQueries({
      queryKey: [QueryKeyString.Yearly_Enrollment, { previewID: undefined }],
      exact: true,
    });
  };
  const onDiscardChanges = async () => {
    if (previewId) {
      const resp = await EnrollmentTrackingSvc.discardChanges(previewId);
      console.log("discard", resp);
    }

    // window.history.replaceState({}, "");

    await closeModal();
  };

  // Updated toggleTable function with auto-centering behavior
  const toggleTable = (id: string) => {
    setVisibleTables((prev) => {
      const isCurrentlyOpen = prev[id];
      const newState = {
        [id]: !isCurrentlyOpen,
      };

      // If we're opening a table, we'll want to scroll to it after state update
      if (!isCurrentlyOpen && id) {
        // Use setTimeout to ensure the state has updated and the element exists
        setTimeout(() => {
          const element = document.getElementById(id);
          if (element && calendarRef.current) {
            // Calculate the position to center the element
            const containerWidth = calendarRef.current.clientWidth;
            const elementLeft = element.offsetLeft;
            const elementWidth = element.offsetWidth;

            // Scroll to center the element
            calendarRef.current.scrollLeft =
              elementLeft - containerWidth / 5 + elementWidth / 5;
          }
        }, 0);
      }

      return newState;
    });
  };

  const [sliderValue, setSliderValue] = useState(15);

  // const updateVisibleMonth = () => {
  //   if (!calendarRef.current) return;

  //   const scrollLeft = calendarRef.current.scrollLeft;
  //   const clientWidth = calendarRef.current.clientWidth;

  //   const dayWidth = 65; //based on the width of the cells

  //   const startIndex = Math.floor(scrollLeft / dayWidth);
  //   const endIndex = Math.min(
  //     daysData.length - 1,
  //     Math.floor((scrollLeft + clientWidth) / dayWidth)
  //   );
  //   const visibleDays = daysData?.slice(
  //     Math.max(0, startIndex),
  //     Math.min(daysData.length, endIndex + 1)
  //   );

  //   if (visibleDays?.length > 0) {
  //     const currentMonth = visibleDays[0]?.month;
  //     const currentYear = visibleDays[0]?.year;
  //     const currentMonthYear = `${currentMonth}, ${currentYear}`;

  //     if (currentMonthYear !== visibleMonthYear) {
  //       setVisibleMonthYear(currentMonthYear);
  //     }
  //   }
  // };

  // const getSlots = async () => {
  //   const res = await classroomSvc.fetchTransitionSlots(enrollmentInfo?.classrooms);
  // };

  const updateVisibleMonth = () => {
    if (!calendarRef.current) return;

    const scrollLeft = calendarRef.current.scrollLeft;
    const clientWidth = calendarRef.current.clientWidth;

    // Make sure this matches your actual cell width including any margins/padding
    const dayWidth = 56; // Based on your CSS: w-[56px] flex-[0_0_56px]

    // Calculate the visible range more accurately
    const startIndex = Math.floor(scrollLeft / dayWidth);
    const visibleDaysCount = Math.ceil(clientWidth / dayWidth);
    const endIndex = Math.min(
      daysData.length - 1,
      startIndex + visibleDaysCount
    );

    // Get the actual visible days
    const visibleDays = daysData?.slice(
      Math.max(0, startIndex),
      Math.min(daysData.length, endIndex + 1)
    );

    // Find the month that takes up the most space in the visible area
    if (visibleDays?.length > 0) {
      // Get the month that appears most frequently in the visible range
      const monthCounts: any = {};
      let dominantMonth = visibleDays[0]?.month;
      let dominantYear = visibleDays[0]?.year;
      let maxCount = 0;

      visibleDays.forEach((day) => {
        const key = `${day.month}-${day.year}`;
        monthCounts[key] = (monthCounts[key] || 0) + 1;

        if (monthCounts[key] > maxCount) {
          maxCount = monthCounts[key];
          dominantMonth = day.month;
          dominantYear = day.year;
        }
      });

      const currentMonthYear = `${dominantMonth}, ${dominantYear}`;

      if (currentMonthYear !== visibleMonthYear) {
        setVisibleMonthYear(currentMonthYear);
      }
    }
  };

  // useEffect(() => {
  //   const calendarContainer = calendarRef.current;

  //   if (calendarContainer) {
  //     // Add smooth scrolling behavior
  //     calendarContainer.style.scrollBehavior = "smooth";
  //     calendarContainer.addEventListener("scroll", updateVisibleMonth);
  //     updateVisibleMonth(); // initial check on load
  //   }

  //   return () => {
  //     if (calendarContainer) {
  //       calendarContainer.removeEventListener("scroll", updateVisibleMonth);
  //     }
  //   };
  // }, [visibleMonthYear, daysData]);

  useEffect(() => {
    const calendarContainer = calendarRef.current;

    if (calendarContainer) {
      // Add smooth scrolling behavior
      calendarContainer.style.scrollBehavior = "smooth";

      // Debounce the scroll event for better performance
      let scrollTimeout: NodeJS.Timeout | null = null;
      const handleScroll = () => {
        if (scrollTimeout) clearTimeout(scrollTimeout);
        scrollTimeout = setTimeout(updateVisibleMonth, 100);
      };

      calendarContainer.addEventListener("scroll", handleScroll);

      // Initial check on load
      updateVisibleMonth();

      // Proper cleanup with the same handleScroll function reference
      return () => {
        if (calendarContainer) {
          calendarContainer.removeEventListener("scroll", handleScroll);
        }
      };
    }
  }, [daysData]); //

  const [isHovered, setIsHovered] = useState(false);

  const handleOpenChange = (open: boolean) => {
    setIsHovered(open);
  };

  return (
    <DashboardWrapper>
      <div className="ps-6 pt-5 bg-secondaryNeutral">
        <div className="flex items-center justify-between pe-6">
          <Breadcrumb items={breadcrumbItems} />
          <div className="flex items-center gap-4">
            <Link
              to={forayRoutes.foray_dashboard}
              className="text-base text-secondary font-semibold p-3 rounded-lg bg-white flex items-center gap-2"
            >
              <MdOutlineDashboardCustomize className="w-6 h-6 text-primary" />{" "}
              Go to Foray Dashboard
            </Link>
            <Link
              to={forayRoutes.student_list}
              className="text-base text-secondary font-semibold p-3 rounded-lg bg-white flex items-center gap-2"
            >
              <FiUser className="w-6 h-6 text-primary" /> View/Modify Student
            </Link>
            <Link
              to={forayRoutes.tour_setup}
              className="text-base text-secondary font-semibold p-3 rounded-lg bg-white flex items-center gap-2"
            >
              <HiOutlineBuildingLibrary className="w-6 h-6 text-primary" /> Tour
              Setup{" "}
            </Link>
          </div>
        </div>{" "}
        {enrollmentInfo && enrollmentInfo.skippedStudents?.length > 0 && (
          <SkippedPastTransitions
            transitionType={StudentExecutions.Transition_In_Or_Out}
            skippedStudents={enrollmentInfo.skippedStudents}
          />
        )}
        {enrollmentInfo && enrollmentInfo.skippedStarts?.length > 0 && (
          <SkippedPastTransitions
            transitionType={StudentExecutions.Start}
            skippedStudents={enrollmentInfo.skippedStarts}
          />
        )}
        {enrollmentInfo && enrollmentInfo.skippedWithDrawals?.length > 0 && (
          <SkippedPastTransitions
            transitionType={StudentExecutions.Withdraw}
            skippedStudents={enrollmentInfo.skippedWithDrawals}
          />
        )}
        <div className="pe-6 mb-6">
          <EnrolTrackingOptimization
            switches={switches}
            handleToggle={handleToggle}
          />
          <AutoMovements
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            currentDayStarts={enrollmentInfo?.currentDayStarts}
            currentDayTransitions={enrollmentInfo?.currentDayTransitions}
            currentDayWithdrawals={enrollmentInfo?.currentDayWithDrawals}
            tomorrowStarts={enrollmentInfo?.tomorrowStarts}
            tomorrowTransitions={enrollmentInfo?.tomorrowTransitions}
            tomorrowWithdrawals={enrollmentInfo?.tomorrowWithDrawals}
            switches={switches}
            onDiscardChanges={onDiscardChanges}
            onCancel={handleToggle}
            showModal={showLeaveWarningModal}
            closeModal={closeModal}
          />
        </div>
        <div
          ref={calendarRef}
          className="relative overflow-x-auto h-[calc(100vh-385px)]"
        >
          <div className="table border-t-[0.5px] sticky top-0 z-[9999]">
            <div className="w-[300px]  table-cell bg-white sticky left-0">
              <div className="bg-white ps-3 py-2 sticky left-0">
                <h2 className="text-base text-primary font-semibold">
                  {visibleMonthYear}
                </h2>
              </div>
              <div className="bg-primaryVariant2 flex items-center gap-[50px] px-3 py-1.5">
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Classroom
                </h3>
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Session
                </h3>
                <h3 className="text-base text-secondaryVariant font-semibold">
                  Wait
                </h3>
              </div>
            </div>
            <div className="table-cell">
              <div className="flex flex-col">
                <EnrollmentCalendar
                  operatingDays={
                    centerSetup?.existingCenterSetup?.operatingHours?.days
                  }
                  daysData={daysData}
                />
                <div className="bg-primaryVariant2 py-[17.5px]"></div>
              </div>
            </div>
          </div>

          {enrollmentInfo?.classrooms?.map((classroom: Classroom, index) => {
            return (
              <div key={classroom.classroomId} className="table border mb-6">
                <div
                  className={`w-[300px] min-w-[300px] bg-white ${
                    isHovered ? "sticky " : "sticky"
                  } left-0 table-cell align-top`}
                >
                  <SessionTime
                    classroom={classroom}
                    handleOpenChange={handleOpenChange}
                  />
                </div>
                <div className="table-cell bg-[#F1F8FF]">
                  <div className="">
                    {/* Full Time */}
                    <div className="flex gap-0 m-0 p-0 flex-1">
                      {classroom.dailyCounts.map((dailyCount, index) => {
                        const uniqueId = `${classroom.classroomId}-${dailyCount.date}-${index}`;

                        return (
                          <div
                            id={uniqueId}
                            key={uniqueId}
                            className="w-[56px]"
                          >
                            <CalendarData
                              operatingDays={
                                centerSetup?.existingCenterSetup?.operatingHours
                                  ?.days
                              }
                              switches={switches}
                              toggleTable={() => toggleTable(uniqueId)}
                              dailyCounts={dailyCount}
                              classroom={classroom}
                              daysData={daysData}
                              isTableOpen={visibleTables[uniqueId] || false}
                              prevDayFullTimeStudents={
                                classroom.dailyCounts[index - 1]
                                  ?.fullTimeStudents || []
                              }
                              prevDayParttimeStudents={
                                classroom.dailyCounts[index - 1]
                                  ?.allPartTimeStudents || []
                              }
                            />
                            {visibleTables[uniqueId] && (
                              <div
                                // ref={containerRef}
                                onClick={(e) => e.stopPropagation()}
                                className={`${
                                  visibleTables[uniqueId] ? "scrollWrapper" : ""
                                }`}
                              >
                                <StudentEnrollmentTable
                                  students={[
                                    ...dailyCount.fullTimeStudents,
                                    ...dailyCount.partTimeStudents,
                                  ]}
                                  studentsOutsideMinimumRange={
                                    dailyCount.countStudentsOutsideMinimumRange
                                  }
                                  studentsOutsideMaximumRange={
                                    dailyCount.countStudentsOutsideMaximumRange
                                  }
                                  date={(() => {
                                    if (!dailyCount.date) return undefined;
                                    const dateObj = new Date(dailyCount.date);
                                    const day = dateObj.toLocaleDateString(
                                      "en-US",
                                      { weekday: "long" }
                                    );
                                    const date = dateObj.getDate();
                                    const month = dateObj.toLocaleDateString(
                                      "en-US",
                                      { month: "long" }
                                    );
                                    const year = dateObj.getFullYear();
                                    return `${day}, ${date} ${month}, ${year}`;
                                  })()}
                                />
                              </div>
                            )}
                          </div>
                        );
                      })}
                    </div>

                    {/* Part Time */}
                    {classroom.settingType === SessionType.FullAndPartTime && (
                      <div className="flex gap-0 m-0 p-0 flex-1">
                        {classroom.dailyCounts.map((dailyCount, index) => {
                          const uniqueId = `${classroom.classroomId}-${dailyCount.date}-${index}-pt`;
                          return (
                            <div
                              id={uniqueId}
                              className="w-[56px]"
                              key={uniqueId}
                            >
                              <CalendarData
                                operatingDays={
                                  centerSetup?.existingCenterSetup
                                    ?.operatingHours?.days
                                }
                                daysData={daysData}
                                switches={switches}
                                toggleTable={() => toggleTable(uniqueId)}
                                dailyCounts={dailyCount}
                                classroom={classroom}
                                isPartTime={true}
                                isTableOpen={visibleTables[uniqueId] || false}
                                prevDayFullTimeStudents={
                                  classroom.dailyCounts[index - 1]
                                    ?.fullTimeStudents || []
                                }
                                prevDayParttimeStudents={
                                  classroom.dailyCounts[index - 1]
                                    ?.allPartTimeStudents || []
                                }
                              />
                              {visibleTables[uniqueId] && (
                                <div
                                  // ref={containerRef}
                                  onClick={(e) => e.stopPropagation()}
                                  className={`${
                                    visibleTables[uniqueId]
                                      ? "scrollWrapper"
                                      : ""
                                  }`}
                                >
                                  <StudentEnrollmentTable
                                    students={[
                                      ...dailyCount.fullTimeStudents,
                                      ...dailyCount.partTimeStudents,
                                    ]}
                                    studentsOutsideMinimumRange={
                                      dailyCount.countStudentsOutsideMinimumRange
                                    }
                                    studentsOutsideMaximumRange={
                                      dailyCount.countStudentsOutsideMaximumRange
                                    }
                                    date={(() => {
                                      if (!dailyCount.date) return undefined;
                                      const dateObj = new Date(dailyCount.date);
                                      const day = dateObj.toLocaleDateString(
                                        "en-US",
                                        { weekday: "long" }
                                      );
                                      const date = dateObj.getDate();
                                      const month = dateObj.toLocaleDateString(
                                        "en-US",
                                        { month: "long" }
                                      );
                                      const year = dateObj.getFullYear();
                                      return `${day}, ${date} ${month}, ${year}`;
                                    })()}
                                  />
                                </div>
                              )}
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        {showLeaveWarningModal && <WarningModal onClose={onDiscardChanges} />}
      </div>
    </DashboardWrapper>
  );
};

export default EnrollmentTracking;
