import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../../../shared/utils/toast";
import { StaffQueryKeyString } from "../../enums/staffQueryKeyString";
import { staffSvc } from "../../services/staff.service";
import { IStaffCreate } from "../../types/IStaff";

export const useCreateStaffMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ personInfo }: { personInfo: IStaffCreate }) =>
      staffSvc.createStaff(personInfo),
    onError: (err) => {
      console.error("error:", err);
      showErrorToast("Failed to save employee information");
    },
    retry:0,
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeyString.Staff],
      });
      // showSuccessToast("Employee information saved successfully");
      
      // IMPORTANT: Return the data but don't invalidate queries here
      // This prevents the chain of re-renders
      return data;
    },
  });
};
