import { BiEdit } from "react-icons/bi";
import { RiDeleteBin3Line } from "react-icons/ri";

import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import { useMemo } from "react";
import { useCompleteChecklist } from "../../hooks/useCompleteDailyChecklist";
import { ChecklistItem, Data } from "../../types/IChecklist.interface";

const columnHelper = createColumnHelper<ChecklistItem>();

interface IProps {
  handleEdit: (id: string) => void;
  rowToEdit: string;
  initials: string;
  handleChange: (value: string) => void;
  // dailyChecklist: ChecklistItem[] | undefined;
  checklist: Data | undefined;
  handleEditChecklist: (item: ChecklistItem) => void;
  deleteItem: any;
  handleToggleDBView: (item: any, value: boolean) => void;
}

const DailyChecklistTable: React.FC<IProps> = ({
  handleEdit,
  rowToEdit,
  initials,
  handleChange,
  // dailyChecklist,
  checklist,
  handleEditChecklist,
  deleteItem,
  handleToggleDBView,
}) => {
  const { mutate } = useCompleteChecklist();

  const columns = useMemo(
    () => [
      columnHelper.accessor("isVisibleOnDashboard", {
        id: "isVisibleOnDashboard",
        header: () => (
          <div className="text-start">
            <span>DB View</span>
          </div>
        ),
        cell: (info: any) => {
          const uniqueId = `isVisibleOnDashboard-${info.row.original._id}`;
          return (
            <div className="text-start">
              <span className="w-full">
                <div className="relative inline-block w-11 h-6">
                  <input
                    type="checkbox"
                    id={uniqueId}
                    className="hidden peer"
                    checked={info.getValue()}
                    onChange={(e) => {
                      handleToggleDBView(info.row.original, e.target.checked);
                    }}
                  />
                  <label
                    htmlFor={uniqueId}
                    className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
                  ></label>
                  <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
                </div>
              </span>
            </div>
          );
        },
      }),
      // columnHelper.display({
      //   id: "Sequence",
      //   header: () => (
      //     <div className="text-start">
      //       <span>Sequence</span>
      //     </div>
      //   ),
      //   cell: (info: any) => (
      //     <div className="text-start">
      //       {/* info.getValue() */}
      //       <span className="w-full">{1}</span>
      //     </div>
      //   ),
      // }),
      columnHelper.accessor("time", {
        id: "time",
        header: () => (
          <div className="text-start">
            <span>Time</span>
          </div>
        ),
        cell: (info) => {
          // Format time to remove leading zero from hours
          const timeValue = info.getValue();
          const formattedTime = timeValue.replace(/^0(\d):/, "$1:");

          return (
            <div className="text-start">
              <span className="w-full">{formattedTime}</span>
            </div>
          );
        },
      }),
      columnHelper.accessor("task", {
        id: "task",
        header: () => (
          <div className="text-start">
            <span>Task</span>
          </div>
        ),
        cell: (info) => (
          <div className="text-start">
            <span className="w-full">{info.getValue()}</span>
          </div>
        ),
      }),
      columnHelper.accessor("completedBy", {
        id: "initials",
        header: () => (
          <div className="text-center">
            <span>Initials</span>
          </div>
        ),
        cell: (info) => (
          <div className="text-center">
            <input
              key={info.row.original._id}
              onFocus={(e) => {
                e.preventDefault();
                handleEdit(info.row.original._id);
              }}
              maxLength={2}
              className="text-center"
              value={
                info.row.original._id !== rowToEdit ? info.getValue() : initials
              }
              onChange={(e) => {
                e.preventDefault();
                handleChange(e.target.value);
              }}
              onBlur={(e) => {
                if (
                  e.target.value.length === 2 &&
                  info.getValue() !== e.target.value
                ) {
                  mutate({
                    checklistId: info.row.original._id,
                    initials: e.target.value,
                  });
                }
              }}
            />
          </div>
        ),
      }),
      columnHelper.display({
        id: "action",
        header: () => (
          <div className="text-center">
            <span>Action</span>
          </div>
        ),
        cell: (info) => (
          <div className="text-center flex items-center justify-center gap-2">
            <button onClick={() => handleEditChecklist(info.row.original)}>
              <BiEdit className="w-6 h-6 text-statusColor" />
            </button>
            <button>
              <RiDeleteBin3Line
                onClick={() => deleteItem({ itemId: info.row.original._id })}
                className="w-6 h-6 text-dangerAlert"
              />
            </button>
          </div>
        ),
      }),
    ],
    []
  );
  // const columns = useMemo(
  //   () => [
  //     columnHelper.accessor("isVisibleOnDashboard", {
  //       id: "isVisibleOnDashboard",
  //       header: () => (
  //         <div className="text-center">
  //           <span>DB View</span>
  //         </div>
  //       ),
  //       cell: (info: any) => {
  //         return (
  //           <div className="text-start">
  //             <span className="w-full">
  //               <div className="relative inline-block w-11 h-6">
  //                 <input
  //                   // defaultChecked={info.getValue()}
  //                   type="checkbox"
  //                   id="isVisibleOnDashboard"
  //                   className="hidden peer"
  //                   checked={info.getValue()}
  //                   onChange={(e) => {
  //                     console.log('clicked')
  //                     e.preventDefault();
  //                     handleToggleDBView(info.row.original, e.target.checked);
  //                   }}
  //                 />
  //                 <label
  //                   htmlFor="strategicView"
  //                   className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
  //                 ></label>
  //                 <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
  //               </div>
  //             </span>
  //           </div>
  //         );
  //       },
  //     }),
  //     // columnHelper.display({
  //     //   id: "isVisibleOnDashboard",
  //     //   header: () => (
  //     //     <div className="text-center">
  //     //       <span>DB View</span>
  //     //     </div>
  //     //   ),
  //     //   cell: (info: any) => (
  //     //     <div className="text-start">
  //     //       <span className="w-full">
  //     //         <div className="relative inline-block w-11 h-6">
  //     //           <input
  //     //             type="checkbox"
  //     //             id="isVisibleOnDashboard"
  //     //             className="hidden peer"
  //     //             checked={info.getValue()}
  //     //           />
  //     //           <label
  //     //             htmlFor="strategicView"
  //     //             className="bg-[#DADADA] peer-checked:bg-[#18C55F] rounded-full cursor-pointer w-full h-full block transition-colors duration-300 ease-in-out"
  //     //           ></label>
  //     //           <div className="dot pointer-events-none absolute left-0.5 top-0.5 bg-white w-5 h-5 rounded-full shadow-md transition-transform duration-300 ease-in-out peer-checked:translate-x-full"></div>
  //     //         </div>
  //     //       </span>
  //     //     </div>
  //     //   ),
  //     // }),
  //     columnHelper.display({
  //       id: "Sequence",
  //       header: () => (
  //         <div className="text-center">
  //           <span>Sequence</span>
  //         </div>
  //       ),
  //       cell: (info: any) => (
  //         <div className="text-center">
  //           <span className="w-full">{info.getValue()}</span>
  //         </div>
  //       ),
  //     }),
  //     // columnHelper.accessor("time", {
  //     //   id: "time",
  //     //   header: () => (
  //     //     <div className="text-center">
  //     //       <span>Sequence</span>
  //     //     </div>
  //     //   ),
  //     //   cell: (info) => (
  //     //     <div className="text-center">
  //     //       <span className="w-full">{info.getValue()}</span>
  //     //     </div>
  //     //   ),
  //     // }),
  //     //newly added above
  //     columnHelper.accessor("time", {
  //       id: "time",
  //       header: () => (
  //         <div className="text-center">
  //           <span>Time</span>
  //         </div>
  //       ),
  //       cell: (info) => (
  //         <div className="text-center">
  //           <span className="w-full">{info.getValue()}</span>
  //         </div>
  //       ),
  //     }),
  //     columnHelper.accessor("task", {
  //       id: "task",
  //       header: () => (
  //         <div className="text-start">
  //           <span>Task</span>
  //         </div>
  //       ),
  //       cell: (info) => (
  //         <div className="text-start">
  //           <span className="w-full">{info.getValue()}</span>
  //         </div>
  //       ),
  //     }),

  //     columnHelper.accessor("completedBy", {
  //       id: "initials",
  //       header: () => (
  //         <div className="text-center">
  //           <span>Initials</span>
  //         </div>
  //       ),
  //       cell: (info) => (
  //         <input
  //           key={info.row.original._id}
  //           onFocus={(e) => {
  //             e.preventDefault();
  //             handleEdit(info.row.original._id);
  //           }}
  //           maxLength={2}
  //           // disabled={info.row.original._id !== rowToEdit}
  //           className="text-center"
  //           value={
  //             info.row.original._id !== rowToEdit ? info.getValue() : initials
  //           }
  //           onChange={(e) => {
  //             e.preventDefault();
  //             handleChange(e.target.value);
  //           }}
  //           onBlur={(e) => {
  //             if (
  //               e.target.value.length === 2 &&
  //               info.getValue() !== e.target.value
  //             ) {
  //               mutate({
  //                 checklistId: info.row.original._id,
  //                 initials: e.target.value,
  //               });
  //             }
  //           }}
  //         />
  //       ),
  //     }),

  //     columnHelper.display({
  //       id: "action",
  //       header: () => (
  //         <div className="text-center">
  //           <span>Action</span>
  //         </div>
  //       ),
  //       cell: (info) => (
  //         <div className="text-center flex items-center justify-center gap-2">
  //           <button onClick={() => handleEditChecklist(info.row.original)}>
  //             <BiEdit className="w-6 h-6 text-statusColor" />
  //           </button>
  //           <button>
  //             <RiDeleteBin3Line
  //               onClick={() => deleteItem({ itemId: info.row.original._id })}
  //               className="w-6 h-6 text-dangerAlert"
  //             />
  //           </button>
  //           {/* <Dropdown className="checklistDrop">
  //             <Dropdown.Toggle variant="success" id="dropdown-basic">
  //               <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
  //             </Dropdown.Toggle>

  //             <Dropdown.Menu className="flex items-center flex-col top-[100%] right-[0_!important] left-auto  z-10  p-2 w-full px-1.5 bg-white shadow-customShadow rounded-lg absolute min-w-[137px]">
  //               <div className="flex flex-col items-start">
  //                 <Dropdown.Item
  //                   onClick={() => handleEdit(info.row.original._id)}
  //                   className="flex items-center gap-2.5 text-secondary px-3 py-1.5"
  //                 >
  //                   <BiEdit className="w-4 h-4" />
  //                   Edit
  //                 </Dropdown.Item>
  //                 <Dropdown.Item className="flex items-center gap-2.5 text-secondary px-3 py-1.5">
  //                   <RiDeleteBin3Line className="w-4 h-4" />
  //                   Delete
  //                 </Dropdown.Item>
  //               </div>
  //             </Dropdown.Menu>
  //           </Dropdown> */}
  //         </div>
  //       ),
  //     }),
  //   ],
  //   []
  // );
  // const columns = [
  //   columnHelper.accessor("time", {
  //     id: "time",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Time</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <div className="text-center">
  //         <span className="w-full">{info.getValue()}</span>
  //       </div>
  //     ),
  //   }),
  //   columnHelper.accessor("task", {
  //     id: "task",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Task</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <div className="text-start">
  //         <span className="w-full">{info.getValue()}</span>
  //       </div>
  //     ),
  //   }),

  //   columnHelper.accessor("completedBy", {
  //     id: "initials",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Initials</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <input
  //         key={info.row.original._id}
  //         onFocus={(e) => {
  //           e.preventDefault();
  //           handleEdit(info.row.original._id);
  //         }}
  //         maxLength={2}
  //         // disabled={info.row.original._id !== rowToEdit}
  //         className="text-center "
  //         value={
  //           info.row.original._id !== rowToEdit ? info.getValue() : initials
  //         }
  //         onChange={(e) => {
  //           e.preventDefault();
  //           handleChange(e.target.value);
  //         }}
  //         onBlur={(e) => {
  //           // Only blur if we have 2 characters
  //           if (e.target.value.length < 2) {
  //             e.target.focus();
  //           }
  //         }}
  //       />
  //     ),
  //   }),

  //   columnHelper.display({
  //     id: "action",
  //     header: () => (
  //       <div className="text-center">
  //         <span>Action</span>
  //       </div>
  //     ),
  //     cell: (info) => (
  //       <div className="text-center relative">
  //         {/* <button>
  //           {info.getValue()} <AiOutlineEye className="w-6 h-6 text-secondary" />
  //         </button> */}
  //         <Dropdown className="checklistDrop">
  //           <Dropdown.Toggle variant="success" id="dropdown-basic">
  //             <BsThreeDotsVertical className="w-6 h-6 text-secondary" />
  //           </Dropdown.Toggle>

  //           <Dropdown.Menu className="flex items-center flex-col top-[100%] right-[0_!important] left-auto  z-10  p-2 w-full px-1.5 bg-white shadow-customShadow rounded-lg absolute min-w-[137px]">
  //             <div className="flex flex-col items-start">
  //               <Dropdown.Item
  //                 onClick={() => handleEdit(info.row.original._id)}
  //                 className="flex items-center gap-2.5 text-secondary px-3 py-1.5"
  //               >
  //                 <BiEdit className="w-4 h-4" />
  //                 Edit
  //               </Dropdown.Item>
  //               <Dropdown.Item className="flex items-center gap-2.5 text-secondary px-3 py-1.5">
  //                 <RiDeleteBin3Line className="w-4 h-4" />
  //                 Delete
  //               </Dropdown.Item>
  //             </div>
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </div>
  //     ),
  //   }),
  // ];

  // const table = useReactTable({
  //   data: dailyChecklist || [],
  //   columns,
  //   getCoreRowModel: getCoreRowModel(),
  // });

  const openingTable = useReactTable({
    data: checklist?.Opening || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const morningTable = useReactTable({
    data: checklist?.Morning || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const afternoonTable = useReactTable({
    data: checklist?.Afternoon || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  const closingTable = useReactTable({
    data: checklist?.Closing || [],
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  return (
    <table className="w-full">
      <thead>
        {openingTable.getHeaderGroups().map((headerGroup) => (
          <tr key={headerGroup.id}>
            {headerGroup.headers.map((header) => (
              <th
                key={header.id}
                className="p-3 text-base text-secondaryVariant"
              >
                {flexRender(
                  header.column.columnDef.header,
                  header.getContext()
                )}
              </th>
            ))}
          </tr>
        ))}
      </thead>
      <tbody className="bg-white divide-y divide-secondaryNeutral">
        {/* Opening Section */}
        {checklist?.Opening && checklist.Opening.length > 0 && (
          <>
            <tr className="bg-primaryVariant2 text-secondary text-lg font-semibold h-[31px]">
              <td
                colSpan={openingTable.getHeaderGroups()[0]?.headers.length}
                className="px-4"
              >
                CENTER CENTER OPENING
              </td>
            </tr>
            {openingTable.getRowModel().rows.map((row) => (
              <tr key={row.id} className="even:bg-secondaryNeutral">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="p-3 text-base text-secondaryVariant border-b border-secondaryNeutral"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}

        {/* Morning Section */}
        {checklist?.Morning && checklist.Morning.length > 0 && (
          <>
            <tr className="bg-primaryVariant2 text-secondary text-lg font-semibold h-[31px]">
              <td
                colSpan={morningTable.getHeaderGroups()[0]?.headers.length}
                className="px-3"
              >
                MORNING (OPEN TO 11:59 AM)
              </td>
            </tr>
            {morningTable.getRowModel().rows.map((row) => (
              <tr key={row.id} className="even:bg-secondaryNeutral">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="p-3 text-base text-secondaryVariant border-b border-secondaryNeutral"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}

        {/* Afternoon Section */}
        {checklist?.Afternoon && checklist.Afternoon.length > 0 && (
          <>
            <tr className="bg-primaryVariant2 text-secondary text-lg font-semibold h-[31px]">
              <td
                colSpan={afternoonTable.getHeaderGroups()[0]?.headers.length}
                className="px-3"
              >
                AFTERNOON (12 PM TO CLOSE)
              </td>
            </tr>
            {afternoonTable.getRowModel().rows.map((row) => (
              <tr key={row.id} className="even:bg-secondaryNeutral">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="p-3 text-base text-secondaryVariant border-b border-secondaryNeutral"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}

        {/* Closing Section */}
        {checklist?.Closing && checklist.Closing.length > 0 && (
          <>
            <tr className="bg-primaryVariant2 text-secondary text-lg font-semibold h-[31px]">
              <td
                colSpan={closingTable.getHeaderGroups()[0]?.headers.length}
                className="px-3"
              >
                CENTER CLOSING
              </td>
            </tr>
            {closingTable.getRowModel().rows.map((row) => (
              <tr key={row.id} className="even:bg-secondaryNeutral">
                {row.getVisibleCells().map((cell) => (
                  <td
                    key={cell.id}
                    className="p-3 text-base text-secondaryVariant border-b border-secondaryNeutral"
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                ))}
              </tr>
            ))}
          </>
        )}
      </tbody>
    </table>
  );

  // return (
  //   <div className="scrollHide   overflow-y-auto h-[calc(100vh-480px)]">
  //     <table className="w-full bg-white rounded-t-lg checklistTable">
  //       <thead className="sticky top-0 bg-white">
  //         {table.getHeaderGroups().map((headerGroup) => (
  //           <tr key={headerGroup.id}>
  //             {headerGroup.headers.map((header) => (
  //               <th
  //                 key={header.id}
  //                 className="p-5 text-left text-base font-medium text-secondary capitalize"
  //               >
  //                 {header.isPlaceholder
  //                   ? null
  //                   : flexRender(
  //                       header.column.columnDef.header,
  //                       header.getContext()
  //                     )}
  //               </th>
  //             ))}
  //           </tr>
  //         ))}
  //       </thead>
  //       <tbody className="bg-white divide-y divide-secondaryNeutral">
  //         {/* Adding a row for Morning, Evening, etc. */}
  //         <tr className="bg-[#FFAE00] text-white text-base text-center h-[31px]">
  //           <td
  //             colSpan={table.getHeaderGroups()[0]?.headers.length}
  //             className="px-3"
  //           >
  //             Morning
  //           </td>
  //         </tr>
  //         {table.getRowModel().rows.map((row) => (
  //           <tr key={row.id} className="even:bg-secondaryNeutral">
  //             {row.getVisibleCells().map((cell) => (
  //               <td
  //                 key={cell.id}
  //                 className="p-3 text-base text-secondaryVariant border-b border-secondaryNeutral"
  //               >
  //                 {flexRender(cell.column.columnDef.cell, cell.getContext())}
  //               </td>
  //             ))}
  //           </tr>
  //         ))}
  //       </tbody>
  //     </table>
  //   </div>
  // );
};

export default DailyChecklistTable;
