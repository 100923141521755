import { FC, useEffect, useState } from "react";
import { RiDeleteBin3Line } from "react-icons/ri";
import InputField from "../inputsFields/InputField";
import CustomCheckbox from "../customCheckbox/CustomCheckbox";
import {
  IClassroomSetup,
  IProgramClassrooms,
} from "../../types/IClassroomSetup.interface";
import { classroomSvc } from "../../services/classroom.service";

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  baProgramInfo: any;
  summerProgramInfo: any;
  allowedNumberOfClassroomsToRemove: number;
  handleArchiveSpecialClassroom: (
    classrooms: IProgramClassrooms[],
    checkArray: boolean[]
  ) => void;
  classroomsToDelete: string;
}

const SureToDeactivateModal: FC<ModalProps> = ({
  isOpen,
  onClose,
  baProgramInfo,
  summerProgramInfo,
  allowedNumberOfClassroomsToRemove,
  handleArchiveSpecialClassroom,
  classroomsToDelete,
}) => {
  const [classrooms, setClassrooms] = useState([]);
  const [checkArray, setCheckArray] = useState<boolean[]>([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const handleMapCheck = () => {
    const tempArray = classrooms.map(() => false); // Initialize all checkboxes as unchecked
    setCheckArray(tempArray);
  };

  const handleCheckBox = (index: number, check: boolean) => {
    const checkedCount = checkArray.filter((checked) => checked).length;

    if (check && checkedCount >= allowedNumberOfClassroomsToRemove) {
      return;
    }

    const tempArray = [...checkArray];
    tempArray[index] = check;
    setCheckArray(tempArray);
  };

  useEffect(() => {
    const checkedCount = checkArray.filter((checked) => checked).length;
    if (checkedCount < allowedNumberOfClassroomsToRemove) {
      setIsDisabled(true);
    } else {
      setIsDisabled(false);
    }
  }, [checkArray]);

  const handleRemove = () => {
    handleArchiveSpecialClassroom(classrooms, checkArray);
  };

  const handleClose = () => {
    onClose();
    handleMapCheck();
  };

  useEffect(() => {
    handleMapCheck();
    if (classroomsToDelete === "ba") setClassrooms(baProgramInfo?.classrooms);
    else if (classroomsToDelete === "summer")
      setClassrooms(summerProgramInfo?.classrooms);
  }, [baProgramInfo, summerProgramInfo, isOpen]);

  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-[9999]">
      <div className="bg-white p-9 rounded-3xl shadow-lg w-[633px]">
        <div className="flex items-center flex-col justify-center gap-9">
          <span className="block">
            {" "}
            <RiDeleteBin3Line className="text-primary w-[88px] h-[88px]" />
          </span>
          <p className="text-2xl text-secondary font-semibold">
            Are you sure you want to Deactivate classroom?
          </p>
          <p className="text-2xl text-secondary">
            Select the below classroom you wanna remove.
          </p>
          <div className="grid grid-cols-3 gap-5">
            {classrooms?.map((classroom: any, index) => (
              <div key={classroom + index}>
                <CustomCheckbox
                  checked={checkArray[index]}
                  text={classroom?.classroomName || `Un-Named`}
                  onChange={(checked) => {
                    handleCheckBox(index, checked);
                  }}
                  isPrimary={true}
                />
              </div>
            ))}
          </div>
          <div className="flex items-center justify-center gap-4">
            <button
              className={`btnPrimary max-w-[270px] gap-2 whitespace-nowrap ${
                isDisabled ? "opacity-50" : ""
              }`}
              onClick={handleRemove}
              disabled={isDisabled}
            >
              Remove, Selected <RiDeleteBin3Line className="w-5 h-5" />
            </button>
            <button
              className="btnSimple max-w-[126px] flex justify-center whitespace-nowrap"
              onClick={handleClose}
            >
              No, Cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SureToDeactivateModal;
