import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import ReactSelect from "../../components/reactSelect/ReactSelect";
import InputField from "../../components/inputsFields/InputField";

const CreateTemplateScreen = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-5 bg-secondaryNeutral min-h-screen">
          <Link
            to={"/"}
            className="text-sm text-secondaryVariant font-medium flex items-center gap-2 mb-6"
          >
            <IoMdArrowBack className="w-5 h-5" />
            <span className="underline">Back to main</span>
          </Link>
          <div className="space-y-6">
            <h2 className="text-xl text-secondary font-semibold">
              Create Template
            </h2>
            <div className="w-full max-w-[280px]">
              <ReactSelect label={"Category"} placeholder={"Parents"} />
            </div>
            <div className="w-full ">
              <InputField label="Name" type={"text"} placeholder={""} />
            </div>
            <div>
              <label
                htmlFor=""
                className="text-base text-secondaryVariant block mb-1"
              >
                Text
              </label>
              <textarea
                name=""
                id=""
                rows={5}
                placeholder="text"
                className="rounded-lg px-3 py-4 w-full border  border-secondaryVariant2"
              ></textarea>
            </div>
            <div className="flex items-center gap-4">
              <button className="btnPrimary max-w-[160px]">
                Save Template
              </button>
              <button className="btnSecondary max-w-[310px]">
                Save Template & Create another
              </button>
              <button className="btnSimple max-w-[120px]">Cancel</button>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default CreateTemplateScreen;
