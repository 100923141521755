import {
  useMutation,
  useQueryClient,
  UseMutationOptions,
} from "@tanstack/react-query";
import { classroomSvc } from "../services/classroom.service";

export const usePostEssentialClassrooms = () => {
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<any | undefined, Error, any> = {
    mutationFn: async (data: any): Promise<any | undefined> => {
      const res = await classroomSvc.createEssentialClassrooms(
        data.payload,
        data.sequential
      );
      if (!res) {
        return undefined;
      }
      return res;
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["classrooms"],
      });
      return res;
    },
  };

  const { mutateAsync } = useMutation(mutationOptions);

  return {
    postEssentialClassroomSetup: mutateAsync,
  };
};

export const usePostClassroomPrograms = () => {
  const queryClient = useQueryClient();

  const mutationOptions: UseMutationOptions<any | undefined, Error, any> = {
    mutationFn: async (payload: any): Promise<any | undefined> => {
      const res = await classroomSvc.createClassroomsPrograms(payload);
      if (!res) {
        return undefined;
      }
      return res;
    },
    onSuccess: (res) => {
      queryClient.invalidateQueries({
        queryKey: ["classrooms"],
      });
      return res;
    },
  };

  const { mutateAsync } = useMutation(mutationOptions);

  return {
    postClassroomsProgram: mutateAsync,
  };
};
