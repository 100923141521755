export const formatTwoDigitNumber = (num: number) => {
    if (!num) return '0'
    if (num > 0 && num < 10) {
        return '0' + num; // Prepend '0' if number is less than 10
    } else {
        return num?.toString(); // Return the number as a string for consistency
    }
}

export const maskPhoneNumber = (number: string) => {
    // Remove any unwanted characters
    const cleanNumber = number?.replace(/[^\d]/g, "");

    // Extract parts of the number
    const areaCode = cleanNumber?.slice(1, 4); // Skip the country code
    const firstPart = cleanNumber?.slice(4, 7);
    const secondPart = cleanNumber?.slice(7);
    if (!areaCode || !firstPart || !secondPart) return number;
    // Format the number
    return `(${areaCode}) ${firstPart}-${secondPart}`;
}