import React from "react";
import { LuClock } from "react-icons/lu";
import { CiCalendar } from "react-icons/ci";
import { ChecklistType } from "../../hr-setup_modules/enums/checklist";

interface IProps {
  handleChecklistSelect: (checklist: ChecklistType) => void;
  selectedChecklist: ChecklistType;
}

const ChecklistCard: React.FC<IProps> = ({
  handleChecklistSelect,
  selectedChecklist,
}) => {
  return (
    <>
      <div className="flex items-center gap-4 my-[30px]">
        <div
          onClick={() => {
            handleChecklistSelect(ChecklistType.Daily);
          }}
          className={`flex items-center gap-2 p-3 rounded-lg max-w-max ${
            selectedChecklist === ChecklistType.Daily
              ? "bg-primary"
              : "bg-white"
          } border ${
            selectedChecklist === ChecklistType.Daily
              ? "border-secondary"
              : "border-white"
          }
          ${
            selectedChecklist === ChecklistType.Daily ? "text-white" : ""
          } hover:bg-primary  hover:border-secondary text-secondary hover:text-white relative`}
        >
          <div>
            <LuClock className="w-6 h-6" />
          </div>
          <h3 className="text-lg font-semibold">Daily Checklist</h3>
        </div>
        <div
          onClick={() => {
            handleChecklistSelect(ChecklistType.Monthly);
          }}
          className={`flex items-center gap-2 p-3 rounded-lg max-w-max ${
            selectedChecklist === ChecklistType.Monthly
              ? "bg-primary"
              : "bg-white"
          } border ${
            selectedChecklist === ChecklistType.Monthly
              ? "border-secondary"
              : "border-white"
          }
          ${
            selectedChecklist === ChecklistType.Monthly ? "text-white" : ""
          } hover:bg-primary  hover:border-secondary text-secondary hover:text-white relative`}
        >
          <div>
            <CiCalendar className="w-6 h-6" />
          </div>
          <h3 className="text-lg font-semibold">Monthly Checklist</h3>
        </div>
      </div>
    </>
  );
};

export default ChecklistCard;
