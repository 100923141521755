import { HoverCard, Text } from "@radix-ui/themes";
import React, { useMemo, useState } from "react";
import { CgChevronRight, CgChevronLeft } from "react-icons/cg";
import {
  FiArrowDown,
  FiArrowDownRight,
  FiArrowUp,
  FiArrowUpRight,
} from "react-icons/fi";
import { IoMdArrowDropdown, IoMdArrowDropup } from "react-icons/io";
import { calculateAge } from "../../../../shared/utils/formatDate";
import {
  Classroom,
  DailyCount,
  Transition,
} from "../../../types/IEnrollmentTrackingInfo";
import { StudentExecutions } from "../../enums/studentExecutions.enum";
import { IEnrollmentSwitchesState } from "../../types/IEnrollmentSwitches";
import StudentExecutionDetailDialog from "../modals/StudentExecutionDetail";

interface CalendarDataProps {
  toggleTable: () => void;
  dailyCounts: DailyCount;
  classroom: Classroom;
  isPartTime?: boolean;
  switches: IEnrollmentSwitchesState;
  daysData: {
    day: string;
    date: string;
    month: string;
    year: number;
    fullDate: Date;
  }[];
  operatingDays: string[];
  isTableOpen?: boolean;
  prevDayFullTimeStudents: string[];
  prevDayParttimeStudents: string[];
}

const CalendarData: React.FC<CalendarDataProps> = ({
  toggleTable,
  dailyCounts,
  classroom,
  isPartTime,
  switches,
  daysData,
  operatingDays,
  isTableOpen = false,
  prevDayFullTimeStudents,
  prevDayParttimeStudents,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [selectedTransition, setSelectedTransition] = useState<Transition>();
  const openModal = () => setModalOpen(true);
  const [selectedTransitonType, setSelectedTransionType] =
    useState<StudentExecutions>();

  const dayObj = useMemo(() => {
    if (!dailyCounts.date) return null;
    const dateObj = new Date(dailyCounts.date);
    return daysData.find(
      (day) =>
        day.fullDate.getUTCDate() === dateObj.getUTCDate() &&
        day.fullDate.getUTCMonth() === dateObj.getUTCMonth() &&
        day.fullDate.getUTCFullYear() === dateObj.getUTCFullYear()
    );
  }, [dailyCounts.date, daysData]);

  const notOperatingDay = useMemo(() => {
    if (!dayObj) return false;

    // Get the weekday in a timezone-independent way
    const weekdayNum = dayObj.fullDate.getUTCDay();
    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];
    const weekdayName = weekdays[weekdayNum];

    return !operatingDays?.includes(weekdayName);
  }, [dayObj, operatingDays]);

  const isToday = (dateString: string) => {
    const transitionDate = new Date(dateString);
    const today = new Date();

    return (
      transitionDate.getUTCFullYear() === today.getUTCFullYear() &&
      transitionDate.getUTCMonth() === today.getUTCMonth() &&
      transitionDate.getUTCDate() === today.getUTCDate()
    );
  };

  return (
    <div className="flex">
      <div
        className={`px-2 pt-2 flex-[0_0_56px] w-[56px] ${
          dailyCounts.date
            ? notOperatingDay
              ? "bg-secondaryVariant2"
              : "bg-[#F1F8FF]"
            : "bg-secondaryVariant2"
        } flex-[0_0_56px] min-h-[66px]`}
      >
        {/* Show only execution icons on non-operating days, full display on operating days */}
        {dailyCounts.date && (
          <div className="flex items-start justify-between">
            <div className="flex flex-col gap-1">
              {/* Filter transitions based on slot */}
              {/* Transitions In */}
              {
                // Filter transitions based on slot and student arrays
                (() => {
                  const filteredTransitions = dailyCounts.transitions.filter(
                    (transition) => {
                      const slot =
                        transition.transitions?.firstTransition?.slot;

                      // Show in full-time section if transitioning TO full-time
                      // Show in part-time section if transitioning TO part-time
                      return isPartTime
                        ? slot && slot !== "Full time" // Show in part-time section if destination is part-time
                        : slot === "Full time"; // Show in full-time section if destination is full-time
                    }
                  );
                  if (switches.movements && filteredTransitions.length > 0) {
                    return (
                      <Text>
                        <HoverCard.Root>
                          <HoverCard.Trigger>
                            <button>
                              <FiArrowDownRight className="w-3 h-3 text-arrowColor" />
                            </button>
                          </HoverCard.Trigger>
                          <HoverCard.Content
                            maxWidth="170px"
                            className="bg-secondaryVariant rounded-lg p-2 shadow-lg z-[99999]"
                          >
                            {filteredTransitions.map((transition) => {
                              const age = calculateAge(
                                transition.dateOfBirth as string
                              );
                              return (
                                <React.Fragment key={transition._id}>
                                  <div className="flex items-start justify-between">
                                    <div className="max-w-[133px]">
                                      <p className="text-sm text-white mb-4">
                                        {`${transition.firstName}, ${transition.lastName}`}
                                        <br />
                                        {`Age: ${age}`}
                                      </p>
                                      <button
                                        onClick={() => {
                                          setSelectedTransition(transition);
                                          setSelectedTransionType(
                                            StudentExecutions.Transition_In
                                          );
                                          openModal();
                                        }}
                                        className="text-sm text-white bg-primary rounded px-2 py-1"
                                      >
                                        Execute
                                      </button>
                                    </div>
                                    <span className="pt-1">
                                      <FiArrowDownRight className="w-3 h-3 text-white" />
                                    </span>
                                  </div>
                                  <hr className="my-3 text-secondaryVariant2" />
                                </React.Fragment>
                              );
                            })}
                          </HoverCard.Content>
                        </HoverCard.Root>
                      </Text>
                    );
                  }
                  return null;
                })()
              }

              {/* Transitions Out */}
              {
                // Filter transitions out based on slot and student arrays
                (() => {
                  const filteredTransitionsOut =
                    dailyCounts.transitionsOut.filter((transition) => {
                      const studentId = transition._id;

                      const isTodayTransition = isToday(
                        transition.transitions.firstTransition.date
                      );

                      if (isPartTime) {
                        return (
                          dailyCounts.partTimeStudents.includes(studentId) ||
                          prevDayParttimeStudents.includes(studentId) ||
                          (isTodayTransition &&
                            transition.transitions.firstTransition.slot !==
                              "Full time")
                        );
                      }

                      return (
                        dailyCounts.fullTimeStudents.includes(studentId) ||
                        prevDayFullTimeStudents.includes(studentId) ||
                        (isTodayTransition &&
                          transition.transitions.firstTransition.slot ===
                            "Full time")
                      );
                    });

                  if (switches.movements && filteredTransitionsOut.length > 0) {
                    return (
                      <Text>
                        <HoverCard.Root>
                          <HoverCard.Trigger>
                            <button>
                              <FiArrowUpRight className="w-3 h-3 text-arrowColor" />
                            </button>
                          </HoverCard.Trigger>
                          <HoverCard.Content
                            maxWidth="170px"
                            className="bg-secondaryVariant rounded-lg p-2 shadow-lg z-[99999]"
                          >
                            {filteredTransitionsOut.map((transition) => {
                              const age = calculateAge(
                                transition.dateOfBirth as string
                              );
                              return (
                                <React.Fragment key={transition._id}>
                                  <div className="flex items-start justify-between">
                                    <div className="max-w-[133px]">
                                      <p className="text-sm text-white mb-4">
                                        {`${transition.firstName}, ${transition.lastName}`}
                                        <br />
                                        {`Age: ${age}`}
                                      </p>
                                      <button
                                        onClick={() => {
                                          setSelectedTransition(transition);
                                          setSelectedTransionType(
                                            StudentExecutions.Transition_Out
                                          );
                                          openModal();
                                        }}
                                        className="text-sm text-white bg-primary rounded px-2 py-1"
                                      >
                                        Execute
                                      </button>
                                    </div>
                                    <span className="pt-1">
                                      <FiArrowUpRight className="w-3 h-3 text-white" />
                                    </span>
                                  </div>
                                  <hr className="my-3 text-secondaryVariant2" />
                                </React.Fragment>
                              );
                            })}
                          </HoverCard.Content>
                        </HoverCard.Root>
                      </Text>
                    );
                  }
                  return null;
                })()
              }

              {/* Starts */}
              {
                // Filter starts based on slot
                (() => {
                  // Keep the existing logic for starts since they're new students
                  // and won't be in the student arrays yet
                  const filteredStarts = dailyCounts.starts.filter((start) => {
                    const slot = start.transitions?.firstTransition?.slot;
                    return isPartTime
                      ? slot && slot !== "Full time"
                      : slot === "Full time";
                  });

                  if (switches.movements && filteredStarts.length > 0) {
                    return (
                      <Text>
                        <HoverCard.Root>
                          <HoverCard.Trigger>
                            <button>
                              <FiArrowDown className="w-3 h-3 text-arrowColor" />
                            </button>
                          </HoverCard.Trigger>
                          <HoverCard.Content
                            maxWidth="170px"
                            className="bg-secondaryVariant rounded-lg p-2 shadow-lg z-[99999]"
                          >
                            {filteredStarts.map((start) => {
                              const age = calculateAge(
                                start.dateOfBirth as string
                              );
                              return (
                                <React.Fragment key={start._id}>
                                  <div className="flex items-start justify-between">
                                    <div className="max-w-[133px]">
                                      <p className="text-sm text-white mb-4">
                                        {`${start.firstName}, ${start.lastName}`}
                                        <br />
                                        {`Age: ${age}`}
                                      </p>
                                      <button
                                        onClick={() => {
                                          setSelectedTransition(start);
                                          setSelectedTransionType(
                                            StudentExecutions.Start
                                          );
                                          openModal();
                                        }}
                                        className="text-sm text-white bg-primary rounded px-2 py-1"
                                      >
                                        Execute
                                      </button>
                                    </div>
                                    <span className="pt-1">
                                      <FiArrowDown className="w-3 h-3 text-white" />
                                    </span>
                                  </div>
                                  <hr className="my-3 text-secondaryVariant2" />
                                </React.Fragment>
                              );
                            })}
                          </HoverCard.Content>
                        </HoverCard.Root>
                      </Text>
                    );
                  }
                  return null;
                })()
              }

              {/* Withdrawals */}
              {
                // Filter withdrawals based on slot and student arrays
                (() => {
                  const filteredWithdrawals = dailyCounts.withDrawals.filter(
                    (withdraw) => {
                      const studentId = withdraw._id;

                      // Show in full-time section if student is in full-time list
                      // Show in part-time section if student is in part-time list
                      return isPartTime
                        ? dailyCounts.partTimeStudents.includes(studentId) // Show in part-time if student is in part-time list
                        : dailyCounts.fullTimeStudents.includes(studentId); // Show in full-time if student is in full-time list
                    }
                  );

                  if (switches.movements && filteredWithdrawals.length > 0) {
                    return (
                      <Text>
                        <HoverCard.Root>
                          <HoverCard.Trigger>
                            <button>
                              <FiArrowUp className="w-3 h-3 text-arrowColor" />
                            </button>
                          </HoverCard.Trigger>
                          <HoverCard.Content
                            maxWidth="170px"
                            className="bg-secondaryVariant rounded-lg p-2 shadow-lg z-[99999]"
                          >
                            {filteredWithdrawals.map((withdraw) => {
                              const age = calculateAge(
                                withdraw.dateOfBirth as string
                              );
                              return (
                                <React.Fragment key={withdraw._id}>
                                  <div className="flex items-start justify-between">
                                    <div className="max-w-[133px]">
                                      <p className="text-sm text-white mb-4">
                                        {`${withdraw.firstName}, ${withdraw.lastName}`}
                                        <br />
                                        {`Age: ${age}`}
                                      </p>
                                      <button
                                        onClick={() => {
                                          setSelectedTransition(withdraw);
                                          setSelectedTransionType(
                                            StudentExecutions.Withdraw
                                          );
                                          openModal();
                                        }}
                                        className="text-sm text-white bg-primary rounded px-2 py-1"
                                      >
                                        Execute
                                      </button>
                                    </div>
                                    <span className="pt-1">
                                      <FiArrowUp className="w-3 h-3 text-white" />
                                    </span>
                                  </div>
                                  <hr className="my-3 text-secondaryVariant2" />
                                </React.Fragment>
                              );
                            })}
                          </HoverCard.Content>
                        </HoverCard.Root>
                      </Text>
                    );
                  }
                  return null;
                })()
              }
            </div>
            {/* Show enrollment numbers and other info only on operating days */}
            {!notOperatingDay && (
              <div className="flex flex-col items-end gap-1 ml-auto">
                {isPartTime ? (
                  <div
                    className={` ${
                      dailyCounts.partTimeStudentsCount >
                      classroom.licenseCapacity
                        ? "bg-overBooked"
                        : dailyCounts.partTimeStudentsCount ===
                          classroom.licenseCapacity
                        ? "bg-white border-[0.75px] border-secondaryVariant2"
                        : "bg-[#369EFF]"
                    } text-base ${
                      dailyCounts.partTimeStudentsCount ===
                      classroom.licenseCapacity
                        ? "text-black"
                        : "text-white"
                    } font-semibold inline-flex items-center text-xs justify-center h-[17px] min-w-[30px]  px-1.5 rounded rounded-l-full`}
                  >
                    {switches.enrollmentNo
                      ? dailyCounts.partTimeStudentsCount
                      : ""}
                  </div>
                ) : (
                  <div
                    className={` ${
                      dailyCounts.fullTimeStudentsCount >
                      classroom.licenseCapacity
                        ? "bg-overBooked"
                        : dailyCounts.fullTimeStudentsCount ===
                          classroom.licenseCapacity
                        ? "bg-white border-[0.75px] border-secondaryVariant2"
                        : "bg-[#369EFF]"
                    } text-base ${
                      dailyCounts.fullTimeStudentsCount ===
                      classroom.licenseCapacity
                        ? "text-black"
                        : "text-white"
                    } font-semibold inline-flex items-center justify-center  h-[17px] min-w-[30px] px-1.5 text-xs rounded rounded-l-full`}
                  >
                    {switches.enrollmentNo
                      ? dailyCounts.fullTimeStudentsCount
                      : ""}
                  </div>
                )}
                {/* {!isPartTime &&
                  switches.ageViolation &&
                  dailyCounts.countStudentsOutsideMaximumRange >
                    classroom.countAllowedOutsideMaximumRange &&
                  dailyCounts.tempAbsentStudentsCount === 0 && (
                    <div className="bg-ageViolation text-xs text-secondary font-semibold min-w-[30px] py-1 h-[17px] px-1.5 rounded rounded-l-full flex items-center">
                      <CgChevronRight className="w-3 h-3" />
                      {dailyCounts.countStudentsOutsideMaximumRange}
                    </div>
                  )} */}
                {!isPartTime &&
                  switches.ageViolation &&
                  dailyCounts.tempAbsentStudentsCount === 0 &&
                  (dailyCounts.countStudentsOutsideMinimumRange.length >
                    classroom.countAllowedOutsideMinimumRange ||
                    dailyCounts.countStudentsOutsideMaximumRange.length >
                      classroom.countAllowedOutsideMaximumRange) && (
                    <div className="bg-ageViolation text-xs text-secondary font-semibold min-w-[30px] py-1 h-[17px] px-1.5 rounded flex items-center gap-0.3">
                      {dailyCounts.countStudentsOutsideMinimumRange.length >
                        classroom.countAllowedOutsideMinimumRange &&
                      dailyCounts.countStudentsOutsideMaximumRange.length >
                        classroom.countAllowedOutsideMaximumRange ? (
                        // Both violations present - show in "min< >max" format
                        <>
                          {dailyCounts.countStudentsOutsideMinimumRange.length}
                          <CgChevronLeft className="w-3 h-3" />
                          <CgChevronRight className="w-3 h-3" />
                          {dailyCounts.countStudentsOutsideMaximumRange.length}
                        </>
                      ) : dailyCounts.countStudentsOutsideMinimumRange.length >
                        0 ? (
                        // Only minimum age violation
                        <>
                          <CgChevronLeft className="w-3 h-3" />
                          {dailyCounts.countStudentsOutsideMinimumRange.length}
                        </>
                      ) : (
                        // Only maximum age violation
                        <>
                          <CgChevronRight className="w-3 h-3" />
                          {dailyCounts.countStudentsOutsideMaximumRange.length}
                        </>
                      )}
                    </div>
                  )}

                {/* show this div when show the temporary absence */}
                {!isPartTime &&
                  switches.ageViolation &&
                  dailyCounts.tempAbsentStudentsCount > 0 && (
                    <div className="bg-[#6C00CA] text-xs text-white font-semibold min-w-[30px] py-1 h-[17px] pe-3 ps-1.5 rounded rounded-l-full flex items-center whitespace-nowrap">
                      {`TA-${dailyCounts.tempAbsentStudentsCount}`}
                    </div>
                  )}
                {switches.optimization && (
                  <div className="text-end">
                    <button className="inline-block" onClick={toggleTable}>
                      {isTableOpen ? (
                        <IoMdArrowDropup className="text-[#050B33]" size={18} />
                      ) : (
                        <IoMdArrowDropdown
                          className="text-[#D3D3D3]"
                          size={18}
                        />
                      )}
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        )}
      </div>
      {isModalOpen && (
        <StudentExecutionDetailDialog
          isOpen={isModalOpen}
          onClose={() => setModalOpen(false)}
          selectedTransition={selectedTransition}
          selectedTransitionType={selectedTransitonType}
        />
      )}
    </div>
  );
};

export default CalendarData;
