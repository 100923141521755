import React, { useEffect, useState } from "react";
import { Buildings, Profile2User, User } from "iconsax-react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import CardStatsSuperAdmin from "./components/CardStatsSuperAdmin";
import TabsFilter from "./components/TabsFilter";
import WeeklyCenterRegisteration from "./components/WeeklyCenterRegisteration";

import { Filters } from "../../types/IDashboard.Interface";
import { useSuperAdminDashboard } from "../../hooks/useSuperAdminDashboard";
import { CardData, WeekData } from "../../types/ICardData.Interface";
import { Tabs } from "../../enums/tabs.enum";
import RegistrationChartMonthly from "./components/RegistrationChartMonthly";
import RegistrationChartYearly from "./components/RegistrationChartYearly";
import DateRangeSelector from "./components/DateRangeSelector";
import { formatDateYearFirst } from "../../../shared/utils/formatDate";
import CustomChart from "./components/CustomDashboardChart";
import RegistrationChartCustom from "./components/CustomDashboardChart";

const cardDataDefault: CardData[] = [
  {
    id: 1,
    title: "All Center Registrations",
    value: 0,
    trend: 0,
    icon: <Buildings size="24" className="text-primary" />,
    iconBgColor: "#FFFAF8",
  },
  {
    id: 2,
    title: "Teacher Registrations",
    value: 0,
    trend: 0,
    icon: <User size="24" className="text-primary" />,
    iconBgColor: "#F8F8FF",
  },
  {
    id: 3,
    title: "Parent Registrations",
    value: 0,
    trend: 0,
    icon: <Profile2User size="24" className="text-primary" />,
    iconBgColor: "#FFF8FA",
  },
];

const AdminDashboard = () => {
  const [activeTab, setActiveTab] = useState<Tabs>(Tabs.WEEKLY);

  const [cardData, setCardData] = useState<CardData[]>(cardDataDefault);

  const [centerWeekData, setCenterWeekData] = useState<WeekData[]>([]);
  const [teacherWeekData, setTeacherWeekData] = useState<WeekData[]>([]);
  const [parentWeekData, setParentWeekData] = useState<WeekData[]>([]);
  const [monthlyToWeekData, setMonthlyToWeekData] = useState<any>([]);
  const [startDate, setStartDate] = useState<any>(
    // formatDateYearFirst(new Date())
    null
  );

  const [endDate, setEndDate] = useState<any>(null);

  const dashboardFilters: Filters = {
    view: activeTab,
    startDate,
    endDate,
  };
  const { data: dashboard }: any = useSuperAdminDashboard(dashboardFilters);

  const updateCardData = (dashboard: any) => {
    setCardData((prevCardData) =>
      prevCardData.map((card) => {
        if (card.id === 1) {
          return {
            ...card,
            value: dashboard?.data?.totalCenters || 0,
            trend: dashboard?.data?.stats?.centerChange || 0,
          };
        }
        if (card.id === 2) {
          return {
            ...card,
            value: dashboard?.data?.totalTeachers || 0,
            trend: dashboard?.data?.stats?.teacherChange || 0,
          };
        }
        if (card.id === 3) {
          return {
            ...card,
            value: dashboard?.data?.totalParents || 0,
            trend: dashboard?.data?.stats?.parentChange || 0,
          };
        }
        return card;
      })
    );
  };

  const transformWeekData = (
    graphData: any,
    type: "centers" | "teachers" | "parents"
  ) => {
    return graphData.map((data: any) => ({
      barValue: data?.dailyRegistrations[type],
      lineValue: data?.totalRegistrationsTillDay[type],
      name: data?.day?.split("-")[2],
    }));
  };
  const [isDialogOpen, setIsDialogOpen] = useState(false);

  useEffect(() => {
    if (dashboard) {
      updateCardData(dashboard);
      if (activeTab === Tabs.WEEKLY) {
        setCenterWeekData(() =>
          transformWeekData(dashboard?.data?.graphData, "centers")
        );
        setTeacherWeekData(() =>
          transformWeekData(dashboard?.data?.graphData, "teachers")
        );
        setParentWeekData(() =>
          transformWeekData(dashboard?.data?.graphData, "parents")
        );
      }
      // else if (activeTab === Tabs.MONTHLY) {
      //   setMonthlyToWeekData(() => getWeeklyData(dashboard?.data?.graphData));
      //   console.log(monthlyToWeekData);
      // }
    }
  }, [dashboard, activeTab]);

  useEffect(() => {
    if (activeTab === Tabs.CUSTOM) {
      setIsDialogOpen(true);
      console.log(startDate, endDate);
    }
  }, [activeTab]);

  const handleTabChange = (tab: Tabs) => {
    console.log(`Tab changed to: ${tab}`);
    if (tab === Tabs.CUSTOM) {
      setIsDialogOpen(true);
    }
  };
  const getTabContent = () => {
    switch (activeTab) {
      case Tabs.WEEKLY:
        return (
          <div className="space-y-8">
            <WeeklyCenterRegisteration
              title="Center Registration"
              containerColor="#1577DA"
              data={centerWeekData}
            />
            <WeeklyCenterRegisteration
              title="Teacher Registration"
              containerColor="#00C79F"
              data={teacherWeekData}
            />
            <WeeklyCenterRegisteration
              title="Parent Registration"
              containerColor="#6436C8"
              data={parentWeekData}
            />
          </div>
        );
      case Tabs.MONTHLY:
        return (
          <div className="space-y-8">
            <RegistrationChartMonthly
              dayData={dashboard?.data?.graphData}
              title="Center Registration"
              barcolor="#1577DA" // Blue for bars
              linecolor="#0A4B96"
            />
            <RegistrationChartMonthly
              dayData={dashboard?.data?.graphData}
              title="Teacher Registration"
              barcolor="#00C79F" // Teal for bars
              linecolor="#00805A" //
            />
            <RegistrationChartMonthly
              dayData={dashboard?.data?.graphData}
              title="Parent Registration"
              barcolor="#6436C8" // Purple for bars
              linecolor="#461C90" // Darker purple for lines
            />
          </div>
        );
      case Tabs.YEARLY:
        return (
          <div className="space-y-8">
            <RegistrationChartYearly
              dayData={dashboard?.data?.graphData}
              title="Center Registration"
              barcolor="#1577DA" // Blue for bars
              linecolor="#0A4B96"
            />
            <RegistrationChartYearly
              dayData={dashboard?.data?.graphData}
              title="Teacher Registration"
              barcolor="#00C79F" // Teal for bars
              linecolor="#00805A" //
            />
            <RegistrationChartYearly
              dayData={dashboard?.data?.graphData}
              title="Parent Registration"
              barcolor="#6436C8" // Purple for bars
              linecolor="#461C90" // Darker purple for lines
            />
          </div>
        );
      case Tabs.CUSTOM:
        return (
          <div className="space-y-8">
            <RegistrationChartCustom
              dayData={dashboard?.data?.graphData}
              title="Center Registration"
              barcolor="#1577DA" // Blue for bars
              linecolor="#0A4B96"
            />
            <RegistrationChartCustom
              dayData={dashboard?.data?.graphData}
              title="Teacher Registration"
              barcolor="#00C79F" // Teal for bars
              linecolor="#00805A" //
            />
            <RegistrationChartCustom
              dayData={dashboard?.data?.graphData}
              title="Parent Registration"
              barcolor="#6436C8" // Purple for bars
              linecolor="#461C90" // Darker purple for lines
            />
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <DashboardWrapper>
      <div className="px-6 pt-5">
        <div className="grid grid-cols-3 gap-3 mb-6">
          {cardData.map((card) => (
            <CardStatsSuperAdmin
              key={card.id}
              title={card.title}
              value={card.value}
              trend={card.trend}
              icon={card.icon}
              iconBgColor={card.iconBgColor}
            />
          ))}
        </div>
        <div className="grid grid-cols-2 gap-8">
          <div>
            <h2 className="font-semibold text-2xl text-secondary">Overview</h2>
          </div>
          <div>
            <TabsFilter
              activeTab={activeTab}
              setActiveTab={setActiveTab}
              handleTabChange={handleTabChange}
            />
          </div>
        </div>
        <div className="mt-6">{getTabContent()}</div>
      </div>

      {isDialogOpen && (
        <DateRangeSelector
          endDate={endDate}
          startDate={startDate}
          setIsDialogOpen={setIsDialogOpen}
          setEndDate={setEndDate}
          setStartDate={setStartDate}
        />
      )}
    </DashboardWrapper>
  );
};

export default AdminDashboard;
