import { formatDateSlashFullYear } from "../../../shared/utils/formatDate";

export const getTransitionObject = (
  transition: any,
  defaultTransition: any
) => {
  if (!transition || !defaultTransition) {
    // Case: First time, no data exists
    return { classroomId: null, slot: "", date: null };
  }

  // Check if all fields are empty (no modifications were made)
  const noModification =
    !transition.classroomId && !transition.slot && !transition.date;

  if (noModification) {
    return { classroomId: null, slot: "", date: null };
  }

  return {
    classroomId: transition.classroomId || defaultTransition.classroomId,
    slot: transition.slot || defaultTransition.slot,
    date: transition.date
      ? formatDateSlashFullYear(transition.date)
      : formatDateSlashFullYear(defaultTransition.date),
  };
};
