import pako from "pako";
import { IEnrollmentTrackingInfo } from "../../types/IEnrollmentTrackingInfo";
import { apiForay } from "../../utils/axios";
import { ICreatePreview } from "../types/ICreatePreview";
import { IStudentInfo } from "../types/IStudentInfo";

// Helper function to handle compressed responses
const handleCompressedResponse = async (response: any) => {
  if (response.headers["content-encoding"] === "gzip") {
    // Handle binary response
    const compressed = new Uint8Array(response.data);
    try {
      const decompressed = pako.inflate(compressed, { to: "string" });
      return JSON.parse(decompressed);
    } catch (error) {
      console.error("Error decompressing response:", error);
      throw error;
    }
  }
  // Return regular response if not compressed
  return response.data;
};

// Modified axios instance to handle compressed responses
const compressedApiForay = {
  ...apiForay,
  get: async (url: string, config?: any) => {
    const response = await apiForay.get(url, {
      ...config,
      responseType: "arraybuffer",
      headers: {
        ...config?.headers,
        "Accept-Encoding": "gzip",
      },
    });
    return {
      ...response,
      data: await handleCompressedResponse(response),
      ok: response.status === 200,
    };
  },
};

// Updated service methods
const fetchYearlyEnrollments = async (
  previewId?: string
): Promise<IEnrollmentTrackingInfo> => {
  try {
    const res = await compressedApiForay.get(
      `/previews/dashboard/yearly${previewId ? "?previewID=" + previewId : ""}`
    );
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch data");
  } catch (err) {
    console.log("error: ", err);
    return {} as IEnrollmentTrackingInfo;
  }
};

const fetchStudents = async (
  studentIds: string[],
  previewId?: string
): Promise<IStudentInfo[]> => {
  try {
    const res: any = await apiForay.get(
      `/previews/students${previewId ? "?previewID=" + previewId : ""}`,
      {
        studentIds: studentIds,
      }
    );
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch data");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const createPreview = async (): Promise<ICreatePreview> => {
  try {
    const res: any = await apiForay.post(`/previews/create`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to create preview");
  } catch (err) {
    console.log("error: ", err);
    return {} as ICreatePreview;
  }
};

const editStudentPreview = async (
  previewId: string,
  studentId: string,
  reqBody: any
): Promise<any> => {
  try {
    const res: any = await apiForay.post(
      `/previews/edit-student/${previewId}/${studentId}`,
      reqBody
    );
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to edit student");
  } catch (err) {
    console.log("error: ", err);
    throw new Error("Something went wrong");
  }
};

const getPreview = async (): Promise<any> => {
  try {
    const res: any = await apiForay.get(`/previews`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to fetch previews");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const saveChanges = async (previewId: string): Promise<any> => {
  try {
    const res: any = await apiForay.post(`/previews/save/${previewId}`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to save changes");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

const discardChanges = async (previewId: string): Promise<any> => {
  try {
    const res: any = await apiForay.delete(`/previews/discard/${previewId}`);
    if (res?.ok) {
      return res.data;
    }
    throw new Error("Failed to discard changes");
  } catch (err) {
    console.log("error: ", err);
    return [];
  }
};

export const EnrollmentTrackingSvc = {
  fetchYearlyEnrollments,
  fetchStudents,
  createPreview,
  editStudentPreview,
  getPreview,
  saveChanges,
  discardChanges,
};
