import React from "react";
import Select, {
  SingleValueProps,
  OptionProps,
  GroupBase,
} from "react-select";

export interface DropdownOption {
  value: string;
  label: string;
  avatar?: string;
}


interface CustomSelectProps {
  options: DropdownOption[];
  placeholder?: string;
  isright?:boolean;
  onChange: (selectedOption: DropdownOption | null) => void;
}

const CustomSingleValue: React.FC<SingleValueProps<DropdownOption, false, GroupBase<DropdownOption>>> = ({
  data,
}) => (
  <div className="flex items-center text-xs font-normal text-secondary">
  
    {data.label}
  </div>
);

const CustomOption: React.FC<OptionProps<DropdownOption, false, GroupBase<DropdownOption>>> = ({
  data,
  innerRef,
  innerProps,
}) => {
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className="flex items-center p-1 hover:bg-gray-200 cursor-pointer"
    >
      {data.avatar && (
        <img
          src={data.avatar}
          alt={data.label}
          className="w-5 h-5 rounded-full mr-2"
        />
      )}
      <span className="text-secondary font-normal">{data.label}</span>
    </div>
  );
};

const CustomSelect: React.FC<CustomSelectProps> = ({
  options,
  placeholder = "Select",
  onChange,
  isright
}) => {
  return (
    <Select
      options={options}
      placeholder={placeholder}
      className="bg-[#F6F9FB] rounded h-7  text-xs"
      formatOptionLabel={(option) => (
        <div className="flex items-center">
        <span className="line-clamp-1 text-xs">  {option.label}</span>
        </div>
      )}
      menuPlacement="auto"
      menuPosition="fixed"
      isSearchable={false}
      classNames={{
        control: () => `bg-[#F6F9FB] rounded h-10 `,
        menu: () => `min-w-[172px] rounded-lg shadow-lg border-none ${isright ? 'right-0 left-auto' : 'right-auto left-0'}`,
      }}
      components={{ SingleValue: CustomSingleValue, Option: CustomOption }}
      onChange={(selected) => onChange(selected as DropdownOption | null)}
      classNamePrefix={'custom-select'}
    />
  );
};

export default CustomSelect;
