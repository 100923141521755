import React from "react";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import CustomTabs from "../../../components/tabsLinks/CustomTabs";
import PlannedPto from "./PlannedPto";
import UnplannedPto from "./UnplannedPto";
import { forayRoutes } from "../../../routes/routes";

const PtoTardySetup = () => {
  const tabsData = [
    {
      eventKey: "planned",
      label: " Planned",
      content: (
        <div>
          <PlannedPto />
        </div>
      ),
    },
    {
      eventKey: "unplanned",
      label: "Unplanned",
      content: (
        <div>
          <UnplannedPto />
        </div>
      ),
    },
  ];
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar space-y-6">
          <Link
            to={forayRoutes.hr_dashboard}
            className="text-sm text-secondaryVariant font-medium flex items-center gap-2"
          >
            <IoMdArrowBack className="w-5 h-5" />
            <span className="underline">Back to main</span>
          </Link>
          <CustomTabs tabs={tabsData} defaultActiveKey="planned" />

          {/* <h2 className="text-xl text-secondary font-semibold">PTO</h2>
          <div className="grid grid-cols-4 gap-3">
            <div>
              <ReactSelect label={"PTO Allotted"} placeholder={"PTO 1"} />
            </div>
            <div>
              <ReactSelect
                label={"Rules"}
                placeholder={"May Use Right Away"}
                required
              />
            </div>
            <div>
              <ReactSelect
                label={"How Earned"}
                placeholder={"Per Hours Worked"}
                required
              />
            </div>
          </div> */}
        </div>{" "}
      </DashboardWrapper>
    </>
  );
};

export default PtoTardySetup;
