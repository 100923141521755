import React from "react";
import { HiOutlinePlusSmall } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";
import { forayRoutes } from "../../routes/routes";

interface AddNewProps {
  imgSrc?: string;
  paragraphText?: string;
  buttonText: string;
  route: string;
  disabled?: boolean
}

const AddNew: React.FC<AddNewProps> = ({
  imgSrc,
  paragraphText,
  buttonText,
  route,
  disabled,
}) => {
  const navigate = useNavigate();
  const handleButtonClick = () => {
    navigate(route);
  };
  return (
    <div className="bg-white p-6 rounded-xl flex justify-start mb-6  border-[1px] border-dashed border-secondaryVariant2">
      {/* <div className="flex justify-center flex-col items-center gap-9 max-w-[600px] text-center"> */}
      {/* <img src={imgSrc} alt="Imag" /> */}
      {/* <p className="text-xl text-secondaryVariant">{paragraphText}</p> */}
      <button
        className={`text-white text-lg font-medium rounded-lg py-2.5 px-4 bg-primary max-w-[306px] ${disabled ? "opacity-50" : ""}`}
        onClick={handleButtonClick}
        disabled={disabled}
      >
        {buttonText}
        {/* <HiOutlinePlusSmall className="w-5 h-5" /> */}
      </button>
      {/* </div> */}
    </div>
  );
};

export default AddNew;
