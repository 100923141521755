import React, { useState } from "react";
import { IoMdArrowDropdown } from "react-icons/io";
import { LuTable2 } from "react-icons/lu";
import { PiPrinterLight } from "react-icons/pi";
import { RxCheckCircled } from "react-icons/rx";
import { VscEdit } from "react-icons/vsc";
import ScheduleCard from "./ScheduleCard";
import ScheduleSelector from "./ScheduleSelector";
import { FaRegCircleCheck } from "react-icons/fa6";
import { IoCloseOutline } from "react-icons/io5";

interface TimeSlot {
  start: string;
  end: string;
  person: string;
  bgColor: string;
  status?: "active" | "inactive";
}

interface ScheduleItem {
  name: string;
  timeSlots: TimeSlot[];
}

interface Category {
  title: string;
  ageGroup: string;
  ratio: number;
  fullTime: number;
  partTime: number;
  pending: number;
  schedules: ScheduleItem[];
  status?: "active" | "inactive";
}
const categories = [
  {
     title: "Category 1", ageGroup: "3-5 Years", ratio: 1.5, fullTime: 10, partTime: 5, pending: 2,
    schedules: [
            {
              name: "Hamid A",
              timeSlots: [
                { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "active" },
                { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#CCD4FF" },
                { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "inactive" },
              ],
            },
            {
              name: "Samar B",
              timeSlots: [
                { start: "6 AM", end: "7 PM", person: "Samar", bgColor: "#FAF5D1" },
                { start: "7 AM", end: "7 AM", person: "Bilal", bgColor: "#D1F3FA", status: "active" },
              ],
            },
          ],
    },
    {
      title: "Category 2", ageGroup: "3-5 Years", ratio: 1.5, fullTime: 10, partTime: 5, pending: 2,
     schedules: [
             {
               name: "Hamid A",
               timeSlots: [
                 { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "active" },
                 { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#CCD4FF" },
                 { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "inactive" },
               ],
             },
             {
               name: "Samar B",
               timeSlots: [
                 { start: "6 AM", end: "7 PM", person: "Samar", bgColor: "#FAF5D1" },
                 { start: "7 AM", end: "7 AM", person: "Bilal", bgColor: "#D1F3FA", status: "active" },
               ],
             },
           ],
     },
     {
      title: "Category 3", ageGroup: "3-5 Years", ratio: 1.5, fullTime: 10, partTime: 5, pending: 2,
     schedules: [
             {
               name: "Hamid A",
               timeSlots: [
                 { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "active" },
                 { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#CCD4FF" },
                 { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "inactive" },
               ],
             },
             {
               name: "Samar B",
               timeSlots: [
                 { start: "6 AM", end: "7 PM", person: "Samar", bgColor: "#FAF5D1" },
                 { start: "7 AM", end: "7 AM", person: "Bilal", bgColor: "#D1F3FA", status: "active" },
               ],
             },
           ],
     },
  { title: "Breaker", ageGroup: "", ratio: 0, fullTime: 0, partTime: 0, pending: 0,     schedules: [
    {
      name: "Hamid A",
      timeSlots: [
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "active" },
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#CCD4FF" },
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "inactive" },
      ],
    },
  ], },
  { title: "Admin", ageGroup: "", ratio: 0, fullTime: 0, partTime: 0, pending: 0,  schedules: [
    {
      name: "Hamid A",
      timeSlots: [
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "active" },
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#CCD4FF" },
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "inactive" },
      ],
    },
  ] },
  { title: "Other", ageGroup: "", ratio: 0, fullTime: 0, partTime: 0, pending: 0,  schedules: [
    {
      name: "Hamid A",
      timeSlots: [
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "active" },
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#CCD4FF" },
        { start: "9 AM", end: "3 PM", person: "Hamid", bgColor: "#F7DCD4", status: "inactive" },
      ],
    },
  ]},
];




const TodaySchedule: React.FC = () => {
  const [activeDay, setActiveDay] = useState<string | null>(null);
  const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"];
  const [isModifyMode, setIsModifyMode] = useState(false);
  const [isModifyIdealMode, setIsModifyIdealMode] = useState(false);
  
  const toggleModify = () => {
    setIsModifyMode((prev) => !prev);
  };

  const toggleIdeal = () => {
    setIsModifyIdealMode((prev) => !prev);
  };
  return (
    <div className="border-[0.5px] border-secondaryVariant2 rounded-xl">
  <ScheduleSelector  toggleModify={toggleModify} toggleIdeal={toggleIdeal} isModifyMode={isModifyMode} isModifyIdealMode={isModifyIdealMode}/>

      <div className="bg-white rounded-xl space-y-3 px-3 pt-3">
        <div className="grid grid-cols-3">
          <div className="col-span-2">
            <ul className="flex items-center gap-2">
              {weekdays.map((day, index) => (
                <li key={index}>
                  <button
                    className={`inline-flex items-center py-2 px-2 rounded-lg font-medium ${
                      activeDay === day ? "bg-primary text-white" : "bg-secondaryNeutral text-secondaryVariant2"
                    }`}
                    onClick={() => setActiveDay(day)}
                  >
                    {day}
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex items-center justify-end gap-3">
            <button className="inline-flex items-center py-2 px-2 gap-2 rounded-lg bg-secondaryNeutral font-medium text-secondaryVariant">
              <PiPrinterLight size={20} /> Print
            </button>
            <button className="inline-flex items-center py-2 px-2 gap-2 rounded-lg bg-secondaryNeutral font-medium text-secondaryVariant">
              <LuTable2 size={20} /> Tabular View
            </button>
          </div>
        </div>

        <div className="p-3 bg-primaryVariant2 text-base text-secondary font-semibold flex items-center">
          <div>Today Activities</div>
          <div className="text-center flex-1">
            <span className="font-normal">Center Break Time:</span> 10AM to 2PM
          </div>
        </div>

        <div>
        <div className="flex items-center bg-secondaryNeutral">
            <div className="py-4 px-3 flex-[0_0_174px]">Classroom</div>
            <div className={ `py-4 pr-3  ${isModifyMode ? 'flex-[0_0_150px]' : 'flex-[0_0_132px]'}`}>Assign</div>
            <div className={ `py-4 pr-3  ${isModifyMode ? 'flex-[0_0_110px]' : 'flex-[0_0_84px]'}`}>Subs</div>
            {isModifyMode ? 
             <div className="py-4 px-2 flex-[0_0_96px]">Action</div> : null
          }
            <div className="py-4 px-3 flex-1">Scheduled</div>
          </div>

         

          <div className="grid gap-1 py-3">
          {categories.map((category, index) => (
        <ScheduleCard key={index} category={category} isModifyMode={isModifyMode}/>
      ))}
      {isModifyMode ?   
       <div className="flex gap-[10px] mt-4">
          <button className="bg-primary py-3 px-4 rounded-lg text-white gap-2 inline-flex items-center"><FaRegCircleCheck className="w-5 h-5"/>Save</button>
          <button className="bg-white border border-secondary py-3 px-4 rounded-lg gap-2 text-secondary inline-flex items-center"><IoCloseOutline className="w-5 h-5"/>Cancel</button>
        </div> : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default TodaySchedule;
