import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import { forayRoutes } from "../../routes/routes";
import ReactSelect from "../../components/reactSelect/ReactSelect";
import { TbFileText } from "react-icons/tb";
import { BsSend } from "react-icons/bs";
import { VscListFilter } from "react-icons/vsc";
import CommunicationTable from "./CommunicationTable";

const Communication = () => {
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Communication", isCurrent: true },
  ];
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-5 bg-secondaryNeutral min-h-screen">
          <div className="mb-9">
            <Breadcrumb items={breadcrumbItems} />
          </div>
          <div className="space-y-6 mb-9">
            <h2 className="text-2xl font-semibold text-secondary">
              You may send emails or texts from here.{" "}
            </h2>
            <div className="grid grid-cols-4 gap-6">
              <div className="w-full">
                <ReactSelect label={"Send By"} placeholder={"Email"} />
              </div>
              <div className="w-full">
                <ReactSelect label={"Send to"} placeholder={"Parents"} />
              </div>
              <div className="w-full">
                <ReactSelect
                  label={"Select"}
                  placeholder={"Parents By Classroom"}
                />
              </div>
              <div className="w-full">
                <ReactSelect
                  label={"Select Classroom"}
                  placeholder={"Infant 0-6 months"}
                />
              </div>
              <div className="w-full">
                <ReactSelect
                  label={"Select Transition"}
                  placeholder={"Student Transition"}
                />
              </div>
              <div className="w-full">
                <ReactSelect label={"Frequency"} placeholder={"Recurring"} />
              </div>
              <div className="w-full">
                <ReactSelect label={"Auto send"} placeholder={"Monthly"} />
              </div>
              <div className="w-full">
                <ReactSelect label={"Select Month"} placeholder={"January"} />
              </div>
              <div className="w-full">
                <ReactSelect label={"Select Dates"} placeholder={"Dates"} />
              </div>
              <div className="w-full">
                <ReactSelect
                  label={"Send from Email"}
                  placeholder={"youremail@xyz.com"}
                />
              </div>
            </div>
            <div>
              <label
                htmlFor=""
                className="text-base text-secondaryVariant block mb-1"
              >
                Text
              </label>
              <textarea
                name=""
                id=""
                rows={6}
                placeholder=""
                className="rounded-lg px-3 py-4 w-full border  border-secondaryVariant2"
              ></textarea>
            </div>
            <div className="space-y-9">
              <label className="border border-dashed border-primary bg-white rounded-lg p-5 cursor-pointer w-full flex items-center justify-between">
                <div className="flex items-center gap-4">
                  <span className="bg-primary p-3 rounded-lg text-white">
                    <TbFileText className="w-5 h-5" />
                  </span>
                  <p>
                    <span className="text-base text-secondary block">
                      Drag and Drop your <strong>Attachment</strong> here or
                      click
                      <strong>“Upload”</strong> to select a file
                    </span>
                    <span className="text-xs text-secondaryVariant2 block">
                      Supported formats : docx, pdf, Jpg, Png
                    </span>
                  </p>
                </div>
                <button className="text-base text-secondary font-medium bg-secondaryNeutral border border-secondary rounded-md py-1 px-4">
                  Upload
                </button>
                <input type="file" accept="image/*" className="hidden" />
              </label>
              <button className="btnPrimary max-w-[101px] gap-2">
                <BsSend className="w-6 h-6" /> Send
              </button>
            </div>
          </div>
          <div className="flex justify-between items-center mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              Recurring Communication
            </h2>
            <button className="text-base text-secondary flex items-center gap-2">
              <VscListFilter className="w-6 h-6" /> Filter
            </button>
          </div>
          <CommunicationTable />
        </div>
      </DashboardWrapper>
    </>
  );
};

export default Communication;
