// export const calculateYearsMonthsDays = (days: number) => {
//   const years = Math.floor(days / 365);
//   const remainingDaysAfterYears = days % 365;
//   const months = Math.floor(remainingDaysAfterYears / 30.5);
//   const daysRemaining = Math.floor(remainingDaysAfterYears % 30.5);
//   return { years, months, days: daysRemaining };
// };
const monthLengths = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]; // Standard non-leap year

export const calculateYearsMonthsDays = (totalDays: number) => {
  let years = Math.floor(totalDays / 365);
  let remainingDays = totalDays % 365;
  
  let months = 0;
  while (remainingDays >= monthLengths[months % 12]) {
    remainingDays -= monthLengths[months % 12];
    months++;
  }

  return { years, months, days: remainingDays };
};