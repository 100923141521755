import moment from "moment";

export const formatDateSlash = (date: string | Date) => {
  return moment(date).format("MM/DD/YY");
};
// export const formatDateSlashFullYear = (date: string | Date) => {
//   if (!date) return undefined;

//   // Handle UTC dates from backend
//   if (typeof date === "string" && date.endsWith("Z")) {
//     return moment.utc(date).local().format("MM/DD/YYYY");
//   }

//   return moment(date).format("MM/DD/YYYY");
// };
export const formatDateSlashFullYear = (date: string | Date) => {
  if (!date) return undefined;

  // Handle UTC dates from backend
  if (typeof date === "string" && date.endsWith("Z")) {
    // Create a moment object from the UTC date
    const utcMoment = moment.utc(date);

    // Convert to local time, but preserve the original date
    const localMoment = moment(utcMoment.format("YYYY-MM-DD"));

    return localMoment.format("MM/DD/YYYY");
  }

  return moment(date).format("MM/DD/YYYY");
};

export const calculateAge = (birthDate: string) => {
  if (!birthDate) return "Invalid Date";

  // Handle UTC dates from backend
  const start = birthDate.endsWith("Z")
    ? moment.utc(birthDate).local()
    : moment(birthDate);
  const end = moment(); // Current local date

  const years = end.diff(start, "years");
  start.add(years, "years");

  const months = end.diff(start, "months");
  start.add(months, "months");

  const days = end.diff(start, "days");

  return `${years}Y ${months}M ${days}D`;
};

export const calculateAgeYM = (
  dateOfBirth: string,
  referenceDate?: string | Date
): { years: number; months: number; days: number } => {
  const dob = new Date(dateOfBirth);
  const today = referenceDate ? new Date(referenceDate) : new Date();

  let years = today.getFullYear() - dob.getFullYear();
  let months = today.getMonth() - dob.getMonth();
  let days = today.getDate() - dob.getDate();

  // Adjust for when the current month is before the birth month
  if (months < 0) {
    years--;
    months += 12; // Correct the month count
  }

  // Adjust for when the current day is before the birth day
  if (today.getDate() < dob.getDate()) {
    months--; // Reduce the month count
    if (months < 0) {
      years--; // If months goes below 0, decrement years
      months += 12; // Correct the month count
    }
    // Get days in the previous month
    const lastMonth = new Date(today.getFullYear(), today.getMonth(), 0);
    days += lastMonth.getDate();
  }

  return { years, months, days };
};

export const formatDateHyphenFullYear = (date: string | Date) => {
  if (!date) return "";

  // If it's a string and ends with 'Z', it's a UTC date from the backend
  if (typeof date === "string" && date.endsWith("Z")) {
    // Create a moment object from the UTC date
    const utcMoment = moment.utc(date);

    // Convert to local time, preserving the original date
    const localMoment = moment(utcMoment.format("YYYY-MM-DD"));

    return localMoment.format("MM-DD-YYYY");
  }

  return moment(date).format("MM-DD-YYYY");
};

export const formatDateSpace = (date: string | Date) => {
  if (!date) return "";

  // Check if it's a UTC date string
  if (typeof date === "string" && date.endsWith("Z")) {
    // Create a moment object from the UTC date
    const utcMoment = moment.utc(date);

    // Convert to local time, preserving the original date
    const localMoment = moment(utcMoment.format("YYYY-MM-DD"));

    return localMoment.format("DD MMMM YYYY") || "";
  }

  return moment(date).format("DD MMMM YYYY") || "";
};

export const formatDateYearFirst = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("YYYY-MM-DD") || "";
};
export const formatDateTimeFull = (date: string | Date) => {
  return moment(date).format("MMMM, Do YYYY. hh:mma");
};
export const formatDateFull = (date: string | Date) => {
  return moment(date).format("MMMM, Do YYYY");
};
export const formatDateAndTime = (date: string | Date) => {
  if (!date) return { date: "", timeSlot: "" };
  const parsedDate = moment(date, "ddd MMM DD YYYY/h:mma");
  return {
    date: parsedDate.format("YYYY-MM-DD"),
    timeSlot: parsedDate.format("hh:mma").toUpperCase(),
  };
};

export const formatDateAmerican = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("MMMM DD, YYYY") || "";
};
export const formatMonthDateSlash = (date: string | Date) => {
  if (!date) return "";
  if (typeof date === "string" && date.endsWith("Z")) {
    // Create a moment object from the UTC date
    const utcMoment = moment.utc(date);

    // Convert to local time, but preserve the original date
    const localMoment = moment(utcMoment.format("YYYY-MM-DD"));
    return localMoment.format("MM/DD") || "";
  }
  return moment(date).format("MM/DD") || "";
};

export const formatFullYearOnly = (date: string | Date) => {
  if (!date) return "";
  return moment(date).format("YYYY") || "";
};

export const revertToDateType = (date: string) => {
  if (!date) return null;

  // For backend dates in ISO format with Z (UTC timezone marker)
  if (typeof date === "string" && date.endsWith("Z")) {
    // Create a moment object from the UTC date
    const utcMoment = moment.utc(date);

    // Convert to a local date without time shift
    const localMoment = moment(utcMoment.format("YYYY-MM-DD"));

    return localMoment.toDate();
  }

  // For all other dates, use standard parsing
  return moment(date).toDate();
};
// Function to standardize any date to midnight UTC format (T00:00:00.000Z)
export const standardizeDateToMidnightUTC = (
  date: string | Date | null
): string => {
  if (!date) return "";

  // Convert to YYYY-MM-DD format and append T00:00:00.000Z
  return moment(date).format("YYYY-MM-DD") + "T00:00:00.000Z";
};
