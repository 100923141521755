import React, { useMemo, useState } from "react";
import {
    useReactTable,
    getCoreRowModel,
    getSortedRowModel,
    ColumnDef,
    SortingState,
    flexRender,
} from "@tanstack/react-table";
import { Link } from "react-router-dom";
import { IoCloseOutline } from "react-icons/io5";
import { Dropdown } from "react-bootstrap";
import { RiArrowUpDownLine } from "react-icons/ri";
import { PiPrinterLight } from "react-icons/pi";

type Data = {
    classroom: string;
    enrolled?: number;
    present?: number;
    assignedTeacher?: string;
    assigned?: string;
    startTime: string;
    endTime: string;
    breaker?: string;
    admin?: string;
};

const breakerData: Data[] = [
    { classroom: "Infant 0-6 months", assigned: "Hamid Ali", startTime: "07 AM", endTime: "03 PM", breaker: "Umer Nadeem" },
    { classroom: "Infant 0-6 months", assigned: "Umar Nadeem", startTime: "07 AM", endTime: "03 PM", breaker: "Bilal" },
    { classroom: "Infant 0-6 months", assigned: "Hamid Ali", startTime: "07 AM", endTime: "03 PM", breaker: "Samar" },
    { classroom: "Infant 0-6 months", assigned: "Umar Nadeem", startTime: "07 AM", endTime: "03 PM", breaker: "Hamza" },
];

const adminData: Data[] = [
    { classroom: "Infant 0-6 months", enrolled: 20, present: 18, assignedTeacher: "Hamid Ali", startTime: "07 AM", endTime: "03 PM" },
    { classroom: "Toddler 6-12 months", enrolled: 15, present: 14, assignedTeacher: "Sana Khan", startTime: "08 AM", endTime: "04 PM" },
    { classroom: "Preschool 1-3 years", enrolled: 30, present: 28, assignedTeacher: "Umar Nadeem", startTime: "09 AM", endTime: "05 PM" },
];
const breakerColumns: ColumnDef<Data>[] = [
    { accessorKey: "classroom", header: "Classroom" },
    { accessorKey: "assigned", header: "Assigned" },
    { accessorKey: "startTime", header: "Start Time" },
    { accessorKey: "endTime", header: "End Time" },
    { accessorKey: "breaker", header: "Breaker" },
];

const adminColumns: ColumnDef<Data>[] = [
    { accessorKey: "classroom", header: "Classroom" },
    { accessorKey: "enrolled", header: "Enrolled" },
    { accessorKey: "present", header: "Present" },
    { accessorKey: "assignedTeacher", header: "Assigned Teacher" },
    { accessorKey: "startTime", header: "Start Time" },
    { accessorKey: "endTime", header: "End Time" },
];

const TabularGrid: React.FC = () => {
    const [sorting, setSorting] = useState<SortingState>([]);
    const [activeButton, setActiveButton] = useState<"breaker" | "admin">("breaker");
    const [filterBy, setFilterBy] = useState<"classroom" | "assigned" | null>(null);
    const columnsToUse = activeButton === "breaker" ? breakerColumns : adminColumns;
    const dataToUse = activeButton === "breaker" ? breakerData : adminData;

    const filteredData = useMemo(() => {
        if (!filterBy) return dataToUse;
        return [...dataToUse].sort((a, b) => (a[filterBy] ?? "").localeCompare(b[filterBy] ?? ""));
    }, [filterBy, dataToUse]);

    const table = useReactTable({
        data: filteredData,
        columns: columnsToUse,
        getCoreRowModel: getCoreRowModel(),
        getSortedRowModel: getSortedRowModel(),
        onSortingChange: setSorting,
        state: { sorting },
    });

    return (
        <div className="w-full border border-[#BEBFCD] bg-white rounded-lg overflow-hidden h-full">
            <div className="bg-[#fafafa] p-3 flex items-center justify-between gap-3">
                <h3 className="text-secondary font-semibold">Tabular View</h3>
                <Link to="#" className="text-[#3396F8] gap-2 inline-flex items-center">
                    Close Tabular <IoCloseOutline size={20} />
                </Link>
            </div>
            <div className="p-3 mt-12">
                <div className="grid grid-cols-2 gap-4 mb-3">
                    <div className="flex gap-2 mb-4">
                        <button
                            onClick={() => setActiveButton("breaker")}
                            className={`inline-flex items-center px-4 py-2 rounded-lg transition ${activeButton === "breaker" ? "bg-secondaryVariant2 text-secondary" : "bg-secondaryNeutral text-secondaryVariant"
                                }`}
                        >
                            For Breaker
                        </button>

                        <button
                            onClick={() => setActiveButton("admin")}
                            className={`inline-flex items-center px-4 py-2 rounded-lg transition ${activeButton === "admin" ? "bg-secondaryVariant2 text-secondary" : "bg-secondaryNeutral text-secondaryVariant"
                                }`}
                        >
                            For Admin
                        </button>
                    </div>
                    <div className="flex items-center justify-end gap-3">
                        <Dropdown align={"end"}>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="py-2 px-4 inline-flex items-center gap-2 justify-center">
                        Sort <RiArrowUpDownLine />
                            </Dropdown.Toggle>
                            <Dropdown.Menu className="absolute left-0 mt-2 w-40 bg-white border border-gray-200 shadow-lg rounded-lg z-10">
                            <Dropdown.Item  className="w-full text-left px-4 py-2 hover:bg-gray-100"  as="button" onClick={() => setFilterBy("classroom")}>
                                    By Classrooms
                                </Dropdown.Item>
                                <Dropdown.Item  className="w-full text-left px-4 py-2 hover:bg-gray-100"  as="button" onClick={() => setFilterBy("assigned")}>
                                    By Teachers
                                </Dropdown.Item>
                                <Dropdown.Item  className="w-full text-left px-4 py-2 hover:bg-gray-100"  as="button" onClick={() => setFilterBy(null)}>
                                    Reset
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                        <button className="inline-flex items-center px-4 py-2 gap-2 bg-primary text-white rounded-lg">
                            <PiPrinterLight />
                            Print
                        </button>
                    </div>
                </div>
                <div className="overflow-x-auto">
                    <table className="w-full border border-gray-300 rounded-lg min-w-[800px]">
                        <thead className="bg-gray-200">
                            {table.getHeaderGroups().map((headerGroup) => (
                                <tr key={headerGroup.id} className="bg-secondaryNeutral">
                                    {headerGroup.headers.map((header) => (
                                        <th key={header.id} className="px-4 py-2 text-left text-secondary font-semibold">
                                            {flexRender(header.column.columnDef.header, header.getContext())}
                                        </th>
                                    ))}
                                </tr>
                            ))}
                        </thead>
                        <tbody>
                            {table.getRowModel().rows.map((row) => (
                                <tr key={row.id} className="border-b border-secondaryNeutral">
                                    {row.getVisibleCells().map((cell) => (
                                        <td key={cell.id} className="px-4 py-2 border border-secondaryNeutral text-secondaryVariant">
                                            {flexRender(cell.column.columnDef.cell, cell.getContext())}
                                        </td>
                                    ))}
                                </tr>
                            ))}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

export default TabularGrid;
