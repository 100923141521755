import { ICenterSetupPayload } from "../types/ICenterSetup.interface";
import { apiForay } from "../utils/axios";



const postCenterSetup = async (reqBody: ICenterSetupPayload) => {
  try {
    const res: any = await apiForay.post(`/center-setup`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("center setup not posted");
  } catch (err) {
    console.log("error: ", err);
  }
};

const fetchCenterSetup = async () => {
  try {
    const res: any = await apiForay.get(`/classroom-setup/getCenterSetup`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("center setup not found");
  } catch (err) {
    console.log("error: ", err);
  }
};
const updateCenterSetup = async (reqBody: ICenterSetupPayload) => {
  try {
    const res: any = await apiForay.patch(`/center-setup`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't update center setup");
  } catch (err) {
    console.log("error: ", err);
  }
};
const updatePartTimeSlots = async (reqBody: ICenterSetupPayload) => {
  try {
    const res: any = await apiForay.patch(`classroom-setup/center-setup/partTimeSlots`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't update part time slots");
  } catch (err) {
    console.log("error: ", err);
  }
};

export const foraySvc = {
  postCenterSetup,
  fetchCenterSetup,
  updateCenterSetup,
  updatePartTimeSlots,
}

