import React from "react";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import { forayRoutes } from "../../routes/routes";
import ForayTabs from "../../components/forayTabs/ForayTabs";
import { useClassrooms } from "../../hooks/useClassrooms";
import InputField from "../../components/inputsFields/InputField";
import { GoPlusCircle } from "react-icons/go";
import ReactSelect from "../../components/reactSelect/ReactSelect";
import { GoPlus } from "react-icons/go";
import { CgSoftwareUpload } from "react-icons/cg";
import { TbFileText } from "react-icons/tb";
import { CgEye } from "react-icons/cg";
import { CiEdit } from "react-icons/ci";
import { Link } from "react-router-dom";

const CommunicationSetup = () => {
  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "System Setup", link: forayRoutes.foray_screen },
    { label: "Template", isCurrent: true },
  ];
  const { data: classroomData } = useClassrooms(true);
  const handleNumberToDisableTab = () => {
    if (classroomData?.classrooms?.length === 0) {
      return 1;
    }
    if (classroomData?.hasClassroomWithUnfilledDetails) {
      return 2;
    } else return 3;
  };
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-5 bg-secondaryNeutral">
          <div className="mb-9">
            <Breadcrumb items={breadcrumbItems} />
          </div>
          <h2 className="text-2xl font-semibold text-secondary mb-6">
            System Setup
          </h2>
          <ForayTabs
            stepNumber={handleNumberToDisableTab()}
            hasClassrooms={(classroomData?.classrooms?.length as number) > 0}
          />
          <p className="my-6 text-base text-secondary">
            Communication with parents, staff, and many others, is an important
            part of managing a successful childcare center. Here, you can create
            templates for emails, texts, or other forms of communications.{" "}
            <br /> <br /> You may use/edit our{" "}
            <strong>
              Child Care <span className="italic text-primary">Forward!</span>{" "}
              (CCF)
            </strong>
            Template <strong>OR</strong> Create/Upload your own.
          </p>
          <h3 className="text-xl text-secondary font-semibold mb-6">
            Send from which email
          </h3>
          <div className="flex items-end gap-6 mb-4">
            <div className="w-full max-w-[272px]">
              <InputField
                label="Email (Default)"
                type={"email"}
                placeholder={"admin@childcarecenter.com"}
              />
            </div>
            {/* show this field when clicked on add another email and hide the button when this fields show */}
            {/* <div className="w-full max-w-[272px]">
              <InputField
                label="New Email"
                type={"email"}
                placeholder={"admin@childcarecenter.com"}
              />
            </div>
            <div className="w-full max-w-[272px]">
              <ReactSelect label={"Choose Default"} placeholder={"Parents"} />
            </div> */}
            <button className="text-base text-statusColor flex items-center gap-1 pb-4 whitespace-nowrap">
              <GoPlusCircle className="w-6 h-6" /> Add Another Email
            </button>
          </div>
          <div className="w-full max-w-[272px] mb-6">
            <ReactSelect label={"Template for whom?"} placeholder={"Parents"} />
          </div>
          <div className="flex items-center justify-between mb-6">
            <h3 className="text-xl text-secondary font-semibold">Templates</h3>
            <div className="flex items-center gap-6">
              <Link
                to="create_template"
                className="text-base text-white rounded flex items-center gap-1 p-3 bg-primary"
              >
                <GoPlus className="w-6 h-6" /> Create Template
              </Link>
              <button className="text-base text-white p-3 rounded bg-secondary whitespace-nowrap flex items-center gap-1">
                <CgSoftwareUpload className="w-6 h-6" /> Upload my own{" "}
              </button>
            </div>
          </div>
          <div className="bg-white p-3 rounded-xl mb-3">
            <div className="bg-secondaryNeutral px-3 py-2 rounded-xl flex justify-between items-center">
              <div className="flex items-center gap-2">
                <TbFileText className="w-6 h-6" />
                <span className="text-lg text-secondary font-medium">
                  Student_Started _Email_Notification.docx
                </span>
              </div>
              <div className="flex items-center gap-3">
                <button className="text-base text-secondary font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CgEye className="text-primary w-6 h-6" /> View
                </button>
                <button className="text-base text-statusColor font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CiEdit className="w-6 h-6" /> Modify
                </button>
                <button className="text-base text-secondary font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CiEdit className="w-6 h-6" /> Modify & Save As
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white p-3 rounded-xl mb-3">
            <div className="bg-secondaryNeutral px-3 py-2 rounded-xl flex justify-between items-center">
              <div className="flex items-center gap-2">
                <TbFileText className="w-6 h-6" />
                <span className="text-lg text-secondary font-medium">
                  Student_Started _Email_Notification.docx
                </span>
              </div>
              <div className="flex items-center gap-3">
                <button className="text-base text-secondary font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CgEye className="text-primary w-6 h-6" /> View
                </button>
                <button className="text-base text-statusColor font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CiEdit className="w-6 h-6" /> Modify
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white p-3 rounded-xl mb-3">
            <div className="bg-secondaryNeutral px-3 py-2 rounded-xl flex justify-between items-center">
              <div className="flex items-center gap-2">
                <TbFileText className="w-6 h-6" />
                <span className="text-lg text-secondary font-medium">
                  Student_Started _Email_Notification.docx
                </span>
              </div>
              <div className="flex items-center gap-3">
                <button className="text-base text-secondary font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CgEye className="text-primary w-6 h-6" /> View
                </button>
                <button className="text-base text-statusColor font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CiEdit className="w-6 h-6" /> Modify
                </button>
              </div>
            </div>
          </div>
          <div className="bg-white p-3 rounded-xl mb-3">
            <div className="bg-secondaryNeutral px-3 py-2 rounded-xl flex justify-between items-center">
              <div className="flex items-center gap-2">
                <TbFileText className="w-6 h-6" />
                <span className="text-lg text-secondary font-medium">
                  Student_Started _Email_Notification.docx
                </span>
              </div>
              <div className="flex items-center gap-3">
                <button className="text-base text-secondary font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CgEye className="text-primary w-6 h-6" /> View
                </button>
                <button className="text-base text-statusColor font-medium flex items-center gap-1 bg-white rounded-lg p-3">
                  <CiEdit className="w-6 h-6" /> Modify
                </button>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default CommunicationSetup;
