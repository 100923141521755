import * as React from "react";
import { MdInfoOutline } from "react-icons/md";

interface ModalProps {
    isOpen: boolean;
    onClose: () => void;
    handleArchive: () => Promise<void>
}

const DeActivateConfirmationModal: React.FC<ModalProps> = ({ isOpen, onClose, handleArchive }) => {
    const handleDeActivate = async () => {
        await handleArchive()
    }
    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white p-9 rounded-3xl shadow-lg w-[633px]">
                <div className="flex items-center flex-col justify-center gap-9">
                    <span className="block">
                        {" "}
                        <MdInfoOutline className="text-primary w-[88px] h-[88px]" />
                    </span>
                    <p className="text-2xl text-secondary font-semibold">
                        Are you sure you want to remove this classroom?
                    </p>
                    <div className="flex items-center justify-center gap-4">
                        <button className="btnPrimary max-w-[234px]" onClick={handleDeActivate}>Yes, Deactivate</button>
                        <button
                            className="btnSimple max-w-[126px] flex justify-center whitespace-nowrap"
                            onClick={onClose}
                        >
                            No, Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeActivateConfirmationModal;
