export const forayRoutes = {
  center_setup: "/fr/setup/center-setup",
  center_modify: "/fr/setup/center-modify",
  classroom_setup: "/fr/setup/classroom-setup",
  classroom_modify: "/fr/setup/classroom-modify",
  classroom_list: "/fr/classroom-list",
  center_view: "/fr/center-view",
  conduct_violations_setup: "/fr/conduct-violations-setup",
  employee_evaluations_setup: "/fr/employee-evaluations-setup",
  overtime_payroll: "/fr/overtime-payroll",
  pto_tardy_setup: "/fr/pto-tardy-setup",
  handbook: "/fr/handbook",
  essential_classroom_setup: "/fr/classroom-essentials-setup",
  essential_classroom_list: "/fr/classroom-essentials-list",
  classroom_detailed: "/fr/classroom-details",
  detailed_classroom_setup: "/fr/setup/classroom-details-setup",

  view_classroom: "/fr/classroom-view",
  student_setup: "/fr/setup/student-setup",
  student_modify: "/fr/setup/student-modify",
  student_list: "/fr/student-list",
  sibling_setup: "/fr/setup/sibling-setup",
  view_student_profile: "/fr/view-student-profile",
  parent_info: "/fr/parent-info",
  enrollment_tracking: "/fr/enrollment-tracking",
  foray_screen: "/fr/foray-screen",
  employee_personal_information: "/fr/employee-personal-information",
  add_interview: "/fr/add-interview",
  student_detail_edit: "/fr/student-detail-edit",

  //Staff Routes
  hr_dashboard: "/fr/human-resource-management",
  employee_details: "/fr/employee-details",
  checkList: "/fr/checkList",
  foray_dashboard: "/fr/foray-dashboard",
  sub_foray: "/fr/sub-foray",
  task_generation: "/fr/task-generation",
  foray: "/fr/foray",
  daily_schedule: "/fr/daily-schedule",
  tabular_view: "/fr/tabular-view",
  modify_daily_schedule: "/fr/modify-daily-schedule",
  communication_setup: "/fr/communication-setup",
  create_template: "/fr/create_template",
  communication: "/fr/communication",
  tour_setup: "/fr/tour-setup",
  class_roster: "/fr/class-roster",
};
