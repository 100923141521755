import React from "react";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import InputField from "../../../components/inputsFields/InputField";
import { IoCloseOutline } from "react-icons/io5";
import { forayRoutes } from "../../../routes/routes";

const ConductViolationsSetup = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar space-y-6">
          <Link
            to={forayRoutes.hr_dashboard}
            className="text-sm text-secondaryVariant font-medium flex items-center gap-2"
          >
            <IoMdArrowBack className="w-5 h-5" />
            <span className="underline">Back to main</span>
          </Link>
          <h2 className="text-xl text-secondary font-semibold">
            Conduct Violation
          </h2>
          <div className="px-6 pt-6 pb-9 rounded-md space-y-9 bg-white">
            <h3 className="text-lg text-secondary font-medium">Violations</h3>
            <div className="flex items-center gap-6">
              <div className="w-[161px]">
                <ReactSelect label={""} placeholder={""} />
              </div>
              <div className="flex items-center gap-3">
                <div className="w-[300px]">
                  <InputField type={""} placeholder={"Add another violation"} />
                </div>
                <button className="text-lg text-white font-medium bg-secondary rounded-md h-[54px] px-5">
                  Add
                </button>
              </div>
            </div>
            <div className="flex items-center flex-wrap gap-3 ">
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">Attendance</span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">Cell Phone Usage</span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">
                  Threatening/Aggressive Behavior
                </span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">Job Abandonment</span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">
                  Company/Employee Sabotage
                </span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">
                  Creating Hostile Work Environment
                </span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">
                  Inappropriate Interaction with Parents
                </span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
              <div className="inline-flex items-center gap-1 px-1.5 py-1 bg-secondaryNeutral rounded">
                <span className="text-sm text-secondary">Dress Code</span>
                <button>
                  <IoCloseOutline className="w-5 h-5 text-secondary" />
                </button>
              </div>
            </div>
          </div>
          <div className="p-6 bg-white rounded-md">
            <h3 className="text-xl text-secondary font-medium mb-6">
              Associate Handbook lines with Conduct Violation
            </h3>
            <div className="grid grid-cols-12 bg-secondaryNeutral p-3">
              <div className="col-span-4">
                <h4 className="text-base text-secondary font-medium">
                  Violations
                </h4>
              </div>
              <div className="col-span-8 grid grid-cols-12 gap-7">
                <div className="col-span-2 ps-3">
                  <h4 className="text-base text-secondary font-medium">
                    From Line
                  </h4>
                </div>
                <div className="col-span-2">
                  <h4 className="text-base text-secondary font-medium">To</h4>
                </div>
                <div className="col-span-8">
                  <h4 className="text-base text-secondary font-medium">Line</h4>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-0.5">
              <div className="col-span-4 ps-3 py-3 pb-2 border-[0.5px] border-secondaryNeutral rounded-b">
                <ul className="space-y-2">
                  <li className="text-base text-secondary">Attendance</li>
                  <li className="text-base text-secondary">Cell Phone Usage</li>
                  <li className="text-base text-secondary">
                    Threatening/Aggressive Behavior
                  </li>
                  <li className="text-base text-secondary">Job Abandonment</li>
                  <li className="text-base text-secondary">
                    Company/Employee Sabotage
                  </li>
                  <li className="text-base text-secondary">
                    Creating Hostile Work Environment
                  </li>
                  <li className="text-base text-secondary">
                    Inappropriate Interaction with Parents
                  </li>
                  <li className="text-base text-secondary">Dress Code</li>
                  <li className="text-base text-secondary">Insubordination</li>
                </ul>
              </div>
              <div className="col-span-8 grid grid-cols-12 ps-3 py-3 pb-2 border border-secondaryNeutral rounded">
                <div className="col-span-2">
                  <ul className="space-y-2">
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                  </ul>
                </div>
                <div className="col-span-2">
                  <ul className="space-y-2">
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                    <li className="text-base text-secondary">-</li>
                  </ul>
                </div>
                <div className="col-span-8">
                  <ul className="space-y-2">
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>
                    <li className="text-base text-secondary">4</li>{" "}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default ConductViolationsSetup;
