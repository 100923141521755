import React from "react";
import { IoCheckmarkOutline } from "react-icons/io5";

interface IProps {
  products: any;
}

const Complimentary: React.FC<IProps> = ({ products }) => {
  return (
    <>
      <div className="border border-primary rounded-2xl p-6 flex items-stretch align-top justify-between bg-white mb-6">
        <div className="flex items-start justify-between flex-col">
          <h3 className="text-lg text-secondary font-semibold">
            Complimentary
          </h3>
          <div>
            {/* {products.data[0]?.marketing_features?.map((item: any) => {
              return (
                <div key={item.name} className="flex items-center gap-6">
                  <i className="text-white bg-successAlert rounded-full p-1 text-center flex items-center justify-center">
                    <IoCheckmarkOutline className="w-4 h-4" />
                  </i>
                  <h3 className="text-[28px] text-primary font-semibold">
                    {item.name}
                  </h3>
                </div>
              );
            })} */}
            <div className="flex items-center gap-6">
              <i className="text-white bg-successAlert rounded-full p-1 text-center flex items-center justify-center">
                <IoCheckmarkOutline className="w-4 h-4" />
              </i>
              <h3 className="text-[28px] text-primary font-semibold">
                Adventures
              </h3>
            </div>
            <div className="flex items-center gap-6">
              <i className="text-white bg-successAlert rounded-full p-1 text-center flex items-center justify-center">
                <IoCheckmarkOutline className="w-4 h-4" />
              </i>
              <h3 className="text-[28px] text-primary font-semibold">
                Medical Evals (Access)
              </h3>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-6">
          <div>
            <h3 className="text-lg text-primary font-semibold mb-4">
              Hi, I’m Ashley
            </h3>
            <p className="text-base text-secondaryVariant flex flex-col items-start gap-6">
              Say, come check me out in <br /> Adventures, Episode 3.
              <span className="text-secondary font-medium">
                Lots of fun stuff going on!
              </span>
            </p>
          </div>
          <div>
            <img
              src="https://a360csastorage.blob.core.windows.net/childcare/98d71650-0c3a-4899-a8b8-f1bda2638a73-pikaso_texttoimage_3-years-old-She-had-long-dark-hair-cascading-all-t%20(2).png"
              alt="I’m Ashley"
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Complimentary;
