import moment from "moment";
import React, { useEffect, useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { PiCalendarPlus } from "react-icons/pi";
import Input, {
  isPossiblePhoneNumber,
  Value,
} from "react-phone-number-input/input";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import ReactDatePicker from "../../../shared/components/reactDatePicker/ReactDatePicker";
import { TooltipComponent } from "../../../shared/components/tooltip/Tooltip";
import RequiredAsterisk from "../../../shared/components/ui/RequiredAsterisk";
import {
  calculateAge,
  formatDateSlashFullYear,
  revertToDateType,
} from "../../../shared/utils/formatDate";
import InputField from "../../components/inputsFields/InputField";
import ReactSelect from "../../components/reactSelect/ReactSelect";
import {
  initStudentSetupForm,
  initStudentSetupFormErrors,
} from "../../constants/initialState";
import { Slot } from "../../enums/Foray.enums";
import { forayRoutes } from "../../routes/routes";
import { classroomSvc } from "../../services/classroom.service";
import { studentSvc } from "../../services/student.service";
import { IOptionS } from "../../types/ICenterSetup.interface";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { states } from "../../../shared/constants/selectOptions";
import { ERR_MSGS } from "../../../shared/constants/errorMsgs";
import {
  IStudentRegistration,
  ITourStudentSetup,
} from "../../types/IStudentSetup.interface";
import { queryClient } from "../../../../config/useQuery.config";
import { StudentQueryKeyString } from "../../enums/studentQueryKey.enum";
import { QueryKeyString } from "../../enrollment_Tracking/enums/queryKey.enum";
import { showSuccessToast } from "../../../shared/utils/toast";
import { Link, useNavigate } from "react-router-dom";
import DuplicateStudentWarningModal from "../../enrollment_Tracking/components/modals/DuplicateStudentWarningModal";
import { warningModalWarningsAndTexts } from "../../constants/constantValues";
import { IoChevronBack } from "react-icons/io5";

// <p>If your child is NOT in the SA (School Age) Program, then for "Elementary School" and "SA Type" use "N/A".</p>
// <p>The various abbreviations under Status refer to the following:</p>
// <ul class="space-y-3">
//   <li>RegdGS - Registered Guaranteed to Start: Child has paid registration, deposit, etc. and is guaranteed a spot in the future.</li>
//   <li>Enrolled - child is currently, physically attending the center.</li>
//   <li>RegdWL - Registered Wait list. Child is on the priority waitlist because Registration has been paid but no guaranteed future start date.</li>
//   <li>Waitlist - Child is on the waitlist (NOT priority waitlist) and no fees have been paid.</li>
//   <li>Withdrawn - self explanatory.</li>
//   <li>Waithold - child is temporarily not attending but will be back, dates of absence required.</li>
//   <li>Tour - self-explanatory.</li>
// </ul>
// <p>CA Approval for Enrollment - The CA (Chief Administrator) needs to approve this child for enrollment. This is for those students who have withdrawn and may come back but for some reason are not allowed to re-enroll without special permission.</p>
// <p>Drop-In - Self explanatory.</p>
// <p>Please enter the classroom the child is currently in, or will be in when he/she starts. If child is Part Time, please make sure you choose the correct PT slot.</p>
const tooltips = {
  basicInformation: {
    id: "basicInformation-tooltip",
    content: `<div class="text-sm space-y-3 text-secondary">
      <p>Please note that all data is mandatory.</p>
   </div>`,
  },
  studentStatus: {
    id: "studentStatus",
    content: `<div class="text-sm space-y-3 text-secondary">
      <p>The various abbreviations under Status refer to the following:</p>
      <ul class="space-y-3">
        <li>Enrolled - child is currently, physically attending the center.</li>
        <li>RegdGS - Registered Guaranteed to Start: Child has paid registration, deposit, etc. and is guaranteed a spot in the future.</li>
        <li>RegdWL - Registered Wait list. Child is on the priority waitlist because Registration has been paid but no guaranteed future start date.</li>
        <li>Waitlist - Child is on the waitlist (NOT priority waitlist) and no fees have been paid.</li>
        <li>Withdrawn - self explanatory.</li>
        <li>Tour - self-explanatory.</li>
      </ul>
      <p>CA Approval for Enrollment - The CA (Chief Administrator) needs to approve this child for enrollment. This is for those students who have withdrawn and may come back but for some reason are not allowed to re-enroll without special permission.</p>
      <p>Drop-In - Self explanatory.</p>
   </div>`,
  },
  startDate: {
    id: "startDate-tooltip",
    content: `<div class="text-sm space-y-3 text-secondary">
        <p>Even though the start date may not be available at this time, please enter anticipated start date in the future.</p>
        </div>`,
  },
  leaveDate: {
    id: "leaveDate-tooltip",
    content: `<div class="text-sm space-y-3 text-secondary">
        <p>Please add leave data as soon as it is available to make the Enrollment Tracking work properly.</p>
        </div>`,
  },
  classroom: {
    id: "classroom-tooltip",
    content: `This classroom will auto-select based on child’s date of birth. You may change if you wish.`,
  },
  transition: {
    id: "transition-tooltip",
    content: `<div class="font-inter text-sm leading-5 text-secondary">
      <p class="mb-4">
        Under <strong>"Modify Transition Session/Date"</strong>, you can 
        change the session and date of the transition classroom. 
        This app will track upto three transitions.
      </p>
    </div>`,
  },
  tempraroryAbsence: {
    id: "tempraroryAbsence-tooltip",
    content: `You can enter up to two vacation/absence times the child will not be at the Center. This will be indicated in the Enrollment Tracking in case you want to allow Drop-Ins in that classroom during the absence.`,
  },
  summerProgram: {
    id: "summerProgram-tooltip",
    content: `<div class=" h-auto font-inter text-sm leading-5 text-secondary">
      <p class="mb-4">
       This section allows summer students to attend any number of weeks during the summer program.
      </p>
    </div>`,
  },
};

const TourSetup = () => {
  const [studentStatusOptions, setStudentStatusOptions] = useState<IOptionS[]>(
    []
  );
  const [studentSetupForm, setStudentSetupForm] = useState({
    ...initStudentSetupForm,
    studentStatus: "Tour",
  });
  const [studentSetupFormErrors, setStudentSetupFormErrors] = useState(
    initStudentSetupFormErrors
  );
  const [weekOptions, setWeekOptions] = useState<IOptionS[]>([]);
  const [classrooms, setClassrooms] = useState<IClassroomSetup[]>([]);
  const [classroomOptions, setClassroomOptions] = useState<IOptionS[]>([]);
  const [submitType, setSubmitType] = useState("");
  const [showWarningModal, setShowWarningModal] = useState(false);
  const [warningModalData, setWarningModalData] = useState<any>();
  const navigate = useNavigate();
  const isSaveDisabled =
    hasErrors(studentSetupFormErrors) ||
    hasEmptyRequiredFields(studentSetupForm) ||
    !isPossiblePhoneNumber(studentSetupForm?.parentInfo[0]?.phoneNumber);

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Student list", link: forayRoutes.student_list },
    { label: "Tour Setup", isCurrent: true },
  ];

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e?.target;
    setStudentSetupForm((prev) => ({ ...prev, [name]: value }));
  };

  const handleDateSelect = (date: Date | null, name: string) => {
    setStudentSetupForm((prev) => ({
      ...prev,
      [name]: formatDateSlashFullYear(date as Date),
    }));
  };

  const handleDropDown = (name: string, value: any) => {
    if (value === Slot.FULL_TIME) {
      setStudentSetupForm((prev) => ({
        ...prev,
        [name]: value,
        classroomSlotType: Slot.FULL_TIME,
      }));
    } else {
      setStudentSetupForm((prev) => ({
        ...prev,
        [name]: value,
        classroomSlotType: Slot.PART_TIME,
      }));
    }
  };

  const handleSectionChange = (
    index: number,
    sectionName: keyof typeof initStudentSetupForm,
    field: string,
    value: any
  ) => {
    setStudentSetupForm((prev) => {
      const section = prev[sectionName];
      // Check if the section is an array
      if (Array.isArray(section)) {
        const updatedSection = section?.map((item, i) => {
          if (i === index) {
            // Ensure the item is an object before spreading
            if (typeof item === "object" && item !== null) {
              return { ...item, [field]: value };
            } else {
              console.error(`Invalid item type in ${sectionName}:`, item);
              return item; // Return the item unchanged if it's not an object
            }
          }
          return item;
        });
        return { ...prev, [sectionName]: updatedSection };
      }
      console.error(
        `${sectionName} is not an array and cannot be updated using this function.`
      );
      return prev;
    });
  };

  const handleFocus = (
    fieldName: string,
    parentKey?: keyof typeof initStudentSetupFormErrors,
    index?: number
  ) => {
    setStudentSetupFormErrors((prev) => {
      if (parentKey) {
        const parentValue = prev[parentKey];
        if (Array.isArray(parentValue)) {
          return {
            ...prev,
            [parentKey]: parentValue.map((item, i) =>
              i === index ? { ...item, [`${fieldName}Error`]: "" } : item
            ),
          };
        } else if (
          typeof parentValue === "object" &&
          parentValue !== null &&
          !Array.isArray(parentValue)
        ) {
          // Explicitly cast parentValue to an object
          const parentValueObj = parentValue as Record<string, any>;
          return {
            ...prev,
            [parentKey]: {
              ...parentValueObj,
              [`${fieldName}Error`]: "",
            },
          };
        } else {
          console.error(
            `Key ${String(parentKey)} does not reference an object or array.`
          );
          return prev;
        }
      }

      return {
        ...prev,
        [`${fieldName}Error`]: "",
      };
    });
  };

  const handleBlur = (
    fieldName: string,
    value: string,
    parentKey?: keyof typeof initStudentSetupFormErrors,
    index?: number
  ) => {
    const error = validateField(fieldName, value);

    setStudentSetupFormErrors((prev) => {
      if (parentKey) {
        const parentValue = prev[parentKey];
        if (Array.isArray(parentValue)) {
          return {
            ...prev,
            [parentKey]: parentValue.map((item, i) =>
              i === index
                ? { ...item, [`${fieldName}Error`]: error || "" }
                : item
            ),
          };
        } else if (
          typeof parentValue === "object" &&
          parentValue !== null &&
          !Array.isArray(parentValue)
        ) {
          // Explicitly cast parentValue to an object
          const parentValueObj = parentValue as Record<string, any>;
          return {
            ...prev,
            [parentKey]: {
              ...parentValueObj,
              [`${fieldName}Error`]: error || "",
            },
          };
        } else {
          console.error(
            `Key ${String(parentKey)} does not reference an object or array.`
          );
          return prev;
        }
      }

      return {
        ...prev,
        [`${fieldName}Error`]: error || "",
      };
    });
  };

  const validateField = (fieldName: string, value: string): string => {
    switch (fieldName) {
      case "firstName":
      case "lastName":
      case "studentFirstName":
      case "studentLastName":
        return value?.trim() === ""
          ? "This field is required"
          : value?.trim()?.length < 3
          ? "Must be at least 3 characters"
          : "";

      case "email":
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)
          ? ""
          : "Please enter a valid email";

      case "zipCode":
        return value?.trim() === ""
          ? "This field is required"
          : !/^\d{5}$/.test(value)
          ? "Zip code must be 5 numeric characters"
          : "";

      case "studentDOB":
        if (value?.trim() === "") return "This field is required";

        const dob = moment(value, "MM/DD/YYYY"); // Convert `value` to Date object
        const joiningDate = studentSetupForm?.studentJoiningDate
          ? moment(studentSetupForm.studentJoiningDate, "MM/DD/YYYY")
          : null;

        if (joiningDate && dob?.isAfter(joiningDate)) {
          return "DoB cannot be greater than Start Date";
        }

        return "";
      case "studentStatus":
      // case 'studentElementarySchool':
      case "studentJoiningDate":
      case "state":
      case "city":
        // case "classroomId":
        // case "classroomTimeSlot":
        // case "startDate":
        // case "endDate":
        return value?.trim() === "" ? "This field is required" : "";

      // Add more cases as needed
      default:
        return "";
    }
  };

  const getClassrooms = async () => {
    const res = await classroomSvc.fetchClassrooms();
    if (res?.ok) {
      const temp = res?.data?.existingClassroomSetups?.map(
        (classroom: IClassroomSetup) => ({
          value: classroom?._id,
          label: classroom?.classroomName,
        })
      );
      // const baClassrooms = res.data.existingClassroomSetups.filter(
      //   (classroom: IClassroomSetup) => classroom.BAProgram === true
      // );
      // setBAClassrooms(baClassrooms);
      setClassroomOptions(temp);
      setClassrooms(res?.data?.existingClassroomSetups || []);
    }
  };

  const getStudentsInfo = async () => {
    const res = await studentSvc.fetchStudentsInfo();
    if (res?.ok) {
      setStudentStatusOptions(
        Object.values(res?.data?.studentStatuses)?.map((item) => ({
          value: item as string,
          label: item as string,
        }))
      );
      setWeekOptions(
        res?.data?.weeks?.map((item: string) => ({
          value: item,
          label: formatDateSlashFullYear(item),
        }))
      );
    }
  };

  const handleSubmit = async (button: string) => {
    setShowWarningModal(false);
    // let uploadedImagePath: string = "";
    // if (file) {
    //   uploadedImagePath = await uploadFile();
    //   if (!uploadedImagePath) return;
    // }

    // const transitions = {
    //   firstTransition: getTransitionObject(
    //     studentSetupForm?.transitions?.transition1
    //   ),
    //   secondTransition: getTransitionObject(
    //     studentSetupForm?.transitions?.transition2
    //   ),
    //   thirdTransition: getTransitionObject(
    //     studentSetupForm?.transitions?.transition3
    //   ),
    // };

    const classroom = classrooms.find(
      (c) => c._id === studentSetupForm.classroomId
    );

    // Helper function to update a single transition
    // const updateTransition = (transition: any) => {
    //   if (!transition || transition.slot === "Full time" || !classroom)
    //     return transition;

    //   const matchingSlot = classroom.partTimeSlots.find(
    //     (slot) => slot.name.toLowerCase() === transition.slot.toLowerCase()
    //   );

    //   if (matchingSlot) {
    //     return {
    //       ...transition,
    //       slot: matchingSlot.uuid,
    //     };
    //   }

    //   return transition;
    // };

    // // Update transitions with UUIDs
    // const updatedTransitions = {
    //   firstTransition: updateTransition(transitions.firstTransition),
    //   secondTransition: updateTransition(transitions.secondTransition),
    //   thirdTransition: updateTransition(transitions.thirdTransition),
    // };
    // console.log("classrooms", classrooms);
    // console.log("transitions", transitions);
    // console.log("updated transitions", updatedTransitions);

    // return;

    // {
    //   firstTransition: {
    //     classroomId:
    //       studentSetupForm?.transitions?.transition1?.modifyClassroom ||
    //       studentSetupForm?.transitions?.transition1?.defaultClassroom ||
    //       null,
    //     slot:
    //       studentSetupForm?.transitions?.transition1
    //         ?.modifyFullOrPartTime ||
    //       studentSetupForm?.transitions?.transition1?.defaultFullOrPartTime,
    //     date:
    //       studentSetupForm?.transitions?.transition1?.modifyDate ||
    //       studentSetupForm?.transitions?.transition1?.defaultDate ||
    //       null,
    //   },
    //   secondTransition: {
    //     classroomId:
    //       studentSetupForm?.transitions?.transition2?.modifyClassroom ||
    //       studentSetupForm?.transitions?.transition2?.defaultClassroom ||
    //       null,
    //     slot:
    //       studentSetupForm?.transitions?.transition2
    //         ?.modifyFullOrPartTime ||
    //       studentSetupForm?.transitions?.transition2?.defaultFullOrPartTime,
    //     date:
    //       studentSetupForm?.transitions?.transition2?.modifyDate ||
    //       studentSetupForm?.transitions?.transition2?.defaultDate ||
    //       null,
    //   },
    //   thirdTransition: {
    //     classroomId:
    //       studentSetupForm?.transitions?.transition3?.modifyClassroom ||
    //       studentSetupForm?.transitions?.transition3?.defaultClassroom ||
    //       null,
    //     slot:
    //       studentSetupForm?.transitions?.transition3
    //         ?.modifyFullOrPartTime ||
    //       studentSetupForm?.transitions?.transition3?.defaultFullOrPartTime,
    //     date:
    //       studentSetupForm?.transitions?.transition3?.modifyDate ||
    //       studentSetupForm?.transitions?.transition3?.defaultDate ||
    //       null,
    //   },
    // },

    const reqBody: ITourStudentSetup = {
      student: {
        firstName: studentSetupForm?.studentFirstName,
        lastName: studentSetupForm?.studentLastName,
        dateOfBirth: studentSetupForm?.studentDOB,
        status: studentSetupForm?.studentStatus,
        // absenceDuringSummer: absenceDuringSummer,
        Dates: {
          startAtCenter: studentSetupForm?.studentJoiningDate || null,
          tourDate: studentSetupForm?.studentTourDate || null,
        },
        // slot:
        //   studentSetupForm?.classroomSlotType === Slot.FULL_TIME
        //     ? [Slot.FULL_TIME]
        //     : [studentSetupForm?.classroomTimeSlot],
        // slotDays:
        //   studentSetupForm?.classroomSlotType === Slot.FULL_TIME
        //     ? []
        //     : studentSetupForm?.slotDays,
        // summerWeeks: studentSetupForm?.summerProgramWeeks,
        // transitions: updatedTransitions,
        // temporaryAbsences: studentSetupForm?.temporaryAbsences?.map(
        //   (tempAbsence) => ({
        //     start: tempAbsence.startDate || null,
        //     end: tempAbsence.endDate || null,
        //   })
        // ),
      },
      parentSetup: studentSetupForm?.parentInfo?.map((parent) => {
        return {
          firstName: parent?.firstName,
          lastName: parent?.lastName,
          email: parent?.email,
          phone: parent?.phoneNumber,
          street: parent?.street,
          city: parent?.city,
          state: parent?.state,
          postalCode: parent?.zipCode,
        };
      }),
    };

    if (studentSetupForm?.classroomId) {
      reqBody.student.classroom = studentSetupForm?.classroomId;
    }

    // if (
    //   studentSetupForm?.classroomSlotType !== Slot.FULL_TIME &&
    //   studentSetupForm?.slotDays?.length
    // ) {
    //   reqBody.student.slotDays = studentSetupForm?.slotDays;
    // }
    // if (!isSiblingSetup) {
    // reqBody.parentSetup = studentSetupForm?.parentInfo?.map((parent) => {
    //   return {
    //     firstName: parent?.firstName,
    //     lastName: parent?.lastName,
    //     email: parent?.email,
    //     phone: parent?.phoneNumber,
    //     street: parent?.street,
    //     city: parent?.city,
    //     state: parent?.state,
    //     postalCode: parent?.zipCode,
    //     emergencyContact: parent?.emergencyContact,
    //     livesWith: parent?.livesWith,
    //     canPickUp: parent?.canPickUp,
    //   };
    // });
    // }
    // if (!isSiblingSetup || handleValidContactSetup().full) {
    //   reqBody.contactSetup = {
    //     contacts: studentSetupForm?.additionalContact?.map((contact) => ({
    //       firstName: contact?.firstName,
    //       lastName: contact?.lastName,
    //       email: contact?.email,
    //       phone: contact?.phone,
    //       emergencyContact: contact?.emergencyContact,
    //       livesWith: contact?.livesWith,
    //       canPickUp: contact?.canPickUp,
    //     })),
    //   };
    // } else if (!isSiblingSetup || handleValidContactSetup().half) {
    //   showErrorToast(
    //     'Please ensure all the fields in the "Additional Contact(s)" are filled.'
    //   );
    //   return;
    // }
    console.log("check req body: ", reqBody);

    // const res = !isSiblingSetup
    //   ? await studentSvc.postStudentSetup(reqBody)
    //   : await studentSvc.postSiblingSetup(reqBody, location.state?.studentId);
    const res = await studentSvc.postStudentSetup(reqBody);
    queryClient.invalidateQueries({
      queryKey: [
        StudentQueryKeyString.Students,
        StudentQueryKeyString.StudentList,
        QueryKeyString.Yearly_Enrollment,
      ],
    });
    if (res?.ok) {
      showSuccessToast("Tour Setup Completed Successfully");
      if (button === "save") {
        navigate(forayRoutes.student_list);
      }
      // else {
      //   containerRef.current.scrollTop = 0;
      //   navigate(forayRoutes.sibling_setup, {
      //     state: { studentId: res?.data?.data?.student?._id },
      //   });
      // }
    }
  };

  const onClose = () => {
    setShowWarningModal(false);
  };

  const handleTourAndStartDateCheck = () => {
    const { studentJoiningDate, studentTourDate } = studentSetupForm || {};

    if (!studentJoiningDate || !studentTourDate) return;

    // Convert string dates to Moment.js date objects
    const joiningDate = moment(studentJoiningDate, "MM/DD/YYYY", true);
    const tourDate = moment(studentTourDate, "MM/DD/YYYY", true);

    // Check if joining date is the same or before the tour date
    if (joiningDate.isSameOrBefore(tourDate)) {
      setWarningModalData(warningModalWarningsAndTexts.tourAfterStart);
      setShowWarningModal(true);
      // showErrorToast("Start date must be after Tour date.")
    } else {
      setWarningModalData(null);
      setShowWarningModal(false);
    }
  };

  useEffect(() => {
    handleTourAndStartDateCheck();
  }, [studentSetupForm?.studentTourDate, studentSetupForm?.studentJoiningDate]);

  const handleSuitableClassroomWithDoB = (dob: Date | null) => {
    if (!dob || !classrooms?.length) return;
    const birthDate = moment(dob).startOf("day");
    const today = moment().startOf("day");
    const studentAgeInDays = today.diff(birthDate, "days");

    const seqClassrooms = classrooms?.filter(
      (classroom) => classroom?.ageSequential
    );

    const suitableClassroom = seqClassrooms?.find((classroom) => {
      const minAgeInDays = classroom.ageRange?.minimumAge?.ageInDays ?? 0;
      const maxAgeInDays =
        classroom.ageRange?.maximumAge?.ageInDays ?? Infinity;

      return (
        studentAgeInDays >= minAgeInDays && studentAgeInDays <= maxAgeInDays
      );
    });

    setStudentSetupForm((prev) => ({
      ...prev,
      classroomId: suitableClassroom?._id || "",
    }));
  };

  const handleFutureDoBCheck = (dob: Date | null) => {
    const today = moment();
    const dateOfBirth = moment(dob);
    const isFutureDate = dateOfBirth.isAfter(today);
    if (isFutureDate) {
      setWarningModalData(warningModalWarningsAndTexts.futureDOB);
      setShowWarningModal(true);
    } else {
      setWarningModalData(null);
      setShowWarningModal(false);
    }
  };

  useEffect(() => {
    getClassrooms();
    getStudentsInfo();
  }, []);

  return (
    <>
      <DashboardWrapper>
        <div className="p-6 bg-secondaryNeutral min-h-full">
          <div className="flex items-center justify-between">
            <Breadcrumb items={breadcrumbItems} />
            <Link
              to={forayRoutes.student_list}
              className="flex items-center gap-2 bg-white rounded-md px-3 py-2 text-lg text-secondaryVariant font-semibold max-w-max"
            >
              <IoChevronBack /> Back to Students list
            </Link>
          </div>

          <h2 className="text-[28px] text-secondary font-semibold mb-6">
            Tour Setup
          </h2>
          <div className="space-y-4">
            <div className="p-6 rounded-xl bg-white">
              <h2 className="text-xl text-secondary font-semibold mb-6 flex items-start">
                Basic Information{" "}
                <span data-tooltip-id={tooltips.basicInformation.id}>
                  <IoMdInformationCircleOutline className="text-primary w-5 h-5" />
                </span>
              </h2>
              <TooltipComponent
                className="max-w-[570px] bg-white"
                id={tooltips.basicInformation.id}
                content={tooltips.basicInformation.content}
                isWhite={true}
                place="right"
              />
              <div className="grid grid-cols-6 gap-3">
                <div className=" w-full">
                  <InputField
                    label="Child First Name"
                    type="text"
                    name="studentFirstName"
                    placeholder="First Name"
                    value={studentSetupForm?.studentFirstName}
                    onChange={handleInput}
                    onFocus={(name) => {
                      handleFocus(name);
                    }}
                    onBlur={(name) => {
                      handleBlur(name, studentSetupForm?.studentFirstName);
                    }}
                    required
                  />
                  {studentSetupFormErrors?.studentFirstNameError && (
                    <span className="text-red-600 text-sm mt-1">
                      {studentSetupFormErrors?.studentFirstNameError}
                    </span>
                  )}
                </div>
                <div className=" w-full">
                  <InputField
                    label="Child Last Name"
                    type="text"
                    name="studentLastName"
                    placeholder="Last Name"
                    value={studentSetupForm?.studentLastName}
                    onChange={handleInput}
                    onFocus={(name) => {
                      handleFocus(name);
                    }}
                    onBlur={(name) => {
                      handleBlur(name, studentSetupForm?.studentLastName);
                    }}
                    required
                  />
                  {studentSetupFormErrors?.studentLastNameError && (
                    <span className="text-red-600 text-sm mt-1">
                      {studentSetupFormErrors?.studentLastNameError}
                    </span>
                  )}
                </div>
                <div className=" w-full relative">
                  <div className="absolute left-12 z-50">
                    {/* <span
                      data-tooltip-id={tooltips.studentStatus.id}
                      className=""
                    >
                      <IoMdInformationCircleOutline className="text-primary w-5 h-5" />
                    </span> */}
                  </div>

                  {/* <TooltipComponent
                    className="max-w-[570px] bg-white"
                    id={tooltips.studentStatus.id}
                    content={tooltips.studentStatus.content}
                    isWhite={true}
                    place="right"
                  /> */}
                  <div>
                    <ReactSelect
                      key={
                        studentStatusOptions?.find(
                          (option) =>
                            option.value === studentSetupForm?.studentStatus
                        )?.value
                      }
                      label="Status"
                      placeholder="Status"
                      options={studentStatusOptions}
                      value={studentStatusOptions?.find(
                        (option) =>
                          option.value === studentSetupForm?.studentStatus
                      )}
                      onChange={(selectedOption) =>
                        handleDropDown(
                          "studentStatus",
                          selectedOption?.value || ""
                        )
                      }
                      onFocus={() => {
                        handleFocus("studentStatus");
                      }}
                      onBlur={() => {
                        handleBlur(
                          "studentStatus",
                          studentSetupForm?.studentStatus
                        );
                      }}
                      required
                      isDisabled={true}
                    />
                    {studentSetupFormErrors?.studentStatusError && (
                      <span className="text-red-600 text-sm mt-1">
                        {studentSetupFormErrors?.studentStatusError}
                      </span>
                    )}
                  </div>
                </div>
                <div className=" w-full">
                  <label htmlFor="" className="text-sm text-secondaryVariant">
                    Date of Birth
                    <RequiredAsterisk />
                  </label>
                  <div className="w-full relative">
                    <ReactDatePicker
                      key={studentSetupForm?.studentDOB}
                      placeholder="MM/DD/YYYY"
                      // maxDate={new Date()} //commented this so user can select future date (estimated) in case of pre booking (required by client)
                      value={revertToDateType(studentSetupForm?.studentDOB)}
                      onChange={(date) => {
                        handleDateSelect(date, "studentDOB");
                        handleSuitableClassroomWithDoB(date);
                        handleFutureDoBCheck(date);
                      }}
                      onFocus={() => {
                        handleFocus("studentDOB");
                      }}
                      onBlur={() => {
                        handleBlur("studentDOB", studentSetupForm?.studentDOB);
                      }}
                    />
                    <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
                  </div>
                  {studentSetupFormErrors?.studentDOBError && (
                    <span className="text-red-600 text-sm mt-1">
                      {studentSetupFormErrors?.studentDOBError}
                    </span>
                  )}
                </div>
                <div className="">
                  <div className="flex items-start flex-col gap-6">
                    <span className="text-sm text-secondaryVariant">Age: </span>
                    <strong className="text-primary text-base font-semibold line-clamp-1">
                      {studentSetupForm?.studentDOB
                        ? calculateAge(studentSetupForm?.studentDOB)
                        : "0Y 0M 0D"}
                    </strong>
                  </div>
                </div>
                {/*<div className=" w-full">
                                       <ReactSelect
                                      
                                      key={studentSetupForm?.studentSAType}
                                        label="SA Type"
                                        placeholder="availability"
                                        options={SATypeOptions}
                                        value={SATypeOptions.find(
                                          (option) => option.value === studentSetupForm?.studentSAType
                                        )}
                                        onChange={(selectedOption) =>
                                          handleDropDown("studentSAType", selectedOption?.value)
                                        }
                                        required
                                      /> 
                                    </div>*/}
              </div>
            </div>
            <div className="bg-white p-6 rounded-xl">
              <h2 className="text-xl text-secondary font-semibold mb-6 flex gap-1 items-start">
                Classroom{" "}
                <span data-tooltip-id={tooltips.classroom.id}>
                  <IoMdInformationCircleOutline className="text-primary w-5 h-5" />
                </span>
              </h2>
              <TooltipComponent
                id={tooltips.classroom.id}
                content={tooltips.classroom.content}
                className="max-w-[331px] bg-white"
                isWhite={true}
                place="right"
              />
              <div className="grid grid-cols-6 gap-3 mb-6">
                <div className="w-full">
                  <ReactSelect
                    key={studentSetupForm?.classroomId}
                    label="Name"
                    placeholder="Select classroom "
                    // required //not required in tour setup
                    options={classroomOptions}
                    value={classroomOptions?.find(
                      (option) =>
                        option?.value === studentSetupForm?.classroomId
                    )}
                    onChange={(selectedOption) =>
                      handleDropDown("classroomId", selectedOption?.value || "")
                    }
                    onFocus={() => {
                      handleFocus("classroomId");
                    }}
                    onBlur={() => {
                      handleBlur("classroomId", studentSetupForm?.classroomId);
                    }}
                  />
                  {studentSetupFormErrors?.classroomIdError && (
                    <span className="text-red-600 text-sm mt-1">
                      {studentSetupFormErrors?.classroomIdError}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-xl mb-3">
              <h2 className="text-xl text-secondary font-semibold mb-6 flex items-start">
                Critical Dates{" "}
              </h2>
              <div className="grid grid-cols-6 gap-3">
                <div className="w-full">
                  <label
                    htmlFor=""
                    className="text-sm text-secondaryVariant line-clamp-1"
                  >
                    Start Date
                    <div className="inline-flex item-center relative">
                      <RequiredAsterisk />
                      <span data-tooltip-id={tooltips.startDate.id}>
                        <IoMdInformationCircleOutline className="text-primary w-5 h-5" />
                      </span>
                    </div>
                  </label>
                  <TooltipComponent
                    className="max-w-[331px]"
                    id={tooltips.startDate.id}
                    content={tooltips.startDate.content}
                    isWhite={true}
                    place="right"
                  />
                  <div className="mt-1 w-full relative">
                    <ReactDatePicker
                      key={studentSetupForm?.studentJoiningDate}
                      value={revertToDateType(
                        studentSetupForm?.studentJoiningDate
                      )}
                      placeholder="MM/DD/YYYY"
                      onChange={(date) => {
                        handleDateSelect(date, "studentJoiningDate");
                      }}
                      onFocus={() => {
                        handleFocus("studentJoiningDate");
                      }}
                      onBlur={() => {
                        handleBlur(
                          "studentJoiningDate",
                          studentSetupForm?.studentJoiningDate
                        );
                      }}
                      minDate={
                        revertToDateType(studentSetupForm?.studentDOB) ||
                        undefined
                      }
                    />
                    <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
                  </div>
                  {studentSetupFormErrors?.studentJoiningDateError && (
                    <span className="text-red-600 text-sm mt-1">
                      {studentSetupFormErrors?.studentJoiningDateError}
                    </span>
                  )}
                </div>

                <div className="w-full">
                  <label htmlFor="" className="text-sm text-secondaryVariant">
                    Tour Date{" "}
                  </label>
                  <div className="w-full relative">
                    <ReactDatePicker
                      key={studentSetupForm?.studentTourDate}
                      value={revertToDateType(
                        studentSetupForm?.studentTourDate
                      )}
                      placeholder="MM/DD/YYYY"
                      onChange={(date) => {
                        handleDateSelect(date, "studentTourDate");
                      }}
                    />
                    <PiCalendarPlus className="w-5 h-5 absolute right-3 top-4 pointer-events-none" />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-white p-6 rounded-xl">
              <h2 className="text-xl text-secondary font-semibold mb-6">
                Parent/Guardian Info{" "}
              </h2>
              {studentSetupForm?.parentInfo?.map((parent, index) => (
                <div key={index}>
                  {studentSetupForm?.parentInfo?.length > 1 && (
                    <div className="flex items-center gap-4 mb-4">
                      <h3 className="text-lg text-secondary font-semibold ">
                        Parent/Guardian {index + 1}
                      </h3>
                    </div>
                  )}
                  <div className="grid grid-cols-6 gap-3 mb-6">
                    <div className="w-full">
                      <InputField
                        label="First Name"
                        type="text"
                        name="firstName"
                        placeholder="First Name"
                        value={parent?.firstName}
                        onChange={(e) => {
                          handleSectionChange(
                            index,
                            "parentInfo",
                            "firstName",
                            e?.target?.value
                          );
                        }}
                        onFocus={(name) => {
                          handleFocus(name, "parentInfoError", index);
                        }}
                        onBlur={(name) => {
                          handleBlur(
                            name,
                            studentSetupForm?.parentInfo[index]?.firstName,
                            "parentInfoError",
                            index
                          );
                        }}
                        required
                      />
                      {studentSetupFormErrors?.parentInfoError[index]
                        ?.firstNameError && (
                        <span className="text-red-600 text-sm mt-1">
                          {
                            studentSetupFormErrors?.parentInfoError[index]
                              ?.firstNameError
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <InputField
                        label="Last Name"
                        type="text"
                        name="lastName"
                        placeholder="Last Name"
                        value={parent?.lastName}
                        onChange={(e) => {
                          handleSectionChange(
                            index,
                            "parentInfo",
                            "lastName",
                            e?.target?.value
                          );
                        }}
                        onFocus={(name) => {
                          handleFocus(name, "parentInfoError", index);
                        }}
                        onBlur={(name) => {
                          handleBlur(
                            name,
                            studentSetupForm?.parentInfo[index]?.lastName,
                            "parentInfoError",
                            index
                          );
                        }}
                        required
                      />
                      {studentSetupFormErrors?.parentInfoError[index]
                        ?.lastNameError && (
                        <span className="text-red-600 text-sm mt-1">
                          {
                            studentSetupFormErrors?.parentInfoError[index]
                              ?.lastNameError
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <InputField
                        label="Email"
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={parent?.email}
                        onChange={(e) => {
                          handleSectionChange(
                            index,
                            "parentInfo",
                            "email",
                            e?.target?.value
                          );
                        }}
                        onFocus={(name) => {
                          handleFocus(name, "parentInfoError", index);
                        }}
                        onBlur={(name) => {
                          handleBlur(
                            name,
                            studentSetupForm?.parentInfo[index]?.email,
                            "parentInfoError",
                            index
                          );
                        }}
                        required
                      />
                      {studentSetupFormErrors?.parentInfoError[index]
                        ?.emailError && (
                        <span className="text-red-600 text-sm mt-1">
                          {
                            studentSetupFormErrors?.parentInfoError[index]
                              ?.emailError
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <label className="text-sm text-secondaryVariant">
                        Phone Number <RequiredAsterisk />
                      </label>
                      <Input
                        className="border border-secondaryVariant2 rounded-lg py-3.5 ps-3 pe-2 w-full bg-white"
                        country="US"
                        placeholder="(123) 456-7890"
                        value={parent?.phoneNumber}
                        onChange={(value) => {
                          handleSectionChange(
                            index,
                            "parentInfo",
                            "phoneNumber",
                            value
                          );
                        }}
                      />
                      {studentSetupForm?.parentInfo[index]?.phoneNumber &&
                        !isPossiblePhoneNumber(
                          studentSetupForm?.parentInfo[index]?.phoneNumber
                        ) && (
                          <span className="text-red-600 text-sm mt-1">
                            {ERR_MSGS.Invalid_Phone_Number}
                          </span>
                        )}
                      {/* <InputField
                                          label="Phone Number"
                                          type="text"
                                          name="phoneNumber"
                                          placeholder="Phone Number"
                                          value={parent?.phoneNumber}
                                          onChange={(e) => {
                                            handleSectionChange(
                                              index,
                                              "parentInfo",
                                              "phoneNumber",
                                              e?.target?.value
                                            );
                                          }}
                                          required
                                        /> */}
                    </div>
                  </div>
                  <div className="grid grid-cols-6 gap-3 mb-6">
                    <div className="w-full">
                      <InputField
                        label="Street"
                        type="text"
                        name="street"
                        placeholder="Street"
                        value={parent?.street}
                        onChange={(e) => {
                          handleSectionChange(
                            index,
                            "parentInfo",
                            "street",
                            e?.target?.value
                          );
                        }}
                        onFocus={(name) => {
                          handleFocus(name, "parentInfoError", index);
                        }}
                        onBlur={(name) => {
                          handleBlur(
                            name,
                            studentSetupForm?.parentInfo[index]?.street,
                            "parentInfoError",
                            index
                          );
                        }}
                        required
                      />
                      {studentSetupFormErrors?.parentInfoError[index]
                        ?.streetError && (
                        <span className="text-red-600 text-sm mt-1">
                          {
                            studentSetupFormErrors?.parentInfoError[index]
                              ?.streetError
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <InputField
                        label="City"
                        type="text"
                        name="city"
                        placeholder="City"
                        value={parent?.city}
                        onChange={(e) => {
                          handleSectionChange(
                            index,
                            "parentInfo",
                            "city",
                            e?.target?.value
                          );
                        }}
                        onFocus={(name) => {
                          handleFocus(name, "parentInfoError", index);
                        }}
                        onBlur={(name) => {
                          handleBlur(
                            name,
                            studentSetupForm?.parentInfo[index]?.city,
                            "parentInfoError",
                            index
                          );
                        }}
                        required
                      />
                      {studentSetupFormErrors?.parentInfoError[index]
                        ?.cityError && (
                        <span className="text-red-600 text-sm mt-1">
                          {
                            studentSetupFormErrors?.parentInfoError[index]
                              ?.cityError
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <ReactSelect
                        key={parent?.state}
                        label="State"
                        placeholder="State"
                        menuPlacement={"auto"}
                        required
                        options={states}
                        value={states?.find(
                          (option) => option.value === parent?.state
                        )}
                        onChange={(selectedOption) => {
                          // if (selectedOption?.value) {
                          setStudentSetupForm((prev) => {
                            const updatedParentInfo = [...prev.parentInfo];
                            updatedParentInfo[index].state =
                              (selectedOption?.value as string) || "";
                            return {
                              ...prev,
                              parentInfo: updatedParentInfo,
                            };
                          });
                          // }
                        }}
                        onFocus={() => {
                          handleFocus("state", "parentInfoError", index);
                        }}
                        onBlur={() => {
                          handleBlur(
                            "state",
                            studentSetupForm?.parentInfo[index]?.city,
                            "parentInfoError",
                            index
                          );
                        }}
                      />
                      {studentSetupFormErrors?.parentInfoError[index]
                        ?.stateError && (
                        <span className="text-red-600 text-sm mt-1">
                          {
                            studentSetupFormErrors?.parentInfoError[index]
                              ?.stateError
                          }
                        </span>
                      )}
                    </div>
                    <div className="w-full">
                      <InputField
                        label="Zip Code"
                        type="text"
                        name="zipCode"
                        placeholder="Zip Code"
                        value={parent?.zipCode}
                        onChange={(e) => {
                          if (e.target.value.length > 5) {
                          } else {
                            handleSectionChange(
                              index,
                              "parentInfo",
                              "zipCode",
                              e?.target?.value
                            );
                          }
                        }}
                        onFocus={(name) => {
                          handleFocus(name, "parentInfoError", index);
                        }}
                        onBlur={(name) => {
                          handleBlur(
                            name,
                            studentSetupForm?.parentInfo[index]?.zipCode,
                            "parentInfoError",
                            index
                          );
                        }}
                        required
                      />
                      {studentSetupFormErrors?.parentInfoError[index]
                        ?.zipCodeError && (
                        <span className="text-red-600 text-sm mt-1">
                          {
                            studentSetupFormErrors?.parentInfoError[index]
                              ?.zipCodeError
                          }
                        </span>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="flex items-center gap-4 mt-9">
            {/* <button className="btnPrimary max-w-[114px]">Save</button> */}
            <button
              className={`btnPrimary max-w-[156px] ${
                isSaveDisabled ? "opacity-50" : ""
              }`}
              onClick={async () => {
                //check duplicate student
                const duplicateStudent = await studentSvc.checkDuplicateStudent(
                  studentSetupForm?.studentFirstName,
                  studentSetupForm?.studentLastName
                );
                if (duplicateStudent?.data) {
                  setWarningModalData(
                    warningModalWarningsAndTexts.duplicateName
                  );
                  setSubmitType("save");
                  setShowWarningModal(true);
                } else {
                  handleSubmit("save");
                }
              }}
              disabled={isSaveDisabled}
            >
              Save Tour
            </button>
            <button className="btnSimple max-w-[92px]">Cancel</button>
          </div>
        </div>
        <DuplicateStudentWarningModal
          onClose={onClose}
          open={showWarningModal}
          onProceed={() => {
            warningModalData?.canProceed ? handleSubmit(submitType) : onClose();
          }}
          warning={warningModalData?.warning}
          text={warningModalData?.text}
        />
      </DashboardWrapper>
    </>
  );
};

export default TourSetup;

const hasErrors = (errors: unknown): boolean => {
  if (typeof errors === "string") {
    return errors?.trim() !== ""; // Return true if the string is a non-empty error
  }

  if (Array.isArray(errors)) {
    // Recursively check each item in the array
    return errors?.some((item) => hasErrors(item));
  }

  if (typeof errors === "object" && errors !== null) {
    // Recursively check each value in the object
    return Object.values(errors)?.some((value) => hasErrors(value));
  }

  return false; // No errors found
};

// Check for empty values in the required fields of the form
const hasEmptyRequiredFields = (form: typeof initStudentSetupForm): boolean => {
  const requiredFields = [
    "studentFirstName",
    "studentLastName",
    "studentDOB",
    "studentStatus",
    "studentJoiningDate",
    // "classroomId",
  ];

  for (const field of requiredFields) {
    if (!form[field as keyof typeof initStudentSetupForm]?.toString()?.trim()) {
      return true; // Empty required field found
    }
  }

  // Check nested required fields (parentInfo, additionalContact, temporaryAbsences)
  if (
    form.parentInfo?.some(
      (parent) =>
        !parent.firstName?.trim() ||
        !parent.lastName?.trim() ||
        !parent.email?.trim() ||
        !parent.phoneNumber?.trim() ||
        !parent.city?.trim() ||
        !parent?.state?.trim() ||
        !parent.zipCode?.trim()
    )
  ) {
    return true;
  }

  if (
    form?.additionalContact?.some((contact) => {
      if (!contact) return false;
      else
        return (
          !contact.firstName?.trim() ||
          !contact.lastName?.trim() ||
          !contact.email?.trim() ||
          !contact.phone?.trim()
        );
    })
  ) {
    return true;
  }

  // if (
  //   form.temporaryAbsences?.some(
  //     (absence) => !absence.startDate?.trim() || !absence.endDate?.trim()
  //   )
  // ) {
  //   return true;
  // }

  if (
    form.classroomTimeSlot &&
    form.classroomTimeSlot !== Slot.FULL_TIME &&
    (!form.slotDays || form.slotDays.length === 0)
  ) {
    return true;
  }

  return false; // No empty required fields found
};
