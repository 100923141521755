import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { MdOutlineClose } from "react-icons/md";
import "../../../../../node_modules/react-datepicker/dist/react-datepicker.module.css";

interface Props {
  value?: Date | null;
  placeholder?: string;
  onChange?: (date: Date | null) => void;
  minDate?: Date;
  maxDate?: Date;
  isMonday?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  disabled?: boolean;
  small?: boolean;
}

const ReactDatePicker: React.FC<Props> = ({
  value,
  placeholder,
  onChange,
  minDate,
  maxDate,
  isMonday,
  onFocus,
  onBlur,
  disabled,
  small,
}) => {
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const containerRef = useRef<HTMLDivElement>(null);

  const handleDateChange = (date: Date | null) => {
    setStartDate(date);
    onChange?.(date);
    setIsOpen(false);
  };

  useEffect(() => {
    setStartDate(value || null);
  }, [value]);

  const handleIsMonday = (date: Date) => {
    return date.getDay() === 1;
  };

  return (
    <div
      ref={containerRef}
      className={`relative ${isOpen ? "" : ""}`} // Add padding bottom when open
    >
      <DatePicker
        className={`border border-secondaryVariant2 rounded-lg w-full relative focus:outline-none ${disabled ? "opacity-50" : "cursor-pointer"
          } ${small ? "px-2 py-1 min-h-[36px] text-sm" : "px-3 py-3.5 text-base"
          }`}
        placeholderText={placeholder}
        selected={startDate}
        onChange={handleDateChange}
        minDate={minDate}
        maxDate={maxDate}
        fixedHeight
        filterDate={isMonday ? handleIsMonday : undefined}
        onFocus={() => {
          setIsOpen(true);
          onFocus?.();
        }}
        onBlur={() => {
          setIsOpen(false)
          onBlur?.();
        }}
        disabled={disabled}
        popperPlacement={"bottom"}
        popperClassName="react-datepicker-popper-z-index"
        open={isOpen}
        onInputClick={() => setIsOpen(true)}
        onClickOutside={() => setIsOpen(false)}
        inline={false}
        portalId="datePickerPortal"
        strictParsing
      />
      {value && (
        <button
          className={`absolute right-11 top-[18px] ${disabled ? "opacity-50 cursor-default" : ""
            } ${small ? "text-sm" : "text-base"}`}
          onClick={(e) => {
            e.stopPropagation();
            onChange?.(null);
          }}
          disabled={disabled}
        >
          <MdOutlineClose className="w-4 h-4 text-secondary" />
        </button>
      )}
    </div>
  );
};

export default ReactDatePicker;
