import { useQuery } from "@tanstack/react-query";
import { StripeQueryKeyString } from "../enums/stripeQueryKeyString";
import { stripeSvc } from "../services/stripe.service";

export const useMyCurrentSubscription = () => {
  return useQuery({
    queryKey: [StripeQueryKeyString.MyCurrentSubscription],
    queryFn: () => stripeSvc.fetchMyCurrentSubscription(),
  });
};
