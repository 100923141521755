import { useEffect, useState } from "react";
import { IoMdInformationCircleOutline } from "react-icons/io";
import { ChecklistType } from "../../hr-setup_modules/enums/checklist";
import { showErrorToast } from "../../../shared/utils/toast";
import { useCenterSetup } from "../../hooks/useCenterSetup";
import { useChecklist } from "../../hooks/useChecklist";
import { useCompleteChecklist } from "../../hooks/useCompleteDailyChecklist";
import { IChecklist } from "../../types/IChecklist.interface";

import { useNavigate } from "react-router-dom";
import { useUpdateTask } from "../../hooks/useUpdateTask";
import { forayRoutes } from "../../routes/routes";
import { ITasks, ITasksResponse } from "../../types/ITaskGeneration.interface";
import { getTodayDayName } from "../../utils/functions/getDayOfWeek";

interface TimelineItem {
  id: string;
  time: string;
  type: "task" | "checklist";
  data: ITasks | IChecklist;
}

interface ForayTrackingProps {
  tasksData?: ITasksResponse;
  categories?: any;
}

const ForayTracking = ({ tasksData, categories }: ForayTrackingProps) => {
  const [checklistInitialsMap, setChecklistInitialsMap] = useState<{
    [key: string]: string;
  }>({});
  const [initialsMap, setInitialsMap] = useState<{ [key: string]: string }>({});
  const [operatingHours, setOperatingHours] = useState({
    startTime: "10:00",
    endTime: "20:00",
  });
  const [timeProgress, setTimeProgress] = useState<number>(0);
  const navigate = useNavigate();
  // console.log('tasksData',tasksData)
  // const { data: categories } = useCategories();
  const { data: checklist } = useChecklist();
  const { data: centerSetup } = useCenterSetup();
  const { mutate: checklistItemComplete } = useCompleteChecklist();
  const { mutate: updateTask } = useUpdateTask();

  const handleMarkAsDone = (task: ITasks) => {
    const initials = initialsMap[task._id];
    if (!initials?.trim()) {
      showErrorToast("Please enter initials before marking as done");
      return;
    }
    updateTask({
      taskId: task._id,
      reqBody: { initials: initials },
    });
  };

  const handleChecklistMarkAsDone = (checklistItem: IChecklist) => {
    const initials = checklistInitialsMap[checklistItem._id];
    if (!initials?.trim()) {
      showErrorToast("Please enter initials before marking as done");
      return;
    }
    checklistItemComplete({
      checklistId: checklistItem._id,
      initials,
    });
  };

  // Convert 24-hour format to 12-hour format
  const convertTo12Hour = (time24: string) => {
    if (!time24) return "";
    if (time24.includes("AM") || time24.includes("PM")) {
      return time24;
    }
    const [hours, minutes] = time24.split(":");
    const hour = parseInt(hours);
    const ampm = hour >= 12 ? "PM" : "AM";
    const hour12 = hour % 12 || 12;
    return `${hour12}:${minutes} ${ampm}`;
  };

  // Convert time string to minutes since midnight
  const convertToMinutes = (time: string): number => {
    if (!time) return 0;

    // Check if the time is already in 24-hour format (no AM/PM)
    if (!time.includes(" ")) {
      const [hours, minutes] = time.split(":").map(Number);
      return hours * 60 + minutes;
    }

    // Handle 12-hour format with AM/PM
    const [timeStr, period] = time.split(" ");
    const [hours, minutes] = timeStr.split(":").map(Number);
    let hour24 = hours;

    // Convert AM/PM to 24-hour time
    if (period === "AM" && hour24 === 12) {
      hour24 = 0; // Midnight case
    } else if (period === "PM" && hour24 !== 12) {
      hour24 += 12; // Convert PM times
    }

    return hour24 * 60 + (minutes || 0);
  };

  // Helper function to extract hour from time string
  const getTimeHour = (time: string): number => {
    if (!time) return 0;

    if (time.includes("AM") || time.includes("PM")) {
      const [timeStr, period] = time.split(" ");
      let [hours] = timeStr.split(":").map(Number);
      if (period === "PM" && hours !== 12) {
        hours += 12;
      } else if (period === "AM" && hours === 12) {
        hours = 0;
      }
      return hours;
    } else {
      const [hours] = time.split(":").map(Number);
      return hours;
    }
  };

  // Create a fixed timeline with all possible hours between operating hours
  const getFixedTimeline = () => {
    // Get start and end hours from operating hours
    const startHour = getTimeHour(operatingHours.startTime);
    const endHour = getTimeHour(operatingHours.endTime);

    // Create an array of all hours between start and end
    const allHours: number[] = [];
    for (let hour = startHour; hour <= endHour; hour++) {
      allHours.push(hour);
    }

    return allHours;
  };

  // Group times by hour for display purposes
  const getGroupedTimes = () => {
    const times = getSortedTimes();
    const groupedTimes: { [hour: number]: string[] } = {};

    // Initialize with all hours from the fixed timeline
    getFixedTimeline().forEach((hour) => {
      groupedTimes[hour] = [];
    });

    // Add actual times to their respective hours
    times.forEach((time) => {
      const hour = getTimeHour(time);
      if (groupedTimes[hour]) {
        // Only add if the hour is within our timeline
        groupedTimes[hour].push(time);
      }
    });

    return groupedTimes;
  };

  const getSortedTimes = () => {
    const times = new Set<string>();

    // Add task times
    tasksData?.data?.forEach((task) => {
      const category = categories?.data?.find(
        (cat: { _id: string }) => cat._id === task.categoryId?._id
      );
      if (category) {
        times.add(convertTo12Hour(category.scheduledTime));
      }
    });

    checklist?.data?.forEach((item) => {
      if (
        item.type === ChecklistType.Daily &&
        item.dayOfWeek === getTodayDayName() &&
        item.isVisibleOnDashboard === true &&
        item.time
      ) {
        times.add(item.time);
      }
    });

    return Array.from(times).sort((a, b) => {
      const timeA = new Date(`1970/01/01 ${a}`);
      const timeB = new Date(`1970/01/01 ${b}`);
      return timeA.getTime() - timeB.getTime();
    });
  };

  const getTasksForTime = (time: string) => {
    // console.log('time',time)
    return (
      tasksData?.data?.filter((task) => {
        const category = categories?.data?.find(
          (cat: any) => cat._id === task.categoryId?._id
        );
        return category && convertTo12Hour(category.scheduledTime) === time;
      }) || []
    );
  };

  const getChecklistsForTime = (time: string) => {
    return (
      checklist?.data?.filter(
        (item) =>
          item.type === ChecklistType.Daily &&
          item.dayOfWeek === getTodayDayName() &&
          item.time === time
      ) || []
    );
  };

  // Combine tasks and checklists into a single timeline
  const getTimelineItems = (): Record<string, TimelineItem[]> => {
    const timeline: Record<string, TimelineItem[]> = {};
    // console.log('tasksData',tasksData?.data,'categories',categories?.data)
    // Add tasks to timeline
    if (tasksData?.data && categories?.data) {
      tasksData.data.forEach((task) => {
        const category = categories?.data?.find(
          (cat: any) => cat._id === task.categoryId?._id
        );
        if (category) {
          const time12h = convertTo12Hour(category.scheduledTime);
          if (!timeline[time12h]) timeline[time12h] = [];
          timeline[time12h].push({
            id: task._id,
            time: time12h,
            type: "task",
            data: task,
          });
        }
      });
    }

    // Add checklist items to timeline
    if (checklist?.data) {
      const todayChecklists = checklist.data.filter(
        (item: IChecklist) =>
          item.type === ChecklistType.Daily &&
          item.dayOfWeek === getTodayDayName()
      );

      todayChecklists.forEach((item) => {
        if (item.time) {
          if (!timeline[item.time]) timeline[item.time] = [];

          timeline[item.time].push({
            id: item._id,
            time: item.time,
            type: "checklist",
            data: item,
          });
        }
      });
    }

    return timeline;
  };

  const getStatusClass = (item: TimelineItem): string => {
    if (item.type === "task") {
      const task = item.data as ITasks;
      if (task.completed) return "border-successAlert bg-successAlert";
    } else {
      const checklist = item.data as IChecklist;
      if (checklist.isCompleted) return "border-successAlert bg-successAlert";
    }

    const currentTime = new Date();
    const itemTime = new Date();
    const [hours, minutes] = item.time.split(":").map(Number);
    itemTime.setHours(hours, minutes);

    if (itemTime < currentTime) {
      return "border-dangerAlert bg-dangerAlert";
    }
    return "border-gray-400 bg-gray-400";
  };

  useEffect(() => {
    if (centerSetup?.existingCenterSetup?.operatingHours?._id) {
      const startEndTimes = {
        startTime: centerSetup.existingCenterSetup.operatingHours?.startTime,
        endTime: centerSetup.existingCenterSetup.operatingHours?.endTime,
      };
      setOperatingHours(startEndTimes);
    }
  }, [centerSetup]);

  useEffect(() => {
    const calculateTimeProgress = () => {
      const now = new Date();
      const currentTimeInMinutes = now.getHours() * 60 + now.getMinutes();

      // Check if operatingHours is defined and valid
      if (
        !operatingHours ||
        !operatingHours.startTime ||
        !operatingHours.endTime
      ) {
        console.error("Invalid operating hours.");
        return;
      }

      // Convert start and end times to minutes since midnight
      const operatingStartMinutes = convertToMinutes(operatingHours.startTime);
      const operatingEndMinutes = convertToMinutes(operatingHours.endTime);

      const totalOperatingMinutes = operatingEndMinutes - operatingStartMinutes;

      // Ensure the operating hours are valid (start time should be before end time)
      if (totalOperatingMinutes <= 0) {
        console.error("Invalid operating hours: start time is after end time.");
        return;
      }

      // Calculate progress within operating hours
      let progress = 0;
      if (currentTimeInMinutes < operatingStartMinutes) {
        // Before operating hours
        progress = 0;
      } else if (currentTimeInMinutes > operatingEndMinutes) {
        // After operating hours
        progress = 100;
      } else {
        // During operating hours
        // Use integer values for progress
        const elapsedMinutes = currentTimeInMinutes - operatingStartMinutes;
        progress = Math.round((elapsedMinutes / totalOperatingMinutes) * 100);
      }

      console.log("progress", progress);
      setTimeProgress(progress);
    };

    // Calculate progress when component mounts or when operating hours change
    calculateTimeProgress();

    // Update progress every minute
    const interval = setInterval(calculateTimeProgress, 60000);

    // Clean up interval on component unmount
    return () => clearInterval(interval);
  }, [operatingHours]); // Recalculate when operatingHours changes

  const timelineItems = getTimelineItems();
  const sortedTimes = Object.keys(timelineItems).sort((a, b) => {
    const [aHours, aMinutes] = a.split(":").map(Number);
    const [bHours, bMinutes] = b.split(":").map(Number);
    return aHours * 60 + aMinutes - (bHours * 60 + bMinutes);
  });
  const convertTo24Hour = (time: string) => {
    const [timeStr, period] = time.split(" ");
    const [hours, minutes] = timeStr.split(":").map(Number);
    if (period === "PM" && hours !== 12) {
      return `${hours + 12}:${minutes}`;
    } else if (period === "AM" && hours === 12) {
      return `00:${minutes}`;
    }
    return `${hours}:${minutes}`;
  };

  // Helper function to check if a time is in the past
  const isTimeInPast = (time: string): boolean => {
    const now = new Date();
    const itemTime = new Date();

    // Handle both 12-hour and 24-hour formats
    if (time.includes("AM") || time.includes("PM")) {
      const [timeStr, period] = time.split(" ");
      const [hours, minutes] = timeStr.split(":").map(Number);
      let hour = hours;

      if (period === "PM" && hours !== 12) {
        hour = hours + 12;
      } else if (period === "AM" && hours === 12) {
        hour = 0;
      }

      itemTime.setHours(hour, minutes, 0, 0);
    } else {
      const [hours, minutes] = time.split(":").map(Number);
      itemTime.setHours(hours, minutes, 0, 0);
    }

    return itemTime < now;
  };

  // Calculate the position percentage for a given time or hour
  const progressPercentage = (timeOrHour: string | number) => {
    let timeInMinutes: number;

    // Handle both string time and numeric hour
    if (typeof timeOrHour === "string") {
      timeInMinutes = convertToMinutes(timeOrHour);
    } else {
      // If just an hour is provided
      timeInMinutes = timeOrHour * 60;
    }

    // Get operating hours in minutes
    const startMinutes = convertToMinutes(operatingHours.startTime);
    const endMinutes = convertToMinutes(operatingHours.endTime);
    const totalOperatingMinutes = endMinutes - startMinutes;

    // Calculate elapsed minutes
    const elapsedMinutes = timeInMinutes - startMinutes;

    if (elapsedMinutes <= 0) return 0;
    if (elapsedMinutes >= totalOperatingMinutes) return 100;

    return Math.round((elapsedMinutes / totalOperatingMinutes) * 100);
  };

  // This function is no longer needed as we're using timeProgress directly
  // Keeping it for potential future use
  const getCurrentTimePercentage = () => {
    return timeProgress;
  };

  const navigateToMonthlyChecklist = () => {
    navigate(forayRoutes.checkList, {
      state: {
        checklistType: ChecklistType.Monthly,
      },
    });
  };

  const navigateToDailyChecklist = () => {
    navigate(forayRoutes.checkList);
  };

  const parseTimeString = (timeStr: string): Date => {
    const today = new Date();
    const [timePart, modifier] = timeStr.split(" ");
    let [hours, minutes] = timePart.split(":").map(Number);

    if (modifier === "PM" && hours < 12) {
      hours += 12;
    } else if (modifier === "AM" && hours === 12) {
      hours = 0;
    }

    today.setHours(hours, minutes, 0, 0);
    return today;
  };
  const startTime = parseTimeString(operatingHours.startTime);
  const endTime = parseTimeString(operatingHours.endTime);
  const currentTime = new Date();

  const isWithinOperatingHours =
    currentTime >= startTime && currentTime <= endTime;
  return (
    <div className="bg-white rounded-xl pb-4">
      <div className="flex items-center justify-between bg-primary rounded-t-xl px-6 py-2">
        <div className="flex items-center gap-9">
          <h3 className="text-2xl text-white font-semibold flex items-center gap-1">
            Tracking{" "}
            <IoMdInformationCircleOutline className="w-5 h-5 text-white" />
          </h3>
          <h3 className="text-lg font-medium text-white">
            {new Date().toLocaleDateString("en-US", {
              weekday: "short",
              month: "short",
              day: "numeric",
              year: "numeric",
            })}
          </h3>
        </div>
        <div className="flex items-center gap-4">
          <button
            onClick={() => navigateToDailyChecklist()}
            className="text-lg text-secondary font-semibold rounded-lg py-2 px-3 bg-secondaryNeutral"
          >
            Daily Checklist
          </button>
          <button
            onClick={() => navigateToMonthlyChecklist()}
            className="text-lg text-secondary font-semibold rounded-lg py-2 px-3 bg-secondaryNeutral"
          >
            Monthly Checklist
          </button>
        </div>
      </div>
      <div className="bg-primaryVariant2 px-6 flex items-center gap-6 py-3">
        <div className="text-sm text-secondaryVariant font-semibold flex items-center gap-2">
          Task Completed
          <span className="w-4 h-4 border-[3px] rounded-full block border-[#79CC25] bg-[#79CC25]" />
        </div>
        <div className="text-sm text-secondaryVariant font-semibold flex items-center gap-2">
          Task Upcoming
          <span className="w-4 h-4 border-[3px] rounded-full block border-[#79CC25] bg-transparent" />
        </div>
        <div className="text-sm text-secondaryVariant font-semibold flex items-center gap-2">
          Checklist Item Completed
          <span className="w-4 h-4 border-[3px] rounded-full block border-[#29D1C6] bg-[#29D1C6]" />
        </div>
        <div className="text-sm text-secondaryVariant font-semibold flex items-center gap-2">
          Checklist Item Completed
          <span className="w-4 h-4 border-[3px] rounded-full block border-[#29D1C6] bg-transparent" />
        </div>
        <div className="text-sm text-secondaryVariant font-semibold flex items-center gap-2">
          Not Completed
          <span className="w-4 h-4 border-[3px] rounded-full block border-[#FF0000] bg-[#FF0000]" />
        </div>
      </div>

      {/* Tasks Section */}
      <div className="flex px-6 mt-4">
        <div className="w-[70px] font-medium text-secondary">Tasks</div>
        <div className="flex-1 relative pe-[70px]">
          <div className="grid grid-rows-1 grid-flow-col gap-1">
            {Object.entries(getGroupedTimes()).map(([hour, times]) => (
              <div
                key={`hour-${hour}`}
                className="flex flex-col items-start"
                style={{
                  position: "relative",
                  left: `${progressPercentage(parseInt(hour))}%`,
                }}
              >
                {times.map((time) => (
                  <div key={`task-time-${time}`} className="flex flex-col mb-1">
                    {getTasksForTime(time).map((task, index) => (
                      <div
                        key={`task-${task._id}-${index}`}
                        className="relative group mb-1"
                      >
                        <span
                          className={`w-4 h-4 border-[3px] rounded-full inline-block ${
                            task.completed
                              ? "border-[#79CC25] bg-[#79CC25]"
                              : "border-[#79CC25] bg-transparent"
                          }`}
                        />
                        {/* Task Popup */}
                        <div className="absolute right-0 left-auto top-full mt-2 text-black min-w-[308px] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-[99999]">
                          <div className="absolute -top-0 left-4 w-4 h-4 bg-white rotate-45 transform origin-center shadow-lg z-[-1]" />
                          <div className="px-4 py-6 bg-white z-5 rounded-md shadow-lg">
                            <div className="flex items-start justify-between gap-2">
                              <p className="text-base text-secondary">
                                {task.categoryId?.title}
                              </p>
                              <p className="text-xs text-secondaryVariant">
                                {convertTo12Hour(
                                  categories?.data?.find(
                                    (cat: any) =>
                                      cat._id === task.categoryId?._id
                                  )?.scheduledTime || ""
                                )}
                              </p>
                            </div>
                            <p className="text-xs text-secondaryVariant">
                              Student: {task.studentId?.firstName}{" "}
                              {task.studentId?.lastName}
                            </p>
                            <div className="flex items-end">
                              <div className="flex-1">
                                <h4 className="text-secondary">Initials</h4>
                                <input
                                  disabled={task.initials ? true : false}
                                  type="text"
                                  maxLength={2}
                                  className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
                                  value={
                                    initialsMap[task._id] !== undefined
                                      ? initialsMap[task._id]
                                      : task.initials || ""
                                  }
                                  onChange={(e) =>
                                    setInitialsMap((prev) => ({
                                      ...prev,
                                      [task._id]: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                              <button
                                disabled={task.completed}
                                className="bg-primary disabled:bg-gray-300 text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
                                onClick={() => handleMarkAsDone(task)}
                              >
                                Mark as Done
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>

      {/* Time Progress Bar */}
      <div className="flex items-center gap-2 my-4 px-6">
        <span className="text-sm text-primary font-semibold w-[78px] whitespace-nowrap">
          {convertTo12Hour(operatingHours.startTime || "6 AM")}
        </span>
        <div className="bg-secondaryNeutral rounded-[5px] h-[37px] w-full ">
          <div
            className="bg-secondaryVariant rounded-[5px] h-[37px] transition-all duration-300 relative"
            style={{ width: `${timeProgress}%` }}
          >
            {isWithinOperatingHours && (
              <span className="text-sm text-white font-semibold absolute right-2 top-2 z-10">
                {new Date().toLocaleTimeString([], {
                  hour: "2-digit",
                  minute: "2-digit",
                })}
              </span>
            )}
          </div>
        </div>
        <span className="text-sm text-secondaryVariant font-semibold w-[60px] text-right">
          {convertTo12Hour(operatingHours.endTime || "6 PM")}
        </span>
      </div>

      {/* Checklist Section */}
      <div className="flex px-6">
        <div className="w-[78px] font-medium text-secondary">Checklist</div>
        <div className="flex-1 relative pe-[90px]">
          <div className="grid grid-rows-1 grid-flow-col gap-1">
            {Object.entries(getGroupedTimes()).map(([hour, times]) => (
              <div
                key={`checklist-hour-${hour}`}
                className="flex flex-col items-start"
                style={{
                  position: "relative",
                  left: `${progressPercentage(parseInt(hour))}%`,
                }}
              >
                {times.map((time) => (
                  <div
                    key={`checklist-time-${time}`}
                    className="flex flex-col mb-1"
                  >
                    {getChecklistsForTime(time).map((checklistItem, index) => (
                      <div
                        key={`checklist-${checklistItem._id}-${index}`}
                        className="relative group mb-1"
                      >
                        <span
                          className={`w-4 h-4 border-[3px] rounded-full inline-block ${
                            checklistItem.isCompleted
                              ? "border-[#29D1C6] bg-[#29D1C6]"
                              : checklistItem.time &&
                                isTimeInPast(checklistItem.time)
                              ? "border-[#FF0000] bg-[#FF0000]"
                              : "border-[#29D1C6] bg-transparent"
                          }`}
                        />
                        {/* Checklist Popup */}
                        <div className="absolute right-0 left-auto top-full mt-2 text-black min-w-[308px] opacity-0 invisible group-hover:opacity-100 group-hover:visible transition-all duration-300 z-[99999]">
                          <div className="absolute -top-0 left-4 w-4 h-4 bg-white rotate-45 transform origin-center shadow-lg z-[-1]" />
                          <div className="px-4 py-6 bg-white z-5 rounded-md shadow-lg">
                            <div className="flex items-start justify-between gap-2">
                              <p className="text-base text-secondary">
                                {checklistItem.task}
                              </p>
                              <p className="text-xs text-secondaryVariant">
                                {checklistItem.time}
                              </p>
                            </div>
                            <p className="text-xs text-secondaryVariant">
                              {checklistItem.dayOfWeek}
                            </p>
                            <div className="flex items-end">
                              <div className="flex-1">
                                <h4 className="text-secondary">Initials</h4>
                                <input
                                  maxLength={2}
                                  disabled={
                                    checklistItem.completedBy ? true : false
                                  }
                                  type="text"
                                  className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
                                  value={
                                    checklistInitialsMap[checklistItem._id] !==
                                    undefined
                                      ? checklistInitialsMap[checklistItem._id]
                                      : checklistItem.completedBy || ""
                                  }
                                  onChange={(e) =>
                                    setChecklistInitialsMap((prev) => ({
                                      ...prev,
                                      [checklistItem._id]: e.target.value,
                                    }))
                                  }
                                />
                              </div>
                              <button
                                disabled={!!checklistItem.completedBy}
                                className="bg-primary disabled:bg-gray-300 text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
                                onClick={() =>
                                  handleChecklistMarkAsDone(checklistItem)
                                }
                              >
                                Mark as Done
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

// Popup Components
const TaskPopup: React.FC<{
  task: ITasks;
  time: string;
  categories: any;
  initialsMap: { [key: string]: string };
  setInitialsMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  handleMarkAsDone: (task: ITasks) => void;
}> = ({
  task,
  time,
  categories,
  initialsMap,
  setInitialsMap,
  handleMarkAsDone,
}) => (
  <>
    <div className="flex items-start justify-between gap-2">
      <p className="text-base text-secondary">{task.categoryId?.title}</p>
      <p className="text-xs text-secondaryVariant">{time}</p>
    </div>
    <p className="text-xs text-secondaryVariant">
      Student: {task.studentId?.firstName} {task.studentId?.lastName}
    </p>
    <div className="flex items-end">
      <div className="flex-1">
        <h4 className="text-secondary">Initials</h4>
        <input
          disabled={task.initials ? true : false}
          type="text"
          className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
          value={
            initialsMap[task._id] !== undefined
              ? initialsMap[task._id]
              : task.initials || ""
          }
          onChange={(e) =>
            setInitialsMap((prev) => ({ ...prev, [task._id]: e.target.value }))
          }
        />
      </div>
      <button
        disabled={task.completed}
        className="bg-primary disabled:bg-gray-300 text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
        onClick={() => handleMarkAsDone(task)}
      >
        Mark as Done
      </button>
    </div>
  </>
);

const ChecklistPopup: React.FC<{
  checklist: IChecklist;
  checklistInitialsMap: { [key: string]: string };
  setChecklistInitialsMap: React.Dispatch<
    React.SetStateAction<{ [key: string]: string }>
  >;
  handleChecklistMarkAsDone: (checklist: IChecklist) => void;
}> = ({
  checklist,
  checklistInitialsMap,
  setChecklistInitialsMap,
  handleChecklistMarkAsDone,
}) => (
  <>
    <div className="flex items-start justify-between gap-2">
      <p className="text-base text-secondary">{checklist.task}</p>
      <p className="text-xs text-secondaryVariant">{checklist.time}</p>
    </div>
    <p className="text-xs text-secondaryVariant">{checklist.dayOfWeek}</p>
    <div className="flex items-end">
      <div className="flex-1">
        <h4 className="text-secondary">Initials</h4>
        <input
          disabled={checklist.completedBy ? true : false}
          type="text"
          className="bg-white rounded-md border border-secondaryVariant w-[60px] h-[36px] p-2"
          value={
            checklistInitialsMap[checklist._id] !== undefined
              ? checklistInitialsMap[checklist._id]
              : checklist.completedBy || ""
          }
          onChange={(e) =>
            setChecklistInitialsMap((prev) => ({
              ...prev,
              [checklist._id]: e.target.value,
            }))
          }
        />
      </div>
      <button
        disabled={!!checklist.completedBy}
        className="bg-primary disabled:bg-gray-300 text-white rounded-md min-w-[131px] h-[33px] flex items-center justify-center"
        onClick={() => handleChecklistMarkAsDone(checklist)}
      >
        Mark as Done
      </button>
    </div>
  </>
);

export default ForayTracking;
