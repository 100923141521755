import { useMutation, useQueryClient } from "@tanstack/react-query";
import { StaffQueryKeyString } from "../../enums/staffQueryKeyString";
import { staffSvc } from "../../services/staff.service";
import {
  EducationalInformation,
  PersonalInformation,
  ProfessionalInformation,
  UploadedDocuments,
} from "../../types/IStaff";

// Define UpdateStaffDto to match the backend structure
interface UpdateStaffDto {
  personalInformation?: PersonalInformation;
  educationalInformation?: EducationalInformation;
  professionalInformation?: ProfessionalInformation;
  uploadedDocuments?: UploadedDocuments;
}

export const useUpdateStaffMutation = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      staffId,
      updatedData,
    }: {
      staffId: string;
      updatedData: UpdateStaffDto;
    }) => staffSvc.updateStaff(staffId, updatedData),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [StaffQueryKeyString.Staff],
      });
    },
  });
};