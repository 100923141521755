import { useMutation, useQueryClient } from "@tanstack/react-query";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";

export const useUpdateCategory = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({
      categoryId,
      reqBody,
    }: {
      categoryId: string;
      reqBody: { title: string; scheduledTime: string };
    }) => taskGenerationSvc.updateCategory(categoryId, reqBody),
    onError: (err) => {
      showErrorToast("Failed to update category");
      console.error("error:", err);
    },
    onSuccess: () => {
      showSuccessToast("Category updated successfully");
      queryClient.invalidateQueries({
        queryKey: [TaskGenerationQueryKeyString.Category],
      });
    },
  });
};
