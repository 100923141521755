import React from "react";
import { Nav, Tab } from "react-bootstrap";
import { IoMdArrowBack } from "react-icons/io";
import { Link } from "react-router-dom";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import InputField from "../../../components/inputsFields/InputField";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import { forayRoutes } from "../../../routes/routes";
import DropdownWithinRadioInput from "../../components/DropdownWithinRadio&Input";

const tabKeysOvertimePayroll = {
  overtime: "overtime",
  payroll: "payroll",
  bonusPrograms: "bonusPrograms",
}

const OvertimePayroll = () => {
  const [isModalOpen, setModalOpen] = React.useState(false);

  const openModal = () => setModalOpen(true);
  const closeModal = () => setModalOpen(false);

  return (
    <DashboardWrapper>
      <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar space-y-6">
        <Link
          to={forayRoutes.hr_dashboard}
          className="text-sm text-secondaryVariant font-medium flex items-center gap-2"
        >
          <IoMdArrowBack className="w-5 h-5" />
          <span className="underline">Back to main</span>
        </Link>

        <Tab.Container id="reusable-tabs" defaultActiveKey={tabKeysOvertimePayroll.overtime}>
          <Nav
            variant="pills"
            className="flex items-center border-b border-secondaryVariant2 mb-6"
          >
            <Nav.Item>
              <Nav.Link
                eventKey={tabKeysOvertimePayroll.overtime}

                className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
              >
                Overtime
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={tabKeysOvertimePayroll.payroll}
                className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
              >
                Payroll
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                eventKey={tabKeysOvertimePayroll.bonusPrograms}
                className="inline-flex items-center gap-2 px-3 pb-3 text-lg [&.active]:text-primary [&.active]:font-medium border-b-[2px] border-transparent [&.active]:border-primary hover:text-primary hover:border-primary"
              >
                Bonus Programs
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Tab.Content>
            <Tab.Pane
              eventKey="overtime"
              className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
            >
              <div className="space-y-9">
                <h2 className="text-xl text-secondary font-semibold">
                  Overtime
                </h2>
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <ReactSelect
                      label="Overtime Rate"
                      placeholder="Time x 1.0"
                      required
                    />
                  </div>
                  <div>
                    <label className="text-base text-secondaryVariant2 block">
                      Overtime hours worked defined*
                    </label>
                    <DropdownWithinRadioInput />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <button className="btnPrimary max-w-[132px]">
                    Save Plan
                  </button>
                  <button className="btnSimple max-w-[100px]">Cancel </button>
                </div>
              </div>
            </Tab.Pane>

            <Tab.Pane
              eventKey="payroll"
              className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
            >
              <div className="space-y-9">
                <h2 className="text-xl text-secondary font-semibold">
                  Payroll
                </h2>
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <ReactSelect
                      label="Pay Period"
                      placeholder="2 weeks"
                      required
                    />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <button className="btnPrimary max-w-[132px]">
                    Save Plan
                  </button>
                  <button className="btnSimple max-w-[100px]">Cancel </button>
                </div>
              </div>
            </Tab.Pane>

            <Tab.Pane
              eventKey="bonusPrograms"
              className="hidden opacity-0 [&.show]:block [&.show]:opacity-100 duration-300 transition-opacity ease-linear"
            >
              <div className="space-y-9">
                <h2 className="text-xl text-secondary font-semibold">
                  Payroll
                </h2>
                <div className="grid grid-cols-3 gap-6">
                  <div>
                    <ReactSelect
                      label="Pay Period"
                      placeholder="2 weeks"
                      required
                    />
                  </div>
                </div>
                <h2 className="text-xl text-secondary font-semibold">
                  Bonus Programs
                </h2>

                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <ReactSelect
                      label={"Plan Type"}
                      placeholder={""}
                      required
                    />
                  </div>
                  <div>
                    <InputField
                      label="Plan Name (optional)"
                      type={"text"}
                      placeholder={""}
                    />
                  </div>
                </div>
                <div className="grid grid-cols-3 gap-4">
                  <div>
                    <ReactSelect
                      label={"Frequency"}
                      placeholder={""}
                      required
                    />
                  </div>
                  <div>
                    <ReactSelect
                      label={"How Much?"}
                      placeholder={""}
                      required
                    />
                  </div>
                </div>
                <div className="flex items-center gap-4">
                  <button className="btnPrimary max-w-[132px]">
                    Save Plan
                  </button>
                  <button className="btnSimple max-w-[100px]">Cancel </button>
                  {/* <BonusPlanModal isOpen={isModalOpen} onClose={closeModal} /> */}
                </div>
              </div>
              {/* <button
                className="text-base text-statusColor flex items-center gap-1"
                onClick={openModal}
              >
                <VscNewFile className="w-6 h-6" />
                Create Bonus Plan
              </button> */}
            </Tab.Pane>
          </Tab.Content>
        </Tab.Container>

        {/* <CreateBonusPlanModal isOpen={isModalOpen} onClose={closeModal} /> */}
      </div>
    </DashboardWrapper>
  );
};

export default OvertimePayroll;
