import React from "react";
import DashboardWrapper from "../../../../shared/components/layout/DashboardWrapper";
import { Link } from "react-router-dom";
import { IoMdArrowBack } from "react-icons/io";
import ReactSelect from "../../../components/reactSelect/ReactSelect";
import InputField from "../../../components/inputsFields/InputField";
import { HiOutlineTrash } from "react-icons/hi2";
import { CiCirclePlus } from "react-icons/ci";
import { forayRoutes } from "../../../routes/routes";

const EmployeeEvaluationsSetup = () => {
  return (
    <>
      <DashboardWrapper>
        <div className="px-6 pt-6 overflow-y-scroll h-[calc(100vh-84px)] custom-scrollbar space-y-6">
          <Link
            to={forayRoutes.hr_dashboard}
            className="text-sm text-secondaryVariant font-medium flex items-center gap-2"
          >
            <IoMdArrowBack className="w-5 h-5" />
            <span className="underline">Back to main</span>
          </Link>
          <h2 className="text-xl text-secondary font-semibold">
            Setup Employee Evaluations{" "}
          </h2>
          <div className="w-[280px]">
            <ReactSelect
              label={"Time to Next Evaluation*"}
              placeholder={"10 weeks"}
            />
          </div>
          <div className="px-6 pt-3 pb-6 rounded-md bg-white">
            <div className="grid grid-cols-12 gap-3 items-center mb-3 pe-[54px]">
              <div className="col-span-3 py-3 pe-3 text-sm text-secondary font-semibold">
                Category
              </div>
              <div className="col-span-3 py-3 text-sm text-secondary font-semibold">
                Section
              </div>
              <div className="col-span-2 py-3 text-sm text-secondary font-semibold">
                Total Possible
              </div>
              <div className="col-span-2 py-3 text-sm text-secondary font-semibold">
                <ReactSelect label={""} placeholder={"40%"} />
              </div>
              <div className="col-span-2 py-3 text-sm text-secondary font-semibold">
                <ReactSelect label={""} placeholder={"60%"} />
              </div>
            </div>
            <hr />
            <div className="flex items-center gap-3">
              <div className="grid grid-cols-12 gap-3 pt-3 w-full">
                <div className="col-span-3">
                  <InputField
                    type={""}
                    placeholder={"Enter Your Category Name"}
                  />
                  <button className="text-base text-statusColor flex items-center gap-1 mt-1">
                    <CiCirclePlus className="w-6 h-6" />
                    Add Category
                  </button>
                </div>
                <div className="col-span-3">
                  <InputField
                    type={""}
                    placeholder={"Enter Your Category Name"}
                  />
                  <button className="text-base text-statusColor flex items-center gap-1 mt-1">
                    <CiCirclePlus className="w-6 h-6" />
                    Add Category
                  </button>
                </div>
                <div className="col-span-2">
                  <InputField type={""} placeholder={"--:--"} />
                </div>
                <div className="col-span-2">
                  <InputField type={""} placeholder={"--:--"} />
                </div>
                <div className="col-span-2">
                  <InputField type={""} placeholder={"--:--"} />
                </div>
              </div>
              <div>
                <button className="bg-secondaryNeutral p-2 rounded-full text-dangerAlert">
                  <HiOutlineTrash className="w-6 h-6" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EmployeeEvaluationsSetup;
