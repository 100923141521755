import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  useReactTable,
} from "@tanstack/react-table";
import moment from "moment";
import { FC, useEffect, useRef, useState } from "react";
import {
  calculateAge,
  formatDateSlash,
  formatFullYearOnly,
  formatMonthDateSlash,
} from "../../../shared/utils/formatDate";
import { maskPhoneNumber } from "../../../shared/utils/formatNumbers";
import { ExistingCenterSetup } from "../../types/ICenterSetup.interface";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import {
  StudentInfoDetailed,
  SummerWeek,
} from "../../types/IStudentSetup.interface";
import CustomCheckbox from "../customCheckbox/CustomCheckbox";

const emptyVal = "-";
interface IProps {
  students: StudentInfoDetailed[];
  centerSetup: ExistingCenterSetup;
  summerWeeks: string[];
  handleUpdateStudent: (student: StudentInfoDetailed, comment: string) => void;
  handleGetClassroom: (classroomId: string) => IClassroomSetup | undefined;
}
const SummerRosterTable: FC<IProps> = ({
  students,
  centerSetup,
  summerWeeks,
  handleUpdateStudent,
  handleGetClassroom,
}) => {
  const [data, setData] = useState<StudentInfoDetailed[]>(students);
  const [comments, setComments] = useState<{ [key: string]: string }>({}); // Track comments per student
  const [editingRows, setEditingRows] = useState<{ [key: string]: boolean }>(
    {}
  ); // Track which row is being edited
  const inputRefs = useRef<{ [key: string]: HTMLTextAreaElement | null }>({}); // Track input field references

  const handleCommentBlur = (student: StudentInfoDetailed) => {
    handleUpdateStudent(student, comments[student._id as string] || ""); // Save the comment
    setEditingRows((prev) => ({ ...prev, [student._id as string]: false })); // Exit edit mode
  };

  const handleInputChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    studentId: string
  ) => {
    const { selectionStart } = e.target; // Get cursor position
    setComments((prev) => ({ ...prev, [studentId]: e.target.value }));

    // Delay restoring cursor position to avoid lag
    setTimeout(() => {
      if (inputRefs.current[studentId]) {
        inputRefs.current[studentId]!.selectionStart = selectionStart;
        inputRefs.current[studentId]!.selectionEnd = selectionStart;
      }
    }, 0);
  };

  const handleDateDifference = (
    idealDate: string | Date,
    actualDate: string | Date
  ) => {
    if (!idealDate || !actualDate) return emptyVal;
    const ideal = moment(idealDate);
    const actual = moment(actualDate);
    const diff = actual.diff(ideal, "days");
    if (diff > 0) {
      return "+" + diff + " Days";
    }
    return diff + " Days";
  };

  useEffect(() => {
    if (students?.length > 0) setData(students);
  }, [students]);

  const columnHelper = createColumnHelper<StudentInfoDetailed>();
  // const summerColumnHelper = createColumnHelper<string>();
  const basicInfoColsId = [
    "studentDetails",
    "studentFirstName",
    "studentLastName",
    "dob",
    "age",
  ];

  const handleCheckForSummerWeek = (
    summerWeeks: SummerWeek[] | undefined,
    weekDate: string
  ) => {
    if (!summerWeeks?.length || !weekDate) return "-"; // Return early

    return summerWeeks.some((week) =>
      moment(week?.weekStartDate).isSame(weekDate, "day")
    ) ? (
      <span className="flex items-center justify-center pl-3">
        {" "}
        <CustomCheckbox isPrimary checked />{" "}
      </span>
    ) : (
      "-"
    );
  };

  const handleSummerWeeksColumns = () => {
    return summerWeeks?.length > 0
      ? [
          // ✅ 1️⃣ First, render the **dynamic week columns** from `summerWeeks`
          ...summerWeeks?.map((week, index) =>
            columnHelper.accessor((row) => week, {
              id: `week_${index}`,
              header: () => (
                <span className="flex items-center gap-1 justify-center">
                  <CustomCheckbox isPrimary checked />
                  {formatMonthDateSlash(week)}
                </span>
              ),
              cell: (info) => (
                <div className="text-center whitespace-nowrap">
                  <span className="w-full">
                    {handleCheckForSummerWeek(
                      info?.row?.original?.summerWeeks,
                      week
                    ) || "-"}
                  </span>
                </div>
              ),
            })
          ),
          columnHelper.accessor((row) => row._id, {
            id: "totalPresentWeek",
            header: () => (
              <span className="flex items-center gap-1 justify-center min-w-[103px]">
                Total Present Week
              </span>
            ),
            cell: (info) => (
              <div className="text-center whitespace-nowrap font-semibold">
                <span className="w-full">
                  {info?.row?.original?.summerWeeks[0].weekNumber
                    ? info?.row?.original?.summerWeeks?.length
                    : 0}
                </span>
              </div>
            ),
          }),

          columnHelper.accessor((row) => row._id, {
            id: "totalAbsentWeek",
            header: () => (
              <span className="flex items-center gap-1 justify-center min-w-[103px]">
                Total Absent Week
              </span>
            ),
            cell: (info) => (
              <div className="text-center whitespace-nowrap font-semibold text-danger">
                <span className="w-full">
                  {info?.row?.original?.summerWeeks[0]?.weekNumber
                    ? summerWeeks?.length -
                      info?.row?.original?.summerWeeks?.length
                    : summerWeeks?.length || 0}
                </span>
              </div>
            ),
          }),
        ]
      : [
          columnHelper.accessor((row) => row._id, {
            id: "totalPresentWeek",
            header: () => (
              <span className="flex items-center gap-1 justify-center">
                Total Present Week
              </span>
            ),
            cell: (info) => (
              <div className="text-center whitespace-nowrap font-semibold">
                <span className="w-full">
                  {info?.row?.original?.summerWeeks?.length || 0}
                </span>
              </div>
            ),
          }),

          columnHelper.accessor((row) => row._id, {
            id: "totalAbsentWeek",
            header: () => (
              <span className="flex items-center gap-1 justify-center">
                Total Absent Week
              </span>
            ),
            cell: (info) => (
              <div className="text-center whitespace-nowrap font-semibold text-danger">
                <span className="w-full">
                  {summerWeeks?.length -
                    info?.row?.original?.summerWeeks?.length || 0}
                </span>
              </div>
            ),
          }),
        ];
  };

  const handleActivitiesColumns = () => {
    return centerSetup?.summerProgram?.activities?.length > 0
      ? [
          ...centerSetup?.summerProgram?.activities?.map((activity, index) =>
            columnHelper.accessor((row) => activity, {
              id: `${activity}`,
              header: () => (
                <span className="flex items-center gap-1 justify-center capitalize whitespace-nowrap">
                  {activity}
                </span>
              ),
              cell: (info) => (
                <div className="text-center whitespace-nowrap">
                  <span className="w-full">{"Yes"}</span>
                </div>
              ),
            })
          ),
        ]
      : [
          // columnHelper.accessor((row) => row.parents?.[0]?.lastName, {
          //   id: "parentLastName",
          //   header: "English",
          //   cell: (info) => (
          //     <div className="text-center whitespace-nowrap">
          //       <span className="w-full">{info.getValue() || emptyVal}</span>
          //     </div>
          //   ),
          // }),
          // columnHelper.accessor((row) => row.parents?.[0]?.phone, {
          //   id: "phoneNo",
          //   header: "Sports",
          //   cell: (info) => (
          //     <div className="text-center whitespace-nowrap">
          //       <span className="w-full">
          //         {info.getValue() ? maskPhoneNumber(info.getValue()) : emptyVal}
          //       </span>
          //     </div>
          //   ),
          // }),
          columnHelper.accessor((row) => row.parents?.[0]?.phone, {
            id: "undecided",
            header: "Undecided",
            cell: (info) => (
              <div className="text-center whitespace-nowrap">
                <span className="w-full">
                  {info.getValue()
                    ? maskPhoneNumber(info.getValue())
                    : emptyVal}
                </span>
              </div>
            ),
          }),
        ];
  };

  const columns = [
    // Student Details Section - with changed background color
    columnHelper.group({
      id: "studentDetails",
      header: () => <span className="font-medium">Student Details</span>,
      columns: [
        columnHelper.accessor((row) => row.firstName, {
          id: "studentFirstName",
          header: "First",
          cell: (info) => (
            <div className="text-center w-[135px] truncate line-clamp-1 whitespace-nowrap">
              <span className="w-full">{info.getValue() || emptyVal}</span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.lastName, {
          id: "studentLastName",
          header: "Last",
          cell: (info) => (
            <div className="text-center whitespace-nowrap w-[135px] truncate line-clamp-1">
              <span className=" w-full">{info.getValue() || emptyVal}</span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.dateOfBirth, {
          id: "dob",
          header: "DOB",
          cell: (info) => (
            <div className="text-center whitespace-nowrap w-[135px] truncate line-clamp-1">
              <span className="w-full">
                {formatDateSlash(info.getValue()) || emptyVal}
              </span>
            </div>
          ),
        }),
        columnHelper.accessor((row) => row.dateOfBirth, {
          id: "age",
          header: "Age",
          cell: (info) => (
            <div className="text-center w-[135px] truncate line-clamp-1">
              <span className="w-full">
                {info.getValue() ? calculateAge(info.getValue()) : emptyVal}
              </span>
            </div>
          ),
        }),
      ],
    }),

    // Summer weeks Section
    // **Dynamic Summer Weeks Columns + Total Present/Absent Weeks**
    columnHelper.group({
      id: "summerWeeks",
      header: () => (
        <span className="font-medium">
          {formatFullYearOnly(centerSetup?.summerProgram?.summerStartDate)} -
          Summer Weeks
        </span>
      ),
      columns: handleSummerWeeksColumns(),
    }),
    // Activity Section
    columnHelper.group({
      id: "activity",
      header: () => <span className="font-medium">Activity</span>,
      columns: handleActivitiesColumns(),
    }),
  ];

  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });

  // Function to determine if a cell is the last in its column group
  const isLastCellInGroup = (cellId: string) => {
    const columnGroups = [
      "studentDetails",
      "parentDetails",
      "dates",
      "comments",
      "transition1",
      "transition2",
      "transition3",
    ];
    for (const group of columnGroups) {
      const columnsInGroup =
        columns.find((col) => col.id === group)?.columns || [];
      if (
        columnsInGroup.length > 0 &&
        columnsInGroup[columnsInGroup.length - 1].id === cellId
      ) {
        return true;
      }
    }
    return false;
  };

  // Function to check if cell is in student details section
  const isStudentDetailsCell = (cellId: string) => {
    return (
      cellId === "studentFirstName" ||
      cellId === "studentLastName" ||
      cellId === "dob" ||
      cellId === "age"
    );
  };

  return (
    <div className="overflow-x-auto relative">
      <table className="min-w-full border-collapse bg-white">
        <thead className="sticky top-0 z-10 bg-white">
          {table.getHeaderGroups().map((headerGroup) => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map((header) => {
                // Apply special styling to group headers
                const isGroupHeader = header.depth === 1;
                const isStudentDetailsHeader = basicInfoColsId.includes(
                  header.column.id
                );

                // Determine if this header is in the Student Details section
                const isStudentDetailsGroupHeader =
                  header.column.id === "studentDetails";

                // Determine if this header is one of the columns in the Student Details section
                const isStudentDetailsColumnHeader = isStudentDetailsCell(
                  header.column.id
                );

                // Calculate left position for each Student Details column
                let leftPosition = "0";
                if (header.column.id === "studentLastName")
                  leftPosition = "167px";
                if (header.column.id === "dob") leftPosition = "334px";
                if (header.column.id === "age") leftPosition = "501px";

                return (
                  <th
                    key={header.id}
                    colSpan={header.colSpan}
                    className={`px-4 py-2 min-w-full text-base font-medium text-secondary border-b border-[#F2F2F2] ${
                      isLastCellInGroup(header.column.id)
                        ? "border-r border-gray-200"
                        : ""
                    } ${isStudentDetailsHeader ? "bg-[#FFFAF8]" : ""}
                    ${
                      isStudentDetailsGroupHeader ||
                      isStudentDetailsColumnHeader
                        ? `sticky z-20 bg-[#FFFAF8] shadow-sm`
                        : ""
                    }
                    ${
                      isStudentDetailsColumnHeader
                        ? `left-[${leftPosition}]`
                        : isStudentDetailsGroupHeader
                        ? "left-0"
                        : ""
                    }`}
                    style={{
                      left:
                        isStudentDetailsColumnHeader ||
                        isStudentDetailsGroupHeader
                          ? leftPosition
                          : "auto",
                    }}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext()
                        )}
                  </th>
                );
              })}
            </tr>
          ))}
        </thead>
        <tbody>
          {table.getRowModel().rows.map((row, rowIndex) => (
            <tr
              key={row.id}
              className={rowIndex % 2 === 0 ? "bg-white" : "bg-gray-50"}
            >
              {row.getVisibleCells().map((cell) => {
                // Check if this cell is the last in its column group to add right border
                const needsRightBorder = isLastCellInGroup(cell.column.id);
                const isStudentCell = isStudentDetailsCell(cell.column.id);

                // Calculate the background color based on row index for student details cells
                const studentCellBgColor =
                  rowIndex % 2 === 0 ? "bg-[#FFFAF8]" : "bg-[#FFF5F0]";

                // Calculate position for each Student Details cell
                let leftPosition = "0";
                if (cell.column.id === "studentLastName")
                  leftPosition = "167px";
                if (cell.column.id === "dob") leftPosition = "334px";
                if (cell.column.id === "age") leftPosition = "501px";

                return (
                  <td
                    key={cell.id}
                    className={`px-4 py-2 min-w-full text-base text-secondaryVariant border-b border-[#F2F2F2] ${
                      needsRightBorder ? "border-r border-[#F2F2F2]" : ""
                    } ${isStudentCell ? studentCellBgColor : ""} ${
                      isStudentCell ? `sticky z-10 shadow-sm` : ""
                    }`}
                    style={{
                      left: isStudentCell ? leftPosition : "auto",
                    }}
                  >
                    {flexRender(cell.column.columnDef.cell, cell.getContext())}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default SummerRosterTable;
