import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { ccfRoutes } from "../../ccfWebsite_module/routes/routes";
import { STORAGE_KEYS, USER_ROLES } from "../enums/sharedEnums";
import { adminRoutes } from "../../super_admin_module/routes/routes";

interface Props {
  role?: string;
}
const ProtectedRoute: React.FC<Props> = ({ role }) => {
  const isAuthenticated = !!localStorage.getItem(STORAGE_KEYS.USER);
  const storedRole = localStorage.getItem(STORAGE_KEYS.ROLE);

  if (!isAuthenticated) {
    localStorage.setItem(STORAGE_KEYS.PATH_NAME, window.location.pathname);
    return <Navigate to={ccfRoutes.home} />;
  }

  if (!storedRole) {
    return <Navigate to={ccfRoutes.home} />;
    // switch (storedRole) {
    //     case USER_ROLES.CENTER:
    //         return <Navigate to={sharedRoutes.dashboard} />;
    //     case USER_ROLES.PARENT:
    //         return <Navigate to={profferRoutes.proffer_list} />;
    //     default:
    //         // return <Navigate to={profferRoutes.home} />;
    //         break;
    // }
  }

  if (
    storedRole === USER_ROLES.SUPER_ADMIN &&
    !window.location.pathname.includes("/admin")
  ) {
    return <Navigate to={adminRoutes.dashboard} />;
  }

  // If user is not super admin but trying to access admin routes, redirect to home
  if (
    storedRole !== USER_ROLES.SUPER_ADMIN &&
    window.location.pathname.includes("/admin")
  ) {
    return <Navigate to={ccfRoutes.home} />;
  }
  if (role && role !== storedRole) {
    return <Navigate to={ccfRoutes.home} />;
  }

  return <Outlet />;
};

export default ProtectedRoute;
