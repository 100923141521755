import { apiForay } from "../../utils/axios";
import {
  EducationalInformation,
  IEmployeeInfo,
  IStaffCreate,
  IStaffQueryParams,
  PersonalInformation,
  ProfessionalInformation,
  UploadedDocuments,
} from "../types/IStaff";

interface UpdateStaffDto {
  personalInformation?: PersonalInformation;
  educationalInformation?: EducationalInformation;
  professionalInformation?: ProfessionalInformation;
  uploadedDocuments?: UploadedDocuments;
}

const createStaff = async (reqBody: IStaffCreate) => {
  try {
    const res: any = await apiForay.post(`/staff-setup`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't create staff setup");
  } catch (err) {
    console.log("error: ", err);
  }
};

const createProspectiveStaff = async (reqBody: IStaffCreate) => {
  try {
    const res: any = await apiForay.post(`/staff-setup/prospective`, reqBody);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't create prospective staff setup");
  } catch (err) {
    console.log("error: ", err);
  }
};

const getStaff = async (params?: IStaffQueryParams) => {
  try {
    const searchParams = new URLSearchParams();

    if (params) {
      params.jobTitle.forEach((title) => {
        searchParams.append("jobTitle", title);
      });
      searchParams.append("session", params.session);
    }

    const res: any = await apiForay.get(
      `/staff-setup?${searchParams.toString()}`
    );
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't create prospective staff setup");
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateStaff = async (
  staffId: string,
  body:UpdateStaffDto
) => {
  try {
    const res: any = await apiForay.put(`staff-setup/${staffId}`, body);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't update staff");
  } catch (err) {
    console.log("error: ", err);
  }
};

const updateProspectiveStaffStatus = async (staffId: string) => {
  try {
    const res: any = await apiForay.put(`staff-setup/${staffId}/current`);
    if (res?.ok) {
      return { data: res?.data, ok: true };
    }
    throw new Error("Couldn't update staff status");
  } catch (err) {
    console.log("error: ", err);
  }
};

export const staffSvc = {
  createStaff,
  createProspectiveStaff,
  getStaff,
  updateStaff,
  updateProspectiveStaffStatus,
};
