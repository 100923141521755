import React from "react";
import EssentialCharacterCard from "./EssentialCharacterCard";

const SetupInstruction = () => {
  return (
    <>
      <div className="mt-3 mb-6">
        <div>
          <EssentialCharacterCard />
          <div>
            <h2 className="text-2xl text-secondary font-semibold mb-3">
              Classroom-Essentials Setup
            </h2>

            <p className="text-lg text-secondary mb-2">
              This setup step will automate many of the daily student movements and other classroom activities.{" "}
            </p>
            <p className="text-base text-secondary">
              The order in which you setup your classrooms is{" "}
              <strong>VERY IMPORTANT.</strong>
            </p>
            <ol className="list-decimal text-secondary text-base ps-5 max-w-[1096px]">
              <li>
                You <strong>MUST</strong> start with the youngest classroom.
              </li>
              <li>
                You <strong>MUST</strong> create the next classroom in sequence.{" "}
              </li>
              <li>There can not be overlap between classroom age groups.</li>
              <li>There can not be gaps between classroom age groups.</li>
              <li>
                You may enter classroom age group in months, years or days. The
                application will automatically use days for increased precision.
              </li>
              {/* <li>
            You <strong>MUST</strong> complete basic information for all
            classroom <strong>BEFORE</strong> moving to the next step.
          </li> */}
              <li>
                <strong> EXAMPLE:</strong> Your center has two classrooms:{" "}
                <strong>0-6 months</strong> and{" "}
                <strong>6 months - 1 year.</strong>
                <div className="text-base font-bold text-secondary">
                  <span className="text-successAlert block">
                    {" "}
                    Correct: Classroom 1 - 0-180 days. Classroom 2 - 181 Days to
                    365 Days
                  </span>
                  <p>
                    {" "}
                    <span className="text-dangerAlert">Wrong:</span> Classroom 1
                    - 0-180 days. Classroom 2 -
                    <span className="text-dangerAlert">178</span> Days to 365
                    Days <span className="text-dangerAlert">(Overlap)</span>{" "}
                    <p>
                      <span className="text-dangerAlert">Wrong:</span> Classroom
                      1 - 0-180 days. Classroom 2 -{" "}
                      <span className="text-dangerAlert">184</span> Days to 365
                      Days
                      <span className="text-dangerAlert"> (Gap)</span>
                    </p>
                  </p>
                </div>
              </li>
              <li>
                Please make sure you add <strong>ALL</strong> your classrooms
                below.
                <span className="block">
                  a. All <strong>Age-Contiguous</strong> classrooms should be
                  added in Section 1.
                </span>
                <span className="block">
                  b. Please do <strong>NOT</strong> add Before/After (School
                  Age) or Summer Programs here. They have already been added in
                  Section 2.
                </span>
                <span className="block">
                  c. Once you have added your last classroom in{" "}
                  <strong>Section 1</strong>, you will get a chance to assign a
                  transition classroom to the last classroom after you click
                  save.
                </span>
                <span className="block">
                  d. Please note that if you have more than one classroom for
                  any age group, you will need to split that age group into two
                  age groups. For example, if you have two classrooms for 2 year
                  olds, then the first classroom will be{" "}
                  <strong>24 months – 30 months</strong>, and the second
                  classroom will be <strong>31 months - 35 months.</strong>
                </span>
                <span className="block">
                  e. You can also split age groups in{" "}
                  <strong>Before/After</strong> and Summer Program if you have
                  two or more classrooms for either program.
                </span>
              </li>
            </ol>
          </div>
        </div>
      </div>
    </>
  );
};

export default SetupInstruction;
