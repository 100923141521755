import React, { useState } from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { FaChevronDown } from "react-icons/fa";

const DropdownWithinRadioInput = () => {
  const [selectedOption, setSelectedOption] = useState("per2week"); // Default selection
  const [showCustomInput, setShowCustomInput] = useState(false); // State to toggle custom input

  return (
    <Dropdown autoClose="outside">
      <Dropdown.Toggle
        variant="success"
        id="dropdown-basic"
        className="border border-secondaryVariant2 rounded-lg p-3.5 w-full bg-white text-start text-secondaryVariant2 flex items-center justify-between"
      >
        Per 2 Week <FaChevronDown className="w-4 h-4 text-secondaryVariant" />
      </Dropdown.Toggle>

      <Dropdown.Menu className="bg-white rounded-lg px-3 pt-4 pb-2 items-start mt-2">
        <Dropdown.Item
          as="div"
          className="px-3 py-1.5 rounded-lg hover:bg-secondaryNeutral block text-base text-secondary"
        >
          <span className="text-base text-secondary font-medium mb-2 block">
            Select Legally defined Work Hours
          </span>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-2">
              <input
                className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
                type="radio"
                name="workHours"
                value="perweek"
                checked={selectedOption === "perweek"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <label className="text-xs font-medium text-secondaryVariant">
                Per week
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
                type="radio"
                name="workHours"
                value="per2week"
                checked={selectedOption === "per2week"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <label className="text-xs font-medium text-secondaryVariant">
                Per 2 week
              </label>
            </div>
            <div className="flex items-center gap-2">
              <input
                className="w-5 h-5 border-2 border-gray-400 appearance-none rounded-full flex items-center justify-center checked:border-primary focus:outline-none checked:bg-white checked:before:content-[''] checked:before:block checked:before:rounded-full checked:before:bg-primary checked:before:w-3 checked:before:h-3 cursor-pointer"
                type="radio"
                name="workHours"
                value="monthly"
                checked={selectedOption === "monthly"}
                onChange={(e) => setSelectedOption(e.target.value)}
              />
              <label className="text-xs font-medium text-secondaryVariant">
                Monthly
              </label>
            </div>
          </div>
        </Dropdown.Item>
        <Dropdown.Item
          as="div"
          className="px-3 py-1.5 rounded-lg hover:bg-secondaryNeutral block text-base text-secondary"
        >
          40 hrs/week (Default)
        </Dropdown.Item>

        {/* Custom Input Section */}
        <Dropdown.Item
          as="div"
          className="px-3 py-1.5 rounded-lg hover:bg-secondaryNeutral block text-base text-secondary"
        >
          <button
            className=""
            onClick={() => setShowCustomInput(!showCustomInput)} // Toggle visibility
          >
            Custom
          </button>

          {showCustomInput && (
            <div className="mt-2">
              <input
                type="text"
                placeholder="Enter Hour/Numbers"
                className="py-3.5 px-3 rounded-lg border border-secondaryVariant2 mb-3 w-full"
              />
              <div className="flex items-center gap-3">
                <button className="text-lg text-secondary border border-secondary rounded-lg px-4 py-1 hover:bg-secondary hover:text-white">
                  Save
                </button>
                <button
                  className="text-lg text-secondary border border-secondary rounded-lg px-4 py-1 hover:bg-secondary hover:text-white"
                  onClick={() => setShowCustomInput(false)} // Hide input when clicking Cancel
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default DropdownWithinRadioInput;
