import { useMutation, useQueryClient } from "@tanstack/react-query";
import { showErrorToast, showSuccessToast } from "../../shared/utils/toast";
import { taskGenerationSvc } from "../services/taskgeneration.service";
import { TaskGenerationQueryKeyString } from "../enums/taskgenerationQueryKey.enum";
import { TaskUpdate } from "../types/ITaskGeneration.interface";

export const useUpdateTasks = () => {
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: ({ reqBody }: { reqBody: { tasks: TaskUpdate[] } }) =>
      taskGenerationSvc.updateTasks(reqBody),
    onError: (err) => {
      console.error("error:", err);
    },
    onSuccess: (res) => {
      if (res?.ok) {
        showSuccessToast("Task updated successfully");
        queryClient.invalidateQueries({
          queryKey: [TaskGenerationQueryKeyString.Tasks],
        });
        queryClient.refetchQueries({
          queryKey: [TaskGenerationQueryKeyString.DashboardTasks],
        });
      } else {
        showErrorToast("Couldn't update task");
      }
      // queryClient.invalidateQueries({
      //     queryKey: [TaskGenerationQueryKeyString.Tasks],
      // });
    },
  });
};
