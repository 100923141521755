import React from "react";

interface IProps {
  handleUpgradePlan: () => void;
}

const ActivePlan1: React.FC<IProps> = ({ handleUpgradePlan }) => {
  return (
    <>
      <div className="bg-white rounded-2xl border border-secondaryVariant2 p-6 mb-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-lg text-secondary font-semibold">
            Active Plan 1
          </h3>
          {/* <button className="text-base text-dangerAlert underline">
            Cancel
          </button> */}
        </div>
        <div className="flex items-center gap-6">
          {" "}
          <h3 className="text-[28px] text-primary font-semibold mb-2">
            Primary{" "}
            {/* <span className="font-normal text-secondary">(Monthly)</span> */}
          </h3>
          <button
            onClick={handleUpgradePlan}
            className="text-lg text-statusColor underline"
          >
            Upgrade plan
          </button>
        </div>
      </div>
    </>
  );
};

export default ActivePlan1;
