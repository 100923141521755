import { useState } from "react";
import { CiSearch } from "react-icons/ci";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Breadcrumb from "../../hr-setup_modules/components/Breadcrumb";
import DashboardWrapper from "../../../shared/components/layout/DashboardWrapper";
import ForayTabs from "../../components/forayTabs/ForayTabs";
import EssentialsClassroomTable from "../../components/tables/EssentialsClassroomTable";
import { forayRoutes } from "../../routes/routes";
import { IClassroomSetup } from "../../types/IClassroomSetup.interface";
import { useClassrooms } from "../../hooks/useClassrooms";

const EssentialClassroomList = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchQuery, setSearchQuery] = useState<string>("");

  const breadcrumbItems = [
    { label: "Foray", link: forayRoutes.foray_dashboard },
    { label: "Classroom-Essentials", isCurrent: true },
  ];
  const handleSetupNewEssentialClassroom = () => {
    navigate(
      forayRoutes?.essential_classroom_setup + `?pr=${location.pathname}`
    );
  };

  const { data: classroomData } = useClassrooms(true);

  const handleNumberToDisableTab = () => {
    if (classroomData?.classrooms?.length === 0) {
      return 1;
    }
    if (classroomData?.hasClassroomWithUnfilledDetails) {
      return 2;
    } else return 3;
  };

  const filteredClassrooms = searchQuery
    ? classroomData?.classrooms?.filter(
        (classroom) =>
          classroom?.classroomName
            ?.toLowerCase()
            .includes(searchQuery.toLowerCase()) ||
          classroom?.nickName?.toLowerCase().includes(searchQuery.toLowerCase())
      )
    : classroomData?.classrooms ?? [];

  return (
    <>
      <DashboardWrapper>
        <div className="px-6 py-5 bg-secondaryNeutral">
          <div className="flex items-center justify-between">
            <Breadcrumb items={breadcrumbItems} />
            <div className="flex items-center gap-4">
              <Link
                to={forayRoutes.foray_dashboard}
                className="text-base text-white font-medium px-4 py-3 rounded bg-primary"
              >
                Go to Foray Dashboard
              </Link>
              <Link
                to={forayRoutes.enrollment_tracking}
                className="text-base text-white font-medium px-4 py-3 rounded bg-secondary"
              >
                Go to Enrollment Tracking{" "}
              </Link>
            </div>
          </div>{" "}
          <ForayTabs
            stepNumber={handleNumberToDisableTab()}
            hasClassrooms={(classroomData?.classrooms?.length as number) > 0}
          />
          <button
            className="btnPrimary flex items-center gap-2 max-w-[310px] mb-9"
            onClick={handleSetupNewEssentialClassroom}
          >
            {(classroomData?.classrooms?.length as number) > 0
              ? "Modify/Add Classroom"
              : "Setup Classroom-Essentials"}
          </button>
          <div className="bg-white p-3 rounded-xl flex items-center justify-between mb-6">
            <h2 className="text-2xl text-secondary font-semibold">
              Classroom-Essentials
            </h2>
            <div className="relative">
              <input
                type="search"
                placeholder="Search Classroom"
                className="border-[#CED2DA] border rounded-lg py-2 ps-3 pe-8"
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <span className="absolute right-2 top-3">
                <CiSearch className="w-5 h-5 text-secondaryVariant2" />
              </span>
            </div>
          </div>
          <EssentialsClassroomTable
            classrooms={filteredClassrooms as IClassroomSetup[]}
            allClassrooms={classroomData?.classrooms as IClassroomSetup[]}
          />
          {/* <div className="flex items-center justify-between mb-7">
                        <h2 className="text-2xl text-secondary font-semibold">
                            Essential Classroom Information
                        </h2>
                        <div className="flex items-center text-statusColor">
                            Modify
                        </div>
                    </div> */}
          {/* <EssentialClassroomListTable /> */}
          {/* <AddNew
                        buttonText="Detailed Classrooms Setup"
                        route={"#" + `?pr=${location.pathname}`}
                    /> */}
        </div>
      </DashboardWrapper>
    </>
  );
};

export default EssentialClassroomList;
