import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import eventSelector from "web-app-library";
import { clientID } from "../../constants/environment.constants";
import { STORAGE_KEYS, USER_ROLES } from "../../enums/sharedEnums";
import { fetchAccountInfo, getTokens } from "../../services/auth.service";
import { profferRoutes } from "../../../proffer_module/routes/routes";
import { ccfRoutes } from "../../../ccfWebsite_module/routes/routes";
import { adminRoutes } from "../../../super_admin_module/routes/routes";

const LoaderPage = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const authCode = urlParams.get("authCode");
  const rememberMe = urlParams.get("remember_me") || "";
  localStorage.setItem(STORAGE_KEYS?.REMEMBER_ME, rememberMe);
  const navigate = useNavigate();
  // const checkRole = localStorage.getItem(STORAGE_KEYS.ROLE)
  // console.log("check role: ", checkRole)

  const getRoles = async () => {
    const path = localStorage.getItem(STORAGE_KEYS.PATH_NAME) || ""
    // if (!path) return
    const res = await fetchAccountInfo();
    if (res?.ok) {
      // Check if user is super admin
      
      
      if (localStorage.getItem(STORAGE_KEYS?.ROLE) === USER_ROLES.SUPER_ADMIN) {
        // Redirect super admin to admin dashboard
        navigate(adminRoutes.dashboard);
      } else {
        // Redirect other users to their previous path or home
        navigate(path || ccfRoutes.home);
      }
      
      localStorage.removeItem(STORAGE_KEYS?.PATH_NAME);
      return;
    }
  };
  // useEffect(() => {
  //   if (!role) return
  //   if (role === USER_ROLES.PARENT) {
  //     navigate(profferRoutes.proffer_list)
  //   } else if (role === USER_ROLES.CENTER) {
  //     navigate(profferRoutes.dashboard)
  //   } else {
  //     navigate(profferRoutes.home)
  //   }


  // }, [role])

  const handleSignIn = async () => {
    if (!authCode) return;
    const res: any = await getTokens(rememberMe, clientID, authCode);
    if (res?.ok) {

      // const socketRes = eventSelector(
      //   "http://localhost:3000",
      //   res?.data?.access_token
      // );
      // // console.log("socket in loader: ", socketRes);
      // if (socketRes) {
      //   // navigate(path || ccfRoutes.home);
      //   // localStorage.removeItem(STORAGE_KEYS?.PATH_NAME);
      // }
    }
  };

  useEffect(() => {
    const accessToken = localStorage.getItem(STORAGE_KEYS.ACC_TOKEN);
    if (accessToken) {
      getRoles();
    };
  }, [localStorage.getItem(STORAGE_KEYS.ACC_TOKEN)]);;

  useEffect(() => {
    // const path = localStorage.getItem(STORAGE_KEYS.PATH_NAME) || ""
    // if (!path) return
    handleSignIn();
  }, [localStorage.getItem(STORAGE_KEYS.PATH_NAME)]);

  return <div></div>;
};

export default LoaderPage;
