import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { MdOutlineClose } from "react-icons/md";
import { GoArrowRight } from "react-icons/go";
import {
  fetchNotifications,
  fetchNotificationsUnread,
  signOut,
} from "../../../services/auth.service";
import { RootState, store } from "../../../../../store/store";
import { setNotifications } from "../../../../../features/Notification/notificationsSlice";
import { useSelector } from "react-redux";
import { formatDateSpace } from "../../../utils/formatDate";
import useOutsideClick from "../../../hooks/useOutsideClick";
import { profferRoutes } from "../../../../proffer_module/routes/routes";
import { STORAGE_KEYS, USER_ROLES } from "../../../enums/sharedEnums";
import { sharedRoutes } from "../../../routes/routes";
import { ccfRoutes } from "../../../../ccfWebsite_module/routes/routes";
import { adventureRoutes } from "../../../../adventures_module/routes/routes";
import { medEvalRoutes } from "../../../../medEvaluation_module/routes/routes";
import { IoNotificationsOutline } from "react-icons/io5";
import { MdOutlineNotificationsActive } from "react-icons/md";

const Topbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenDropDown, setIsOpenDropDown] = useState(false);
  const [isUnread, setIsUnread] = useState(false);
  const navigate = useNavigate();
  const { Notifications } = useSelector(
    (state: RootState) => state?.notifications
  );
  const { User } = useSelector((state: RootState) => state?.auth);
  const role = localStorage.getItem(STORAGE_KEYS.ROLE);
  const notificationRef = useRef<HTMLDivElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);

  const handleSignOut = () => {
    signOut();
    toggleMenu();
    handleNav();
  };
  const handleNav = () => {
    const pathCheck = window.location.pathname.split("/")[1];
    if (pathCheck === "pr") navigate(profferRoutes.home);
    else if (pathCheck === "adv") navigate(adventureRoutes.home);
    else if (pathCheck === "ccf") navigate(ccfRoutes.home);
    else if (pathCheck === "md") navigate(medEvalRoutes.eval_center_home);
    else navigate(ccfRoutes.home);
  };

  const getNotification = async () => {
    const res = await fetchNotifications();
    if (res?.ok) {
      store.dispatch(setNotifications(res?.data));
    }
  };
  const getNotificationUnread = async () => {
    const res = await fetchNotificationsUnread();
    if (res?.ok) {
      setIsUnread(res?.data);
    }
  };

  useEffect(() => {
    if (!isOpenDropDown) return;
    getNotification();
    // getNotificationUnread();
  }, [isOpenDropDown]);

  // useEffect(() => {
  //   getNotificationUnread();
  // }, []);

  const handleViewAllClick = () => {
    setIsOpenDropDown(false);
    navigate(profferRoutes.notifications);
  };

  const handleNavToProfile = () => {
    setIsOpen(false);
    if (role && role === USER_ROLES.CENTER)
      navigate(sharedRoutes.center_profile);
    else {
      navigate(sharedRoutes.parent_profile);
    }
  };

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
  const toggleDropdown = () => {
    setIsOpenDropDown(!isOpenDropDown);
  };

  const handleMySubscription = () => {
    navigate(sharedRoutes.subscription);
  };

  useOutsideClick(menuRef, () => setIsOpen(false));
  useOutsideClick(notificationRef, () => setIsOpenDropDown(false));

  return (
    <>
      <div className="py-5 pe-[50px] flex justify-end bg-white sticky top-0 z-30">
        <div className="flex items-center gap-9">
          <div className="relative inline-block">
            <button
              onClick={toggleDropdown}
              className=""
              disabled={isOpenDropDown}
            >
              {/* <img
                src={
                  isUnread
                    ? "../../images/notification-bell-unread.svg"
                    : "<IoNotificationsOutline/>"
                }
                alt="notification bell"
              /> */}
              {isUnread ? (
                <MdOutlineNotificationsActive className="w-6 h-6" />
              ) : (
                <IoNotificationsOutline className="w-6 h-6" />
              )}
            </button>
            {isOpenDropDown && (
              <div
                ref={notificationRef}
                className="custom-scrollbar z-30 absolute right-0 w-[495px] origin-top-right bg-white border border-secondaryVariant2 rounded-md shadow-lg overflow-auto h-[420px]"
              >
                <div className="flex justify-between items-center py-4 px-6 sticky top-0 bg-white">
                  <h2 className="text-2xl text-secondary font-semibold">
                    Notification
                  </h2>
                  {/* <button
                    className="flex items-center gap-2 text-statusColor text-sm"
                    onClick={handleViewAllClick}
                  >
                    View all <GoArrowRight className="w-6 h-6" />
                  </button> */}
                </div>
                {Notifications &&
                  Notifications?.length > 0 &&
                  Notifications?.map((notif) => (
                    <div className="py-4 px-6 text-sm text-gray-700 border-b border-secondaryVariant2 ">
                      <div className="flex items-start justify-between mb-2">
                        <p className="text-base text-secondaryVariant line-clamp-2">
                          {notif?.message || ""}
                        </p>
                        {/* <button className="text-secondaryVariant">
                        <MdOutlineClose className="w-6 h-6" />
                      </button> */}
                      </div>
                      <span className="text-base text-secondaryVariant">
                        {formatDateSpace(notif?.createdAt as string) || ""}
                      </span>
                    </div>
                  ))}
              </div>
            )}
          </div>

          <div className="relative" id="dropdown-menu">
            <div className="inline-flex items-center overflow-hidden ">
              <button
                id="menu-toggle-button"
                className="flex items-center gap-3 text-base text-secondary font-medium"
                onClick={toggleMenu}
              >
                <img
                  className="rounded-full w-9 h-9 border-2 border-primary object-cover"
                  src={
                    User?.profilePictureUrl ||
                    "https://a360csastorage.blob.core.windows.net/childcare/c5c02b9f-6960-4796-9779-23d131f93a3d-fileName-empty-profile-img.png"
                  }
                  alt="profile avatar"
                />
                <span className="max-w-44 truncate">{User?.name}</span>

                <span className="sr-only">Menu</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6"
                  viewBox="0 0 20 20"
                  fill="currentColor"
                >
                  <path
                    fillRule="evenodd"
                    d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                    clipRule="evenodd"
                  />
                </svg>
              </button>
            </div>

            {isOpen && (
              <div
                ref={menuRef}
                id="menu-items"
                className="absolute end-0 z-30 mt-2 w-max rounded-md border border-gray-100 bg-white shadow-lg"
                role="menu"
              >
                <div className="p-4 flex items-center gap-3">
                  <div>
                    <div className="flex justify-center">
                      <img
                        src={
                          User?.profilePictureUrl ||
                          "https://a360csastorage.blob.core.windows.net/childcare/c5c02b9f-6960-4796-9779-23d131f93a3d-fileName-empty-profile-img.png"
                        }
                        alt="Profile"
                        className="w-9 h-9 rounded-full object-cover"
                      />
                    </div>
                    <p className="text-sm font-bold text-secondary text-center max-w-44 truncate">
                      {User?.name}
                    </p>
                    <p className="text-sm text-gray-500 text-center">
                      {User?.email}
                    </p>
                  </div>
                </div>
                <div className="border-t border-gray-100"></div>
                <div className="p-2">
                  <button
                    id="view-storefront"
                    className="block rounded-lg w-full text-start px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary"
                    role="menuitem"
                    onClick={handleNavToProfile}
                  >
                    Go to Profile
                  </button>
                  {role && role === USER_ROLES.CENTER && (
                    <button
                      id="view-storefront"
                      className="block rounded-lg w-full text-start px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary"
                      role="menuitem"
                      onClick={handleMySubscription}
                    >
                      My Subscriptions
                    </button>
                  )}
                  <button
                    id="view-storefront"
                    className="block rounded-lg w-full text-start px-4 py-2 text-sm text-secondary hover:bg-gray-50 hover:text-primary"
                    role="menuitem"
                    onClick={handleSignOut}
                  >
                    Log Out
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Topbar;
