import MainWrapper from "../../components/layout/MainWrapper";

const CompleteCenterRegistration = () => {
  return (
    <MainWrapper>
      <div className="container min-h-screen flex flex-col justify-center items-center text-center mx-auto px-4">
        <h2 className="text-2xl font-semibold text-secondary mb-4">
          Please Complete Your Registration
        </h2>
        <p className="text-lg text-secondaryVariant max-w-lg mb-4">
          Please check your inbox and follow the link to complete your
          registration.
        </p>
        <p className="text-lg text-secondaryVariant max-w-lg">
          <strong>Note:</strong> Please use the same link we have sent you to
          verify your email.
        </p>
      </div>
    </MainWrapper>
  );
};

export default CompleteCenterRegistration;
